import React, { useState } from "react";

import { Card, IconButton, TextField } from "@material-ui/core";
import { Search } from "@material-ui/icons";

import { transform } from "ol/proj.js";

import "./LocationSearchbar.css"

export default function LocationSearchbar (props) {
  const [searchText, setSearchText] = useState("");

  const handleTextChange = (event) => {
    setSearchText(event.target.value);
  }

  const checkKeyPress = (e) => {
    if (e.key ==='Enter' || e.key ==='NumpadEnter' )
    {
        e.preventDefault();
        searchLocation();
    }
  }

  const searchLocation = (event) => {
    fetch("https://dev.virtualearth.net/REST/v1/Locations?query=" + encodeURIComponent(searchText) + "&key=AodaDoXgognX4vhPqaouMx44frx2G9a5Swio1r9aGK0iOiUNyYu9jnqpHMT0_1hL",
      {
        method: 'GET', 
        headers: {
          'Content-Type': 'application/json'
        }
      }
    ).then((response) => {
      return response.blob();
    }).then((blob) => {
      var reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = function () {
        try {
          var base64data = reader.result;
          const json = atob(base64data.substring(29));
          const result = JSON.parse(json);
          var lat = result.resourceSets[0].resources[0].geocodePoints[0].coordinates[0];
          var long = result.resourceSets[0].resources[0].geocodePoints[0].coordinates[1];
          var addressType = result.resourceSets[0].resources[0].entityType;
          window.map.getView().setCenter(transform([long, lat], 'EPSG:4326', 'EPSG:3857'));
            window.map.getView().setZoom(getCorrectZoomLevel(addressType));
        }
        catch (error) {
          console.log('location search error: ', error);
        }
      }
    });
  };

  const getCorrectZoomLevel =  (addressType)=> {
    switch (addressType) {
      case 'PopulatedPlace':
        return 12;
      case 'neighborhood':
        return 17;
      case 'route':
        return 17;
      case 'sublocality':
        return 16;
      case 'locality':
        return 14;
      case 'postal_code':
        return 14;
      case 'administrative_area_level_3':
        return 14;
      case 'administrative_area_level_2':
        return 10;
      case 'administrative_area_level_1':
            return 7;
        case 'Address':
            return 20;

      default:
        return 20;
    }
  };

  return (
    <Card id="locationSearchPanel">
      <TextField
        placeholder="Search for a location"
        defaultValue=""
        id="locationSearchField"
        onKeyDown={checkKeyPress}
        onChange={handleTextChange} />
      <IconButton
        onClick={searchLocation}
      >
        <Search />
      </IconButton>
    </Card>
  )
}