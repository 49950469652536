import React, { useState } from "react";
import { useSelector } from "react-redux";

// @material-ui/core components
import { CardContent, Collapse, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@material-ui/core";
import EditIcon from '@material-ui/icons/Edit';

// custom components
import Card from "../../components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import ExpandCollapseIcon from "../ExpandCollapseIcon.jsx";

import { CO2Units, CO2AcreYearUnits, CO2DollarUnits, CO2YearUnits, formatDollars, formatResult } from "helpers/report.js";

export default function GHGOverallReportTable(props) {
    const { classes, reportData, handleFundsClick } = props;
    const user = useSelector(state => state.user);

    //Todo: make a base class for all these report page cards to inherit from that handles the collapse functionality?
    // would take a title and children as props
    const [collapsed, setCollapsed] = useState(false);

    function toggleCollapse() {
        setCollapsed(!collapsed);
    }

    return (
        <Card id="ghgOverallReport">
            <CardHeader style={{ backgroundColor: '#cc66ff' }} onClick={toggleCollapse}>
                <ExpandCollapseIcon collapsed={collapsed} />
                <Typography align="center" variant="h5">
                    SWEEP Project Key Outputs
                </Typography>
            </CardHeader>
            <Collapse in={!collapsed}>
                <CardContent>
                    <Table>
                        <TableHead className={classes.tableHeader}>
                            <TableRow>
                                <TableCell>
                                    Results
                                </TableCell>
                                <TableCell>
                                    GHG Emissions
                                </TableCell>
                                <TableCell>
                                    Units
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell>
                                    Pumping GHG Benefits per Growing Season*
                                </TableCell>
                                <TableCell align="right">
                                    {formatResult(reportData.pumpingReport.yearlyGHGBenefits)}
                                </TableCell>
                                <TableCell>
                                    <CO2YearUnits />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    N2O GHG Benefits per Growing Season*
                                </TableCell>
                                <TableCell align="right">
                                    {formatResult(reportData.overallReport.convertedN2OBenefits)}
                                </TableCell>
                                <TableCell>
                                    <CO2YearUnits />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    Total GHG Benefits per Growing Season*
                                </TableCell>
                                <TableCell align="right">
                                    {formatResult(reportData.overallReport.yearlyGHGBenefits)}
                                </TableCell>
                                <TableCell>
                                    <CO2YearUnits />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    Total Before Water use*

                                </TableCell>
                                <TableCell align="right">
                                    {formatResult(reportData.waterReport.beforeWaterUse)}
                                </TableCell>
                                <TableCell>
                                    <CO2YearUnits />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    Total Water Savings*
                                </TableCell>
                                <TableCell align="right">
                                    {formatResult(reportData.waterReport.waterSavings)}
                                </TableCell>
                                <TableCell>
                                    <CO2YearUnits />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    Net GHG Benefits over Useful 10-yr Life
                                </TableCell>
                                <TableCell align="right">
                                    {formatResult(reportData.overallReport.tenYearGHGBenefits)}
                                </TableCell>
                                <TableCell>
                                    <CO2Units />
                                </TableCell>
                            </TableRow>

                           
                            <TableRow>
                                <TableCell>
                                    GHG Benefits per Acre-Year
                                </TableCell>
                                <TableCell align="right">
                                    {formatResult(reportData.overallReport.ghgBenefitsPerAcreYear)}
                                </TableCell>
                                <TableCell>
                                    <CO2AcreYearUnits />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    Total Funds Requested
                                    <IconButton onClick={handleFundsClick}><EditIcon /></IconButton>
                                </TableCell>
                                <TableCell align="right">
                                    {formatDollars(user.fundsRequested)}
                                </TableCell>
                                <TableCell>
                                    dollars
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    Total GHG Emission Reductions per Total Funds Requested
                                </TableCell>
                                <TableCell align="right">
                                    {formatResult(reportData.overallReport.emissionReductionsPerFundsRequested)}
                                </TableCell>
                                <TableCell>
                                    <CO2DollarUnits />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    *To be transferred to application
                                </TableCell>
                                
                            </TableRow>
                        </TableBody>
                    </Table>
                </CardContent>
            </Collapse>
        </Card>
    );
}