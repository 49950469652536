import React, { useEffect, useState } from "react";
import CustomInput from "../CustomInput/CustomInput.js";
import GridItem from "../Grid/GridItem.js";
import GridContainer from "../Grid/GridContainer.js";
import TextField from '@material-ui/core/TextField';

/*Not going to use redux forms.  Just going to have controls and track the state. 
Drawback here is we will need to manually update items and manually send updated items back to parent.*/
export default function EditFieldForm(props) 
{
   
    const { parentEditHandleChange, parentEditAcreChange, field } = props
    const [editfieldName, setEditFieldName] = React.useState(field.field.name);
    const [editAcreage, setAcreage] = React.useState(field.field.acreage);

  
    const handleChangedEdit = (e,field) => {
        parentEditHandleChange(e.target.value,field)
        setEditFieldName(e.target.value);
    };

    
    const handleChangedEditAcreage = (e, field) => {
       
        parentEditAcreChange(e.target.value, field)
        setAcreage(e.target.value);
  };

  return (
    <div style={{ marginTop: 10 }}>
      <GridContainer
        direction="column"
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        <GridItem xs={11} sm={11} md={11}>
          
                  <TextField
                      id="fieldName"
                      label="Field Name"
                      defaultValue={editfieldName}
                      onChange={(event) => handleChangedEdit(event, field)}
                  />

<CustomInput
            labelText="Acreage"
            inputProps={{
                value: editAcreage,
                type:"number",
              //disabled: true,
                onChange: (event) => handleChangedEditAcreage(event, field),
            }}
            id="acreage"
            formControlProps={{
              fullWidth: true,
            }}
          />

          {field.field.apns && <div style={{maxWidth: 325}}>{field.field.apns.length > 1 ? "APNs:" : "APN:"} {field.field.apns.join(", ")}</div>}
        </GridItem>
      </GridContainer>
    </div>
  );
}
