import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";

import Button from "components/CustomButtons/Button";
import DynamicDialog from "components/Dialogs/Dialog";
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import { updateUser } from "../../../actions/user";


import styles from "assets/jss/material-dashboard-react/components/dialogStyle";
import { Box } from "@material-ui/core";
import SaveLoadButtons from "components/Field/SaveLoadButtons";

const useStyles = makeStyles(styles);

export default function WelcomePopup(props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const currentUser = useSelector((state) => state.user);
  const fields = currentUser.fields;

  const shouldBeOpen = () => {
    return currentUser.agopname.length === 0 &&
      (!fields || fields.length === 0) &&
      (!currentUser.preprojectpumps || currentUser.preprojectpumps.length === 0) &&
      (!currentUser.postprojectpumps || currentUser.postprojectpumps.length === 0);
  }

  const [dialogOpen, setDialogOpen] = useState(shouldBeOpen());
  const [agrOpName, SetAgrOpName] = useState("");

  useEffect(() => {
    setDialogOpen(shouldBeOpen());
  }, [currentUser]);

  const startButton = (
    <Button
      id={'startButton'}
          onClick={() => saveAgOperation()}
      label={"Start!"}
      color={"primary"}
    >
      {"Start!"}
    </Button>
    );

    const saveAgOperation = () => {
        if (document.getElementById("agrOperName").value != "") {
            var currentUserAgOp = Object.assign({}, currentUser);
            if (currentUserAgOp) {

                currentUserAgOp.agopname = agrOpName;
                dispatch(updateUser(currentUserAgOp));

            }
            setDialogOpen(false);
            document.getElementById("agopNameReq").style.display = "none";
        }
        else {
            document.getElementById("agopNameReq").style.display = "block"
        }
    };
    const handleAgOpernChange=(event) => {
        SetAgrOpName(event.target.value);
       
        

    };
    
    return (
      
    <DynamicDialog
      open={dialogOpen}
      buttons={[startButton]}
      title={"Information"}
    >
      <div style={{fontSize: "0.9rem"}}>
        <h3 className={classes.primaryColor}>Welcome to the State Water Efficiency and Enhancement Programs (SWEEP) Project Assessment Tool.</h3>
        <p>
          SWEEP is a grant program designed to help California farmers and ranchers estimate the water and greenhouse
          gas (GHG) impacts of farm-level irrigation projects. <br/> Users should have the following
          items on hand and accessible before completing the tool:
        </p>
        <ul>
          <li>Project location information</li>
          <li>Current and future crop types, and optionally acreage and predominant soil type</li>
          <li>Current and future irrigation methods</li>
          <li>
            Information on irrigation pumps that are part of the project, including impacted pumps'
            Overall Pumping Efficiency (OPE), well depth, and motor horsepower
          </li>
          <li>Annual energy use information for pumps associated with the project</li>
        </ul>
        <p>
          Users will need undergo several steps to complete this assessment tool:
        </p>
        <ol>
          <li>Identify the field(s) that the proposed project will impact</li>
          <li>Identify what the current and future crops will be on the impacted field</li>
          <li>Input the current and future irrigation system type for each field</li>
          <li>Identify the location and pump specification of the pumps that are part of this project</li>
          <li>Input the energy use of the pumps that are impacting this project</li>
          <li>Complete, save, and download the project report</li>
        </ol>
      </div>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        style={{ width: 920 }}
      >
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="flex-start"
          alignItems="center"
          style={{ width: '100%' }}
        >
          <InputLabel htmlFor="agopName" style={{color:"black"}} >Enter the name of your agricultural operation to begin:</InputLabel>
          <TextField
            id="agrOperName"
            label=""
            defaultValue={agrOpName}
            variant="outlined"
            color="primary"
            width="500px"
            onChange={handleAgOpernChange}
            style={{ width: "500px", marginLeft: 10 }}
          />
          <InputLabel htmlFor="agopNameRequired" style={{ color: "red", marginLeft: 5 }} > * </InputLabel>
        </Box>

        <Box
          display="flex"
          flexDirection="row"
          justifyContent="flex-end"
          alignItems="center"
          style={{ width: '100%', marginTop: 5 }}
        >
          <InputLabel
            htmlFor="agopNameMandatory"
            id="agopNameReq"
            style={{ color: "red", fontStyle:"italic", marginRight: 35, display: 'none' }}
          >
            Please enter the name of your agricultural operation to continue
          </InputLabel>
        </Box>

        <Box
          display="flex"
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
          style={{ width: '100%', marginTop: 10, marginLeft: 30 }}
        >
          <InputLabel style={{color:"black", marginRight: 15}} htmlFor="">Or, </InputLabel>
          <SaveLoadButtons saveText={null} />
        </Box>
      </Box>
    </DynamicDialog>
  );
}