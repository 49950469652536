import React, { Component } from "react";
import * as helpers from "../helpers/helpers";
import DynamicDialog from "./Dialogs/Dialog";
import "./PropertyReportClick.css";
import { unmountComponentAtNode } from 'react-dom';
class PropertyReportClick extends Component {
    constructor(props) {
        super(props);
        // LISTEN FOR MAP TO MOUNT
        window.emitter.addListener("mapLoaded", () => this.onMapLoad());

        this.onMapLoad = this.onMapLoad.bind(this);
        this.convertHTMLTable = this.convertHTMLTable.bind(this);

        this.state = {
            propInfo: null,
            feature: null,
            showDialog: false,
            dialogHtml: "",
            parsedHtml: {} // holds parsed htm info as key/val pairs using column keys and row values
        };
    }

    onMapLoad() {
        // LISTEN FOR MAP CLICK
        window.map.on("singleclick", evt => {
            var root = document.getElementById("portal-root");
            unmountComponentAtNode(root);
            // SCALE
            if (helpers.getMapScale() > 20000) return;
        });

        // LISTEN FOR CALLS
        let that = this;
        window.emitter.addListener("showPropertyReport", coords =>
            this.onPropertyEmitter(coords, that)
        );
    }

    /*
      parse html table response
       strip tags from column and keys, then turn column and keys into object of key value pairs
       @returns: an object of key value pairs, or {}
    */
    convertHTMLTable(html) {
        let obj = {};
        if (html.length > 0) {
            let tableRows = html.split("<tr"); // an array with header info in first spot. The next few columns hold columns and rows

            if (tableRows.length >= 3) {
                let thArr = tableRows[1].split("<th>"); // will be an array of column names stripped of the html open tags
                let tdArr = tableRows[2].split("<td>"); // will be an array of row values stripped of the html open tags

                if (thArr.length == tdArr.length) {
                    for (let i = 0; i < thArr.length; i++) {
                        if (i > 0) {
                            let col = thArr[i].split("</th>");
                            let row = tdArr[i].split("</td>");
                            if (col.length > 0 && row.length > 0) {
                                obj[col[0]] = row[0];
                            }
                        }
                    }
                    //  console.log(obj);
                }
            }
        }
        return obj;
    }

    makePrettyLine(Name, Value) {
        if (Value) {
            return ("<b>" + Name + ":</b> " + Value + "</br>");
        }
        else {
            return ('');
        }
    }

    prettyFormat(objArray) {
        // console.log(objArray);
        let html = '';// "<div>";

      //  let SOILT = objArray['MUNAME']; //Soil Texture
       // html += this.makePrettyLine("Soil Texture", SOILT);

        let MUNAME = objArray['MUNAME']; //Map unit name
        html += this.makePrettyLine("Map Unit Name", MUNAME);

        let MUSYM = objArray['MUSYM']; //map unit map label
        html += this.makePrettyLine("Map Unit Label", MUSYM);


        let DRCLASSDCD = objArray['DRCLASSDCD']; //drainage class - dominant condition
        html += this.makePrettyLine("Drainage Class", DRCLASSDCD);


        let BROCKDEPMIN = objArray['BROCKDEPMIN']; //Bedrock Depth - Minimum  (if "" set to 0)
        if (BROCKDEPMIN == '') {
            BROCKDEPMIN = "0";
        }
        html += this.makePrettyLine("Minimum Bedrock Depth", BROCKDEPMIN);

        let FLODFREQMAX = objArray['FLODFREQMAX']; //Flooding Frequency - Maximum
        html += this.makePrettyLine("Maximum Flooding Frequency", FLODFREQMAX);

        return (<div
             
            dangerouslySetInnerHTML={{ __html: html }} 
                />
        

      );

      
    }
    /**
     * This method should get all layers that are visible.
     * Get those layers feature info from wmsSource.getFeatureInfoUrl
     * Display all information found for each visible layer
     */
    onPropertyEmitter = (coords, that) => {
        //console.log(coords);
        //OK so coordinates are not entirely based on things on the map, also a function of where the pan-window is looking
        //probably a combination of the loaded layer and the mouse click

        if(coords == null)
        {
            coords = new Array();
            coords.push(-13425831.684807662);
            coords.push(4419721.974711653);
        }


        if (coords) {
            if (window.map)
                window.map.getLayers().forEach(layer => {
                    let title = layer.get("title");

                    if (title)

                    //    if (layer.getVisible())  //would only show soils on click if show sols was visible
                    {
                        if (typeof layer.getSource().getFeatureInfoUrl === "function") {
                            //  console.log(layer.getSource().getFeatureInfoUrl());
                            let viewResolution = window.map.getView().getResolution();
                            let infoUrl = layer
                                .getSource()
                                .getFeatureInfoUrl(coords, viewResolution, "EPSG:3857", {
                                    INFO_FORMAT: "text/html"
                                });
                            //console.log(infoUrl)
                            let url =
                                "https://SDMDataAccess.sc.egov.usda.gov/Spatial/SDM.wms?SERVICE=WMS&VERSION=1.1.1&REQUEST=GetFeatureInfo&FORMAT=image%2Fpng&TRANSPARENT=true&QUERY_LAYERS=MapunitPoly&LAYERS=MapunitPoly&INFO_FORMAT=text%2Fhtml&X=50&Y=50&SRS=EPSG%3A3857&STYLES=&WIDTH=101&HEIGHT=101&BBOX=";
                            url += coords[0] + "," + coords[1];
                        }

                        if (title === "Soils Imagery") {
                            //the reason the "soils on click seem off" is because it's not based on the click but on the window's bounding box
                            //behavior people seem to want is a 'soils on click WHERE I CLICKED' so instead of a bbox the whole map view, fake one around the click?

                            let bb = [coords[0] - .01, coords[1] - .01, coords[0] + .01, coords[1] + .01]

                            /*      let bb = window.map
                                    .getView()
                                          .calculateExtent(window.map.getSize());*/

                            let url =
                                "https://SDMDataAccess.sc.egov.usda.gov/Spatial/SDM.wms?SERVICE=WMS&VERSION=1.1.1&REQUEST=GetFeatureInfo&FORMAT=image%2Fpng&TRANSPARENT=true&QUERY_LAYERS=mapunitpolyextended&LAYERS=MapunitPoly&INFO_FORMAT=text%2Fhtml&X=50&Y=50&SRS=EPSG%3A3857&STYLES=&WIDTH=101&HEIGHT=101&BBOX=";
                            url += bb[0] + "," + bb[1] + "," + bb[2] + "," + bb[3];

                            if (url) {
                                fetch(url)
                                    .then(function (response) {
                                        return response.text();
                                    })
                                    .then(function (html) {
                                        let parsedHtmlObj = that.convertHTMLTable(html);
                                        that.setState({
                                            showDialog: true,
                                            dialogHtml: { html },
                                            parsedHtml: parsedHtmlObj
                                        });
                                    });
                            }


                            //  console.log(bb);
                            // console.log('2',url);
                        }
                    }
                });
        } else {
            console.log("coords bad");
        }
    };

    addInfoToPopUp = (wmsURL, clickEvt = null) => {
        helpers.getJSON(wmsURL, result => {
            //  console.log(result);
            if (result.features.length === 0) return;
        });
    };

    resetDialogs = () => {
        this.setState({ showDialog: false });
        var root = document.getElementById("portal-root");
        unmountComponentAtNode(root);
    };

    render() {
        var okButton = helpers.getDialogButton("Ok", this.resetDialogs);
        var dialogButtons = [okButton];

        return (
            <DynamicDialog
                buttons={dialogButtons}
                open={this.state.showDialog}
                labelId={"proclick"}
                title={"Soils Information"}
            >
                {this.prettyFormat(this.state.parsedHtml)
                }
            </DynamicDialog>
        );
    }
}

export default PropertyReportClick;