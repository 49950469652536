import React, { useEffect } from "react";
import Box from "@material-ui/core/Box";
import { useSelector } from "react-redux";
import Button from "@material-ui/core/Button";
import GridItem from "../../../components/Grid/GridItem.js";
import GridContainer from "../../../components/Grid/GridContainer.js";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';


const styles = {
  card: {
    marginLeft: "10%",
    boxShadow: "none",
    marginTop: "unset",
  },

  preprojpumplabel: {
    width: "100px",
    marginTop: "15px",
  },
  preprojpumpAddIcon: {
    float: "left",
    padding: "unset",
    },
    paper: { minWidth: "300px" },
};

const useStyles = makeStyles(styles);


export default function SuccessPopup({ open, handleClose }) {
  const classes = useStyles();
    const currentUser = useSelector((state) => state.user);
    
    return (
        <div>
            <Dialog open={open} onClose={handleClose}  fullWidth maxWidth="xs" >
           
            <DialogContent style={{ height:"max-content"}}>
    <GridContainer
      direction="column"
      justifyContent="flex-start"
      alignItems="flex-start"
    >
      <GridItem xs={12} sm={12} md={12}>
        <Box
          component="span"
          sx={{
            "& > :not(style)": { width: "5ch" },
          }}
          noValidate
          autoComplete="off"
        >
        
                                <label htmlFor="succMsg" id="idSucessMsg">Field added! You can add additional fields by selecting a drawing tool from the toolbar on the left and drawing the field on the map.</label>
                                <br />
                                <br />
                                {((currentUser.preprojectpumps.length > 0) || (currentUser.postprojectpumps.length > 0))? <label htmlFor="pumpsMsg" id="idPumpsMsg" >We see that you have already defined pumps. If any of the pumps service this field, please click the Edit button for those pumps and add this field under the "Which fields does this pump service?"</label>:""}
               
        </Box>
      </GridItem>
      

                </GridContainer>
            </DialogContent>
            <DialogActions>

                <Button variant="contained" onClick={handleClose} color="secondary">Ok</Button>
            </DialogActions>
            </Dialog>
        </div>
  );
}
