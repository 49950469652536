import { put, call } from "redux-saga/effects";
import { receiveFieldMetadata } from "../actions/user";
import { FETCH_APN, FETCH_ASSEMBLY_DISTRICTS, FETCH_CENSUS_TRACTS, FETCH_SENATE_DISTRICTS } from "../constants/api";
import { plainPostWithBody } from "../utils/api";


export function* updateUser (action) {
  const fieldList = {};

  for (var field of action.payload.fields) {
    yield* fetchApnData(field, fieldList);

    // would these be better as arrays of arrays instead of named attributes?
    // e.g. var censusAttributes = [
    //   ['censusTracts', 'GEOID10_1'],
    //   ['isEconomicallyDisadvantaged', 'Flag_MHI_P']
    // ]
    var senateAttributes= {
      senateDistricts: 'SenateDistrictLabel'
    };
    var assemblyAttributes = {
      assemblyDistricts: 'AssemblyDistrictLabel'
    };
    var censusAttributes = {
      censusTracts: 'GEOID10_1',
      isEconomicallyDisadvantaged: 'Flag_MHI_P'
    };

    yield* fetchDistrictData(field, fieldList, senateAttributes, FETCH_SENATE_DISTRICTS);
    yield* fetchDistrictData(field, fieldList, assemblyAttributes, FETCH_ASSEMBLY_DISTRICTS);
    yield* fetchDistrictData(field, fieldList, censusAttributes, FETCH_CENSUS_TRACTS);
  }

  yield put(receiveFieldMetadata(fieldList));
}

function* fetchApnData (field, fieldList) {
  if (!field.apns) {
    const apnFormData = buildApnFormData(field.feature);

    try {
      const apnData = yield call(plainPostWithBody, FETCH_APN, apnFormData);

      if (apnData.results && apnData.results.length > 0) {
        var apns = [];
        for (var result of apnData.results) {
          apns.push(result.value);
        }

        createEntryIfNeeded(field.id, fieldList);
        fieldList[field.id].apns = apns;
      }
    } catch(error) {
      console.log('update user saga apn error: ', error);
    }
  }
}

function* fetchDistrictData(field, fieldList, attributes, apiCall) {
  var outFields = [];
  var hasAllFields = true;

  for (var attributeName in attributes)
  {
    if (field[attributeName] === undefined)
    {
      hasAllFields = false;
    }
    outFields.push(attributes[attributeName]);
  }

  if (!hasAllFields) {
    const formData = buildDistrictFormData(field.feature, outFields.join(','));

    try {
      const responseData = yield call(plainPostWithBody, apiCall, formData);

      if (responseData.features && responseData.features.length > 0) {
        for (var attributeName in attributes) {
          var values = [];
          for (var feature of responseData.features) {
            values.push(feature.attributes[attributes[attributeName]]);
          }

          createEntryIfNeeded(field.id, fieldList);
          fieldList[field.id][attributeName] = values;
        }
      }
    }
    catch (error) {
      console.log('update user saga district fetch error: ', error);
    }
  }
}

function buildApnFormData(feature) {
  const geometryFormObject = buildGeometryFormObject(feature);

  const formData = new FormData();
  formData.append('f', 'pjson');
  formData.append('geometryType', 'esriGeometryPolygon');
  formData.append('sr', 3857);
  formData.append('layers', 0);
  formData.append('tolerance', 1);
  formData.append('mapExtent', '.');
  formData.append('imageDisplay','1920,1080,96');
  formData.append('returnGeometry', false);
  formData.append('geometry', JSON.stringify(geometryFormObject));

  return formData;
}

function buildDistrictFormData(feature, outField) {
  const geometryFormObject = buildGeometryFormObject(feature);

  const formData = new FormData();
  formData.append('f', 'pjson');
  formData.append('geometryType', 'esriGeometryPolygon');
  formData.append('inSR', 3857);
  formData.append('geometry', JSON.stringify(geometryFormObject));
  formData.append('spatialRel', 'esriSpatialRelIntersects');
  formData.append('returnGeometry', false);
  formData.append('outFields', outField);

  return formData;
}

function buildGeometryFormObject (feature) {
  try {
    // when a field is first created, the feature comes through as a string instead of an object for some reason
    if (typeof feature === 'string') {
      feature = JSON.parse(feature);
    }

    const parsedGeoJson = JSON.parse(feature.featureGeoJSON);

    return ({
      "rings": parsedGeoJson.geometry.coordinates
    });
  }
  catch (exception) {
    console.log('error when building geometry form object: ', exception);
    return ({
      "rings": []
    });
  }
}

function createEntryIfNeeded (fieldId, fieldList) {
  if (!fieldList[fieldId]) {
    fieldList[fieldId] = {};
  }
}