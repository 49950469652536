import React, { useEffect } from "react";

import { Theme, useTheme } from '@material-ui/core/styles';
import Button from "@material-ui/core/Button";
import GridItem from "../../../components/Grid/GridItem.js";
import GridContainer from "../../../components/Grid/GridContainer.js";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Select, { SelectChangeEvent } from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};
function getStyles(name, personName, theme) {
    return {
        fontWeight: personName.indexOf(name) === -1
            ? theme.typography.fontWeightRegular
            : theme.typography.fontWeightMedium,
    };
}
export default function CopyFieldDetails(props) {
    const { copyFromField, copyfieldsArr, parentCopyFieldsListChange } = props
    const theme = useTheme();
    const [fieldNames, setFieldNames] = React.useState([]);
  
    let fieldName = copyFromField.field.name;
    var labelText = "Select the fields you would like to copy field " + fieldName +"  data to";
    const handleCopyFieldsChange = function (event) {
        var value = event.target.value;
        setFieldNames(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value);
        parentCopyFieldsListChange(value);
    };
    
    
    return (
        <div style={{width:"400px"} }>
          
    <GridContainer
      direction="column"
      justifyContent="flex-start"
      alignItems="flex-start"
    >
      <GridItem xs={12} sm={12} md={12}>
                
                    <InputLabel id="demo-multiple-chip-label" style={{ color:"black",marginBottom:"10px"} }>{labelText } </InputLabel>
                        <Select
                            labelId="demo-multiple-chip-label"
                            id="demo-multiple-chip"
                            multiple
                            value={fieldNames}
                            onChange={handleCopyFieldsChange}
                            input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                            renderValue={(selected) => (
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                    {selected.map((value) => (
                                        <Chip key={value} label={value} />
                                    ))}
                                </Box>
                            )}
                            MenuProps={MenuProps}
                        >
                            {copyfieldsArr.map((field) => (
                                <MenuItem
                                    key={field.name}
                                    value={field.name}
                                    style={getStyles(field.name, fieldNames, theme)}
                                >
                                    {field.name}
                                </MenuItem>
                            ))}
                        </Select>
                    
      </GridItem>
      

                </GridContainer>
          
        </div>
  );
}
