import { formatResult } from "helpers/report";
import { buildFieldSummary, buildHeader, buildOverallSection } from "./shared";

export function buildDetailedReportData(user, reportData) {
    const data = [];

    buildHeader(data, user);

    data.push([]);
    data.push([]);

    buildOverallSection(data, reportData);

    data.push([]);
    data.push([]);

    buildPumpingSection(data, user, reportData);

    data.push([]);
    data.push([]);

    buildN2OSection(data, user, reportData);

    data.push([]);
    data.push([]);

    buildWaterSection(data, user, reportData);

    data.push([]);
    data.push([]);

    buildFieldSection(data, user);

    return data;
}

function buildPumpingSection(data, user, reportData) {
    data.push([
        {
            value: 'Estimated GHG Emission Reductions from Pumping System for Proposed Project',
            fontWeight: 'bold'
        }
    ]);
    
    var count = 1;
    for (var result of user.pumpResults) {
        data.push([
            {
                value: 'Pump ' + count,
                fontWeight: 'bold'
            }
        ]);

        data.push([
            {
                value: 'Pump Name'
            },
            {
                value: formatResult(result.name, true)
            }
        ]);

        data.push([
            {
                value: 'TDH Pre-project'
            },
            {
                value: formatResult(result.tdhPre, true)
            }
        ]);
        
        data.push([
            {
                value: 'TDH Post-project'
            },
            {
                value: formatResult(result.tdhPost, true)
            }
        ]);
        
        data.push([
            {
                value: 'GHG Pre-project'
            },
            {
                value: formatResult(result.ghgPre, true)
            }
        ]);
        
        data.push([
            {
                value: 'GHG Post-project Without Fuel Change'
            },
            {
                value: formatResult(result.ghgPumpWithoutFuelChange, true)
            }
        ]);
        
        data.push([
            {
                value: 'GHG Post-project With Fuel Conversion Adjustment'
            },
            {
                value: formatResult(result.ghgPumpWithFuelChange, true)
            }
        ]);
        
        data.push([
            {
                value: 'GHG Post-project'
            },
            {
                value: formatResult(result.ghgPump, true)
            }
        ]);
        
        data.push([
            {
                value: 'Energy Savings from VFD'
            },
            {
                value: formatResult(result.energySavingsVFD, true)
            }
        ]);
        
        data.push([
            {
                value: 'GHG Savings from VFD'
            },
            {
                value: formatResult(result.ghgSavingsVFD, true)
            }
        ]);
        
        data.push([
            {
                value: 'GHG Savings from Renewable Energy'
            },
            {
                value: formatResult(result.ghgSavingsRenewableEnergy, true)
            }
        ]);
        
        data.push([
            {
                value: 'GHG Reductions Post-project'
            },
            {
                value: formatResult(result.ghgReductions, true)
            }
        ]);

        count++;
    }

    data.push([]);

    data.push([
        {
            value: 'Estimated Total GHG Emission Reductions from Pumping System',
            fontWeight: 'bold'
        }
    ]);

    data.push([
        {
            value: 'GHG Benefits per Growing Season (tonnes CO2-eq/yr)'
        },
        {
            value: formatResult(reportData.pumpingReport.yearlyGHGBenefits, true)
        }
    ]);

    const tenYearBenefits = reportData.pumpingReport.yearlyGHGBenefits * 10;
    data.push([
        {
            value: 'Net GHG Benefits over Useful Life (tonnes CO2-eq)'
        },
        {
            value: formatResult(tenYearBenefits, true)
        }
    ]);

    const totalArea = user.fields.reduce((sum, field) => sum + field.acreage, 0);
    const benefitsPerAcreYear = totalArea != 0 ? reportData.pumpingReport.yearlyGHGBenefits / totalArea : 0;
    data.push([
        {
            value: 'GHG Benefits per Acre-Year (tonnes CO2-eq/ac/yr)'
        },
        {
            value: formatResult(benefitsPerAcreYear, true)
        }
    ]);

    const benefitsPerDollar = user.fundsRequested && user.fundsRequested > 0
        ? formatResult(tenYearBenefits / user.fundsRequested, true)
        : "N/A";
    data.push([
        {
            value: 'Total GHG Emission Reductions per Total Funds Requested (tonnes CO2-eq/$)'
        },
        {
            value: benefitsPerDollar
        }
    ]);
}

function buildN2OSection (data, user, reportData) {
    data.push([
        {
            value: 'Estimated Nitrous Oxide Emission Reductions for Proposed Project',
            fontWeight: 'bold'
        }
    ]);

    var fieldCount = 1;
    for (var field of user.fields) {
        data.push([
            {
                value: 'Field ' + fieldCount,
                fontWeight: 'bold'
            }
        ]);

        data.push([
            {
                value: 'Pre-Project Emissions (lb N2O-N)',
                fontWeight: 'bold'
            }
        ]);

        var yearCount = 1;
        for (var year of field.results.beforeYearlyN2O) {
            data.push([
                {
                    value: 'Year ' + yearCount
                }
            ]);

            var cropCount = 1;
            for (var crop of year) {
                data.push([
                    {
                        value: 'Crop ' + cropCount
                    },
                    {
                        value: crop
                    }
                ]);

                cropCount++;
            }

            yearCount++;
        }

        data.push([
            {
                value: 'Post-Project Emissions (lb N2O-N)',
                fontWeight: 'bold'
            }
        ]);

        yearCount = 1;
        for (var year of field.results.afterYearlyN2O) {
            data.push([
                {
                    value: 'Year ' + yearCount
                }
            ]);

            var cropCount = 1;
            for (var crop of year) {
                data.push([
                    {
                        value: 'Crop ' + cropCount
                    },
                    {
                        value: crop
                    }
                ]);

                cropCount++;
            }

            yearCount++;
        }

        data.push([
            {
                value: 'Pre-Project Average N2O Emissions (tonnes CO2-eq)'
            },
            {
                value: field.results.beforeN2O
            }
        ]);

        data.push([
            {
                value: 'Post-Project Average N2O Emissions (tonnes CO2-eq)'
            },
            {
                value: field.results.afterN2O
            }
        ]);

        data.push([
            {
                value: 'Average Yearly N2O-N Emission Reductions (tonnes CO2-eq)'
            },
            {
                value: field.results.avgYearlyN2OBenefits
            }
        ]);

        data.push([
            {
                value: '10-Year Project Benefits (tonnes CO2-eq)'
            },
            {
                value: field.results.tenYearN2OBenefits
            }
        ]);

        fieldCount++;
    }

    data.push([]);

    data.push([
        {
            value: 'Estimated Total N2O Emissions',
            fontWeight: 'bold'
        }
    ]);

    data.push([
        {
            value: 'Pre-Project Average N2O Emissions (tonnes CO2-eq)'
        },
        {
            value: reportData.n2oReport.beforeN2O
        }
    ]);

    data.push([
        {
            value: 'Post-Project Average N2O Emissions (tonnes CO2-eq)'
        },
        {
            value: reportData.n2oReport.afterN2O
        }
    ]);

    data.push([
        {
            value: 'Average Yearly N2O-N Emission Reductions (tonnes CO2-eq)'
        },
        {
            value: reportData.n2oReport.avgYearlyN2OBenefits
        }
    ]);

    data.push([
        {
            value: '10-Year Project Benefits (tonnes CO2-eq)'
        },
        {
            value: reportData.n2oReport.tenYearN2OBenefits
        }
    ]);
}

function buildWaterSection (data, user, reportData) {
    data.push([
        {
            value: 'Estimated Water Savings for Proposed Project',
            fontWeight: 'bold'
        }
    ]);

    var fieldCount = 1;
    for (var field of user.fields) {
        data.push([
            {
                value: 'Field ' + fieldCount,
                fontWeight: 'bold'
            }
        ]);

        data.push([
            {
                value: 'Before Water Use (ac-in/ac)'
            },
            {
                value: field.results.beforeWater
            }
        ]);

        data.push([
            {
                value: 'After Water Use (ac-in/ac)'
            },
            {
                value: field.results.afterWater
            }
        ]);

        data.push([
            {
                value: 'Water Savings (ac-in/ac)'
            },
            {
                value: field.results.estWaterSavings
            }
        ]);

        data.push([
            {
                value: 'Water Savings (%)'
            },
            {
                value: field.results.percentWaterSavings
            }
        ]);

        fieldCount++;
    }

    data.push([]);

    data.push([
        {
            value: 'Field Totals',
            fontWeight: 'bold'
        }
    ]);

    data.push([
        {
            value: 'Before Water Use (ac-in/ac)'
        },
        {
            value: reportData.waterReport.beforeWaterUse
        }
    ]);

    data.push([
        {
            value: 'After Water Use (ac-in/ac)'
        },
        {
            value: reportData.waterReport.afterWaterUse
        }
    ]);

    data.push([
        {
            value: 'Water Savings (ac-in/ac)'
        },
        {
            value: reportData.waterReport.waterSavings
        }
    ]);

    data.push([
        {
            value: 'Water Savings (%)'
        },
        {
            value: reportData.waterReport.percentSavings
        }
    ]);
}

function buildFieldSection (data, user) {
    data.push([
        {
            value: 'SWEEP Project Assessment Tool Field Data Summary',
            fontWeight: 'bold'
        }
    ]);

    var fieldCount = 1;
    for (var field of user.fields) {
        data.push([
            {
                value: 'Field ' + fieldCount,
                fontWeight: 'bold'
            }
        ]);

        buildFieldSummary(data, field);

        fieldCount++;
    }
}