import React, { useState } from "react";
import { useSelector } from "react-redux";

// @material-ui/core components
import { CardContent, Collapse, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@material-ui/core";

// custom components
import Card from "../../components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import ExpandCollapseIcon from "../ExpandCollapseIcon.jsx";

import { CO2Units, CO2YearUnits, formatResult, loadingText } from "helpers/report.js";

export default function GHGPumpingReportTable (props) {
  const { classes, reportData } = props;
  const pumpResults = useSelector(state => state.user.pumpResults);

  const [collapsed, setCollapsed] = useState(false);
  
  function toggleCollapse() {
    setCollapsed(!collapsed);
  }

  return (
    <Card id="ghgPumpingReport">
      <CardHeader style={{backgroundColor: '#f4b083'}} onClick={toggleCollapse}>
        <ExpandCollapseIcon collapsed={collapsed} />
        <Typography align="center" variant="h5">
          GHG Emission Reductions from Pumping System Enhancements in Proposed Project 
        </Typography>
      </CardHeader>
      <Collapse in={!collapsed}>
        <CardContent>
          <Table>
            <TableHead className={classes.tableHeader}>
              <TableRow>
                <TableCell>
                  Pumping System
                </TableCell>
                <TableCell>
                  Pre-Project GHG Emissions <br/> (<CO2YearUnits/>)
                </TableCell>
                <TableCell>
                  Post-Project GHG Emissions <br/> (<CO2YearUnits/>)
                </TableCell>
                <TableCell>
                  GHG Emissions Reductions <br/> (<CO2YearUnits/>)
                </TableCell>
                <TableCell>
                  10-Year Project Benefits <br/> (<CO2Units/>) 
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {pumpResults && pumpResults.map(pump =>
                <TableRow key={'ghgPumpingReportRow_' + pump.id}>
                  <TableCell>{pump.name}</TableCell>
                  <TableCell>{formatResult(pump.ghgPre)}</TableCell>
                  <TableCell>{formatResult(pump.ghgPost)}</TableCell>
                  <TableCell>{formatResult(pump.ghgPre - pump.ghgPost)}</TableCell>
                  <TableCell>{formatResult((pump.ghgPre - pump.ghgPost) * 10)}</TableCell>
                </TableRow>
              )}
                          <TableRow key={'ghgRenewableEnergySavings'} >
                              <TableCell className={classes.renewableEnergyRow} >Renewable Energy Savings</TableCell>
                              <TableCell>
                                  {}
                              </TableCell>
                              <TableCell>
                                  {}
                              </TableCell>
                              <TableCell>
                                  {formatResult(reportData.pumpingReport.renewableEnergy)}
                              </TableCell>
                              <TableCell>
                                  {formatResult(reportData.pumpingReport.renewableEnergy*10)}
                              </TableCell>
                          </TableRow>
              <TableRow key={'ghgPumpingReportRow_totals'} className={classes.totalsRow}>
                <TableCell>Total</TableCell>
                <TableCell>
                  {formatResult(reportData.pumpingReport.beforeGHG)}
                </TableCell>
                <TableCell>
                  {formatResult(reportData.pumpingReport.afterGHG)}
                </TableCell>
                <TableCell>
                  {formatResult(reportData.pumpingReport.yearlyGHGBenefits)}
                </TableCell>
                <TableCell>
                  {formatResult(reportData.pumpingReport.tenYearGHGBenefits)}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </CardContent>
      </Collapse>
    </Card>
  );
}