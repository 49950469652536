import { call, put } from "redux-saga/effects";
import { post } from "../utils/api";
import { DOWNLOAD_REPORT, RUN_CALCULATIONS } from "../constants/api";
import { receiveCalculationData } from "../actions/user";
import JSAlert from 'js-alert'
export function* runCalculations(action) {
    try {
        const data = yield call(post, RUN_CALCULATIONS, action.payload);
        yield put(receiveCalculationData(data)); // invoke action & reducer
    }
    catch (error) {
        console.log('calculations error: ', error.message);
        
        if (error.message.message == "nullSoilTexture" || error.message.message == "unknownSoilTexture") {
            
            JSAlert.alert("Soil data is not available for one or more soil map units on this field. Please use the soil map data layer imagery to move your field to a similar area nearby that includes a soil texture similar to your field.", "", "Ok").setIcon(JSAlert.Icons.Failed);
        }
        else if ((error.message.title!=undefined)&& (error.message.title.indexOf("validation") > -1)) {
            if (error.message.errors != undefined && error.message.errors != null) {
                JSAlert.alert("Calculations failing due to missing " + error.message.errors[Object.keys(error.message.errors)[0]][0].split("|")[0].split("Path: $.")[1] + " inputs", "Calculations Error", "Ok").setIcon(JSAlert.Icons.Failed);
            }
        }
        else if ((error.message != undefined)) {
            
                JSAlert.alert("JSON Error", "Calculations Error", "Ok").setIcon(JSAlert.Icons.Failed);
            
        }
        else {
            JSAlert.alert("Apologies, we are unable to access soil data at this time. Please try again shortly.", "", "Ok").setIcon(JSAlert.Icons.Failed);
        }

        
        
       
       
    }
}

export function* downloadReport(action) {
    try {
        const report = yield call(post, DOWNLOAD_REPORT, action.payload);
    }
    catch (error) {
        console.log('report download error: ', error);
    }
}