
import React, { useEffect } from "react";
import Box from "@material-ui/core/Box";
import { styled } from '@material-ui/core/styles';
import { useState } from "react";
import Button from "@material-ui/core/Button";
import GridItem from "../../../components/Grid/GridItem.js";
import GridContainer from "../../../components/Grid/GridContainer.js";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Select, { SelectChangeEvent } from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import NativeSelect from '@material-ui/core/NativeSelect';
import InputBase from '@material-ui/core/InputBase';
import { string } from "prop-types";
import * as CMBYN from "../../../constants/api";
import OutlinedInput from '@material-ui/core/OutlinedInput';
import KWhWarning from "./KWhWarning.js";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
    'label + &': {
        marginTop: theme.spacing(3),
        width: '90%',
    },
    '& .MuiInputBase-input': {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #ced4da',
        fontSize: 16,

        padding: '10px 26px 10px 12px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:focus': {
            borderRadius: 4,
            borderColor: '#80bdff',
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        },
    },
}));
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};


const styles = {
  card: {
    marginLeft: "10%",
    boxShadow: "none",
    marginTop: "unset",
  },

  preprojpumplabel: {
    width: "100px",
    marginTop: "15px",
  },
  preprojpumpAddIcon: {
    float: "left",
    padding: "unset",
    },
    paper: { minWidth: "300px" },
};

const useStyles = makeStyles(styles);
export default function AddNewPostPump(props ) {
    const { postfieldsArr, parentPostPumpNameChange, parentPostFieldsListeChange, parentPostHpChange, parentPostHoursChange,
        parentPostEfficiencyChange, parentPostSystemPressureChange, parentpostpumppressureChange, parentpostpumpdepthChange, parentpostdischargepressureChange,
         parentpostfrictionlossChange,parentPostVFDChange, parentPostFuelTypeChange, parentPostFuelUseChange,
        parentPostRenewenergySelect, parentPostRenewenergySelectCapcty } = props
    
   
    const classes = useStyles();
   
    const [textInput, setTextInput] = useState('');
   // const [fields, setFields] = React.useState('');
    // @ts-check
    const [postfields, setPostFields] = useState([]);
    const [postEff, setPostEff] = React.useState('');
    const [postHP, setPostHP] = React.useState('');
    const [postHours, setPostHours] = React.useState('');
    const [postVFD, setPostVFD] = React.useState('');
    const [postVFDList, setPostVFDList] = React.useState([]);
    const [postFuelUse, setPostFuelUse] = React.useState('');
    const [renewenergyselect, setRenewenergyselect] = React.useState("1");
    const [addnewpostpumpsystempressure, setaddnewpostpumpsystempressure] = React.useState(2);
    const [postpumpressure, setPostpumpressure] = React.useState('');
    const [postpumpdepth, setPostpumpdepth] = React.useState('');
    const [postdischargepressure, setPostdischargepressure] = React.useState('');
    const [postfrictionloss, setPostfrictionloss] = React.useState('');

    const [addnewpostpumpfueltype, setaddnewpostpumpfueltype] = React.useState("150");
    const [addnewpostpumpfueltypeList, setaddnewpostpumpfueltypeList] = React.useState([]);
    const [renewenergyselectcapcty, setRenewenergyselectcapcty] = React.useState('');
  



    
    //const handleChange = (event) => {
    //    setFields(event.target.value);

    //    //setPumpInputs({ ...pumpinputs, fields: event.target.value });
    //    parentFieldsListeChange(event.target.value);

    //};
     // @ts-check
    
    const handlePostFieldsChange = function (event) {
        var value = event.target.value;
        setPostFields(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value);
        parentPostFieldsListeChange(value);
    };

    const handlePostHP = (event) => {
        setPostHP(event.target.value);
        //setPumpInputs({ ...pumpinputs, prehp: event.target.value });
        parentPostHpChange(event.target.value);
    };
    const handlePostHours = (event) => {
        setPostHours(event.target.value);
        //setPumpInputs({ ...pumpinputs, prehours: event.target.value });
        parentPostHoursChange(event.target.value);

    };
    const handlePostEff = (event) => {
        setPostEff(event.target.value);
        //setPumpInputs({ ...pumpinputs, preeff: event.target.value });
        parentPostEfficiencyChange(event.target.value);

    };
    const handlePostFuelUse = (event) => {
        setPostFuelUse(event.target.value);
        //setPumpInputs({ ...pumpinputs, prefueluse: event.target.value });
        parentPostFuelUseChange(event.target.value);

    };
   
    const handlePostVFD = (event) => {
        setPostVFD(event.target.value);
        //setPumpInputs({ ...pumpinputs, prevfd: event.target.value });
        parentPostVFDChange(event.target.value);

    };
        
    const handleaaddnewpostpumpSystempressure
        = (event) => {
            setaddnewpostpumpsystempressure(event.target.value);
            if (event.target.value == 1) {
                document.getElementById("syspressitemspost").style.display = "none";
                //document.getElementById("syspressinputpost").style.display = "block";
            } else if (event.target.value == 2) {

                document.getElementById("syspressitemspost").style.display = "block";
                //document.getElementById("syspressinputpost").style.display = "none";
            }
            parentPostSystemPressureChange(event.target.value)
        };
    const handlePostSysPress
        = (event) => {
            setPostpumpressure(event.target.value)
            parentpostpumppressureChange(event.target.value)
        };
    const handlePostPumpDepth
        = (event) => {
            setPostpumpdepth(event.target.value)
            parentpostpumpdepthChange(event.target.value)
        };

    const handlePostDisPress
        = (event) => {
            setPostdischargepressure(event.target.value)
            parentpostdischargepressureChange(event.target.value)
        };
    const handlePostFricLoss
        = (event) => {
            setPostfrictionloss(event.target.value)
            parentpostfrictionlossChange(event.target.value)
        };
    const handlePostTextInputChange = event => {
        setTextInput(event.target.value);
        
       
        //setPumpInputs({ ...pumpinputs, pumpName: event.target.value });
        parentPostPumpNameChange(event.target.value)
    };

    const handleaddnewpostpumpfueltype = event => {
        

        setaddnewpostpumpfueltype(event.target.value);
        //setPumpInputs({ ...pumpinputs, prefueltype: event.target.value });

        parentPostFuelTypeChange(event.target.value);
        if (event.target.value === "155" || event.target.value === "156" || event.target.value === "157") {
            document.getElementById("annualfueluseadd").style.display = "none";
        }
        else {
            document.getElementById("annualfueluseadd").style.display = "block";
        }
    };

   
    const handleRenewEnrgySelChange = event => {


        setRenewenergyselect(event.target.value);
        
        if (event.target.value === "0") {
           
            document.getElementById("renewenrgycapctyDiv").style.display = "block";
           
        }
        else {
            document.getElementById("renewenrgycapctyDiv").style.display = "none";
           
        }
        parentPostRenewenergySelect(event.target.value)
    };
    const handlePostRenewEnrgyCapcty = event => {
        setRenewenergyselectcapcty(event.target.value);
        parentPostRenewenergySelectCapcty(event.target.value);

    };

    
    useEffect(() => {
        fetch(CMBYN.FETCH_POSTFUELTYPES)
            .then(response => response.json())
            .then(results => {
                var list = [];
                for (var u = 0; u < results.length; u++) {
                    let usr = results[u];
                    if (usr) {
                        let option = {};
                        option.name = usr.name;
                        option.id = usr.id;
                        list.push(option);
                    }
                }
                setaddnewpostpumpfueltypeList(list);

            })
    }, []);

    useEffect(() => {
        fetch(CMBYN.FETCH_VFDTYPES)
            .then(response => response.json())
            .then(results => {
                var list = [];
                for (var u = 0; u < results.length; u++) {
                    let usr = results[u];
                    if (usr) {

                        let option = {};
                        option.name = usr.vfdType.name;
                        option.id = usr.vfdType.id;
                        list.push(option);
                    }
                }



                setPostVFDList(list);
            })
    }, []);
    return (
        <div style={{ marginTop: 10 }}>
      <GridContainer
                direction="column"
        justifyContent="flex-start"
        alignItems="flex-start"
      >
                      
    
                          <GridItem xs={11} sm={11} md={11}>
                            <div style={{ marginTop: "10px", width: "max-content" }}>
                                <InputLabel htmlFor="newpumppost">Name of the pump :</InputLabel>
                           </div>
                        </GridItem>

                          <GridItem xs={11} sm={11} md={11}>
                            <div style={{ marginTop: "10px", width: "max-content" }}>
                                <TextField

                                    id="addnewpostpump"
                                    label=""
                                    defaultValue=""
                                    variant="outlined"
                                color="primary"
                            onChange={handlePostTextInputChange}
                                        
                                />
                                </div>
                            
                        </GridItem>
                    </GridContainer>

                    <GridContainer
                     
                        direction="column"
                        justifyContent="flex-start"
                        alignItems="flex-start">

                          <GridItem xs={11} sm={11} md={11}>

                            <div style={{ marginTop: "10px", width: "max-content" }}>
                                <InputLabel id="demo-simple-select-standard-label">Which fields does this pump service :</InputLabel>
                                </div>
                        </GridItem>
                          <GridItem xs={11} sm={11} md={11}>
                    <div style={{ marginTop: "10px", width: "max-content" }}>

                        <Select
                            labelId="demo-multiple-name-label"
                            id="demo-multiple-name"
                            multiple
                            value={postfields}
                            onChange={handlePostFieldsChange}
                            input={<OutlinedInput label="Fields" />}
                            MenuProps={MenuProps}
                        >
                            {postfieldsArr.map((field) => (
                                <MenuItem
                                    key={field.name}
                                    value={field.name}
                                    
                                >
                                    {field.name}
                                </MenuItem>
                            ))}
                        </Select>
                        </div>

                        </GridItem>
                 </GridContainer>
                    <GridContainer
                        direction="column"
                     
                        justifyContent="flex-start"
                        alignItems="flex-start">
                          <GridItem xs={11} sm={11} md={11}>
                            <div style={{ marginTop: "10px", width: "max-content" }}>
                        <InputLabel htmlFor="motorratedhppost">Expected Motor Rated Horsepower :</InputLabel>
                            </div>
                        </GridItem>

                          <GridItem xs={11} sm={11} md={11}>
                            <div style={{ marginTop: "10px", width: "max-content" }}>
                                <TextField

                                    id="fieldmotorhorseratedhppost"
                                    label=""
                                    defaultValue=""
                                    variant="outlined"
                                        color="primary"
                                        onChange={handlePostHP}
                                />
                            </div>
                        </GridItem>
                    </GridContainer>
              
                
                    <GridContainer
                        direction="column"
                        justifyContent="flex-start"
                        alignItems="flex-start">
                          <GridItem xs={11} sm={11} md={11}>
                            <div style={{ marginTop: "10px", width: "max-content" }}>
                        <InputLabel htmlFor="addnewpostpumpefficiency">Expected Overall Pumping Efficiency (%) :</InputLabel>
                            </div>
                        </GridItem>

                          <GridItem xs={11} sm={11} md={11}>
                            <div style={{ marginTop: "10px", width: "max-content" }}>
                                <TextField

                                    id="fieldaddnewpostpumpefficiency"
                                    label=""
                                    defaultValue=""
                                    variant="outlined"
                                        color="primary"
                            onChange={handlePostEff}
                            type="number"
                                />
                            </div>
                        </GridItem>
                    </GridContainer>
                   
            
           
            <GridContainer
                direction="column"
                justifyContent="flex-start"
                alignItems="flex-start">
                <GridItem xs={11} sm={11} md={11}>
                    <div id="syspressitemspost" style={{ marginTop: "10px", width: "max-content"}}>
                        <GridContainer
                            direction="column"
                            justifyContent="flex-start"
                            alignItems="flex-start">
                            <GridItem>
                                <div style={{ marginTop: "10px", width: "max-content" }}>
                                    <InputLabel htmlFor="pumpdepthpost">Enter the pumping depth (ft): </InputLabel>
                                </div>
                            </GridItem>

                            <GridItem xs={11} sm={11} md={11}>
                                <div style={{ marginTop: "10px", width: "max-content" }}>
                                    <TextField

                                        id="fieldumpingdepthpost"
                                        label=""
                                        defaultValue=""
                                        variant="outlined"
                                        color="primary"
                                        onChange={handlePostPumpDepth}
                                        type="number"
                                    />
                                </div>
                            </GridItem>
                        </GridContainer>
                        <GridContainer
                            direction="column"
                            justifyContent="flex-start"
                            alignItems="flex-start">
                            <GridItem xs={11} sm={11} md={11}>
                                <div style={{ marginTop: "10px", width: "max-content" }}>
                                    <InputLabel htmlFor="dischargepresspost">Enter the discharge pressure (ft) (if only psi is known please multiply by 2.31 to convert to feet):</InputLabel>
                                </div>
                            </GridItem>

                            <GridItem xs={11} sm={11} md={11}>
                                <div style={{ marginTop: "10px", width: "max-content" }}>
                                    <TextField

                                        id="fielddischargepressurepost"
                                        label=""
                                        defaultValue=""
                                        variant="outlined"
                                        color="primary"
                                        onChange={handlePostDisPress}
                                        type="number"
                                    />
                                </div>
                            </GridItem>
                        </GridContainer>
                        <GridContainer
                            direction="column"
                            justifyContent="flex-start"
                            alignItems="flex-start">
                            <GridItem xs={11} sm={11} md={11}>
                                <div style={{ marginTop: "10px", width: "max-content" }}>
                                    <InputLabel htmlFor="friclosspost">Enter the friction losses (ft) (friction loss is 5 for a booster and 10 for a well pump): </InputLabel>
                                </div>
                            </GridItem>

                            <GridItem xs={11} sm={11} md={11}>
                                <div style={{ marginTop: "10px", width: "max-content" }}>
                                    <TextField

                                        id="fieldfrictionlosspost"
                                        label=""
                                        defaultValue=""
                                        variant="outlined"
                                        color="primary"
                                        onChange={handlePostFricLoss}
                                        type="number"
                                    />
                                </div>
                            </GridItem>
                        </GridContainer>
                    </div>
                </GridItem>
            </GridContainer>

            <GridContainer
                direction="column"
                justifyContent="flex-start"
                alignItems="flex-start">
                <GridItem xs={11} sm={11} md={11}>
                    <div style={{ marginTop: "10px", width: "max-content" }}>
                        <InputLabel htmlFor="addnewpostpumpvfds">Are you installing a VFD?</InputLabel>
                    </div>
                </GridItem>
                <GridItem xs={11} sm={11} md={11}>
                    <div style={{ marginTop: "10px", width: "max-content" }}>
                        <NativeSelect
                            id="fieldaddnewpostpumpvfd"
                            value={postVFD}
                            onChange={handlePostVFD}
                            input={<BootstrapInput />}
                        >
                            {postVFDList.map((cr) => (
                                <option value={cr.id}> {cr.name}
                                </option >

                            ))}
                        </NativeSelect>
                    </div>
                </GridItem>

            </GridContainer>

           
                    <GridContainer
                        direction="column"
                        justifyContent="flex-start"
                        alignItems="flex-start">
                          <GridItem xs={11} sm={11} md={11}>
                            <div style={{ marginTop: "10px", width: "max-content" }}>
                                <InputLabel htmlFor="addnewpostpumpFuel">New Fuel Type :</InputLabel>
                            </div>
                        </GridItem>
                          <GridItem xs={11} sm={11} md={11}>
                            <div style={{ marginTop: "10px", width: "max-content" }}>
                                <NativeSelect
                                    id="addNewPumpFuelType"
                                    value={addnewpostpumpfueltype}
                                    onChange={handleaddnewpostpumpfueltype}
                                    input={<BootstrapInput />}
                                >
                            {addnewpostpumpfueltypeList.map((cr) => (
                                <option value={cr.id}> {cr.name}
                                </option >

                            ))}
                                </NativeSelect>
                            </div>
                        </GridItem>
            </GridContainer>
            <GridContainer direction="column" alignItems="flex-start">
                <GridItem>

                    <div id="annualfueluseadd" style={{ marginTop: "10px", width: "max-content", display: "block" }}>
            <GridContainer
                direction="column"
                justifyContent="flex-start"
                alignItems="flex-start">
                <GridItem xs={11} sm={11} md={11}>
                    <div style={{ marginTop: "10px", width: "max-content" }}>
                                    <InputLabel htmlFor="addpostpumpfueluse">Anticipated Fuel Use (gallons or scf or kWh):</InputLabel>
                    </div>
                </GridItem>

                <GridItem xs={11} sm={11} md={11}>
                    <div style={{ marginTop: "10px", width: "max-content" }}>
                        <TextField

                            id="fieldanticipatedfueluse"
                            label=""
                            defaultValue=""
                            variant="outlined"
                            color="primary"
                            onChange={handlePostFuelUse}
                            type="number"
                        />
                    </div>
                </GridItem>
            </GridContainer>
                    </div>
                </GridItem> 
            </GridContainer>
                </div>
  );
}
