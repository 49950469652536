import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { TextField } from "@material-ui/core";

import { updateUser } from "actions/user";

import DynamicDialog from "components/Dialogs/Dialog";
import * as helpers from "../../../helpers/helpers"

export default function EditNamePopup ({open, handleClose, value}) {
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user);
  const [name, setName] = useState(value);

  const handleNameChange = (event) => {
      setName(event.target.value);
  }

  const saveName = () => {
    dispatch(updateUser({...user, agopname: name}));
    handleClose();
  }

  const checkKeyPress = (e) => {
      if (e.key ==='Enter' || e.key ==='NumpadEnter' )
      {
        e.preventDefault();
        saveName();
      }
  }

  const saveEditNameButton = helpers.getDialogButton("Save", saveName);
  const cancelEditNameButton = helpers.getDialogButton("Cancel", handleClose);
  const editNameButtons = [cancelEditNameButton, saveEditNameButton];

  const form = (
    <TextField
      defaultValue={value}
      onKeyDown={checkKeyPress}
      onChange={handleNameChange}
    />
  );

  return (
    <DynamicDialog
      buttons={editNameButtons}
      open={open}
      title="Edit Operation Name"
      form={form}
    />
  )
}