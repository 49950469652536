import React from "react";
import Box from "@material-ui/core/Box";

import Button from "@material-ui/core/Button";
import GridItem from "../../../components/Grid/GridItem.js";
import GridContainer from "../../../components/Grid/GridContainer.js";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';


const styles = {
  card: {
    marginLeft: "10%",
    boxShadow: "none",
    marginTop: "unset",
  },

  preprojpumplabel: {
    width: "100px",
    marginTop: "15px",
  },
  preprojpumpAddIcon: {
    float: "left",
    padding: "unset",
    },
    paper: { minWidth: "300px" },
};

const useStyles = makeStyles(styles);
export default function UniqueFieldName({ open, handleClose }) {
  const classes = useStyles();
   
    return (
        <div>
            <Dialog open={open} onClose={handleClose}  fullWidth maxWidth="xs" >
           
            <DialogContent style={{ height: '100px' }}>
    <GridContainer
      direction="column"
      justifyContent="flex-start"
      alignItems="flex-start"
    >
      <GridItem xs={12} sm={12} md={12}>
        <Box
          component="span"
          sx={{
            "& > :not(style)": { width: "5ch" },
          }}
          noValidate
          autoComplete="off"
        >
        
                                <label htmlFor="uniqueWarning" id="uniqueWarningMsg">Field name must be unique.</label>
               
        </Box>
      </GridItem>
      

                </GridContainer>
            </DialogContent>
            <DialogActions>

                <Button variant="contained" onClick={handleClose} color="secondary">Ok</Button>
            </DialogActions>
            </Dialog>
        </div>
  );
}
