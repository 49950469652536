import React from "react";

export const loadingText = 'Loading...';

export function CO2Units () {return(<span>tonnes CO<sub>2</sub>-eq</span>);}
export function CO2AcreYearUnits () {return(<span>tonnes CO<sub>2</sub>-eq/ac/yr</span>);}
export function CO2DollarUnits () {return(<span>tonnes CO<sub>2</sub>-eq/$</span>);}
export function CO2YearUnits () {return(<span>tonnes CO<sub>2</sub>-eq/yr</span>);}
export function N2OYearUnits () {return(<span>lb N<sub>2</sub>O-N/yr</span>);}

export function formatResult (value, stripZeroes = false) {
    if (value == null || value === undefined) {
        return loadingText;
    }

    if (typeof value === 'number') {
        var precision = 4;

        return stripZeroes ? (+value.toFixed(precision)) : value.toFixed(precision);  //plus sign removes trailing zeroes
    }

    return value;
}

export function formatDollars (value) {
    return '$' + (value ?  value : 0).toFixed(2)
}