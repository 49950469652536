import { buildHeader, buildOverallSection } from "./shared";
import { formatResult } from "helpers/report";

export function buildSummaryReportData(user, reportData) {
    const data = [];

    buildHeader(data, user);

    data.push([]);

    buildOverallSection(data, reportData);

    data.push([]);

    buildPumpingSection(data, reportData);

    data.push([]);

    buildN2OSection(data, reportData);

    data.push([]);

    buildWaterSection(data, reportData);

    return data;
}

function buildPumpingSection(data, reportData){
    data.push([
        {
            value: 'Estimated GHG Emission Reductions from Pumping System for Proposed Project',
            fontWeight: 'bold'
        }
    ]);

    data.push([
        {
            value: 'Pre-Project GHG Emissions (tonnes CO2-eq/yr)'
        },
        {
            value: formatResult(reportData.pumpingReport.beforeGHG)
        }
    ]);

    data.push([
        {
            value: 'Post-Project GHG Emissions (tonnes CO2-eq/yr)'
        },
        {
            value: formatResult(reportData.pumpingReport.afterGHG)
        }
    ]);

    data.push([
        {
            value: 'GHG Emissions Reductions from Pumping (tonnes CO2-eq/yr)'
        },
        {
            value: formatResult(reportData.pumpingReport.yearlyGHGBenefits)
        }
    ]);

    data.push([
        {
            value: '10-year Project Benefits (tonnes CO2-eq)'
        },
        {
            value: formatResult(reportData.pumpingReport.tenYearGHGBenefits)
        }
    ]);
}

function buildN2OSection(data, reportData) {
    data.push([
        {
            value: 'Estimated Annual Nitrous Oxide Emission Reductions',
            fontWeight: 'bold'
        }
    ]);

    data.push([
        {
            value: 'Baseline N2O Emissions (tonnes CO2-eq)'
        },
        {
            value: formatResult(reportData.n2oReport.beforeN2O)
        }
    ]);

    data.push([
        {
            value: 'Projected N2O Emissions (tonnes CO2-eq)'
        },
        {
            value: formatResult(reportData.n2oReport.afterN2O)
        }
    ]);

    data.push([
        {
            value: 'Average Annual N2O-N Reductions (tonnes CO2-eq)'
        },
        {
            value: formatResult(reportData.n2oReport.avgYearlyN2OBenefits)
        }
    ]);

    data.push([
        {
            value: '10-Year Project Benefits (tonnes CO2-eq)'
        },
        {
            value: formatResult(reportData.n2oReport.tenYearN2OBenefits)
        }
    ]);
}

function buildWaterSection(data, reportData) {
    data.push([
        {
            value: 'Estimated Annual Water Savings',
            fontWeight: 'bold'
        }
    ]);

    data.push([
        {
            value: 'Baseline Water Use (in)'
        },
        {
            value: formatResult(reportData.waterReport.beforeWaterUse)
        }
    ]);

    data.push([
        {
            value: 'Projected Water Use (in)'
        },
        {
            value: formatResult(reportData.waterReport.afterWaterUse)
        }
    ]);

    data.push([
        {
            value: 'Water Use Savings (in)'
        },
        {
            value: formatResult(reportData.waterReport.waterSavings)
        }
    ]);

    data.push([
        {
            value: 'Water Savings Estimate (%)'
        },
        {
            value: formatResult(reportData.waterReport.percentSavings)
        }
    ]);
}