import React, { useEffect, useState } from "react";
import CustomInput from "../CustomInput/CustomInput.js";
import GridItem from "../Grid/GridItem.js";
import GridContainer from "../Grid/GridContainer.js";

/*Not going to use redux forms.  Just going to have controls and track the state. 
Drawback here is we will need to manually update items and manually send updated items back to parent.*/
export default function FieldForm(props) 
{
  const { parentHandleChange, field } = props

  const [fieldName, setFieldName] = React.useState("");

  const handleChanged = (e) => {
    //console.log("Token Text Value: ", e);
    parentHandleChange(e.target.value)
    setFieldName(e.target.value);
  };

  const handleChangedAcreage = (e) => {

  };

  return (
    <div style={{ marginTop: 10 }}>
      <GridContainer
        direction="column"
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        <GridItem xs={11} sm={11} md={11}>
          <CustomInput
            labelText="Field Name"
            inputProps={{
              value: fieldName,
              onChange: (event) => handleChanged(event),
            }}
            id="fieldName"
            formControlProps={{
              fullWidth: true,
            }}
          />

<CustomInput
            labelText="Acreage"
            inputProps={{
              value: field.acreage,
              disabled: true,
              onChange: (event) => handleChangedAcreage(event),
            }}
            id="acreage"
            formControlProps={{
              fullWidth: true,
            }}
          />
        </GridItem>
      </GridContainer>
    </div>
  );
}
