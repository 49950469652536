import * as React from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from "@material-ui/core/Button";
import GridItem from "../../../components/Grid/GridItem.js";
import GridContainer from "../../../components/Grid/GridContainer.js";
export default function PumpImpQues(props) {
    const { parentAddnlPumpLocsChange } = props;
    const [value, setValue] = React.useState(null);
    const handleChange = (event) => {
        setValue(event.target.value);
        parentAddnlPumpLocsChange(event.target.value);
    };
    return (
        <div style={{ marginTop: 10 }}>
            <GridContainer
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
            >
                <GridItem xs={11} sm={11} md={11}>
                    <div style={{ marginTop: "10px", marginLeft: "5px", width: "320px" }}>
                        <FormLabel id="demo-row-radio-buttons-group-label">As part of your proposed project, will you be adding any additional pump locations ?</FormLabel>
                    </div>
                </GridItem>
                <GridItem xs={11} sm={11} md={11}>
                    <div style={{ marginTop: "10px", marginLeft: "5px", width: "200px" }}>
            <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            value={value}
                            onChange={handleChange}
            >
                <FormControlLabel value="0" control={<Radio />} label="Yes" />
                <FormControlLabel value="1" control={<Radio />} label="No" />
           
            </RadioGroup>
                    </div>

                </GridItem>
            </GridContainer>
        </div>
    );
}