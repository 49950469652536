import React from "react";
import PropTypes from "prop-types";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import styles from "../../assets/jss/material-dashboard-react/components/headerStyle.js";
import sweepImg from "../../images/partners/sweep.png";
import cdfaImg from "../../images/partners/cdfa.png";
import prop68Img from "../../images/partners/prop68.png";
import teardropImg from "../../images/partners/teardrop.jpg";

const useStyles = makeStyles(styles);

export default function Header(props) {

  const classes = useStyles();
    
  const styles = {
      float: "left",
      height: "50px",
      marginRight: "1em",
  };

  return (
    <div className={classes.container}>
            <img src={teardropImg} alt="teardroplogo" style={styles}  className={classes.logo} />
            <img src={sweepImg} alt="sweeplogo" style={styles}  className={classes.logo} />
            <img src={cdfaImg} alt="cdfalogo" style={styles}  className={classes.logo} />
            <img src={prop68Img} alt="prop68Imglogo" style={styles}  className={classes.logo} />
    </div>
  );
}

Header.propTypes = {
  color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
  rtlActive: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
  routes: PropTypes.arrayOf(PropTypes.object)
};
