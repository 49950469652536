

import React from "react";
import Box from "@material-ui/core/Box";
import { styled } from '@material-ui/core/styles';
import { useState } from "react";
import Button from "@material-ui/core/Button";
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Select, { SelectChangeEvent } from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import NativeSelect from '@material-ui/core/NativeSelect';
import InputBase from '@material-ui/core/InputBase';
import { string } from "prop-types";
import OutlinedInput from '@material-ui/core/OutlinedInput';

const BootstrapInput = styled(InputBase)(({ theme }) => ({
    'label + &': {
        marginTop: theme.spacing(3),
        width: '90%',
    },
    '& .MuiInputBase-input': {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #ced4da',
        fontSize: 16,

        padding: '10px 26px 10px 12px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:focus': {
            borderRadius: 4,
            borderColor: '#80bdff',
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        },
    },
}));
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};


const styles = {
  card: {
    marginLeft: "10%",
    boxShadow: "none",
    marginTop: "unset",
  },

  preprojpumplabel: {
    width: "100px",
    marginTop: "15px",
  },
  preprojpumpAddIcon: {
    float: "left",
    padding: "unset",
    },
    paper: { minWidth: "300px" },
};

const useStyles = makeStyles(styles);
export default function Electricity(props ) {
    const { selkwh,selirr,parentTotalKWHChange, parentIrrHardwareChange } = props
    
   
    const classes = useStyles();
   
    const [pumpName, setPumpName] = useState('');
  
    const [fields, setFields] = useState([]);
   
    const [kwh, setKwh] = React.useState(selkwh);
    const [irrHardware, setIrrHardware] = React.useState(selirr);
    
    const handleFieldsChange = function (event) {
        var value = event.target.value;
        setFields(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value);
        parentPrePumpsListChange(value);
    };
   
    const handleKwhChange = (event) => {
        setKwh(event.target.value);
        parentTotalKWHChange(event.target.value);
       
    };
    const handleIrrHardware = (event) => {
        setIrrHardware(event.target.value);
        //setPumpInputs({ ...pumpinputs, prehp: event.target.value });
        parentIrrHardwareChange(event.target.value);
    };
    
    
    return (
        <div style={{ marginTop: 10 }}>
                    <GridContainer
                        direction="row"
                     
                        justifyContent="flex-start"
                        alignItems="flex-start">
                          <GridItem xs={11} sm={11} md={11}>
                            <div style={{ marginTop: "10px", width: "max-content" }}>
                        <InputLabel htmlFor="htmlkwh">Enter the total annual Kwh from all of the utility meters that power your current irrigation system:</InputLabel>
                            </div>
                        </GridItem>

                          <GridItem xs={11} sm={11} md={11}>
                            <div style={{ marginTop: "10px", width: "max-content" }}>
                                <TextField

                                    id="fieldkwh"
                                    label=""
                            defaultValue={kwh}
                                    variant="outlined"
                                        color="primary"
                            onChange={handleKwhChange}
                                />
                            </div>
                        </GridItem>
                    </GridContainer>
              
                    <GridContainer
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start">
                      <GridItem xs={11} sm={11} md={11}>
                    <div style={{ marginTop: "10px", width: "max-content" }}>
                        <InputLabel htmlFor="htmlhardwareirr">Does any non-irrigation hardware draw electricity from the utility meters:</InputLabel>
                        </div>
                    </GridItem>
                <GridItem xs={11} sm={11} md={11}>
                    <div style={{ marginTop: "10px", width: "max-content" }}>
                        <Select
                            labelId="labelhardwareirr"
                            id="fieldhardwareirr"
                            value={irrHardware}
                            onChange={handleIrrHardware}
                            label=""
                        >
                            <MenuItem value={0}>Yes</MenuItem>
                            <MenuItem value={1}>No</MenuItem>

                        </Select>
                    </div>
                </GridItem>
               
                    </GridContainer>
                    
                </div>
            
   
  );
}
