// REACT
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import ColorBar from "./ColorBar.js";
import ButtonBar from "./ButtonBar.js";
import * as FieldSidebarHelpers from "./FieldSidebarHelpers";
import GridItem from "../Grid/GridItem.js";
import GridContainer from "../Grid/GridContainer.js";

import DynamicDialog from "../Dialogs/Dialog.js";
import FieldForm from "../Forms/FieldForm";
import EditFieldForm from "../Forms/EditFieldForm";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import ExpandCollapseIcon from "components/ExpandCollapseIcon.jsx";

import { Box, Button, CardContent, Collapse, Typography } from "@material-ui/core";

import "./FieldSidebar.css";
import IconButton from "@material-ui/core/IconButton";  //will use this for project name edit
import EditIcon from "@material-ui/icons/Edit";  //will use this for project name edit
import FileCopyIcon from '@material-ui/icons/FileCopy';
import DeleteIcon from '@material-ui/icons/Delete';
// OPEN LAYERS
import Draw, { createBox } from "ol/interaction/Draw.js";
import postmarkerpng from "./images/markerred.png";
import premarkerpng from "./images/markerblue.png";
import { defaults as defaultInteractions, Modify, Select, Translate } from "ol/interaction.js";  //todo: remove these?
import { Vector as VectorSource } from "ol/source.js";
import WKT from "ol/format/WKT.js";
import { getCenter } from "ol/extent";
import Marker from "ol-marker-feature";  //todo: remove these and related npm package?
import Popup from "ol-popup";  //todo: remove this and related npm package?
import { asArray } from "ol/color";  //todo: remove this?
import { Circle as CircleStyle, Fill, Stroke, Style, Icon, Text } from "ol/style.js";

import { Feature } from "ol";  //todo: remove this?
import { toLonLat, transform } from "ol/proj.js";  //todo: remove transform?
import { LineString, Polygon, Point } from "ol/geom";
import { Vector as VectorLayer } from "ol/layer.js";
import { boundingExtent } from "ol/extent";  //todo: remove this?
import { get, transformExtent } from "ol/proj";  //todo: remove these?
import { getArea } from "ol/sphere";
import Collection from "ol/Collection";
import GeoJSON from "ol/format/GeoJSON.js";
import { fromCircle } from "ol/geom/Polygon.js";
import { updateUser } from "../../actions/user";

import * as helpers from "../../helpers/helpers";
import UserDetails from "../../views/UserDetails/UserDetails.js";

import SuccessPopup from "../../views/Popups/Misc/SuccessPopup.js";
import DataEntry from "../../views/Popups/Misc/DataEntry.js";
import UniqueFieldName from "../../views/Popups/Misc/UniqueFieldName.js";
import MaxFields from "../../views/Popups/Misc/MaxFields.js";
import AddPumpInstr from "../../views/Popups/Misc/AddPumpInstr.js";
import PumpImpQues from "../../views/Popups/Misc/PumpImpQues.js";
import AddNewPump from "../../views/Popups/Misc/AddNewPump.js";
import AddNewPostPump from "../../views/Popups/Misc/AddNewPostPump.js";
import EditNewPump from "../../views/Popups/Misc/EditNewPump.js";
import EditNewPostPump from "../../views/Popups/Misc/EditNewPostPump.js";

import Electricity from "../../views/Popups/Electricity.js";

import CopyFieldDetails from "../../views/Popups/Misc/CopyFieldDetails.js";
import { useHistory } from "react-router-dom";
import SaveLoadButtons from "./SaveLoadButtons.jsx";
import { jsPDF } from "jspdf";
import MaxPreProjPumps from "../../views/Popups/Misc/MaxPreProjPumps.js";
import MaxPostProjPumps from "../../views/Popups/Misc/MaxPostProjPumps.js";
import EditNamePopup from "views/Popups/Misc/EditNamePopup.jsx";
import TextField from '@material-ui/core/TextField';
import RenewEnrgyCapcty from "../../views/Popups/RenewEnrgyCapcty.js";



var draw = null;
var storageKey = "fieldSidebar";
var vectorSource = null;
var vectorLayer = null;
var pumpVectorSource = null;
var draw = null;
var translate = null;
var pinLayer = null;
var fieldData = [];

export default function FieldSidebar() {
    const [drawType, setDrawType] = useState("Cancel");
    const [drawColor, setDrawColor] = useState("#e809e5");
    const [drawStyle, setDrawStyle] = useState(null);
    const [isEditing, setIsEditing] = useState(false);  //todo: this never gets set/changed?
    const [fields, setFields] = useState([]);
    const [preprojectpumps, setPreProjectPumps] = useState([]);
    const [postprojectpumps, setPostProjectPumps] = useState([]);

    const [selectedField, setSelectedField] = useState(null);
    const [selectedPrePump, setSelectedPrePump] = useState(null);
    const [selectedPostPump, setSelectedPostPump] = useState(null);

    const [fieldJustCreated, setFieldJustCreated] = useState(null);
    const [premarkerJustCreated, setPremarkerJustCreated] = useState(null);
    const [postmarkerJustCreated, setPostmarkerJustCreated] = useState(null);
    const [preprojectpumpJustCreated, setPreProjectPumpJustCreated] = useState(null);
    const [postprojectpumpJustCreated, setPostProjectPumpJustCreated] = useState(null);
    const [fieldJustEdited, setFieldJustEdited] = useState(null);
    const [showAddNewField, setShowAddNewField] = useState(false);
    const [showEditNewField, setShowEditNewField] = useState(false);
    const [showEditName, setShowEditName] = useState(false);
    const [showDeleteField, setShowDeleteField] = useState(false);
    const [existPumpClicked, setExistPumpClicked] = useState(false);
    const [addntlPumpClicked, setAddntlPumpClicked] = useState(false);
    const [userDetailsOpen, setUserDetailsOpen] = useState(false);
    const [userDetailsTitle, setUserDetailsTitle] = useState("");
    const [copyFieldDetailsOpen, setCopyFieldDetailsOpen] = useState(false);
    const [fieldDetailsAdded, setFieldDetailsAdded] = useState("");
    const [successPopOpen, setSuccessPopOpen] = useState(false);
    const [dataEntryPopOpen, setDataEntryPopOpen] = useState(false);
    const [addPumpInstrPopOpen, setAddPumpInstrPopOpen] = useState(false);
    const [pumpImpOpen, setPumpImpOpen] = useState(false);
    const [addPumpOpen, setAddPumpOpen] = useState(false);
    const [addPostPumpOpen, setAddPostPumpOpen] = useState(false);
    const [editPumpOpen, setEditPumpOpen] = useState(false);
    const [editPostPumpOpen, setEditPostPumpOpen] = useState(false);
    const [copyArr, setCopyArr] = useState([]);

    const [userDetSelField, setUserDetSelField] = useState(null);
    const [pumpImpChanged, setPumpImpChanged] = useState(null);
    const [electricityPopUpOpen, setElectricityPopUpOpen] = useState(false);
    const [renewenrgyPopUpOpen, setRenewenrgyPopUpOpen] = useState(false);
    const [fieldsToCopy, setFieldsToCopy] = useState("");
    const [fieldNameWarnPopOpen, setFieldNameWarnPopOpen] = useState(false);
    const [maxFieldsWarn, setMaxFieldsWarn] = useState(false);
    const [maxPreProjPumpsWarn, setMaxPreProjPumpsWarn] = useState(false);
    const [maxPostProjPumpsWarn, setMaxPostProjPumpsWarn] = useState(false);
    const [enableViewReportBtn, setEnableViewReportBtn] = useState(false);
    const [fieldsComplete, setFieldsComplete] = useState(false);
    const [prePumpsComplete, setPrePumpsComplete] = useState(false);
    const [postPumpsComplete, setPostPumpsComplete] = useState(false);
    const currentUser = useSelector((state) => state.user);

    const [pumpinputs, setPumpInputs] = React.useState({
        prepumpName: "",
        prefields: [],
        prehp: "",
        preeff: "",
        prehours: "",
        presyspress: "2",
        prepumpress: "",
        prepumpdepth: "",
        predispress: "",
        prefricloss: "",
        prevfd: "",
        prefueltype: "150",
        prefueluse: "",
        preimprovques: "1",
        prestayinuseques: "0",
        prefeature: "",
        posthp: "",
        posteff: "",
        posthours: "",
        postsyspress: "1",
        postpumpress: "",
        postpumpdepth: "",
        postdispress: "",
        postfricloss: "",
        postvfd: "",
        postfueltype: "",
        postfueluse: "",
        postrenewenrgysel: "1",
        postrenewenrgycapcty: "",
    });

    const [postPumpinputs, setPostPumpInputs] = React.useState({
        postpumpName: "",
        postfields: [],
        posthp: "",
        posteff: "",
        posthours: "",
        postsyspress: "2",
        postpumpress: "",
        postpumpdepth: "",
        postdispress: "",
        postfricloss: "",
        postvfd: "",
        postfueltype: "",
        postfueluse: "",
        postfeature: "",
        postrenewenrgysel: "1",
        postrenewenrgycapcty: "",
    });

    const [pumpEditInputs, setPumpEditInputs] = React.useState({
        preeditpumpName: "",
        preeditfields: "",
        preedithp: "",
        preediteff: "",
        preedithours: "",
        preeditsyspress: "",
        preeditpumpress: "",
        preeditpumpdepth: "",
        preeditdispress: "",
        preeditfricloss: "",
        preeditvfd: "",
        preeditfueltype: "",
        preeditfueluse: "",
        preeditfeature: "",
    });

    const [postPumpEditInputs, setPostPumpEditInputs] = React.useState({
        posteditpumpName: "",
        posteditfields: "",
        postedithp: "",
        postediteff: "",
        postedithours: "",
        posteditsyspress: "",
        posteditpumpress: "",
        posteditpumpdepth: "",
        posteditdispress: "",
        posteditfricloss: "",
        posteditvfd: "",
        posteditfueltype: "",
        posteditfueluse: "",
        posteditfeature: "",
        posteditrenewenrgysel: "",
        posteditrenewenrgycapcty: "",
    });

    const [fieldDetails, setFieldDetails] = React.useState({
        precroponeyearone: "",
        precroponeyeartwo: "",
        precroponeyearthree: "",
        precroptwoyearone: "",
        precroptwoyeartwo: "",
        precroptwoyearthree: "",
        precropthreeyearone: "",
        precropthreeyeartwo: "",
        precropthreeyearthree: "",
        postcroponeyearone: "",
        postcroponeyeartwo: "",
        postcroponeyearthree: "",
        postcroptwoyearone: "",
        postcroptwoyeartwo: "",
        postcroptwoyearthree: "",
        postcropthreeyearone: "",
        postcropthreeyeartwo: "",
        postcropthreeyearthree: "",
        preirr: "",
        postirr: "",
        presoilmoisture: false,
        preflow: false,
        preet: false,
        preno: false,
        postsoilmoisture: false,
        postflow: false,
        postet: false,
        postno: false,
    });

    const [preSoilMoistureState, setPreSoilMoistureState] = React.useState(false);
    const [preFlowState, setPreFlowState] = React.useState(false);
    const [preETState, setPreETState] = React.useState(false);
    const [preNoState, setPreNoState] = React.useState(false);

    const [postSoilMoistureState, setPostSoilMoistureState] = React.useState(false);
    const [postFlowState, setPostFlowState] = React.useState(false);
    const [postETState, setPostETState] = React.useState(false);
    const [postNoState, setPostNoState] = React.useState(false);
    const [electricityDetails, setElectricityDetails] = React.useState({
        kwh: "",
        irrhardware: "1",
    });
    const [renewEnrgyDets, setRenewEnrgyDets] = React.useState({
        capcty: "",
       
    });
    //card collapsed states
    const [fieldCardCollapsed, setFieldCardCollapsed] = React.useState(false);
    const [prePumpCardCollapsed, setPrePumpCardCollapsed] = React.useState(false);
    const [postPumpCardCollapsed, setPostPumpCardCollapsed] = React.useState(false);

    //card collapse toggles
    function toggleFieldCardCollapse() {
        setFieldCardCollapsed(!fieldCardCollapsed);
    }

    function togglePrePumpCardCollapse() {
        setPrePumpCardCollapsed(!prePumpCardCollapsed);
    }

    function togglePostPumpCardCollapse() {
        setPostPumpCardCollapsed(!postPumpCardCollapsed);
    }

    const dispatch = useDispatch();
    const history = useHistory();

    const handleSuccessPopClose = () => {
        setSuccessPopOpen(false);
    };
    const handleDataEntryPopClose = () => {
        setDataEntryPopOpen(false);
    };
    const handleAddPumpInstrPopClose = () => {
        setAddPumpInstrPopOpen(false);
    };
    const handleFieldNameWarnPopClose = () => {
        setFieldNameWarnPopOpen(false);
    };

    const handleMaxFieldsWarnPopClose = () => {
        setMaxFieldsWarn(false);
    };
    const handleMaxPreProjPumpsWarnPopClose = () => {
        setMaxPreProjPumpsWarn(false);
    };
    const handleMaxPostProjPumpsWarnPopClose = () => {
        setMaxPostProjPumpsWarn(false);
    };

    useEffect(() => {
        updateStyle();
        importGeometries();
        fieldDetailsValidation();
        PreProjPumpsValidation();
        PostProjPumpsValidation();
       
        
        
        if (fields != null)
            if (fields.count > 0) {
                setSelectedField[fields[0]];
            }
    }, [fields]);

    useEffect(() => {
        importpoints();
        
    }, [preprojectpumps]);

    useEffect(() => {
        importpointsPost();
       
    }, [postprojectpumps]);

    useEffect(() => {
        setDrawControl();
    }, [drawType]);

    useEffect(() => {
        updateStyle();
    }, [drawColor]);

    useEffect(() => {
        setDrawControl();
    }, [drawStyle]);

    useEffect(() => {
        if (fieldJustCreated)
            if (!showAddNewField && !showDeleteField) {
                //No need to waste a render if the popup is currently open
                setShowAddNewField(true);
                setAddPumpOpen(false);
            } else {
                setShowDeleteField(false);
                setAddPumpOpen(false);
            }
    }, [fieldJustCreated]);

    useEffect(() => {
        updateStyle();
        importGeometries();
    }, [showAddNewField]);
    useEffect(() => {
        updateStyle();
        importGeometries();
    }, [showEditNewField]);
    useEffect(() => {
        updateStyle();
        importGeometries();
    }, [showDeleteField]);

    useEffect(() => {
        if (selectedField)
            if (!showEditNewField)
                //No need to waste a render if the popup is currently open
                setShowEditNewField(true);
    }, [selectedField]);

    // LISTEN FOR MAP TO MOUNT
    window.emitter.addListener("mapLoaded", () => onMapLoad());

    // LISTEN FOR OTHER COMPONENTS ADDING A FEATURE
    //window.emitter.addListener("showAddFieldSuccess", (feature, labelText) =>
    //    addNewItem(feature, labelText, true)
    //  );
    React.useLayoutEffect(() => {
        if (currentUser) {
            if (currentUser.fields) {
                setFields(helpers.serializeFeature(currentUser.fields));
            }
            if (currentUser.preprojectpumps) {
                setPreProjectPumps(helpers.serializeFeature(currentUser.preprojectpumps));
            }
            if (currentUser.postprojectpumps) {
                setPostProjectPumps(helpers.serializeFeature(currentUser.postprojectpumps));
            }
        } else {
            console.log("No current user!");
        }
    });

    const onMapLoad = () => {
        updateStyle();

        vectorSource = new VectorSource();
        pumpVectorSource = new VectorSource();
        vectorLayer = new VectorLayer({
            source: vectorSource,
            zIndex: 1000,
            style: drawStyle,
        });
        pinLayer = new VectorLayer({
            source: pumpVectorSource,
            zIndex: 1000,
            name: "prepumpslayer",
        });
        // PROPERTY CLICK WILL IGNORE THIS LAYER
        vectorLayer.setProperties({
            disableParcelClick: true,
            name: "fieldSidebar",
        });

        window.map.addLayer(vectorLayer);
        window.map.addLayer(pinLayer);
        window.map.on("singleclick", (evt) => {
            if (draw !== null || isEditing) return;

            window.map.forEachFeatureAtPixel(evt.pixel, (feature, layer) => {
                if (layer === null) return;

                if (layer.get("name") !== undefined && layer.get("name") === "fieldSidebar") {
                    if (drawType === "Eraser") {
                        // REMOVE ITEM FROM SOURCE
                        //  console.log(feature);
                        onItemDelete(getFieldByFeatureId(feature.get("id")).id);
                        return;
                    } else showDrawingOptionsPopup(feature, evt);

                    return;
                }
            });
        });
       
    };
    
    const getFieldById = (id) => {
        //  console.log(id);

        let foundField = null;

        /*  farms.map(farm => {           
                foundField = farm.fields.filter(fieldX => {
                    return fieldX.id === id;
                })[0]
            }); Not working everytime*/

        if (farms)
            if (farms.length > 0)
                for (var i = 0; i < farms.length; i++) {
                    let farm = farms[i];
                    let found = false;

                    if (farm.fields)
                        if (farm.fields.length > 0)
                            for (var x = 0; x < farm.fields.length; x++) {
                                if (farm.fields[x].id === id) {
                                    foundField = farm.fields[x];

                                    found = true;
                                    break;
                                }

                                if (found) break;
                            }
                }

        //console.log(foundField);

        if (foundField) return Object.assign({}, foundField);
        else return null;
    };

    // DELETE CLICK
    const onItemDelete = (id) => {
        let field = getFieldById(id);

        if (field) {
            // console.log(field);

            if (window.confirm("Are you sure you want to delete this field?")) {
                // Save it!
                props.submitFieldDelete(field);
            } else {
                // Do nothing!
            }
        }
    };

    const styles = {
        poly: new Style({
            stroke: new Stroke({
                width: 4,
                color: [255, 0, 0, 0.8],
            }),
        }),
        prepoint: new Style({
            image: new Icon({
                anchor: [0.5, 1],
                //src: "http://openlayers.org/en/v3.20.1/examples/data/icon.png"
                src: premarkerpng,
            }),
            text: new Text({
                //text: '',
                scale: 1.3,
                fill: new Fill({
                    color: "#000000",
                }),
            }),
        }),
        postpoint: new Style({
            image: new Icon({
                anchor: [0.5, 1],
                //src: "http://openlayers.org/en/v3.20.1/examples/data/icon.png"
                src: postmarkerpng,
            }),
            text: new Text({
                //text: '',
                scale: 1.3,
                fill: new Fill({
                    color: "#000000",
                }),
            }),
        }),
        geocode: new Style({
            image: new CircleStyle({
                opacity: 0.5,
                radius: 7,
                fill: new Fill({ color: [236, 156, 155, 0.7] }),
            }),
        }),
    };

    // BUTTON BAR CLICK
    const onButtonBarClick = (type) => {
        setDrawType(type);
    };

    // COLORBAR CLICK
    const onColorBarClick = (color) => {
        setDrawColor(color);
    };

    // FIELD CLICK
    const onFieldClick = (field) => {
        for (var i = 0; i < fields.length; i++) {
            if (field.field.name != fields[i].name) {
                document.getElementById("selectedField-" + fields[i].name).className = "sc-container sc-userprojects-item-container sc-nohighlight";
            }
        }

        setUserDetSelField(field);
        setUserDetailsTitle("Field details for " + field.field.name);
        //if (document.getElementById("selectedField-" + field.field.name).id.split("-")[1] == field.field.name) {
        //    document.getElementById("selectedField-" + field.field.name).className = "sc-container sc-userprojects-item-container sc-highlighted";
        //}

        /* } else setUserDetailsOpen(false);*/
    };

    const ClearFieldDetailsState = () => {
        setFieldDetails({
            precroponeyearone: "",
            precroponeyeartwo: "",
            precroponeyearthree: "",
            precroptwoyearone: "",
            precroptwoyeartwo: "",
            precroptwoyearthree: "",
            precropthreeyearone: "",
            precropthreeyeartwo: "",
            precropthreeyearthree: "",
            postcroponeyearone: "",
            postcroponeyeartwo: "",
            postcroponeyearthree: "",
            postcroptwoyearone: "",
            postcroptwoyeartwo: "",
            postcroptwoyearthree: "",
            postcropthreeyearone: "",
            postcropthreeyeartwo: "",
            postcropthreeyearthree: "",
            preirr: "",
            postirr: "",
            presoilmoisture: false,
            preflow: false,
            preet: false,
            preno: false,
            postsoilmoisture: false,
            postflow: false,
            postet: false,
            postno: false,
        });
        setPreSoilMoistureState(false);
        setPreFlowState(false);
        setPreETState(false);
        setPreNoState(false);
        setPostSoilMoistureState(false);
        setPostFlowState(false);
        setPostETState(false);
        setPostNoState(false);
    };
    const onFieldCopyClick = (field) => {
        var cArr = [];
        for (var i = 0; i < fields.length; i++) {
            if (field.field.name != fields[i].name) {
                cArr.push(fields[i]);
            }
        }
        setCopyArr(cArr);
        setCopyFieldDetailsOpen(true);
        // console.log("This is cArr");
        // console.log(cArr);
        // console.log("This is cArr");

        /* } else setUserDetailsOpen(false);*/
    };
    const onPreProjEditClick = (preprojectpump) => {
        for (var i = 0; i < preprojectpumps.length; i++) {
            if (preprojectpump.preprojectpump.preprojpumpName != preprojectpumps[i].preprojpumpName) {
                document.getElementById("selectedPreProj-" + preprojectpumps[i].preprojpumpName).className = "sc-container sc-userprojects-item-container sc-nohighlight";
            }
        }

        //if (document.getElementById("selectedPreProj-" + preprojectpump.preprojectpump.preprojpumpName).id.split("-")[1] == preprojectpump.preprojectpump.preprojpumpName) {
        //    document.getElementById("selectedPreProj-" + preprojectpump.preprojectpump.preprojpumpName).className = "sc-container sc-userprojects-item-container sc-highlighted";
        //}
        setEditPumpOpen(true);
    };
    const onPostProjEditClick = (postprojectpump) => {
        for (var i = 0; i < postprojectpumps.length; i++) {
            if (postprojectpump.postprojectpump.postprojpumpName != postprojectpumps[i].postprojpumpName) {
                document.getElementById("selectedPostProj-" + postprojectpumps[i].postprojpumpName).className = "sc-container sc-userprojects-item-container sc-nohighlight";
            }
        }

        //if (document.getElementById("selectedPostProj-" + postprojectpump.postprojectpump.postprojpumpName).id.split("-")[1] == postprojectpump.postprojectpump.postprojpumpName) {
        //    document.getElementById("selectedPostProj-" + postprojectpump.postprojectpump.postprojpumpName).className = "sc-container sc-userprojects-item-container sc-highlighted";
        //}
        setEditPostPumpOpen(true);
    };
    const onFieldClickEdit = (field) => {
        /* if (fieldJustCreated) {*/


        /* } else setUserDetailsOpen(false);*/
    };
    // DRAW START
    const onDrawStart = (evt) => {
        // ENABLE PARCEL CLICK
        window.disableParcelClick = true;

        // ADD DRAWN FEATURE TO MAIN SOURCE
        vectorSource.addFeature(evt.feature);
    };

    // DRAW END
    const onDrawEnd = (evt) => {
        window.map.removeInteraction(draw);

        FieldSidebarHelpers.controlDoubleClickZoom(false);
        setTimeout(() => {
            FieldSidebarHelpers.controlDoubleClickZoom(true);

            // RE-ENABLE PARCEL CLICK
            window.disableParcelClick = false;
        }, 1000);

        // ADD NEW ITEM

        if (drawType === "Point") {
            if (addntlPumpClicked) {
                if (currentUser.postprojectpumps.length < 5) {
                    addPostPumpClicked(evt.feature);
                } else {
                    setMaxPostProjPumpsWarn(true);
                }
            } else if (existPumpClicked) {
                if (currentUser.preprojectpumps.length < 5) {
                    addPumpClicked(evt.feature);
                } else {
                    setMaxPreProjPumpsWarn(true);
                }
            }
        } else {
            addNewItem(evt.feature);
        }

        // WAIT A BIT TO AVOID MAP CLICK EVENT
        setTimeout(() => {
            draw = null;
        }, 1000);
    };

    const setDrawControl = () => {
        // REMOVE THE LAST DRAW
        if (draw !== null) window.map.removeInteraction(draw);

        // DO NOTHING IF ITS CANCEL
        if (drawType === "Cancel") {
            return;
        }

        // GET DRAW TYPE
        let localDrawType = drawType;

        // DELETE/REMOVE TOOL
        if (localDrawType === "Eraser") return;

        if (localDrawType === "Rectangle") localDrawType = "Circle";
        else if (localDrawType === "Arrow") localDrawType = "LineString";
        else if (localDrawType === "Text") localDrawType = "Point";
        else if (localDrawType === "Point") localDrawType = "Point";

        //console.log('Draw style in set draw control: ', drawStyle);

        // CREATE A NEW DRAW
        draw = new Draw({
            features: new Collection([]),
            type: localDrawType,
            geometryFunction: drawType === "Rectangle" ? createBox() : undefined,
            style: drawStyle,
        });

        // END DRAWING
        draw.on("drawend", (event) => {
            onDrawEnd(event);
        });

        // START DRAWING
        draw.on("drawstart", (event) => {
            onDrawStart(event);
        });
        //   console.log(draw);
        //ADD DRAW INTERACTION TO MAP
        window.map.addInteraction(draw);
    };

    const updateStyle = () => {
        setDrawStyle(FieldSidebarHelpers.getDefaultDrawStyle(drawColor));
    };

    const getSelectedField = () => {
        if (fieldJustCreated) {
            setSelectedField[fieldJustCreated];
            return fieldJustCreated;
        }
        return "";
        //return Object.assign({}, selectedField);
    };
    const handleReportClick = () => {
        
        history.push("/user/Report");
        document.getElementById("ReportsTab").removeAttribute("disabled");
    };

    const canViewReports = () => {
        
        var enableReportBtns = true;
        var fieldElem = document.getElementById("viewFieldReportStatusText");
        var prePumpElem = document.getElementById("viewPrePumpReportStatusText");
        var postPumpElem = document.getElementById("viewPostPumpReportStatusText");
      
        if (fields.length > 0) {
            if (fieldElem != null) {
                
                if (fieldElem.value == 1) {
                    enableReportBtns = false;
                    if (preprojectpumps.length > 0) {
                        if (prePumpElem != null) {
                            if (prePumpElem.value == 1) {
                                if (postprojectpumps.length > 0) {
                                    if (postPumpElem != null) {
                                        if (postPumpElem.value == 1) {
                                            enableReportBtns = false;
                                        }
                                        else {
                                            enableReportBtns = true;
                                        }
                                    }
                                }
                            }
                            else {
                                enableReportBtns = true;
                            }
                        }
                    }
                    else {
                        if (postprojectpumps.length > 0) {
                            if (postPumpElem != null) {
                                if (postPumpElem.value == 1) {
                                    enableReportBtns = false;
                                }
                                else {
                                    enableReportBtns = true;
                                }
                            }
                        }
                    }
                }

                else  {
                    enableReportBtns = true;
                }



                
                
            }

            else {
                enableReportBtns = true;
            }
        }
        else {
            enableReportBtns = true;
        }
        
        return enableReportBtns;
    }; 
    const downloadMap = () => {
        window.map.once("rendercomplete", function () {
            const mapCanvas = document.createElement("canvas");
            const size = window.map.getSize();
            mapCanvas.width = size[0];
            mapCanvas.height = size[1];
            const mapContext = mapCanvas.getContext("2d");
            Array.prototype.forEach.call(window.map.getViewport().querySelectorAll(".ol-layer canvas, canvas.ol-layer"), function (canvas) {
                if (canvas.width > 0) {
                    const opacity = canvas.parentNode.style.opacity || canvas.style.opacity;
                    mapContext.globalAlpha = opacity === "" ? 1 : Number(opacity);
                    let matrix;
                    const transform = canvas.style.transform;
                    if (transform) {
                        // Get the transform parameters from the style's transform matrix
                        matrix = transform
                            .match(/^matrix\(([^\(]*)\)$/)[1]
                            .split(",")
                            .map(Number);
                    } else {
                        matrix = [parseFloat(canvas.style.width) / canvas.width, 0, 0, parseFloat(canvas.style.height) / canvas.height, 0, 0];
                    }
                    // Apply the transform to the export map context
                    CanvasRenderingContext2D.prototype.setTransform.apply(mapContext, matrix);
                    const backgroundColor = canvas.parentNode.style.backgroundColor;
                    if (backgroundColor) {
                        mapContext.fillStyle = backgroundColor;
                        mapContext.fillRect(0, 0, canvas.width, canvas.height);
                    }
                    mapContext.drawImage(canvas, 0, 0);
                }
            });
            mapContext.globalAlpha = 1;
            mapContext.setTransform(1, 0, 0, 1, 0, 0);

            const pdf = new jsPDF("landscape");
            pdf.addImage(mapCanvas.toDataURL("image/jpeg"), "JPEG", 50, 10, 180, 150);
            pdf.save("map.pdf");
        });
        window.map.renderSync();
    };

    const getDefaultArea = (feature) => {
        let area = 0;

        let formatArea = function (polygon) {
            var area = getArea(polygon);
            var output = parseFloat((area / 4047).toFixed(2));
            return output;
        };

        if (feature) if (feature.getGeometry()) if (feature.getGeometry() instanceof Polygon) area = formatArea(feature.getGeometry());

        return area;
    };

    const importGeometries = () => {
        if (vectorLayer) {
            vectorLayer.getSource().clear();

            fields.map((field) => {
                const style = FieldSidebarHelpers.getStyleFromJSON(field.feature.style, field.feature.pointType);

                let feature = helpers.getFeatureFromGeoJSON(field.feature.featureGeoJSON);

                // VISIBILITY
                if (feature != null) {
                    if (field.feature.visible) feature.setStyle(style);
                    else feature.setStyle(new Style({}));

                    feature.values_.label = field.name;

                    vectorLayer.getSource().addFeature(feature);
                    //var coordinates = vectorLayer.getSource().getFeatures()
                    //    .map(function (f) { return f.getGeometry().getCoordinates(); });

                    //var extent = boundingExtent(coordinates);

                    // LABELS
                    if (field.feature) {
                        if (field.feature.visible) FieldSidebarHelpers.setFeatureLabel(field.feature);
                    }
                }
            });

            /*  vectorLayer.getSource().once('change', function (e) {*/
            if (vectorSource.getState() === "ready") {
                if (vectorSource.getFeatures().length > 0) {
                    window.map.getView().fit(vectorSource.getExtent(), window.map.getSize());
                    // console.log("now this is the size", window.map.getSize());
                }
            }
            /* });*/
        }
      
    };

    const importpoints = () => {
        if (pinLayer) {
            pinLayer.getSource().clear();

            preprojectpumps.map((preprojectpump) => {
                if (preprojectpump.preprojfeature != undefined) {
                    var prefeaturejson = JSON.parse(preprojectpump.preprojfeature);
                    var prefeature = helpers.getFeatureFromGeoJSON(prefeaturejson.featureGeoJSON);
                    const prepumpstyle = new Style({
                        image: new Icon({
                            anchor: [0.5, 1],
                            //src: "http://openlayers.org/en/v3.20.1/examples/data/icon.png"
                            src: premarkerpng,
                        }),
                        text: new Text({
                            //text: '',
                            scale: 1.3,
                            fill: new Fill({
                                color: "#000000",
                            }),
                        }),
                    });
                    // console.log("this is prepump: ", prefeature);
                    // VISIBILITY
                    if (prefeature != null) {
                        if (prefeaturejson.visible) prefeature.setStyle(prepumpstyle);
                        else prefeature.setStyle(new Style({}));

                        //prefeature.values_.label = preprojectpump.preprojpumpName;
                        //alert(prefeature.values_.label );
                        //x = prefeature.values_.label;

                        prefeature.style_.text_.text_ = preprojectpump.preprojpumpName;

                        pinLayer.getSource().addFeature(prefeature);

                        // LABELS
                        //if (preprojectpump.preprojfeature) {

                        //    if (prefeaturejson.visible)
                        //        FieldSidebarHelpers.setFeatureLabel(prefeaturejson);
                        //}
                    }
                }
            });

            //if (preprojectpumps.length===0) {
            //    alert('ok I am here');
            //    updateStyle();
            //    importGeometries();
            //}
        }
    };

    const importpointsPost = () => {
        if (pinLayer) {
            pinLayer.getSource().clear();
            importpoints();

            postprojectpumps.map((postprojectpump) => {
               
                if (postprojectpump.postprojisprepump == "N") {
                    if (postprojectpump.postprojfeature != undefined) {
                        var postfeaturejson = JSON.parse(postprojectpump.postprojfeature);
                        var postfeature = helpers.getFeatureFromGeoJSON(postfeaturejson.featureGeoJSON);
                        const postpumpstyle = new Style({
                            image: new Icon({
                                anchor: [0.5, 1],
                                //src: "http://openlayers.org/en/v3.20.1/examples/data/icon.png"
                                src: postmarkerpng,
                            }),
                            text: new Text({
                                //text: '',
                                scale: 1.3,
                                fill: new Fill({
                                    color: "#000000",
                                }),
                            }),
                        });
                        // console.log("this is postpump: ", postfeature);
                        // VISIBILITY
                        if (postfeature != null) {
                            if (postfeaturejson.visible) postfeature.setStyle(postpumpstyle);
                            else postfeature.setStyle(new Style({}));

                            postfeature.style_.text_.text_ = postprojectpump.postprojpumpName;

                            pinLayer.getSource().addFeature(postfeature);
                        }
                    }
                }
            });
        }
    };

    //TODO if we want to add a popup on click
    const showDrawingOptionsPopup = (feature, evt = null, activeTool = "none") => {
        // GET FEATURE AND CENTER
        var featureId = feature.getProperties().id;
        // var item = getFieldByFeatureId(featureId);

        let center = null;

        if (evt === null) {
            const geo = new GeoJSON().writeFeatureObject(feature);
            var feature = new GeoJSON().readFeature(turf.centroid(geo));
            center = feature.getGeometry().flatCoordinates;
        } else {
            center = evt.coordinate;
        }
        // console.log(item);
    };

    const removeItemFromVectorSource = (idParam) => {
        vectorSource.getFeatures().forEach((feature) => {
            const id = feature.getProperties().id;
            if (id === idParam) vectorSource.removeFeature(feature);
            return;
        });
    };

    const addNewItem = (feature, labelText = null, fromEmmiter = false) => {
        // console.log("NEW FEATURE", feature);

        // UID FOR FEATURE

        const featureId = new Date().valueOf().toString();

        // NEW NAME OF FEATURE
        let numOfFields = 0;

        if (currentUser) if (currentUser.fields) if (currentUser.fields.length > 0) numOfFields = currentUser.fields.length;

        //if (labelText === null) labelText = "Field " + (numOfFields + 1);

        //if (labelText === null) labelText = (fieldJustCreated) ? fieldJustCreated.name : "Field " + (numOfFields + 1);

        // GIVE TEXT A CUSTOM MESSAGE
        if (drawType === "Text") labelText = "Enter Custom Text";

        let customStyle = null;
        if (!fromEmmiter) {
            if (drawType === "Text") {
                labelText = "Enter Custom Text";
                customStyle = FieldSidebarHelpers.getDefaultDrawStyle(drawColor, true, undefined, undefined, feature.getGeometry().getType());
                feature.setStyle(customStyle);
            } else {
                customStyle = FieldSidebarHelpers.getDefaultDrawStyle(drawColor, undefined, undefined, undefined, feature.getGeometry().getType());
                feature.setStyle(customStyle);
            }
        } else customStyle = feature.getStyle();

        feature.setProperties({
            id: featureId,
            label: labelText,
            labelVisible: true,
            drawType: drawType,
            isParcel: false,
        });

        // CONVERT CIRCLE TO POLYGON (GEOJSON DOESNT SUPPORT CIRCLES)
        if (feature.getGeometry() !== undefined && feature.getGeometry().getType() === "Circle") {
            var polygon = fromCircle(feature.getGeometry());
            feature.setGeometry(polygon);
        }

        var lonLat = toLonLat(getCenter(feature.getGeometry().getExtent()));

        // CREATE NEW ITEM
        const featureInfo = {
            id: featureId,
            label: labelText,
            labelVisible: true,
            labelStyle: null,
            labelRotation: 0,
            featureGeoJSON: new GeoJSON({
                dataProjection: "EPSG:3857",
                featureProjection: "EPSG:3857",
            }).writeFeature(feature, {
                dataProjection: "EPSG:3857",
                featureProjection: "EPSG:3857",
            }),
            style: customStyle === null ? drawStyle : customStyle,
            visible: true,
            drawType: drawType,
            geometryType: feature.getGeometry().getType(),
        };

        let prespecies = {
            preyearcroponeyearone: "",
            preyearcroponeyeartwo: "",
            preyearcroponeyearthree: "",
            preyearcroptwoyearone: "",
            preyearcroptwoyeartwo: "",
            preyearcroptwoyearthree: "",
            preyearcropthreeyearone: "",
            preyearcropthreeyeartwo: "",
            preyearcropthreeyearthree: "",
        };
        let postspecies = {
            postyearcroponeyearone: "",
            postyearcroponeyeartwo: "",
            postyearcroponeyearthree: "",
            postyearcroptwoyearone: "",
            postyearcroptwoyeartwo: "",
            postyearcroptwoyearthree: "",
            postyearcropthreeyearone: "",
            postyearcropthreeyeartwo: "",
            postyearcropthreeyearthree: "",
        };
        let prewatermgmt = {
            preyearsoilmoisture: false,
            preyearflow: false,
            preyearet: false,
            preyearno: false,
        };
        let postwatermgmt = {
            postyearsoilmoisture: false,
            postyearflow: false,
            postyearet: false,
            postyearno: false,
        };
        let FieldDets = {
            precropping: prespecies,
            postcropping: postspecies,
            preirr: "",
            postirr: "",
            prewater: prewatermgmt,
            postwater: postwatermgmt,
        };
        let newUserField = {
            name: labelText,
            id: crypto.randomUUID(),
            feature: JSON.stringify(featureInfo),
            userId: currentUser.id,
            fielddetails: FieldDets,
            acreage: getDefaultArea(feature),
            latLon: [lonLat[1], lonLat[0]],
            message: "showAddFieldSuccess",
        };

        setDrawType("Cancel");

        setFieldJustCreated(newUserField);

        if (fromEmmiter) {
            // FLAG AS PARCEL
            if (feature.get("arn") !== undefined) feature.setProperties({ isParcel: true });

            // ADD FEATURE TO MAIN SOURCE
            vectorSource.addFeature(feature);
        }
    };

    const okPumpImpField = () => {
        if (pumpImpChanged === "0") {
            document.getElementById("addpostprojectpump").style.display = "block";
            document.getElementById("doneallpumps").style.display = "block";
            document.getElementById("doneprepumps").style.display = "none";
        } else {
            document.getElementById("addpostprojectpump").style.display = "none";
            document.getElementById("doneallpumps").style.display = "none";
            document.getElementById("doneprepumps").style.display = "none";
        }
       
        setPumpImpOpen(false);
    };
    const saveEditField = () => {
        resetEditDialogs();
        let editUserField = {
            name: fieldJustEdited.name,
            feature: fieldJustEdited.feature,
            userId: fieldJustEdited.id,
            acreage: fieldJustEdited.acreage,
            message: "showEditFieldSuccess",
        };

        setSelectedField[editUserField];
    };

    const saveNewPostPump = () => {
        let newPostProjectPump = {
            postprojpumpId: crypto.randomUUID(),
            postprojpumpName: postPumpinputs.postpumpName,
            postprojfields: postPumpinputs.postfields,
            postprojhp: postPumpinputs.posthp,
            postprojeff: postPumpinputs.posteff,
            postprojhours: postPumpinputs.posthours,
            postprojsyspress: postPumpinputs.postsyspress,
            postprojpumpress: postPumpinputs.postpumpress,
            postprojpumpdepth: postPumpinputs.postpumpdepth,
            postprojdispress: postPumpinputs.postdispress,
            postprojfricloss: postPumpinputs.postfricloss,
            postprojvfd: postPumpinputs.postvfd,
            postprojfueltype: postPumpinputs.postfueltype,
            postprojfueluse: postPumpinputs.postfueluse,
            postprojfeature: postPumpinputs.postfeature,
            postprojrenewenrgysel: postPumpinputs.postrenewenrgysel,
            postprojrenewenrgycapcty: postPumpinputs.postrenewenrgycapcty,
            postprojisprepump: "N",
        };

        setPostProjectPumpJustCreated(newPostProjectPump);

        for (var i = 0; i < pinLayer.getSource().getFeatures().length; i++) {
            if (pinLayer.getSource().getFeatures()[i].style_.text_.text_ === undefined) {
                pinLayer.getSource().getFeatures()[i].style_.text_.text_ = postPumpinputs.postpumpName;
            }
        }

        var currentUserpost = Object.assign({}, currentUser);
        if (currentUserpost) {
            if (currentUserpost.postprojectpumps) {
                currentUserpost.postprojectpumps.push(newPostProjectPump);

                dispatch(updateUser(currentUserpost));
            } else {
                currentUserpost.postprojectpumps = new Array();
                currentUserpost.postprojectpumps.push(newPostProjectPump);

                dispatch(updateUser(currentUserpost));
               
            }
        } else {
            console.log("current user or field just created is null");
        }

        window.map.getView().setZoom(window.map.getView().getZoom() - 5);
        setAddPostPumpOpen(false);
    };

    const cancelNewPostPump = () => {
        if (postmarkerJustCreated) {
            postmarkerJustCreated.setStyle("");
            pumpVectorSource.removeFeature(postmarkerJustCreated);

        }

        setAddPostPumpOpen(false);
    };
    /*Add new field to list of fields.
      Save current user object.   */
    const saveCopyFieldDetails = () => {
        setCopyFieldDetailsOpen(false);
        let selectedfields = [];
        var fieldToCopyFrom = null;
        var currentUserSelFields = Object.assign({}, currentUser);
        for (var i = 0; i < fieldsToCopy.length; i++) {
            for (var m = 0; m < currentUserSelFields.fields.length; m++) {
                if (fieldsToCopy[i] == currentUserSelFields.fields[m].name) {
                    selectedfields.push(currentUserSelFields.fields[m]);
                }
            }
        }

        for (var n = 0; n < currentUserSelFields.fields.length; n++) {
            if (userDetSelField.field.name == currentUserSelFields.fields[n].name) {
                fieldToCopyFrom = currentUserSelFields.fields[n];
            }
        }

        var currentUserCopyFields = Object.assign({}, currentUser);

        for (var t = 0; t < selectedfields.length; t++) {
            for (var z = 0; z < currentUserCopyFields.fields.length; z++) {
                if (selectedfields[t].name == currentUserCopyFields.fields[z].name) {
                    currentUserCopyFields.fields[z].fielddetails.precropping.preyearcroponeyearone = fieldToCopyFrom.fielddetails.precropping.preyearcroponeyearone;
                    currentUserCopyFields.fields[z].fielddetails.precropping.preyearcroponeyeartwo = fieldToCopyFrom.fielddetails.precropping.preyearcroponeyeartwo;
                    currentUserCopyFields.fields[z].fielddetails.precropping.preyearcroponeyearthree = fieldToCopyFrom.fielddetails.precropping.preyearcroponeyearthree;
                    currentUserCopyFields.fields[z].fielddetails.precropping.preyearcroptwoyearone = fieldToCopyFrom.fielddetails.precropping.preyearcroptwoyearone;
                    currentUserCopyFields.fields[z].fielddetails.precropping.preyearcroptwoyeartwo = fieldToCopyFrom.fielddetails.precropping.preyearcroptwoyeartwo;
                    currentUserCopyFields.fields[z].fielddetails.precropping.preyearcroptwoyearthree = fieldToCopyFrom.fielddetails.precropping.preyearcroptwoyearthree;
                    currentUserCopyFields.fields[z].fielddetails.precropping.preyearcropthreeyearone = fieldToCopyFrom.fielddetails.precropping.preyearcropthreeyearone;
                    currentUserCopyFields.fields[z].fielddetails.precropping.preyearcropthreeyeartwo = fieldToCopyFrom.fielddetails.precropping.preyearcropthreeyeartwo;
                    currentUserCopyFields.fields[z].fielddetails.precropping.preyearcropthreeyearthree = fieldToCopyFrom.fielddetails.precropping.preyearcropthreeyearthree;

                    currentUserCopyFields.fields[z].fielddetails.postcropping.postyearcroponeyearone = fieldToCopyFrom.fielddetails.postcropping.postyearcroponeyearone;
                    currentUserCopyFields.fields[z].fielddetails.postcropping.postyearcroponeyeartwo = fieldToCopyFrom.fielddetails.postcropping.postyearcroponeyeartwo;
                    currentUserCopyFields.fields[z].fielddetails.postcropping.postyearcroponeyearthree = fieldToCopyFrom.fielddetails.postcropping.postyearcroponeyearthree;
                    currentUserCopyFields.fields[z].fielddetails.postcropping.postyearcroptwoyearone = fieldToCopyFrom.fielddetails.postcropping.postyearcroptwoyearone;
                    currentUserCopyFields.fields[z].fielddetails.postcropping.postyearcroptwoyeartwo = fieldToCopyFrom.fielddetails.postcropping.postyearcroptwoyeartwo;
                    currentUserCopyFields.fields[z].fielddetails.postcropping.postyearcroptwoyearthree = fieldToCopyFrom.fielddetails.postcropping.postyearcroptwoyearthree;
                    currentUserCopyFields.fields[z].fielddetails.postcropping.postyearcropthreeyearone = fieldToCopyFrom.fielddetails.postcropping.postyearcropthreeyearone;
                    currentUserCopyFields.fields[z].fielddetails.postcropping.postyearcropthreeyeartwo = fieldToCopyFrom.fielddetails.postcropping.postyearcropthreeyeartwo;
                    currentUserCopyFields.fields[z].fielddetails.postcropping.postyearcropthreeyearthree = fieldToCopyFrom.fielddetails.postcropping.postyearcropthreeyearthree;

                    currentUserCopyFields.fields[z].fielddetails.preirr = fieldToCopyFrom.fielddetails.preirr;
                    currentUserCopyFields.fields[z].fielddetails.postirr = fieldToCopyFrom.fielddetails.postirr;

                    currentUserCopyFields.fields[z].fielddetails.prewater.preyearsoilmoisture = fieldToCopyFrom.fielddetails.prewater.preyearsoilmoisture;
                    currentUserCopyFields.fields[z].fielddetails.prewater.preyearflow = fieldToCopyFrom.fielddetails.prewater.preyearflow;
                    currentUserCopyFields.fields[z].fielddetails.prewater.preyearet = fieldToCopyFrom.fielddetails.prewater.preyearet;
                    currentUserCopyFields.fields[z].fielddetails.prewater.preyearno = fieldToCopyFrom.fielddetails.prewater.preyearno;

                    currentUserCopyFields.fields[z].fielddetails.postwater.postyearsoilmoisture = fieldToCopyFrom.fielddetails.postwater.postyearsoilmoisture;
                    currentUserCopyFields.fields[z].fielddetails.postwater.postyearflow = fieldToCopyFrom.fielddetails.postwater.postyearflow;
                    currentUserCopyFields.fields[z].fielddetails.postwater.postyearet = fieldToCopyFrom.fielddetails.postwater.postyearet;
                    currentUserCopyFields.fields[z].fielddetails.postwater.postyearno = fieldToCopyFrom.fielddetails.postwater.postyearno;
                }
            }
        }

        dispatch(updateUser(currentUserCopyFields));
    };
    const saveNewPump = () => {
        // resetDialogs();

        var id = crypto.randomUUID();
        var postispre = "U";
        let newPreProjectPump = {
            preprojpumpId: id,
            preprojpumpName: pumpinputs.prepumpName,
            preprojfields: pumpinputs.prefields,
            preprojhp: pumpinputs.prehp,
            preprojeff: pumpinputs.preeff,
            preprojhours: pumpinputs.prehours,
            preprojsyspress: pumpinputs.presyspress,
            preprojpumpress: pumpinputs.prepumpress,
            preprojpumpdepth: pumpinputs.prepumpdepth,
            preprojdispress: pumpinputs.predispress,
            preprojfricloss: pumpinputs.prefricloss,
            preprojvfd: pumpinputs.prevfd,
            preprojfueltype: pumpinputs.prefueltype,
            preprojfueluse: pumpinputs.prefueluse,
            preprojimprovques: pumpinputs.preimprovques,
            preprojstayinuseques: pumpinputs.prestayinuseques,
            preprojfeature: pumpinputs.prefeature,
            postprojhp: pumpinputs.posthp,
            postprojeff: pumpinputs.posteff,
            postprojhours: pumpinputs.posthours,
            postprojsyspress: pumpinputs.postsyspress,
            postprojpumpress: pumpinputs.postpumpress,
            postprojpumpdepth: pumpinputs.postpumpdepth,
            postprojdispress: pumpinputs.postdispress,
            postprojfricloss: pumpinputs.postfricloss,
            postprojvfd: pumpinputs.postvfd,
            postprojfueltype: pumpinputs.postfueltype,
            postprojfueluse: pumpinputs.postfueluse,
            postprojrenewenrgysel: pumpinputs.postrenewenrgysel,
            postprojrenewenrgycapcty: pumpinputs.postrenewenrgycapcty,
        };

        setPreProjectPumpJustCreated(newPreProjectPump);
       
        if (pumpinputs.preimprovques === "0" || pumpinputs.prestayinuseques === "0") {
            postispre = "Y";
        } else {
            postispre = "U";
        }

        var pumpUnimproved = false;
        
        if (pumpinputs.prestayinuseques === "0" && pumpinputs.preimprovques === "0") {
            // if the user has selected that the pump will not be improved but will stay in use, we
            // want to populate the post-project pump with the same values as the pre-project pump
            pumpUnimproved = false;
        }
        else if (pumpinputs.prestayinuseques === "1" && pumpinputs.preimprovques === "0") {
            pumpUnimproved = false;
        }
        else if (pumpinputs.prestayinuseques === "0" && pumpinputs.preimprovques === "1") {
            pumpUnimproved = true;
        }
        
        let newPrePostProjectPump = {
            postprojpumpId: id,
            postprojpumpName: pumpinputs.prepumpName,
            postprojfields: pumpinputs.prefields,
            postprojhp: pumpUnimproved ? pumpinputs.prehp : pumpinputs.posthp,
            postprojeff: pumpUnimproved ? pumpinputs.preeff : pumpinputs.posteff,
            postprojhours: pumpUnimproved ? pumpinputs.prehours : pumpinputs.posthours,
            postprojsyspress: pumpUnimproved ? pumpinputs.presyspress : pumpinputs.postsyspress,
            postprojpumpress: pumpUnimproved ? pumpinputs.prepumpress : pumpinputs.postpumpress,
            postprojpumpdepth: pumpUnimproved ? pumpinputs.prepumpdepth : pumpinputs.postpumpdepth,
            postprojdispress: pumpUnimproved ? pumpinputs.predispress : pumpinputs.postdispress,
            postprojfricloss: pumpUnimproved ? pumpinputs.prefricloss : pumpinputs.postfricloss,
            postprojvfd: pumpUnimproved ? pumpinputs.prevfd : pumpinputs.postvfd,
            postprojfueltype: pumpUnimproved ? pumpinputs.prefueltype : pumpinputs.postfueltype,
            postprojfueluse: pumpUnimproved ? '' : pumpinputs.postfueluse,
            postprojfeature: pumpinputs.prefeature,
            postprojrenewenrgysel: pumpinputs.postrenewenrgysel,
            postprojrenewenrgycapcty: pumpinputs.postrenewenrgycapcty,
            postprojisprepump: postispre,
        };
       
        //setPostProjectPumpJustCreated(newPrePostProjectPump)

        for (var i = 0; i < pinLayer.getSource().getFeatures().length; i++) {
            if (pinLayer.getSource().getFeatures()[i].style_.text_.text_ === undefined) {
                pinLayer.getSource().getFeatures()[i].style_.text_.text_ = pumpinputs.prepumpName;
            }
        }

        var currentUser1 = Object.assign({}, currentUser);

        /*  if (currentUser1 && preprojectpumpJustCreated) {*/
        if (currentUser1) {
            if (currentUser1.preprojectpumps) {
                currentUser1.preprojectpumps.push(newPreProjectPump);
                if (newPrePostProjectPump.postprojisprepump == "Y") {
                    currentUser1.postprojectpumps.push(newPrePostProjectPump);
                }

                dispatch(updateUser(currentUser1));
            } else {
                currentUser1.preprojectpumps = new Array();
                currentUser1.preprojectpumps.push(newPreProjectPump);
                if (newPrePostProjectPump.postprojisprepump == "Y") {
                    currentUser1.postprojectpumps = new Array();
                    currentUser1.postprojectpumps.push(newPrePostProjectPump);
                }

                dispatch(updateUser(currentUser1));
                
            }
        } else {
            console.log("current user or field just created is null");
        }

       
        window.map.getView().setZoom(window.map.getView().getZoom() - 5);
        setAddPumpOpen(false);
    };
    const saveNewField = () => {
        resetDialogs();

        var currentUserCopy = Object.assign({}, currentUser);

        if (currentUserCopy && fieldJustCreated) {
            if (currentUserCopy.fields) {
                var fieldNamesArr = new Array();
                for (var k = 0; k < currentUserCopy.fields.length; k++) {
                    fieldNamesArr.push(currentUserCopy.fields[k].name.toUpperCase());
                }

                if (currentUserCopy.fields.length < 5) {
                    if (fieldNamesArr.includes(fieldJustCreated.name.toUpperCase())) {
                        setFieldNameWarnPopOpen(true);
                    } else {
                        currentUserCopy.fields.push(fieldJustCreated);

                        dispatch(updateUser(currentUserCopy));
                        callSuccessPopup();
                    }
                } else {
                    setMaxFieldsWarn(true);
                }
            } else {
                currentUserCopy.fields = new Array();
                currentUserCopy.fields.push(fieldJustCreated);

                dispatch(updateUser(currentUserCopy));
                callSuccessPopup();
            }
        } else {
            console.log("current user or field just created is null");
        }
    };
    const resetEditDialogs = () => {
        setShowEditNewField(false);
        setShowEditName(false);
    };
    const resetDialogs = () => {
        setShowAddNewField(false);
    };
    const cancelEditField = () => {
        resetEditDialogs();
    };
    const cancelNewField = () => {
        if (fieldJustCreated) {
            var featureObj = JSON.parse(fieldJustCreated.feature);

            removeItemFromVectorSource(featureObj.id);
            resetDialogs();
        }
    };
    const SubmitElecDetails = () => {

        let newElectricityDets = {
            annualkwh: (currentUser.electricity != null) ? currentUser.electricity[0] != undefined ? ((currentUser.electricity[0].annualkwh == "") || (currentUser.electricity[0].annualkwh != "" && currentUser.electricity[0].annualkwh != electricityDetails.kwh && electricityDetails.kwh != "")) ? electricityDetails.kwh : currentUser.electricity[0].annualkwh : ((currentUser.electricity.annualkwh != "" && currentUser.electricity.annualkwh != electricityDetails.kwh && electricityDetails.kwh != "") || (currentUser.electricity.annualkwh == "")) ? electricityDetails.kwh : currentUser.electricity.annualkwh : electricityDetails.kwh,
            irrgtnhardware: (currentUser.electricity != null) ? currentUser.electricity[0] != undefined ? ((currentUser.electricity[0].irrgtnhardware == "") || (currentUser.electricity[0].irrgtnhardware != "" && currentUser.electricity[0].irrgtnhardware != electricityDetails.irrhardware && electricityDetails.irrhardware != "1")) ? electricityDetails.irrhardware : currentUser.electricity[0].irrgtnhardware : ((currentUser.electricity.irrgtnhardware != "" && currentUser.electricity.irrgtnhardware != electricityDetails.irrhardware && electricityDetails.irrhardware != "1") || (currentUser.electricity.irrgtnhardware == "")) ? electricityDetails.irrhardware : currentUser.electricity.irrgtnhardware : electricityDetails.irrhardware,
        };

        var currentUserElec = Object.assign({}, currentUser);
        if (currentUserElec) {
            currentUserElec.electricity = newElectricityDets;

            setElectricityPopUpOpen(false);

            dispatch(updateUser(currentUserElec));
        } else {
            console.log("current user or field just created is null");
        }
    };
    const SubmitRenewEnrgy = () => {
        
        let newRenewEnrgy = {
            renewcapcty: (currentUser.renewenrgycapcty != null) ? currentUser.renewenrgycapcty[0] != undefined ? ((currentUser.renewenrgycapcty[0].renewcapcty == "") || (currentUser.renewenrgycapcty[0].renewcapcty != "" && currentUser.renewenrgycapcty[0].renewcapcty != renewEnrgyDets.capcty && renewEnrgyDets.capcty != "")) ? renewEnrgyDets.renewcapcty : currentUser.renewenrgycapcty[0].renewcapcty : ((currentUser.renewenrgycapcty.renewcapcty != "" && currentUser.renewenrgycapcty.renewcapcty != renewEnrgyDets.capcty && renewEnrgyDets.capcty != "") || (currentUser.renewenrgycapcty.renewcapcty == "")) ? renewEnrgyDets.capcty : currentUser.renewenrgycapcty.renewcapcty : renewEnrgyDets.capcty,
            
        };

        var currentUserRenewEnrgy = Object.assign({}, currentUser);
        if (currentUserRenewEnrgy) {
            currentUserRenewEnrgy.renewenrgycapcty = newRenewEnrgy;

            setRenewenrgyPopUpOpen(false);

            dispatch(updateUser(currentUserRenewEnrgy));
        } else {
            console.log("current user or field just created is null");
        }
    };
    
    const submitFieldDetails = () => {
        setUserDetailsOpen(false);

        // console.log("This is field details", fieldDetails);

        if (!Object.values(fieldDetails).every((x) => x === "" || x === undefined)) {
            let prespecies = {
                preyearcroponeyearone: fieldDetails.precroponeyearone,
                preyearcroponeyeartwo: fieldDetails.precroponeyeartwo,
                preyearcroponeyearthree: fieldDetails.precroponeyearthree,
                preyearcroptwoyearone: fieldDetails.precroptwoyearone,
                preyearcroptwoyeartwo: fieldDetails.precroptwoyeartwo,
                preyearcroptwoyearthree: fieldDetails.precroptwoyearthree,
                preyearcropthreeyearone: fieldDetails.precropthreeyearone,
                preyearcropthreeyeartwo: fieldDetails.precropthreeyeartwo,
                preyearcropthreeyearthree: fieldDetails.precropthreeyearthree,
            };
            let postspecies = {
                postyearcroponeyearone: fieldDetails.postcroponeyearone,
                postyearcroponeyeartwo: fieldDetails.postcroponeyeartwo,
                postyearcroponeyearthree: fieldDetails.postcroponeyearthree,
                postyearcroptwoyearone: fieldDetails.postcroptwoyearone,
                postyearcroptwoyeartwo: fieldDetails.postcroptwoyeartwo,
                postyearcroptwoyearthree: fieldDetails.postcroptwoyearthree,
                postyearcropthreeyearone: fieldDetails.postcropthreeyearone,
                postyearcropthreeyeartwo: fieldDetails.postcropthreeyeartwo,
                postyearcropthreeyearthree: fieldDetails.postcropthreeyearthree,
            };
            let prewatmgmt = {
                preyearsoilmoisture: fieldDetails.presoilmoisture,
                preyearflow: fieldDetails.preflow,
                preyearet: fieldDetails.preet,
                preyearno: fieldDetails.preno,
            };
            let postwatmgmt = {
                postyearsoilmoisture: fieldDetails.postsoilmoisture,
                postyearflow: fieldDetails.postflow,
                postyearet: fieldDetails.postet,
                postyearno: fieldDetails.postno,
            };
            let FieldDets = {
                precropping: prespecies,
                postcropping: postspecies,
                preirr: fieldDetails.preirr,
                postirr: fieldDetails.postirr,
                prewater: prewatmgmt,
                postwater: postwatmgmt,
            };
            // console.log("this is field details", FieldDets);
            // console.log(preSoilMoistureState);
            // console.log(preFlowState);
            // console.log(preETState);
            var currentUserFieldCopy = Object.assign({}, currentUser);

            if (currentUserFieldCopy) {
                if (currentUserFieldCopy.fields) {
                    for (var i = 0; i < currentUserFieldCopy.fields.length; i++) {
                        if (currentUserFieldCopy.fields[i].name == userDetSelField.field.name) {
                            currentUserFieldCopy.fields[i].fielddetails.precropping.preyearcroponeyearone = FieldDets.precropping.preyearcroponeyearone != "" ? FieldDets.precropping.preyearcroponeyearone : currentUserFieldCopy.fields[i].fielddetails.precropping.preyearcroponeyearone;
                            currentUserFieldCopy.fields[i].fielddetails.precropping.preyearcroponeyeartwo = FieldDets.precropping.preyearcroponeyeartwo != "" ? FieldDets.precropping.preyearcroponeyeartwo : currentUserFieldCopy.fields[i].fielddetails.precropping.preyearcroponeyeartwo;
                            currentUserFieldCopy.fields[i].fielddetails.precropping.preyearcroponeyearthree = FieldDets.precropping.preyearcroponeyearthree != "" ? FieldDets.precropping.preyearcroponeyearthree : currentUserFieldCopy.fields[i].fielddetails.precropping.preyearcroponeyearthree;
                            currentUserFieldCopy.fields[i].fielddetails.precropping.preyearcroptwoyearone = FieldDets.precropping.preyearcroptwoyearone != "" ? FieldDets.precropping.preyearcroptwoyearone : currentUserFieldCopy.fields[i].fielddetails.precropping.preyearcroptwoyearone;
                            currentUserFieldCopy.fields[i].fielddetails.precropping.preyearcroptwoyeartwo = FieldDets.precropping.preyearcroptwoyeartwo != "" ? FieldDets.precropping.preyearcroptwoyeartwo : currentUserFieldCopy.fields[i].fielddetails.precropping.preyearcroptwoyeartwo;
                            currentUserFieldCopy.fields[i].fielddetails.precropping.preyearcroptwoyearthree = FieldDets.precropping.preyearcroptwoyearthree != "" ? FieldDets.precropping.preyearcroptwoyearthree : currentUserFieldCopy.fields[i].fielddetails.precropping.preyearcroptwoyearthree;
                            currentUserFieldCopy.fields[i].fielddetails.precropping.preyearcropthreeyearone = FieldDets.precropping.preyearcropthreeyearone != "" ? FieldDets.precropping.preyearcropthreeyearone : currentUserFieldCopy.fields[i].fielddetails.precropping.preyearcropthreeyearone;
                            currentUserFieldCopy.fields[i].fielddetails.precropping.preyearcropthreeyeartwo = FieldDets.precropping.preyearcropthreeyeartwo != "" ? FieldDets.precropping.preyearcropthreeyeartwo : currentUserFieldCopy.fields[i].fielddetails.precropping.preyearcropthreeyeartwo;
                            currentUserFieldCopy.fields[i].fielddetails.precropping.preyearcropthreeyearthree = FieldDets.precropping.preyearcropthreeyearthree != "" ? FieldDets.precropping.preyearcropthreeyearthree : currentUserFieldCopy.fields[i].fielddetails.precropping.preyearcropthreeyearthree;

                            currentUserFieldCopy.fields[i].fielddetails.postcropping.postyearcroponeyearone = FieldDets.postcropping.postyearcroponeyearone != "" ? FieldDets.postcropping.postyearcroponeyearone : currentUserFieldCopy.fields[i].fielddetails.postcropping.postyearcroponeyearone;
                            currentUserFieldCopy.fields[i].fielddetails.postcropping.postyearcroponeyeartwo = FieldDets.postcropping.postyearcroponeyeartwo != "" ? FieldDets.postcropping.postyearcroponeyeartwo : currentUserFieldCopy.fields[i].fielddetails.postcropping.postyearcroponeyeartwo;
                            currentUserFieldCopy.fields[i].fielddetails.postcropping.postyearcroponeyearthree = FieldDets.postcropping.postyearcroponeyearthree != "" ? FieldDets.postcropping.postyearcroponeyearthree : currentUserFieldCopy.fields[i].fielddetails.postcropping.postyearcroponeyearthree;
                            currentUserFieldCopy.fields[i].fielddetails.postcropping.postyearcroptwoyearone = FieldDets.postcropping.postyearcroptwoyearone != "" ? FieldDets.postcropping.postyearcroptwoyearone : currentUserFieldCopy.fields[i].fielddetails.postcropping.postyearcroptwoyearone;
                            currentUserFieldCopy.fields[i].fielddetails.postcropping.postyearcroptwoyeartwo = FieldDets.postcropping.postyearcroptwoyeartwo != "" ? FieldDets.postcropping.postyearcroptwoyeartwo : currentUserFieldCopy.fields[i].fielddetails.postcropping.postyearcroptwoyeartwo;
                            currentUserFieldCopy.fields[i].fielddetails.postcropping.postyearcroptwoyearthree = FieldDets.postcropping.postyearcroptwoyearthree != "" ? FieldDets.postcropping.postyearcroptwoyearthree : currentUserFieldCopy.fields[i].fielddetails.postcropping.postyearcroptwoyearthree;
                            currentUserFieldCopy.fields[i].fielddetails.postcropping.postyearcropthreeyearone = FieldDets.postcropping.postyearcropthreeyearone != "" ? FieldDets.postcropping.postyearcropthreeyearone : currentUserFieldCopy.fields[i].fielddetails.postcropping.postyearcropthreeyearone;
                            currentUserFieldCopy.fields[i].fielddetails.postcropping.postyearcropthreeyeartwo = FieldDets.postcropping.postyearcropthreeyeartwo != "" ? FieldDets.postcropping.postyearcropthreeyeartwo : currentUserFieldCopy.fields[i].fielddetails.postcropping.postyearcropthreeyeartwo;
                            currentUserFieldCopy.fields[i].fielddetails.postcropping.postyearcropthreeyearthree = FieldDets.postcropping.postyearcropthreeyearthree != "" ? FieldDets.postcropping.postyearcropthreeyearthree : currentUserFieldCopy.fields[i].fielddetails.postcropping.postyearcropthreeyearthree;

                            currentUserFieldCopy.fields[i].fielddetails.preirr = FieldDets.preirr != "" ? FieldDets.preirr : currentUserFieldCopy.fields[i].fielddetails.preirr;
                            currentUserFieldCopy.fields[i].fielddetails.postirr = FieldDets.postirr != "" ? FieldDets.postirr : currentUserFieldCopy.fields[i].fielddetails.postirr;

                            currentUserFieldCopy.fields[i].fielddetails.prewater.preyearsoilmoisture = preSoilMoistureState ? FieldDets.prewater.preyearsoilmoisture : currentUserFieldCopy.fields[i].fielddetails.prewater.preyearsoilmoisture;
                            currentUserFieldCopy.fields[i].fielddetails.prewater.preyearflow = preFlowState ? FieldDets.prewater.preyearflow : currentUserFieldCopy.fields[i].fielddetails.prewater.preyearflow;
                            currentUserFieldCopy.fields[i].fielddetails.prewater.preyearet = preETState ? FieldDets.prewater.preyearet : currentUserFieldCopy.fields[i].fielddetails.prewater.preyearet;
                            currentUserFieldCopy.fields[i].fielddetails.prewater.preyearno = preNoState ? FieldDets.prewater.preyearno : currentUserFieldCopy.fields[i].fielddetails.prewater.preyearno;

                            currentUserFieldCopy.fields[i].fielddetails.postwater.postyearsoilmoisture = postSoilMoistureState ? FieldDets.postwater.postyearsoilmoisture : currentUserFieldCopy.fields[i].fielddetails.postwater.postyearsoilmoisture;
                            currentUserFieldCopy.fields[i].fielddetails.postwater.postyearflow = postFlowState ? FieldDets.postwater.postyearflow : currentUserFieldCopy.fields[i].fielddetails.postwater.postyearflow;
                            currentUserFieldCopy.fields[i].fielddetails.postwater.postyearet = postETState ? FieldDets.postwater.postyearet : currentUserFieldCopy.fields[i].fielddetails.postwater.postyearet;
                            currentUserFieldCopy.fields[i].fielddetails.postwater.postyearno = postNoState ? FieldDets.postwater.postyearno : currentUserFieldCopy.fields[i].fielddetails.postwater.postyearno;
                        }
                    }

                    // console.log("currentUserFromDispatch", currentUserFieldCopy);
                    dispatch(updateUser(currentUserFieldCopy));

                    fieldData.push(currentUserFieldCopy);

                } else {
                    currentUserFieldCopy.fields = new Array();
                    currentUserFieldCopy.fields.push(fieldJustCreated);
                    currentUserFieldCopy.fields[0].fielddetails = FieldDets;

                    dispatch(updateUser(currentUserFieldCopy));

                }
                //callSuccessPopup();
            } else {
                console.log("current user or field just created is null");
            }
        }
    };
    const cancelNewPump = () => {
       
        if (premarkerJustCreated) {
               premarkerJustCreated.setStyle("");
               pumpVectorSource.removeFeature(premarkerJustCreated);

        }
      
        setAddPumpOpen(false);
    };
    const saveEditPump = () => {
        for (var i = 0; i < pinLayer.getSource().getFeatures().length; i++) {
            if (pinLayer.getSource().getFeatures()[i].style_.text_.text_ === undefined) {
                pinLayer.getSource().getFeatures()[i].style_.text_.text_ = pumpinputs.prepumpName;
            }
        }
      
        var currentUser2 = Object.assign({}, currentUser);
        if (currentUser2) {
            for (var j = 0; j < currentUser2.preprojectpumps.length; j++) {
                if (currentUser2.preprojectpumps[j].preprojpumpId === selectedPrePump.preprojectpump.preprojpumpId) {
                    (currentUser2.preprojectpumps[j].preprojpumpName = pumpEditInputs.preeditpumpName === "" ? currentUser2.preprojectpumps[j].preprojpumpName : pumpEditInputs.preeditpumpName),
                    (currentUser2.preprojectpumps[j].preprojfields = pumpEditInputs.preeditfields === "" ? currentUser2.preprojectpumps[j].preprojfields : pumpEditInputs.preeditfields),
                    (currentUser2.preprojectpumps[j].preprojhp = pumpEditInputs.preedithp === "" ? currentUser2.preprojectpumps[j].preprojhp : pumpEditInputs.preedithp),
                    (currentUser2.preprojectpumps[j].preprojeff = pumpEditInputs.preediteff === "" ? currentUser2.preprojectpumps[j].preprojeff : pumpEditInputs.preediteff),
                    (currentUser2.preprojectpumps[j].preprojhours = pumpEditInputs.preedithours === "" ? currentUser2.preprojectpumps[j].preprojhours : pumpEditInputs.preedithours),
                    (currentUser2.preprojectpumps[j].preprojsyspress = pumpEditInputs.preeditsyspress === "" ? currentUser2.preprojectpumps[j].preprojsyspress : pumpEditInputs.preeditsyspress),
                    (currentUser2.preprojectpumps[j].preprojpumpress = pumpEditInputs.preeditpumpress === "" ? currentUser2.preprojectpumps[j].preprojpumpress : pumpEditInputs.preeditpumpress),
                    (currentUser2.preprojectpumps[j].preprojpumpdepth = pumpEditInputs.preeditpumpdepth === "" ? currentUser2.preprojectpumps[j].preprojpumpdepth : pumpEditInputs.preeditpumpdepth),
                    (currentUser2.preprojectpumps[j].preprojdispress = pumpEditInputs.preeditdispress === "" ? currentUser2.preprojectpumps[j].preprojdispress : pumpEditInputs.preeditdispress),
                    (currentUser2.preprojectpumps[j].preprojfricloss = pumpEditInputs.preeditfricloss === "" ? currentUser2.preprojectpumps[j].preprojfricloss : pumpEditInputs.preeditfricloss),
                    (currentUser2.preprojectpumps[j].preprojvfd = pumpEditInputs.preeditvfd === "" ? currentUser2.preprojectpumps[j].preprojvfd : pumpEditInputs.preeditvfd),
                    (currentUser2.preprojectpumps[j].preprojfueltype = pumpEditInputs.preeditfueltype === "" ? currentUser2.preprojectpumps[j].preprojfueltype : pumpEditInputs.preeditfueltype),
                    (currentUser2.preprojectpumps[j].preprojfueluse = pumpEditInputs.preeditfueluse === "" ? currentUser2.preprojectpumps[j].preprojfueluse : pumpEditInputs.preeditfueluse),
                    (currentUser2.preprojectpumps[j].preprojfeature = (pumpEditInputs.preeditfeature === "" || pumpEditInputs.preeditfeature===undefined )? currentUser2.preprojectpumps[j].preprojfeature : pumpEditInputs.preeditfeature),
                    setPreProjectPumpJustCreated(currentUser2.preprojectpumps[j]);

                    break;
                }
            }

            dispatch(updateUser(currentUser2));
            
            setPumpEditInputs({
                preeditpumpName: "",
                preeditfields: "",
                preedithp: "",
                preediteff: "",
                preedithours: "",
                preeditsyspress: "",
                preeditpumpress: "",
                preeditpumpdepth: "",
                preeditdispress: "",
                preeditfricloss: "",
                preeditvfd: "",
                preeditfueltype: "",
                preeditfueluse: "",
            });
            
        } else {
            console.log("current user or field just created is null");
        }
       
        
        setEditPumpOpen(false);
    };

    const saveEditPostPump = () => {
        for (var i = 0; i < pinLayer.getSource().getFeatures().length; i++) {
            if (pinLayer.getSource().getFeatures()[i].style_.text_.text_ === undefined) {
                pinLayer.getSource().getFeatures()[i].style_.text_.text_ = postPumpinputs.postpumpName;
            }
        }

        var currentUserPostEdit = Object.assign({}, currentUser);
        if (currentUserPostEdit) {
            for (var j = 0; j < currentUserPostEdit.postprojectpumps.length; j++) {
                if (currentUserPostEdit.postprojectpumps[j].postprojpumpId === selectedPostPump.postprojectpump.postprojpumpId) {
                    (currentUserPostEdit.postprojectpumps[j].postprojpumpName = postPumpEditInputs.posteditpumpName === "" ? currentUserPostEdit.postprojectpumps[j].postprojpumpName : postPumpEditInputs.posteditpumpName),
                        (currentUserPostEdit.postprojectpumps[j].postprojfields = postPumpEditInputs.posteditfields === "" ? currentUserPostEdit.postprojectpumps[j].postprojfields : postPumpEditInputs.posteditfields),
                        (currentUserPostEdit.postprojectpumps[j].postprojhp = postPumpEditInputs.postedithp === "" ? currentUserPostEdit.postprojectpumps[j].postprojhp : postPumpEditInputs.postedithp),
                        (currentUserPostEdit.postprojectpumps[j].postprojeff = postPumpEditInputs.postediteff === "" ? currentUserPostEdit.postprojectpumps[j].postprojeff : postPumpEditInputs.postediteff),
                        (currentUserPostEdit.postprojectpumps[j].postprojhours = postPumpEditInputs.postedithours === "" ? currentUserPostEdit.postprojectpumps[j].postprojhours : postPumpEditInputs.postedithours),
                        (currentUserPostEdit.postprojectpumps[j].postprojsyspress = postPumpEditInputs.posteditsyspress === "" ? currentUserPostEdit.postprojectpumps[j].postprojsyspress : postPumpEditInputs.posteditsyspress),
                        (currentUserPostEdit.postprojectpumps[j].postprojpumpress = postPumpEditInputs.posteditpumpress === "" ? currentUserPostEdit.postprojectpumps[j].postprojpumpress : postPumpEditInputs.posteditpumpress),
                        (currentUserPostEdit.postprojectpumps[j].postprojpumpdepth = postPumpEditInputs.posteditpumpdepth === "" ? currentUserPostEdit.postprojectpumps[j].postprojpumpdepth : postPumpEditInputs.posteditpumpdepth),
                        (currentUserPostEdit.postprojectpumps[j].postprojdispress = postPumpEditInputs.posteditdispress === "" ? currentUserPostEdit.postprojectpumps[j].postprojdispress : postPumpEditInputs.posteditdispress),
                        (currentUserPostEdit.postprojectpumps[j].postprojfricloss = postPumpEditInputs.posteditfricloss === "" ? currentUserPostEdit.postprojectpumps[j].postprojfricloss : postPumpEditInputs.posteditfricloss),
                        (currentUserPostEdit.postprojectpumps[j].postprojvfd = postPumpEditInputs.posteditvfd === "" ? currentUserPostEdit.postprojectpumps[j].postprojvfd : postPumpEditInputs.posteditvfd),
                        (currentUserPostEdit.postprojectpumps[j].postprojfueltype = postPumpEditInputs.posteditfueltype === "" ? currentUserPostEdit.postprojectpumps[j].postprojfueltype : postPumpEditInputs.posteditfueltype),
                        (currentUserPostEdit.postprojectpumps[j].postprojfueluse = postPumpEditInputs.posteditfueluse === "" ? currentUserPostEdit.postprojectpumps[j].postprojfueluse : postPumpEditInputs.posteditfueluse),
                        (currentUserPostEdit.postprojectpumps[j].postprojfeature = postPumpEditInputs.posteditfeature === "" ? currentUserPostEdit.postprojectpumps[j].postprojfeature : postPumpEditInputs.posteditfeature),
                        (currentUserPostEdit.postprojectpumps[j].postprojrenewenrgysel = postPumpEditInputs.posteditrenewenrgysel === "" ? currentUserPostEdit.postprojectpumps[j].postprojrenewenrgysel : postPumpEditInputs.posteditrenewenrgysel),
                        (currentUserPostEdit.postprojectpumps[j].postprojrenewenrgycapcty = postPumpEditInputs.posteditrenewenrgycapcty === "" ? currentUserPostEdit.postprojectpumps[j].postprojrenewenrgycapcty : postPumpEditInputs.posteditrenewenrgycapcty),
                        setPostProjectPumpJustCreated(currentUserPostEdit.postprojectpumps[j]);

                    break;
                }
            }

            dispatch(updateUser(currentUserPostEdit));
           
            setPostPumpEditInputs({
                posteditpumpName: "",
                posteditfields: "",
                postedithp: "",
                postediteff: "",
                postedithours: "",
                posteditsyspress: "",
                posteditpumpress: "",
                posteditpumpdepth: "",
                posteditdispress: "",
                posteditfricloss: "",
                posteditvfd: "",
                posteditfueltype: "",
                posteditfueluse: "",
                posteditrenewenrgysel: "",
                posteditrenewenrgycapcty: "",
            });
            
        } else {
            console.log("current user or field just created is null");
        }
        setEditPostPumpOpen(false);
    };
    const cancelEditPump = () => {
        setEditPumpOpen(false);
    };

    const cancelEditPostPump = () => {
        setEditPostPumpOpen(false);
    };

    const handleEditFieldNameChanged = (value, field) => {
        var currentUserEditCopy = Object.assign({}, currentUser);
        let editedField = currentUserEditCopy.fields.find((u) => u.name === field.field.name);
        editedField.name = value;
        setFieldJustEdited(editedField);
        dispatch(updateUser(currentUserEditCopy));
    };

    const handleEditAcreageChanged = (value, field) => {
        var currentUserEditCopy = Object.assign({}, currentUser);
        let editedField = currentUserEditCopy.fields.find((u) => u.name === field.field.name);
        editedField.acreage = (value != "" && value != null && value != undefined) ? parseFloat(value) : 0;
        setFieldJustEdited(editedField);
        dispatch(updateUser(currentUserEditCopy));
    };

    const handleFieldNameChanged = (value) => {
        var fieldJustCreatedCopy = Object.assign({}, fieldJustCreated);
        fieldJustCreatedCopy.name = value;
        setFieldJustCreated(fieldJustCreatedCopy);
    };
    
    const addPumpClicked = (marker, labelText = null, fromEmmiter = false) => {
        const featureId = "marker" + new Date().valueOf().toString();
        if (drawType === "Text") labelText = "Enter Custom Text";

        let customStyle = styles["prepoint"];
        if (!fromEmmiter) {
            if (drawType === "Text") {
                labelText = "Enter Custom Text";
                customStyle = FieldSidebarHelpers.getDefaultDrawStyle(drawColor, true, undefined, undefined, marker.getGeometry().getType());
                marker.setStyle(customStyle);
            } else {
                customStyle = FieldSidebarHelpers.getDefaultDrawStyle(drawColor, undefined, undefined, undefined, marker.getGeometry().getType());
                marker.setStyle(customStyle);
            }
        } else customStyle = marker.getStyle();

        //customStyle = styles["point"];
        marker.setProperties({
            id: featureId,
            label: labelText,
            labelVisible: true,
            drawType: drawType,
            isParcel: false,
        });
        marker.setStyle(styles["prepoint"]);
        const markerInfo = {
            id: featureId,
            label: labelText,
            labelVisible: true,
            labelStyle: null,
            labelRotation: 0,
            featureGeoJSON: new GeoJSON({
                dataProjection: "EPSG:3857",
                featureProjection: "EPSG:3857",
            }).writeFeature(marker, {
                dataProjection: "EPSG:3857",
                featureProjection: "EPSG:3857",
            }),
            style: customStyle === null ? drawStyle : customStyle,
            visible: true,
            drawType: drawType,
            geometryType: marker.getGeometry().getType(),
        };

        //setPumpInputs({ ...pumpinputs, prefeature: JSON.stringify(markerInfo) });
        setPumpInputs({
            prepumpName: "",
            prefields: [],
            prehp: "",
            preeff: "",
            prehours: "",
            presyspress: "2",
            prepumpress: "",
            prepumpdepth: "",
            predispress: "",
            prefricloss: "",
            prevfd: "",
            prefueltype: "150",
            prefueluse: "",
            preimprovques: "1",
            prestayinuseques: "0",
            prefeature: JSON.stringify(markerInfo),
            posthp: "",
            posteff: "",
            posthours: "",
            postsyspress: "2",
            postpumpress: "",
            postpumpdepth: "",
            postdispress: "",
            postfricloss: "",
            postvfd: "",
            postfueltype: "",
            postfueluse: "",
            postrenewenrgysel: "1",
            postrenewenrgycapcty: "",
        });

        let newMarker = {
            name: labelText,
            feature: JSON.stringify(markerInfo),
            style: customStyle,
        };
       
        pumpVectorSource.addFeature(marker);
        setPremarkerJustCreated(marker);
        // console.log("this is prepumpclicked:" + marker);
        setDrawType("Cancel");

        setAddPumpOpen(true);
    };

    const addPostPumpClicked = (postmarker, labelText = null, fromEmmiter = false) => {
        const featureId = "marker" + new Date().valueOf().toString();
        if (drawType === "Text") labelText = "Enter Custom Text";

        let customStyle = styles["postpoint"];
        if (!fromEmmiter) {
            if (drawType === "Text") {
                labelText = "Enter Custom Text";
                customStyle = FieldSidebarHelpers.getDefaultDrawStyle(drawColor, true, undefined, undefined, postmarker.getGeometry().getType());
                postmarker.setStyle(customStyle);
            } else {
                customStyle = FieldSidebarHelpers.getDefaultDrawStyle(drawColor, undefined, undefined, undefined, postmarker.getGeometry().getType());
                postmarker.setStyle(customStyle);
            }
        } else customStyle = postmarker.getStyle();

        //customStyle = styles["point"];
        postmarker.setProperties({
            id: featureId,
            label: labelText,
            labelVisible: true,
            drawType: drawType,
            isParcel: false,
        });
        postmarker.setStyle(styles["postpoint"]);
        const postmarkerInfo = {
            id: featureId,
            label: labelText,
            labelVisible: true,
            labelStyle: null,
            labelRotation: 0,
            featureGeoJSON: new GeoJSON({
                dataProjection: "EPSG:3857",
                featureProjection: "EPSG:3857",
            }).writeFeature(postmarker, {
                dataProjection: "EPSG:3857",
                featureProjection: "EPSG:3857",
            }),
            style: customStyle === null ? drawStyle : customStyle,
            visible: true,
            drawType: drawType,
            geometryType: postmarker.getGeometry().getType(),
        };

        setPostPumpInputs({
            postpumpName: "",
            postfields: [],
            posthp: "",
            posteff: "",
            posthours: "",
            postsyspress: "2",
            postpumpress: "",
            postpumpdepth: "",
            postdispress: "",
            postfricloss: "",
            postvfd: "",
            postfueltype: "",
            postfueluse: "",
            postfeature: JSON.stringify(postmarkerInfo),
            postrenewenrgysel: "1",
            postrenewenrgycapcty: "",
        });

        let newPostMarker = {
            name: labelText,
            feature: JSON.stringify(postmarkerInfo),
            style: customStyle,
        };

        pumpVectorSource.addFeature(postmarker);
        setPostmarkerJustCreated(postmarker);
        setDrawType("Cancel");

        setAddPostPumpOpen(true);
    };

    const handleAddnlPumpLocsChanged = (value) => {
        setPumpImpChanged(value);
    };
    const handlePumpNameChanged = (value) => {
        setPumpInputs({ ...pumpinputs, prepumpName: value });
    };

    const handleFieldsListChanged = (value) => {
        setPumpInputs({ ...pumpinputs, prefields: value });
    };

    const handleHpChanged = (value) => {
        setPumpInputs({ ...pumpinputs, prehp: value });
    };

    const handleHoursChanged = (value) => {
        setPumpInputs({ ...pumpinputs, prehours: value });
    };
    const handleEfficiencyChanged = (value) => {
        setPumpInputs({ ...pumpinputs, preeff: value });
    };
    const handleSystemPressureChanged = (value) => {
        setPumpInputs({ ...pumpinputs, presyspress: value });
    };

    const handlePrePumpPressChanged = (value) => {
        setPumpInputs({ ...pumpinputs, prepumpress: value });
    };
    const handlePrePumpDepthChanged = (value) => {
        setPumpInputs({ ...pumpinputs, prepumpdepth: value });
    };
    const handlePreDisPressChanged = (value) => {
        setPumpInputs({ ...pumpinputs, predispress: value });
    };
    const handlePreFricLossChanged = (value) => {
        setPumpInputs({ ...pumpinputs, prefricloss: value });
    };
    const handleVFDChanged = (value) => {
        setPumpInputs({ ...pumpinputs, prevfd: value });
    };
    const handleFuelTypeChanged = (value) => {
     
        setPumpInputs({ ...pumpinputs, prefueltype: value });
    };
    const handleFuelUseChanged = (value) => {
        setPumpInputs({ ...pumpinputs, prefueluse: value });
    };

    const handlePumpImprovQuesChanged = (value) => {
        setPumpInputs({ ...pumpinputs, preimprovques: value });
    };
    const handlePumpStayInUseQuesChanged = (value) => {
        setPumpInputs({ ...pumpinputs, prestayinuseques: value });
    };

    const handleHpPostChanged = (value) => {
        setPumpInputs({ ...pumpinputs, posthp: value });
    };

    const handleHoursPostChanged = (value) => {
        setPumpInputs({ ...pumpinputs, posthours: value });
    };
    const handleEfficiencyPostChanged = (value) => {
        setPumpInputs({ ...pumpinputs, posteff: value });
    };
    const handleSystemPressurePostChanged = (value) => {
        setPumpInputs({ ...pumpinputs, postsyspress: value });
    };
    const handlePumpPressPostChanged = (value) => {
        setPumpInputs({ ...pumpinputs, postpumpress: value });
    };
    const handlePumpDepthPostChanged = (value) => {
        setPumpInputs({ ...pumpinputs, postpumpdepth: value });
    };
    const handleDisPressPostChanged = (value) => {
        setPumpInputs({ ...pumpinputs, postdispress: value });
    };
    const handleFricLossPostChanged = (value) => {
        setPumpInputs({ ...pumpinputs, postfricloss: value });
    };
    const handleVFDPostChanged = (value) => {
        setPumpInputs({ ...pumpinputs, postvfd: value });
    };
    const handleFuelTypePostChanged = (value) => {
        setPumpInputs({ ...pumpinputs, postfueltype: value });
    };
    const handleFuelUsePostChanged = (value) => {
        setPumpInputs({ ...pumpinputs, postfueluse: value });
    };
    const handleRenewEnrgySelPostChanged = (value) => {
        setPumpInputs({ ...pumpinputs, postrenewenrgysel: value });
    };
    const handleRenewEnrgyCapctyPostChanged = (value) => {
        setPumpInputs({ ...pumpinputs, postrenewenrgycapcty: value });
    };

    const handlePostPumpNameChanged = (value) => {
        setPostPumpInputs({ ...postPumpinputs, postpumpName: value });
    };

    const handlePostFieldsListChanged = (value) => {
        setPostPumpInputs({ ...postPumpinputs, postfields: value });
    };

    const handlePostHpChanged = (value) => {
        setPostPumpInputs({ ...postPumpinputs, posthp: value });
    };

    const handlePostHoursChanged = (value) => {
        setPostPumpInputs({ ...postPumpinputs, posthours: value });
    };
    const handlePostEfficiencyChanged = (value) => {
        setPostPumpInputs({ ...postPumpinputs, posteff: value });
    };
    const handlePostSystemPressureChanged = (value) => {
        setPostPumpInputs({ ...postPumpinputs, postsyspress: value });
    };
    const handlePostPumpPressChanged = (value) => {
        setPostPumpInputs({ ...postPumpinputs, postpumpress: value });
    };
    const handlePostPumpDepthChanged = (value) => {
        setPostPumpInputs({ ...postPumpinputs, postpumpdepth: value });
    };
    const handlePostDisPressChanged = (value) => {
        setPostPumpInputs({ ...postPumpinputs, postdispress: value });
    };
    const handlePostFricLossChanged = (value) => {
        setPostPumpInputs({ ...postPumpinputs, postfricloss: value });
    };
    const handlePostVFDChanged = (value) => {
        setPostPumpInputs({ ...postPumpinputs, postvfd: value });
    };
    const handlePostFuelTypeChanged = (value) => {
        setPostPumpInputs({ ...postPumpinputs, postfueltype: value });
    };
    const handlePostFuelUseChanged = (value) => {
        setPostPumpInputs({ ...postPumpinputs, postfueluse: value });
    };
    const handlePostRenewenergySelect = (value) => {
        setPostPumpInputs({ ...postPumpinputs, postrenewenrgysel: value });
    };
    const handlePostRenewenergySelectCapcty = (value) => {
        setPostPumpInputs({ ...postPumpinputs, postrenewenrgycapcty: value });
    };

    const handleEditPumpNameChanged = (value) => {
        setPumpEditInputs({ ...pumpEditInputs, preeditpumpName: value });
    };

    const handleEditFieldsListChanged = (value) => {
        setPumpEditInputs({ ...pumpEditInputs, preeditfields: value });
    };

    const handleEditHpChanged = (value) => {
        setPumpEditInputs({ ...pumpEditInputs, preedithp: value });
    };

    const handleEditHoursChanged = (value) => {
        setPumpEditInputs({ ...pumpEditInputs, preedithours: value });
    };
    const handleEditEfficiencyChanged = (value) => {
        setPumpEditInputs({ ...pumpEditInputs, preediteff: value });
    };
    const handleEditSystemPressureChanged = (value) => {
        setPumpEditInputs({ ...pumpEditInputs, preeditsyspress: value });
    };
    const handleEditPrePumpPressChanged = (value) => {
        setPumpEditInputs({ ...pumpEditInputs, preeditpumpress: value });
    };
    const handleEditPrePumpDepthChanged = (value) => {
        setPumpEditInputs({ ...pumpEditInputs, preeditpumpdepth: value });
    };
    const handleEditPreDisPressChanged = (value) => {
        setPumpEditInputs({ ...pumpEditInputs, preeditdispress: value });
    };
    const handleEditPreFricLossChanged = (value) => {
        setPumpEditInputs({ ...pumpEditInputs, preeditfricloss: value });
    };
    const handleEditFuelTypeChanged = (value) => {
        setPumpEditInputs({ ...pumpEditInputs, preeditfueltype: value });
    };
    const handleEditFuelUseChanged = (value) => {
        setPumpEditInputs({ ...pumpEditInputs, preeditfueluse: value });
    };

    const handleEditPostPumpNameChanged = (value) => {
        setPostPumpEditInputs({ ...postPumpEditInputs, posteditpumpName: value });
    };

    const handleEditPostFieldsListChanged = (value) => {
        setPostPumpEditInputs({ ...postPumpEditInputs, posteditfields: value });
    };

    const handleEditPostHpChanged = (value) => {
        setPostPumpEditInputs({ ...postPumpEditInputs, postedithp: value });
    };

    const handleEditPostHoursChanged = (value) => {
        setPostPumpEditInputs({ ...postPumpEditInputs, postedithours: value });
    };
    const handleEditPostEfficiencyChanged = (value) => {
        setPostPumpEditInputs({ ...postPumpEditInputs, postediteff: value });
    };
    const handleEditPostSystemPressureChanged = (value) => {
        setPostPumpEditInputs({ ...postPumpEditInputs, posteditsyspress: value });
    };
    const handleEditPostPumpPressChanged = (value) => {
        setPostPumpEditInputs({ ...postPumpEditInputs, posteditpumpress: value });
    };
    const handleEditPostPumpDepthChanged = (value) => {
        setPostPumpEditInputs({ ...postPumpEditInputs, posteditpumpdepth: value });
    };
    const handleEditPostDisPressChanged = (value) => {
        setPostPumpEditInputs({ ...postPumpEditInputs, posteditdispress: value });
    };
    const handleEditPostFricLossChanged = (value) => {
        setPostPumpEditInputs({ ...postPumpEditInputs, posteditfricloss: value });
    };
    const handleEditPostVFDChanged = (value) => {
        setPostPumpEditInputs({ ...postPumpEditInputs, posteditvfd: value });
    };
    const handleEditPostFuelTypeChanged = (value) => {
        setPostPumpEditInputs({ ...postPumpEditInputs, posteditfueltype: value });
    };
    const handleEditPostFuelUseChanged = (value) => {
        setPostPumpEditInputs({ ...postPumpEditInputs, posteditfueluse: value });
    };

    const handleEditPostRenewEnrgySelChanged = (value) => {
        setPostPumpEditInputs({ ...postPumpEditInputs, posteditrenewenrgysel: value });
    };

    const handleEditPostRenewEnrgyCapctyChanged = (value) => {
       
        setPostPumpEditInputs({ ...postPumpEditInputs, posteditrenewenrgycapcty: value });
    };

    const callSuccessPopup = () => {
        setSuccessPopOpen(true);
    };

    const handlePreSpeciesCropOneYearOneChanged = (value) => {
        setFieldDetails({ ...fieldDetails, precroponeyearone: value });
    };
    const handlePreSpeciesCropOneYearTwoChanged = (value) => {
        setFieldDetails({ ...fieldDetails, precroponeyeartwo: value });
    };
    const handlePreSpeciesCropOneYearThreeChanged = (value) => {
        setFieldDetails({ ...fieldDetails, precroponeyearthree: value });
    };
    const handlePreSpeciesCropTwoYearOneChanged = (value) => {
        setFieldDetails({ ...fieldDetails, precroptwoyearone: value });
    };
    const handlePreSpeciesCropTwoYearTwoChanged = (value) => {
        setFieldDetails({ ...fieldDetails, precroptwoyeartwo: value });
    };
    const handlePreSpeciesCropTwoYearThreeChanged = (value) => {
        setFieldDetails({ ...fieldDetails, precroptwoyearthree: value });
    };
    const handlePreSpeciesCropThreeYearOneChanged = (value) => {
        setFieldDetails({ ...fieldDetails, precropthreeyearone: value });
    };
    const handlePreSpeciesCropThreeYearTwoChanged = (value) => {
        setFieldDetails({ ...fieldDetails, precropthreeyeartwo: value });
    };
    const handlePreSpeciesCropThreeYearThreeChanged = (value) => {
        setFieldDetails({ ...fieldDetails, precropthreeyearthree: value });
    };
    const fieldDetailsValidation = () => {
        var valid = false;
        for (var i = 0; i < fields.length; i++) {
            
            if (fields[i].fielddetails != undefined) {
                var fieldPrecropping = false;
                var fieldPostcropping = false;
                var fieldPreIrr = false;
                var fieldPostIrr = false;
                var fieldPreWater = false;
                var fieldPostWater = false;
                if (fields[i].fielddetails.precropping != undefined) {


                    if (!Object.values(fields[i].fielddetails.precropping).every(x => (x === '' || x === undefined))) {
                        fieldPrecropping = true;
                    }
                    else {
                        fieldPrecropping = false;
                    }
                }

                if (fields[i].fielddetails.postcropping != undefined) {

                    if (!Object.values(fields[i].fielddetails.postcropping).every(x => (x === '' || x === undefined))) {
                        fieldPostcropping = true;
                    }

                    else {
                        fieldPostcropping = false;
                    }
                }

                if (fields[i].fielddetails.preirr != undefined) {
                    if (!Object.values(fields[i].fielddetails.preirr).every(x => x === '' || x === undefined)) {
                        fieldPreIrr = true;
                    }
                    else {
                        fieldPreIrr = false;
                    }

                }

                if (fields[i].fielddetails.postirr != undefined) {
                    if (!Object.values(fields[i].fielddetails.postirr).every(x => x === '' || x === undefined)) {
                        fieldPostIrr = true;

                    }
                    else {
                        fieldPostIrr = false;
                    }
                }

                //if (fields[i].fielddetails.prewater != undefined) {
                //    if (!Object.values(fields[i].fielddetails.prewater).every(x => x === false || x === undefined)) {
                //        fieldPreWater = true;
                //    }
                //    else {
                //        fieldPreWater = false;
                //    }
                //}

                if (fields[i].fielddetails.postwater != undefined) {
                    if (!Object.values(fields[i].fielddetails.postwater).every(x => x === false || x === undefined)) {
                        fieldPostWater = true;
                    }
                    else {
                        fieldPostWater = false;
                    }
                }
                    if (fieldPrecropping && fieldPostcropping && fieldPreIrr && fieldPostIrr && fieldPostWater) {
                        document.getElementById("completeStatus-" + fields[i].name).style.display = "inline-block";
                        document.getElementById("inCompleteStatus-" + fields[i].name).style.display = "none";
                        
                        valid = true;
                    }
                    else {
                        document.getElementById("inCompleteStatus-" + fields[i].name).style.display = "inline-block";
                        document.getElementById("completeStatus-" + fields[i].name).style.display = "none";
                        
                        valid = false;
                    }

                
            }
        }
        if (valid) {
            document.getElementById("viewFieldReportStatusText").value = 1;
        }
        else {
            document.getElementById("viewFieldReportStatusText").value = 0;
        }
        setFieldsComplete(valid);

    };
    const PreProjPumpsValidation = () => {

        var valid = false;
        for (var j = 0; j < preprojectpumps.length; j++) {

            document.getElementById("prePumpInCompleteStatus-" + preprojectpumps[j].preprojpumpName).style.display = "inline-block";
            document.getElementById("prepumpCompleteStatus-" + preprojectpumps[j].preprojpumpName).style.display = "none";
            document.getElementById("elecUseNotValid").style.display = "none";
            document.getElementById("renewEnrgyNotValid").style.display = "none";
            if (preprojectpumps[j].preprojpumpName != '' && preprojectpumps[j].preprojfields != '' &&
                preprojectpumps[j].preprojhp != '' && preprojectpumps[j].preprojeff != '' &&
                ((preprojectpumps[j].preprojsyspress == "1") ? preprojectpumps[j].preprojpumpress != '' : (preprojectpumps[j].preprojpumpdepth != '' && preprojectpumps[j].preprojdispress != '' && preprojectpumps[j].preprojfricloss != '')) &&
                (preprojectpumps[j].preprojfueltype == "151" ? (preprojectpumps[j].preprojfueluse != '' || preprojectpumps[j].preprojfueluse == '') : preprojectpumps[j].preprojfueluse != '')) {

                valid = true;
                document.getElementById("prePumpInCompleteStatus-" + preprojectpumps[j].preprojpumpName).style.display = "none";
                document.getElementById("prepumpCompleteStatus-" + preprojectpumps[j].preprojpumpName).style.display = "inline-block";


            }
            else {
                valid = false;
                document.getElementById("prePumpInCompleteStatus-" + preprojectpumps[j].preprojpumpName).style.display = "inline-block";
                document.getElementById("prepumpCompleteStatus-" + preprojectpumps[j].preprojpumpName).style.display = "none";

            }
        }
        if (preprojectpumps.filter((u) => u.preprojfueltype === "151").length > 0) {


            if (currentUser.electricity != null) {
                if (currentUser.electricity[0] != undefined) {

                    if (currentUser.electricity[0].annualkwh != "") {

                        valid = true;

                        document.getElementById("elecUseNotValid").style.display = "none";

                    }
                    else {

                        valid = false;
                        document.getElementById("elecUseNotValid").style.display = "inline-block";

                    }
                }
                else if (currentUser.electricity.annualkwh != "") {

                    valid = true;

                    document.getElementById("elecUseNotValid").style.display = "none";
                }
                else {

                    valid = false;
                    document.getElementById("elecUseNotValid").style.display = "inline-block";

                }

            }
            else {

                valid = false;
                document.getElementById("elecUseNotValid").style.display = "inline-block";

            }


        }
        if ((preprojectpumps.filter((u) => u.postprojfueltype === "155" || u.postprojfueltype === "156" || u.postprojfueltype === "157").length > 0)) {


            if (currentUser.renewenrgycapcty != null) {
                if (currentUser.renewenrgycapcty != undefined) {

                    if (currentUser.renewenrgycapcty.renewcapcty != "") {

                        valid = true;

                        document.getElementById("renewEnrgyNotValid").style.display = "none";

                    }
                    else {

                        valid = false;
                        document.getElementById("renewEnrgyNotValid").style.display = "inline-block";

                    }
                }
            }
            else {
                valid = false;
                document.getElementById("renewEnrgyNotValid").style.display = "inline-block";

            }

        } else {

            valid = true;
            document.getElementById("renewEnrgyNotValid").style.display = "none";



        }
        if (valid) {
            document.getElementById("viewPrePumpReportStatusText").value = 1;
        }
        else {
            document.getElementById("viewPrePumpReportStatusText").value = 0;
        }
        setPrePumpsComplete(valid);
      
    };
    const PostProjPumpsValidation = () => {
        var valid = false;
        for (var m = 0; m < postprojectpumps.length; m++) {

           
            
            if (postprojectpumps[m].postprojpumpName != '' && postprojectpumps[m].postprojfields != '' &&
                postprojectpumps[m].postprojhp != ''  && postprojectpumps[m].postprojeff != '' &&
                ((postprojectpumps[m].postprojsyspress == "1") ? postprojectpumps[m].postprojpumpress != '' : (postprojectpumps[m].postprojpumpdepth != '' && postprojectpumps[m].postprojdispress != '' && postprojectpumps[m].postprojfricloss != '')) &&
                (postprojectpumps[m].postprojrenewenrgysel == "0" ? (postprojectpumps[m].postprojrenewenrgycapcty != '') : (postprojectpumps[m].postprojrenewenrgycapcty == '' || postprojectpumps[m].postprojrenewenrgycapcty != '')) && ((postprojectpumps[m].postprojisprepump == "N") ? (postprojectpumps[m].postprojfueltype != 155 && postprojectpumps[m].postprojfueltype != 156 && postprojectpumps[m].postprojfueltype != 157) ? postprojectpumps[m].postprojfueluse != '' : postprojectpumps[m].postprojfueluse == '':postprojectpumps[m].postprojfueluse == '')) {

                
                valid = true;
                document.getElementById("postPumpInCompleteStatus-" + postprojectpumps[m].postprojpumpName).style.display = "none";
                document.getElementById("postpumpCompleteStatus-" + postprojectpumps[m].postprojpumpName).style.display = "inline-block";
               
            }
            else {
                valid = false;
                document.getElementById("postPumpInCompleteStatus-" + postprojectpumps[m].postprojpumpName).style.display = "inline-block";
                document.getElementById("postpumpCompleteStatus-" + postprojectpumps[m].postprojpumpName).style.display = "none";
               
            }
        }
        if ((postprojectpumps.filter((u) => u.postprojfueltype === "155" || u.postprojfueltype === "156" || u.postprojfueltype === "157").length > 0)) {


            if (currentUser.renewenrgycapcty != null) {
                if (currentUser.renewenrgycapcty != undefined) {

                    if (currentUser.renewenrgycapcty.renewcapcty != "") {

                        valid = true;

                        document.getElementById("renewEnrgyNotValid").style.display = "none";

                    }
                    else {

                        valid = false;
                        document.getElementById("renewEnrgyNotValid").style.display = "inline-block";

                    }
                }
            }
            else {
                valid = false;
                document.getElementById("renewEnrgyNotValid").style.display = "inline-block";

            }
        }
        else {

            valid = true;
            document.getElementById("renewEnrgyNotValid").style.display = "none";

        }

          
        if (valid) {
            document.getElementById("viewPostPumpReportStatusText").value = 1;
        }
        else {
            document.getElementById("viewPostPumpReportStatusText").value = 0;
        }
        setPostPumpsComplete(valid);
    };
    const handlePreSpeciesCrop1VerticalChanged = (value,precrop,postcrop,userDetsObj) => {
       
        setFieldDetails({
            precroponeyearone: value,
            precroponeyeartwo: value,
            precroponeyearthree: value,
            precroptwoyearone: precrop.croptwoyearone,
            precroptwoyeartwo: precrop.croptwoyeartwo ,
            precroptwoyearthree: precrop.croptwoyearthree,
            precropthreeyearone: precrop.cropthreeyearone,
            precropthreeyeartwo: precrop.cropthreeyeartwo,
            precropthreeyearthree: precrop.cropthreeyearthree,
            postcroponeyearone: postcrop.postcroponeyearone, 
            postcroponeyeartwo: postcrop.postcroptwoyearone,
            postcroponeyearthree: postcrop.postcropthreeyearone,
            postcroptwoyearone: postcrop.postcroptwoyearone,
            postcroptwoyeartwo: postcrop.postcroptwoyeartwo, 
            postcroptwoyearthree: postcrop.postcroptwoyearthree,
            postcropthreeyearone: postcrop.postcropthreeyearone,
            postcropthreeyeartwo: postcrop.postcropthreeyeartwo,
            postcropthreeyearthree: postcrop.postcropthreeyearthree,
            preirr: userDetsObj.preirrmethod,
            postirr: userDetsObj.postirrmethod,
            presoilmoisture: userDetsObj.presm,
            preflow: userDetsObj.preflow,
            preet: userDetsObj.preet,
            preno: userDetsObj.preno,
            postsoilmoisture: userDetsObj.postsm,
            postflow: userDetsObj.postflow,
            postet: userDetsObj.postet,
            postno: userDetsObj.postno,

        });


    };
    const handlePreSpeciesCrop2VerticalChanged = (value, precrop, postcrop, userDetsObj) => {
        setFieldDetails({
            precroponeyearone: precrop.croponeyearone,
            precroponeyeartwo: precrop.croponeyeartwo,
            precroponeyearthree: precrop.croponeyearthree,
            precroptwoyearone: value,
            precroptwoyeartwo: value,
            precroptwoyearthree: value,
            precropthreeyearone: precrop.cropthreeyearone,
            precropthreeyeartwo: precrop.cropthreeyeartwo,
            precropthreeyearthree: precrop.cropthreeyearthree,
            postcroponeyearone: postcrop.postcroponeyearone,
            postcroponeyeartwo: postcrop.postcroponeyeartwo,
            postcroponeyearthree: postcrop.postcroponeyearthree,
            postcroptwoyearone: postcrop.postcroptwoyearone,
            postcroptwoyeartwo: postcrop.postcroptwoyeartwo,
            postcroptwoyearthree: postcrop.postcroptwoyearthree,
            postcropthreeyearone: postcrop.postcropthreeyearone,
            postcropthreeyeartwo: postcrop.postcropthreeyeartwo,
            postcropthreeyearthree: postcrop.postcropthreeyearthree,
            preirr: userDetsObj.preirrmethod,
            postirr: userDetsObj.postirrmethod,
            presoilmoisture: userDetsObj.presm,
            preflow: userDetsObj.preflow,
            preet: userDetsObj.preet,
            preno: userDetsObj.preno,
            postsoilmoisture: userDetsObj.postsm,
            postflow: userDetsObj.postflow,
            postet: userDetsObj.postet,
            postno: userDetsObj.postno,

        });


    };
    const handlePreSpeciesCrop3VerticalChanged = (value, precrop, postcrop, userDetsObj) => {
        setFieldDetails({
            precroponeyearone: precrop.croponeyearone,
            precroponeyeartwo: precrop.croponeyeartwo,
            precroponeyearthree: precrop.croponeyearthree,
            precroptwoyearone: precrop.croptwoyearone,
            precroptwoyeartwo: precrop.croptwoyeartwo,
            precroptwoyearthree: precrop.croptwoyearthree,
            precropthreeyearone: value,
            precropthreeyeartwo: value,
            precropthreeyearthree: value,
            postcroponeyearone: postcrop.postcroponeyearone,
            postcroponeyeartwo: postcrop.postcroponeyeartwo,
            postcroponeyearthree: postcrop.postcroponeyearthree,
            postcroptwoyearone: postcrop.postcroptwoyearone,
            postcroptwoyeartwo: postcrop.postcroptwoyeartwo,
            postcroptwoyearthree: postcrop.postcroptwoyearthree,
            postcropthreeyearone: postcrop.postcropthreeyearone,
            postcropthreeyeartwo: postcrop.postcropthreeyeartwo,
            postcropthreeyearthree: postcrop.postcropthreeyearthree,
            preirr: userDetsObj.preirrmethod,
            postirr: userDetsObj.postirrmethod,
            presoilmoisture: userDetsObj.presm,
            preflow: userDetsObj.preflow,
            preet: userDetsObj.preet,
            preno: userDetsObj.preno,
            postsoilmoisture: userDetsObj.postsm,
            postflow: userDetsObj.postflow,
            postet: userDetsObj.postet,
            postno: userDetsObj.postno,

        });



    };
    const handlePostSpeciesCrop1VerticalChanged = (value, precrop, postcrop, userDetsObj) => {
        setFieldDetails({
            precroponeyearone: precrop.croponeyearone,
            precroponeyeartwo: precrop.croponeyeartwo,
            precroponeyearthree: precrop.croponeyearthree,
            precroptwoyearone: precrop.croptwoyearone,
            precroptwoyeartwo: precrop.croptwoyeartwo,
            precroptwoyearthree: precrop.croptwoyearthree,
            precropthreeyearone: precrop.cropthreeyearone,
            precropthreeyeartwo: precrop.cropthreeyeartwo,
            precropthreeyearthree: precrop.cropthreeyearthree,
            postcroponeyearone: value,
            postcroponeyeartwo: value,
            postcroponeyearthree: value,
            postcroptwoyearone: postcrop.postcroptwoyearone,
            postcroptwoyeartwo: postcrop.postcroptwoyeartwo,
            postcroptwoyearthree: postcrop.postcroptwoyearthree,
            postcropthreeyearone: postcrop.postcropthreeyearone,
            postcropthreeyeartwo: postcrop.postcropthreeyeartwo,
            postcropthreeyearthree: postcrop.postcropthreeyearthree,
            preirr: userDetsObj.preirrmethod,
            postirr: userDetsObj.postirrmethod,
            presoilmoisture: userDetsObj.presm,
            preflow: userDetsObj.preflow,
            preet: userDetsObj.preet,
            preno: userDetsObj.preno,
            postsoilmoisture: userDetsObj.postsm,
            postflow: userDetsObj.postflow,
            postet: userDetsObj.postet,
            postno: userDetsObj.postno,

        });



    };
    const handlePostSpeciesCrop2VerticalChanged = (value, precrop, postcrop, userDetsObj) => {
        setFieldDetails({
            precroponeyearone: precrop.croponeyearone,
            precroponeyeartwo: precrop.croponeyeartwo,
            precroponeyearthree: precrop.croponeyearthree,
            precroptwoyearone: precrop.croptwoyearone,
            precroptwoyeartwo: precrop.croptwoyeartwo,
            precroptwoyearthree: precrop.croptwoyearthree,
            precropthreeyearone: precrop.cropthreeyearone,
            precropthreeyeartwo: precrop.cropthreeyeartwo,
            precropthreeyearthree: precrop.cropthreeyearthree,
            postcroponeyearone: postcrop.postcroponeyearone,
            postcroponeyeartwo: postcrop.postcroponeyeartwo,
            postcroponeyearthree: postcrop.postcroponeyearthree,
            postcroptwoyearone: value,
            postcroptwoyeartwo: value,
            postcroptwoyearthree: value,
            postcropthreeyearone: postcrop.postcropthreeyearone,
            postcropthreeyeartwo: postcrop.postcropthreeyeartwo,
            postcropthreeyearthree: postcrop.postcropthreeyearthree,
            preirr: userDetsObj.preirrmethod,
            postirr: userDetsObj.postirrmethod,
            presoilmoisture: userDetsObj.presm,
            preflow: userDetsObj.preflow,
            preet: userDetsObj.preet,
            preno: userDetsObj.preno,
            postsoilmoisture: userDetsObj.postsm,
            postflow: userDetsObj.postflow,
            postet: userDetsObj.postet,
            postno: userDetsObj.postno,

        });



    };
    const handlePostSpeciesCrop3VerticalChanged = (value, precrop, postcrop, userDetsObj) => {
        setFieldDetails({
            precroponeyearone: precrop.croponeyearone,
            precroponeyeartwo: precrop.croponeyeartwo,
            precroponeyearthree: precrop.croponeyearthree,
            precroptwoyearone: precrop.croptwoyearone,
            precroptwoyeartwo: precrop.croptwoyeartwo,
            precroptwoyearthree: precrop.croptwoyearthree,
            precropthreeyearone: precrop.cropthreeyearone,
            precropthreeyeartwo: precrop.cropthreeyeartwo,
            precropthreeyearthree: precrop.cropthreeyearthree,
            postcroponeyearone: postcrop.postcroponeyearone,
            postcroponeyeartwo: postcrop.postcroponeyeartwo,
            postcroponeyearthree: postcrop.postcroponeyearthree,
            postcroptwoyearone: postcrop.postcroptwoyearone,
            postcroptwoyeartwo: postcrop.postcroptwoyeartwo,
            postcroptwoyearthree: postcrop.postcroptwoyearthree,
            postcropthreeyearone: value,
            postcropthreeyeartwo: value,
            postcropthreeyearthree: value,
            preirr: userDetsObj.preirrmethod,
            postirr: userDetsObj.postirrmethod,
            presoilmoisture: userDetsObj.presm,
            preflow: userDetsObj.preflow,
            preet: userDetsObj.preet,
            preno: userDetsObj.preno,
            postsoilmoisture: userDetsObj.postsm,
            postflow: userDetsObj.postflow,
            postet: userDetsObj.postet,
            postno: userDetsObj.postno,

        });



    };
    const handlePostSpeciesCropOneYearOneChanged = (value) => {
        setFieldDetails({ ...fieldDetails, postcroponeyearone: value });
    };

    const handlePostSpeciesCropOneYearTwoChanged = (value) => {
        setFieldDetails({ ...fieldDetails, postcroponeyeartwo: value });
    };
    const handlePostSpeciesCropOneYearThreeChanged = (value) => {
        setFieldDetails({ ...fieldDetails, postcroponeyearthree: value });
    };
    const handlePostSpeciesCropTwoYearOneChanged = (value) => {
        setFieldDetails({ ...fieldDetails, postcroptwoyearone: value });
    };
    const handlePostSpeciesCropTwoYearTwoChanged = (value) => {
        setFieldDetails({ ...fieldDetails, postcroptwoyeartwo: value });
    };
    const handlePostSpeciesCropTwoYearThreeChanged = (value) => {
        setFieldDetails({ ...fieldDetails, postcroptwoyearthree: value });
    };
    const handlePostSpeciesCropThreeYearOneChanged = (value) => {
        setFieldDetails({ ...fieldDetails, postcropthreeyearone: value });
    };
    const handlePostSpeciesCropThreeYearTwoChanged = (value) => {
        setFieldDetails({ ...fieldDetails, postcropthreeyeartwo: value });
    };
    const handlePostSpeciesCropThreeYearThreeChanged = (value) => {
        setFieldDetails({ ...fieldDetails, postcropthreeyearthree: value });
    };
    const handlePreIrrChanged = (value) => {
        setFieldDetails({ ...fieldDetails, preirr: value });
    };
    const handlePostIrrChanged = (value) => {
        setFieldDetails({ ...fieldDetails, postirr: value });
    };
    const handlePreSoilMoistureChanged = (value) => {
        setFieldDetails({ ...fieldDetails, presoilmoisture: value });
        setPreSoilMoistureState(true);
        //setFieldDetails({ presoilmoisture: value, preflow: userDetSelField.field.fielddetails.prewater.preyearflow, preet: userDetSelField.field.fielddetails.prewater.preyearet, preyearno: false });
    };
    const handlePreFlowChanged = (value) => {
        setFieldDetails({ ...fieldDetails, preflow: value });
        setPreFlowState(true);
        //setFieldDetails({ presoilmoisture: userDetSelField.field.fielddetails.prewater.preyearsoilmoisture, preflow: value, preet: userDetSelField.field.fielddetails.prewater.preyearet, preyearno: false});
    };
    const handlePreETChanged = (value) => {
        setFieldDetails({ ...fieldDetails, preet: value });
        setPreETState(true);
        //setFieldDetails({ presoilmoisture: userDetSelField.field.fielddetails.prewater.preyearsoilmoisture, preflow: userDetSelField.field.fielddetails.prewater.preyearflow, preet: value, preyearno: false });
    };
    const handlePreNoChanged = (value) => {
        setFieldDetails({ ...fieldDetails, preno: value });
        setPreNoState(true);
        //setFieldDetails({ presoilmoisture: userDetSelField.field.fielddetails.prewater.preyearsoilmoisture, preflow: userDetSelField.field.fielddetails.prewater.preyearflow, preet: userDetSelField.field.fielddetails.prewater.preyearet, preyearno: false });
    };

    const handlePostSoilMoistureChanged = (value) => {
        setFieldDetails({ ...fieldDetails, postsoilmoisture: value });
        setPostSoilMoistureState(true);
    };
    const handlePostFlowChanged = (value) => {
        setFieldDetails({ ...fieldDetails, postflow: value });
        setPostFlowState(true);
    };
    const handlePostETChanged = (value) => {
        setFieldDetails({ ...fieldDetails, postet: value });
        setPostETState(true);
    };
    const handlePostNoChanged = (value) => {
        setFieldDetails({ ...fieldDetails, postno: value });
        setPostNoState(true);
    };

    const handleTotalKWHChanged = (value) => {
        setElectricityDetails({ ...electricityDetails, kwh: value });
    };

    const handleRenewCapctyChanged = (value) => {
        setRenewEnrgyDets({ ...renewEnrgyDets, capcty : value });
    };
    const handleIrrHardwareChanged = (value) => {
        setElectricityDetails({ ...electricityDetails, irrhardware: value });
    };

    const handleCopyFieldsListChange = (value) => {
        setFieldsToCopy(value);
    };
    const getAddNewFieldForm = () => {
        if (fieldJustCreated) {
            return <FieldForm field={fieldJustCreated} parentHandleChange={handleFieldNameChanged} />;
        } else return null;
    };

    const getPumpImpForm = () => {
        return <PumpImpQues parentAddnlPumpLocsChange={handleAddnlPumpLocsChanged} />;
    };
    const getCopyFieldDetailsForm = () => {
        return <CopyFieldDetails copyFromField={userDetSelField} copyfieldsArr={copyArr} parentCopyFieldsListChange={handleCopyFieldsListChange} />;
    };
    const getFieldDetailsForm = () => {
        return (
            <UserDetails
                selField={userDetSelField}
                detailsAdded={fieldDetailsAdded}
                parentprespeciescroponeyearonechange={handlePreSpeciesCropOneYearOneChanged}
                parentprespeciescroptwoyearonechange={handlePreSpeciesCropTwoYearOneChanged}
                parentprespeciescropthreeyearonechange={handlePreSpeciesCropThreeYearOneChanged}
                parentprespeciescroponeyeartwochange={handlePreSpeciesCropOneYearTwoChanged}
                parentprespeciescroptwoyeartwochange={handlePreSpeciesCropTwoYearTwoChanged}
                parentprespeciescropthreeyeartwochange={handlePreSpeciesCropThreeYearTwoChanged}
                parentprespeciescroponeyearthreechange={handlePreSpeciesCropOneYearThreeChanged}
                parentprespeciescroptwoyearthreechange={handlePreSpeciesCropTwoYearThreeChanged}
                parentprespeciescropthreeyearthreechange={handlePreSpeciesCropThreeYearThreeChanged}
                parentpostspeciescroponeyearonechange={handlePostSpeciesCropOneYearOneChanged}
                parentpostspeciescroptwoyearonechange={handlePostSpeciesCropTwoYearOneChanged}
                parentpostspeciescropthreeyearonechange={handlePostSpeciesCropThreeYearOneChanged}
                parentpostspeciescroponeyeartwochange={handlePostSpeciesCropOneYearTwoChanged}
                parentpostspeciescroptwoyeartwochange={handlePostSpeciesCropTwoYearTwoChanged}
                parentpostspeciescropthreeyeartwochange={handlePostSpeciesCropThreeYearTwoChanged}
                parentpostspeciescroponeyearthreechange={handlePostSpeciesCropOneYearThreeChanged}
                parentpostspeciescroptwoyearthreechange={handlePostSpeciesCropTwoYearThreeChanged}
                parentpostspeciescropthreeyearthreechange={handlePostSpeciesCropThreeYearThreeChanged}
                parentpreirrchange={handlePreIrrChanged}
                parentpostirrchange={handlePostIrrChanged}
                parentpresoilmoisturechange={handlePreSoilMoistureChanged}
                parentpreflowchange={handlePreFlowChanged}
                parentpreetchange={handlePreETChanged}
                parentprenochange={handlePreNoChanged}
                parentpostsoilmoisturechange={handlePostSoilMoistureChanged}
                parentpostflowchange={handlePostFlowChanged}
                parentpostetchange={handlePostETChanged}
                parentpostnochange={handlePostNoChanged}
                //parentprespeciescropverticalchange={handlePreSpeciesCropVerticalChanged}
                //parentpostspeciescropverticalchange={handlePostSpeciesCropVerticalChanged}
                parentprespeciescrop1verticalchange={handlePreSpeciesCrop1VerticalChanged}
                parentprespeciescrop2verticalchange={handlePreSpeciesCrop2VerticalChanged}
                parentprespeciescrop3verticalchange={handlePreSpeciesCrop3VerticalChanged}
                parentpostspeciescrop1verticalchange={handlePostSpeciesCrop1VerticalChanged}
                parentpostspeciescrop2verticalchange={handlePostSpeciesCrop2VerticalChanged}
                parentpostspeciescrop3verticalchange={handlePostSpeciesCrop3VerticalChanged}
            />
        );
    };

    const getElectricityFormEdit = () => {
       
        return <Electricity selkwh={currentUser.electricity != null ? (currentUser.electricity[0] != undefined) ? currentUser.electricity[0].annualkwh == "" ? electricityDetails.kwh : currentUser.electricity[0].annualkwh : currentUser.electricity.annualkwh : electricityDetails.kwh} selirr={currentUser.electricity != null ? (currentUser.electricity[0] != undefined) ? currentUser.electricity[0].irrgtnhardware == "" ? electricityDetails.irrhardware : currentUser.electricity[0].irrgtnhardware : currentUser.electricity.irrgtnhardware: electricityDetails.irrhardware} parentTotalKWHChange={handleTotalKWHChanged} parentIrrHardwareChange={handleIrrHardwareChanged} />;
    };
    const getRenewEnrgyCapctyFormEdit = () => {

        return <RenewEnrgyCapcty selcapcty={currentUser.renewenrgycapcty != null ? (currentUser.renewenrgycapcty[0] != undefined) ? currentUser.renewenrgycapcty[0].renewcapcty == "" ? renewEnrgyDets.capcty : currentUser.renewenrgycapcty[0].renewcapcty : currentUser.renewenrgycapcty.renewcapcty : renewEnrgyDets.capcty} parentRenewCapctyChange={handleRenewCapctyChanged} />;
    };
    const getAddNewPostPumpForm = () => {
        return (
            <AddNewPostPump
                postfieldsArr={fields}
                parentPostPumpNameChange={handlePostPumpNameChanged}
                parentPostFieldsListeChange={handlePostFieldsListChanged}
                parentPostHpChange={handlePostHpChanged}
                parentPostHoursChange={handlePostHoursChanged}
                parentPostEfficiencyChange={handlePostEfficiencyChanged}
                parentPostSystemPressureChange={handlePostSystemPressureChanged}
                parentpostpumppressureChange={handlePostPumpPressChanged}
                parentpostpumpdepthChange={handlePostPumpDepthChanged}
                parentpostdischargepressureChange={handlePostDisPressChanged}
                parentpostfrictionlossChange={handlePostFricLossChanged}
                parentPostVFDChange={handlePostVFDChanged}
                parentPostFuelTypeChange={handlePostFuelTypeChanged}
                parentPostFuelUseChange={handlePostFuelUseChanged}
                parentPostRenewenergySelect={handlePostRenewenergySelect}
                parentPostRenewenergySelectCapcty={handlePostRenewenergySelectCapcty}
            />
        );
    };
    const getAddNewPumpForm = () => {
        return (
            <AddNewPump
                fieldsArr={fields}
                parentPumpNameChange={handlePumpNameChanged}
                parentFieldsListeChange={handleFieldsListChanged}
                parentHpChange={handleHpChanged}
                parentHoursChange={handleHoursChanged}
                parentEfficiencyChange={handleEfficiencyChanged}
                parentSystemPressureChange={handleSystemPressureChanged}
                parentprepumpressureChange={handlePrePumpPressChanged}
                parentprepumpdepthChange={handlePrePumpDepthChanged}
                parentpredischargepressureChange={handlePreDisPressChanged}
                parentprefrictionlossChange={handlePreFricLossChanged}
                parentVFDChange={handleVFDChanged}
                parentFuelTypeChange={handleFuelTypeChanged}
                parentFuelUseChange={handleFuelUseChanged}
                parentPumpImprovQuesChange={handlePumpImprovQuesChanged}
                parentPumpStayInUseQuesChange={handlePumpStayInUseQuesChanged}
                parentHpPostChange={handleHpPostChanged}
                parentHoursPostChange={handleHoursPostChanged}
                parentEfficiencyPostChange={handleEfficiencyPostChanged}
                parentSystemPressurePostChange={handleSystemPressurePostChanged}
                parentpostpumpressureChange={handlePumpPressPostChanged}
                parentpostpumpdepthChange={handlePumpDepthPostChanged}
                parentpostdischargepressureChange={handleDisPressPostChanged}
                parentpostfrictionlossChange={handleFricLossPostChanged}
                parentVFDPostChange={handleVFDPostChanged}
                parentFuelTypePostChange={handleFuelTypePostChanged}
                parentFuelUsePostChange={handleFuelUsePostChanged}
                parentPostRenewenergySelectChange={handleRenewEnrgySelPostChanged}
                parentPostRenewenergySelectCapctyChange={handleRenewEnrgyCapctyPostChanged}
            />
        );
    };
    const getEditNewPumpForm = () => {
        return (
            <EditNewPump
                editFieldsArr={fields}
                PreProjectPump={selectedPrePump}
                parentEditPumpNameChange={handleEditPumpNameChanged}
                parentEditFieldsListeChange={handleEditFieldsListChanged}
                parentEditHpChange={handleEditHpChanged}
                parentEditHoursChange={handleEditHoursChanged}
                parentEditEfficiencyChange={handleEditEfficiencyChanged}
                parentEditSystemPressureChange={handleEditSystemPressureChanged}
                parentEditprepumpressureChange={handleEditPrePumpPressChanged}
                parentEditprepumpdepthChange={handleEditPrePumpDepthChanged}
                parentEditpredischargepressureChange={handleEditPreDisPressChanged}
                parentEditprefrictionlossChange={handleEditPreFricLossChanged}
                parentEditFuelTypeChange={handleEditFuelTypeChanged}
                parentEditFuelUseChange={handleEditFuelUseChanged}
            />
        );
    };
    const getEditNewPostPumpForm = () => {
        return (
            <EditNewPostPump
                editFieldsArrPost={fields}
                PostProjectPumpEdit={selectedPostPump}
                parentEditPostPumpNameChange={handleEditPostPumpNameChanged}
                parentEditPostFieldsListeChange={handleEditPostFieldsListChanged}
                parentEditPostHpChange={handleEditPostHpChanged}
                parentEditPostHoursChange={handleEditPostHoursChanged}
                parentEditPostEfficiencyChange={handleEditPostEfficiencyChanged}
                parentEditPostSystemPressureChange={handleEditPostSystemPressureChanged}
                parentEditPostPumpPressureChange={handleEditPostPumpPressChanged}
                parentEditPostPumpDepthChange={handleEditPostPumpDepthChanged}
                parentEditPostDischargePressureChange={handleEditPostDisPressChanged}
                parentEditPostFrictionLossChange={handleEditPostFricLossChanged}
                parentEditPostVFDChange={handleEditPostVFDChanged}
                parentEditPostFuelTypeChange={handleEditPostFuelTypeChanged}
                parentEditPostFuelUseChange={handleEditPostFuelUseChanged}
                parentEditPostRenewEnrgySelChange={handleEditPostRenewEnrgySelChanged}
                parentEditPostRenewEnrgyCapctyChange={handleEditPostRenewEnrgyCapctyChanged}
            />
        );
    };
    const getEditFieldForm = () => {
        return <EditFieldForm field={selectedField} parentEditHandleChange={handleEditFieldNameChanged} parentEditAcreChange={handleEditAcreageChanged} />;
    };

    const deleteSelectedField = (field) => {
        setShowDeleteField(true);

        if (confirm("Are you sure you want to delete field: " + field.field.name) == true) {
            var currentUserdeleteCopy = Object.assign({}, currentUser);
            currentUserdeleteCopy.fields = currentUserdeleteCopy.fields.filter((u) => u.name != field.field.name);
            // console.log("currentUserdeleteCopy.fields: ", currentUserdeleteCopy.fields);
            dispatch(updateUser(currentUserdeleteCopy));
            setFields(helpers.serializeFeature(currentUserdeleteCopy.fields));
            for (var i = 0; i < vectorLayer.getSource().getFeatures().length; i++) {
                if (vectorLayer.getSource().getFeatures()[i].values_.id == field.field.feature.id) {
                    vectorLayer.getSource().getFeatures()[i].setStyle(new Style({}));
                    vectorLayer.getSource().removeFeature(vectorLayer.getSource().getFeatures()[i]);
                    vectorLayer.setVisible(false);
                    vectorLayer.setVisible(true);
                    // pinLayer.getSource().refresh();
                    break;
                }
            }
        } else {
        }
    };
    const deleteSelectedPrePump = (preprojectpump) => {
        if (confirm("Are you sure you want to delete this Pre-Project Pump: " + preprojectpump.preprojectpump.preprojpumpName) == true) {
            var currentUserdeleteCopy = Object.assign({}, currentUser);

            var preprojfeature = JSON.parse(preprojectpump.preprojectpump.preprojfeature);

            currentUserdeleteCopy.preprojectpumps = currentUserdeleteCopy.preprojectpumps.filter((u) => u.preprojpumpName != preprojectpump.preprojectpump.preprojpumpName);

            // console.log("currentUserdeleteCopy.fields: ", currentUserdeleteCopy.preprojectpumps);
            for (var i = 0; i < pinLayer.getSource().getFeatures().length; i++) {
                if (pinLayer.getSource().getFeatures()[i].values_.id == preprojfeature.id) {
                    pinLayer.getSource().getFeatures()[i].setStyle(new Style({}));
                    pinLayer.getSource().removeFeature(pinLayer.getSource().getFeatures()[i]);
                    // pinLayer.getSource().refresh();
                    break;
                }
            }

            dispatch(updateUser(currentUserdeleteCopy));
            setPreProjectPumps(helpers.serializeFeature(currentUserdeleteCopy.preprojectpumps));
            currentUserdeleteCopy.preprojectpumps = currentUserdeleteCopy.preprojectpumps.filter((u) => u.preprojfueltype === "151");
            
            window.map.getView().setZoom(window.map.getView().getZoom() - 5);
        } else {
        }
    };

    const deleteSelectedPostPump = (postprojectpump) => {
        if (confirm("Are you sure you want to delete this Post Project Pump: " + postprojectpump.postprojectpump.postprojpumpName) == true) {
            var currentUserdeleteCopyPost = Object.assign({}, currentUser);
         
            var postprojfeature = JSON.parse(postprojectpump.postprojectpump.postprojfeature);

            currentUserdeleteCopyPost.postprojectpumps = currentUserdeleteCopyPost.postprojectpumps.filter((u) => u.postprojpumpName != postprojectpump.postprojectpump.postprojpumpName);

            // console.log("currentUserdeleteCopyPost.fields: ", currentUserdeleteCopyPost.postprojectpumps);
            for (var i = 0; i < pinLayer.getSource().getFeatures().length; i++) {
                if (pinLayer.getSource().getFeatures()[i].values_.id == postprojfeature.id) {
                    pinLayer.getSource().getFeatures()[i].setStyle(new Style({}));
                    pinLayer.getSource().removeFeature(pinLayer.getSource().getFeatures()[i]);
                    // pinLayer.getSource().refresh();
                    break;
                }
            }

            dispatch(updateUser(currentUserdeleteCopyPost));
            setPostProjectPumps(helpers.serializeFeature(currentUserdeleteCopyPost.postprojectpumps));

            window.map.getView().setZoom(window.map.getView().getZoom() - 5);
        } else {
        }
    };

    var saveNewFieldButton = helpers.getDialogButton("Save", saveNewField);
    var cancelNewFieldButton = helpers.getDialogButton("Cancel", cancelNewField);

    var saveEditFieldButton = helpers.getDialogButton("Save", saveEditField);
    var cancelEditFieldButton = helpers.getDialogButton("Cancel", cancelEditField);

    var okPumpImpButton = helpers.getDialogButton("Ok", okPumpImpField);

    var submitFieldDetailsButton = helpers.getDialogButton("Save", submitFieldDetails);

    var submitElecDetailsButton = helpers.getDialogButton("Save", SubmitElecDetails);
    var submitRenewEnrgyButton = helpers.getDialogButton("Save", SubmitRenewEnrgy);
    var addNewFieldButtons = [cancelNewFieldButton, saveNewFieldButton];
    var editNewFieldButtons = [cancelEditFieldButton, saveEditFieldButton];

    var saveNewPumpButton = helpers.getDialogButton("Save", saveNewPump);
    var cancelNewPumpButton = helpers.getDialogButton("Cancel", cancelNewPump);
    var saveCopyFieldDetailsButton = helpers.getDialogButton("Save", saveCopyFieldDetails);
    var saveNewPostPumpButton = helpers.getDialogButton("Save", saveNewPostPump);
    var cancelNewPostPumpButton = helpers.getDialogButton("Cancel", cancelNewPostPump);

    var saveEditPumpButton = helpers.getDialogButton("Save", saveEditPump);
    var cancelEditPumpButton = helpers.getDialogButton("Cancel", cancelEditPump);

    var saveEditPostPumpButton = helpers.getDialogButton("Save", saveEditPostPump);
    var cancelEditPostPumpButton = helpers.getDialogButton("Cancel", cancelEditPostPump);

    var addNewPumpButtons = [cancelNewPumpButton, saveNewPumpButton];
    var addNewPostPumpButtons = [cancelNewPostPumpButton, saveNewPostPumpButton];

    var editNewPumpButtons = [cancelEditPumpButton, saveEditPumpButton];

    var editNewPostPumpButtons = [cancelEditPostPumpButton, saveEditPostPumpButton];

    var pumpImpButtons = [okPumpImpButton];

    var fieldDetsButtons = [submitFieldDetailsButton];

    var electricityPopupButtons = [submitElecDetailsButton];
    var renewenrgyPopupButtons = [submitRenewEnrgyButton];
    var copyfieldDetsButtons = [saveCopyFieldDetailsButton];
    // console.log("Current User Obj : ", currentUser);
    //  console.log("Selected Field : ", getSelectedField());
    // console.log("Fields : ", fields);
      //console.log("PreProjectPumps : ", preprojectpumps);
      //console.log("PostProjectPumps : ", postprojectpumps);

    // console.log("Electricty Details : ", electricityDetails);
    return (
        <Box sx={{ flexGrow: 1, padding: '0 10px' }}>
            <Typography
                align="center"
                variant="h5"
                onClick={() => setShowEditName(true)}
                style={{marginTop: '10px'}}
            >
                {currentUser.agopname}
                <IconButton>
                    <EditIcon/>
                </IconButton>
            </Typography>
            <SaveLoadButtons />
                
            {/*todo: split this off into its own component*/}
            <Card id="fieldCard" style={{marginTop: 20}}>
                <CardHeader style={{backgroundColor: 'rgba(139, 187, 232, 1)'}} onClick={toggleFieldCardCollapse}>
                    <ExpandCollapseIcon collapsed={fieldCardCollapsed} />
                    <Typography align="center" variant="h6">
                        Fields with Management
                    </Typography>
                </CardHeader>
                <Collapse in={!fieldCardCollapsed}>
                    <CardContent>
                        <ButtonBar onClick={onButtonBarClick} activeButton={drawType} isEditing={isEditing} />
                        <ColorBar onClick={onColorBarClick} activeColor={drawColor} isEditing={isEditing} />
                        <div style={{ width: "100%", padding: "5px" }}>
                            <div style={{ float: "left", padding: "5px" }}>
                                {`Fields: ${fields.length} of 5`}
                            </div>
                            <div id="viewFieldReportStatus" style={{ borderColor: "transparent", color: "red", fontSize: "10px", display: "none" }}>
                                <TextField

                                    id="viewFieldReportStatusText"
                                    label=""
                                    defaultValue={0}
                                    variant="outlined"
                                    color="primary"

                                />
                            </div>
                            {/*{fields.length > 0 && (*/}
                            {/*    <div id="selectedFieldId" style={{ float: "right", padding: "5px" }}>*/}
                            {/*        {userDetSelField ? "Selected Field: " + userDetSelField.field.name : ""}*/}
                            {/*    </div>*/}
                            {/*)}*/}
                        </div>
                        <div className="sc-userprojects-container-inner" style={{ float: "left", position: "relative" }}>
                            {fields && fields.map((field, index) => (
                                <div id={"selectedField-" + field.name} key={"selectedFieldId" + index} className={fieldJustCreated && field.name == fieldJustCreated.name ? "sc-container sc-userprojects-item-container" : "sc-container sc-userprojects-item-container"}>
                                    <div className="sc-userprojects-items-container-header">
                                        <div>
                                            <div style={{ borderColor: "transparent", display: "inline-block",marginRight:"5px" }}> {field.name}</div>
                                            <div id={"completeStatus-" + field.name} style={{ borderColor: "transparent", color: "green", fontSize: "10px", display: "none" }}>Data Entry Complete</div>
                                            <div id={"inCompleteStatus-" + field.name} style={{ borderColor: "transparent", color: "red", fontSize: "10px", display: "none" }}>Data Entry Incomplete</div>
                                            
                                        </div>
                                        <Box style={{ display: "flex", borderColor: "transparent" }} flexDirection="row" justifyContent="space-around" alignItems="center">
                                            <Button
                                                id="addFieldDetails"
                                                style={{ marginRight: "5px", fontSize: "10px" }}
                                                variant="contained"
                                                title="Enter field details"
                                                color="primary"
                                                onClick={() => {
                                                    onFieldClick({ field });
                                                    ClearFieldDetailsState();
                                                    setUserDetailsOpen(true);
                                                }}
                                            >
                                               Management
                                            </Button>
                                            
                                            <IconButton id="CopyFieldDetails"
                                                color="primary"
                                                style={{
                                                    marginRight: "5px", border:"transparent"
                                                }}
                                                title="Copy field details to additional fields"
                                                onClick={() => {
                                                onFieldClick({ field });
                                                onFieldCopyClick({ field });
                                            }}>
                                                <FileCopyIcon />
                                            </IconButton>
                                            
                                            <IconButton id="EditFieldDetails"
                                                color="primary"
                                                style={{
                                                    marginRight: "5px", border:"transparent"
                                                }}
                                                title="Edit field name and acreage"
                                                onClick={() => {
                                                    onFieldClick({ field });
                                                    setSelectedField({ field });
                                                }}>
                                                <EditIcon />
                                            </IconButton>
                                           
                                            <IconButton id="DeleteFieldDetails"
                                                color="primary"
                                                style={{
                                                    border: "transparent"
                                                }}
                                                title="Delete field and all field management"
                                                onClick={() => {
                                                    deleteSelectedField({ field });
                                                }}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </Box>
                                        {/*    </div>*/}
                                        {/*</div>*/}
                                    </div>
                                </div>
                            ))}

                            {fields.length == 0 && (
                                <div style={{ padding: "10px", float: "left" }} className={"sc-userproject-items-no-data"}>
                                    Use the drawing tools and colors above to add fields. You may add up to 5 fields per project.
                                </div>
                            )}
                        </div>
                    </CardContent>
                </Collapse>
            </Card>

            {/*todo: split this off into its own component*/}
            <Card id="prePumpCard" style={{marginTop: 10}}>
                <CardHeader style={{backgroundColor: 'rgba(139, 187, 232, 1)'}} onClick={togglePrePumpCardCollapse}>
                    <ExpandCollapseIcon collapsed={prePumpCardCollapsed} />
                    <Typography align="center" variant="h6">
                        Pre-Project Pumps
                    </Typography>
                </CardHeader>
                <Collapse in={!prePumpCardCollapsed}>
                    <CardContent>
                        <div style={{ width: "100%", padding: "5px" }}>
                            <div style={{ float: "left", padding: "5px" }}>
                                {`Pre-Project Pumps: ${preprojectpumps.length} of 5`}
                            </div>
                            <div id={"viewPrePumpReportStatus"} style={{ borderColor: "transparent", color: "red", fontSize: "10px", display: "none" }}>
                                <TextField

                                    id="viewPrePumpReportStatusText"
                                    label=""
                                    defaultValue={0}
                                    variant="outlined"
                                    color="primary"

                                />
                            </div>
                            {/*{preprojectpumps.length > 0 && (*/}
                            {/*    <div id="selectedPreProjectId" style={{ float: "right", padding: "5px" }}>*/}
                            {/*        {preprojectpumpJustCreated ? "Selected Pre-Project Pump: " + preprojectpumpJustCreated.preprojpumpName : ""}*/}
                            {/*    </div>*/}
                            {/*)}*/}
                        </div>

                        <div className="sc-userprojects-container-inner" style={{ float: "left" }}>
                            {preprojectpumps &&
                                preprojectpumps.map((preprojectpump, index) => (
                                    <div id={"selectedPreProj-" + preprojectpump.preprojpumpName} key={"selectedPreProjId" + index} className={preprojectpump.preprojpumpName ? "sc-container sc-userprojects-item-container" : "sc-container sc-userprojects-item-container"}>
                                        <div className="sc-userprojects-items-container-header">
                                           
                                            <div>
                                                <div style={{ borderColor: "transparent", display: "inline-block", marginRight: "5px" }}> {preprojectpump.preprojpumpName}</div>
                                                <div id={"prepumpCompleteStatus-" + preprojectpump.preprojpumpName} style={{ borderColor: "transparent", color: "green", fontSize: "10px", display: "none" }}>Data Entry Complete</div>
                                                <div id={"prePumpInCompleteStatus-" + preprojectpump.preprojpumpName} style={{ borderColor: "transparent", color: "red", fontSize: "10px", display: "none" }}>Data Entry Incomplete</div>
                                                
                                                </div>
                                        

                                            <IconButton id="prepumpEdit"
                                                color="primary"
                                                style={{ border: "transparent", marginLeft: "25px", marginRight: "5px", fontSize: "10px" }}
                                                title="Edit Existing Irrigation Pump"
                                                onClick={() => {
                                                    setSelectedPrePump({ preprojectpump });
                                                    onPreProjEditClick({ preprojectpump });
                                                }}>
                                                <EditIcon />
                                            </IconButton>
                                           
                                            <IconButton id="prepumpDelete"
                                                color="primary"
                                                style={{
                                                    border: "transparent", marginRight: "5px", fontSize: "10px" 
                                                }}
                                                title="Delete Existing Irrigation Pump"
                                                onClick={() => {
                                                    deleteSelectedPrePump({ preprojectpump });
                                                }}
                                                >
                                                <DeleteIcon />
                                            </IconButton>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>

                        {preprojectpumps.length < 5 && (
                            <Button
                                id="addprprojectpump"
                                variant="contained"
                                title="Draw a pump on the map"
                                onClick={() => {
                                    setAddPumpInstrPopOpen(true);
                                    setExistPumpClicked(true);
                                    setAddntlPumpClicked(false);
                                    onButtonBarClick("Point");
                                }}
                                color="primary"
                            >
                                + &nbsp; Add Existing Irrigation Pump
                            </Button>
                        )}
                    </CardContent>
                </Collapse>
            </Card>

            {/*todo: split this off into its own component*/}
            <Card id="postPumpCard" style={{marginTop: 10}}>
                <CardHeader style={{backgroundColor: 'rgba(139, 187, 232, 1)'}} onClick={togglePostPumpCardCollapse}>
                    <ExpandCollapseIcon collapsed={postPumpCardCollapsed} />
                    <Typography align="center" variant="h6">
                        Post-Project Pumps
                    </Typography>
                </CardHeader>
                <Collapse in={!postPumpCardCollapsed}>
                    <CardContent>
                        <div style={{ width: "100%", padding: "5px" }}>
                            <div id="postprojcountDiv" style={{ float: "left", padding: "5px" }}>
                                {`Post-Project Pumps: ${postprojectpumps.length} of 5`}
                            </div>
                            <div id="viewPostPumpReportStatus" style={{ borderColor: "transparent", color: "red", fontSize: "10px", display: "none" }}>
                                <TextField

                                    id="viewPostPumpReportStatusText"
                                    label=""
                                    defaultValue={0}
                                    variant="outlined"
                                    color="primary"

                                />
                            </div>
                            {/*{postprojectpumps.length > 0 && (*/}
                            {/*    <div id="selectedPostProjectId" style={{ float: "right", padding: "5px" }}>*/}
                            {/*        {postprojectpumpJustCreated && postprojectpumpJustCreated.postprojisprepump == "N" ? "Selected Post-Project Pump: " + postprojectpumpJustCreated.postprojpumpName : ""}*/}
                            {/*    </div>*/}
                            {/*)}*/}
                        </div>

                        <div className="sc-userprojects-container-inner" style={{ float: "left" }}>
                            {postprojectpumps &&
                                postprojectpumps.map((postprojectpump, index) => (
                                    <div id={"selectedPostProj-" + postprojectpump.postprojpumpName} key={"selectedPostProjId" + index} className={postprojectpump.postprojpumpName ? "sc-container sc-userprojects-item-container" : "sc-container sc-userprojects-item-container"}>
                                        <div className="sc-userprojects-items-container-header">
                                            <div>
                                                <div style={{ borderColor: "transparent", display: "inline-block", marginRight: "5px" }}> {postprojectpump.postprojpumpName}</div>
                                                <div id={"postpumpCompleteStatus-" + postprojectpump.postprojpumpName} style={{ borderColor: "transparent", color: "green", fontSize: "10px", display: "none" }}>Data Entry Complete</div>
                                                <div id={"postPumpInCompleteStatus-" + postprojectpump.postprojpumpName} style={{ borderColor: "transparent", color: "red", fontSize: "10px", display: "none" }}>Data Entry Incomplete</div>
                                               
                                            </div>
                                            
                                            <IconButton id="postpumpEdit"
                                                color="primary"
                                                style={{ border: "transparent", marginLeft: "25px", marginRight: "5px", fontSize: "10px" }}
                                                title="Edit Proposed Irrigation Pump"
                                                 onClick={() => {
                                                    setSelectedPostPump({ postprojectpump });
                                                    onPostProjEditClick({ postprojectpump });
                                                }}>
                                                <EditIcon />
                                            </IconButton>
                                            
                                            <IconButton id="postpumpDelete"
                                                color="primary"
                                                style={{
                                                    border: "transparent", marginRight: "5px", fontSize: "10px"
                                                }}
                                                title="Delete Proposed Irrigation Pump"
                                                onClick={() => {
                                                    deleteSelectedPostPump({ postprojectpump });
                                                }}
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>

                        {postprojectpumps.length < 5 && (
                            <Button
                                id="addpostprojectpump"
                                variant="contained"
                                onClick={() => {
                                    setExistPumpClicked(false);
                                    setAddntlPumpClicked(true);
                                    onButtonBarClick("Point");
                                }}
                                color="primary"
                            >
                                + &nbsp; Add Proposed Irrigation Pump
                            </Button>
                        )}
                    </CardContent>
                </Collapse>
            </Card>

            <Card id="nextStepsCard" style={{marginTop: 10}}>
                <CardHeader style={{backgroundColor: 'rgba(139, 187, 232, 1)'}}>
                    <Typography align="center" variant="h6">
                        Next Steps
                    </Typography>
                </CardHeader>
                <CardContent>
                    <Box display="flex" flexDirection="column" justifyContent="flex-start" alignItems="center">
                        
                        <div id={"elecUseNotValid"} style={{ borderColor: "transparent", color: "red", marginBottom: "10px", fontSize: "12px", display: "none" }}>Enter the electricity usage for your current irrigation system</div>
                        {currentUser.preprojectpumps.filter((u) => u.preprojfueltype === "151").length > 0 ? <Button
                            id="elecButton"
                            variant="contained"
                            style={{
                               
                                marginBottom: 10,
                                display: "inline-block"
                            }}
                            onClick={() => {
                                setElectricityPopUpOpen(true);
                            }}
                            color="primary"
                        >
                            Electricity Use
                        </Button> :null}
                        <div id={"renewEnrgyNotValid"} style={{ borderColor: "transparent", color: "red", marginBottom: "10px", fontSize: "12px", display: "none" }}>Enter the capacity for your proposed renewable energy installation</div>
                        {(currentUser.postprojectpumps.filter((u) => u.postprojfueltype === "155" || u.postprojfueltype === "156" || u.postprojfueltype === "157").length > 0) || (currentUser.preprojectpumps.filter((u) => u.postprojfueltype === "155" || u.postprojfueltype === "156" || u.postprojfueltype === "157").length > 0) ? <Button
                            id="renewEnrgyButton"
                            variant="contained"
                            style={{

                                marginBottom: 10,
                                display: "inline-block"
                            }}
                            onClick={() => {
                                setRenewenrgyPopUpOpen(true);
                            }}
                            color="primary"
                        >
                            Renewable Energy
                        </Button> : null}
                        <Button
                            id="repButton"
                            variant="contained"
                            style={{ marginBottom: 10 }}
                            onClick={() => {
                                handleReportClick();
                            }}
                            color="primary"
                            disabled={
                                canViewReports()
                            }
                        >
                            View Reports
                        </Button>

                        <Button
                            id="downloadButton"
                            variant="contained"
                            onClick={() => {
                                downloadMap();
                            }}
                            color="primary"
                        >
                            Download Map
                        </Button>
                    </Box>
                </CardContent>
            </Card>

            <DynamicDialog buttons={addNewFieldButtons} open={showAddNewField} labelId={"showAddNewFieldDialog"} title={"Add new field "} textMessage={""} form={getAddNewFieldForm()} />
            <DynamicDialog buttons={editNewFieldButtons} open={showEditNewField} labelId={"showEditNewFieldDialog"} title={"Edit field "} textMessage={""} form={getEditFieldForm()} />
            <DynamicDialog buttons={addNewPumpButtons} open={addPumpOpen} labelId={"showAddNewPumpDialog"} title={"Pumping System Details"} textMessage={""} form={getAddNewPumpForm()} />
            <DynamicDialog buttons={editNewPumpButtons} open={editPumpOpen} labelId={"showEditNewPumpDialog"} title={"Edit Pumping System Details"} textMessage={""} form={getEditNewPumpForm()} />
            <DynamicDialog buttons={addNewPostPumpButtons} open={addPostPumpOpen} labelId={"showAddNewPostPumpDialog"} title={"Add Additional Post Project Pumps"} textMessage={""} form={getAddNewPostPumpForm()} />
            <DynamicDialog buttons={editNewPostPumpButtons} open={editPostPumpOpen} labelId={"showEditNewPostPumpDialog"} title={"Edit Additional Pumps "} textMessage={""} form={getEditNewPostPumpForm()} />
            <DynamicDialog buttons={pumpImpButtons} open={pumpImpOpen} labelId={"showPumpImpDialog"} title={"Additional Pump Locations "} textMessage={""} form={getPumpImpForm()} />
            <DynamicDialog buttons={fieldDetsButtons} open={userDetailsOpen} labelId={"showFieldDetailsDialog"} title={userDetailsTitle} textMessage={""} form={getFieldDetailsForm()} />
            <DynamicDialog buttons={electricityPopupButtons} open={electricityPopUpOpen} labelId={"showElectricityDialog"} title={"Electricity Use for Existing Pumps"} textMessage={""} form={getElectricityFormEdit()} />
            <DynamicDialog buttons={renewenrgyPopupButtons} open={renewenrgyPopUpOpen} labelId={"showRenewEnrgyDialog"} title={"Renewable Energy "} textMessage={""} form={getRenewEnrgyCapctyFormEdit()} />
            <DynamicDialog buttons={copyfieldDetsButtons} open={copyFieldDetailsOpen} labelId={"copyFieldDetailsDialog"} title={"Copy Field Details "} textMessage={""} form={getCopyFieldDetailsForm()} />

            <EditNamePopup open={showEditName} handleClose={resetEditDialogs} value={currentUser.agopname}/>
            <SuccessPopup open={successPopOpen} handleClose={handleSuccessPopClose} />
            <AddPumpInstr open={addPumpInstrPopOpen} handleClose={handleAddPumpInstrPopClose} />
            <DataEntry open={dataEntryPopOpen} handleClose={handleDataEntryPopClose} />
            <UniqueFieldName open={fieldNameWarnPopOpen} handleClose={handleFieldNameWarnPopClose} />
            <MaxFields open={maxFieldsWarn} handleClose={handleMaxFieldsWarnPopClose} />
            <MaxPreProjPumps open={maxPreProjPumpsWarn} handleClose={handleMaxPreProjPumpsWarnPopClose} />
            <MaxPostProjPumps open={maxPostProjPumpsWarn} handleClose={handleMaxPostProjPumpsWarnPopClose} />
        </Box>
    );
}

// IMPORT ALL IMAGES
const images = importAllImages(require.context("./images", false, /\.(png|jpe?g|svg)$/));
function importAllImages(r) {
    let images = {};
    // console.log('importing all images');
    r.keys().map((item, index) => (images[item.replace("./", "")] = r(item)));
    return images;
}
