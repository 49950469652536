import React from "react";
import { styled } from '@material-ui/core/styles';
import { useEffect } from "react";
import GridItem from "../../../components/Grid/GridItem.js";
import GridContainer from "../../../components/Grid/GridContainer.js";
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import NativeSelect from '@material-ui/core/NativeSelect';
import InputBase from '@material-ui/core/InputBase';
import * as CMBYN from "../../../constants/api";
import OutlinedInput from '@material-ui/core/OutlinedInput';

const BootstrapInput = styled(InputBase)(({ theme }) => ({
    'label + &': {
        marginTop: theme.spacing(3),
        width: '90%',
    },
    '& .MuiInputBase-input': {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #ced4da',
        fontSize: 16,

        padding: '10px 26px 10px 12px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:focus': {
            borderRadius: 4,
            borderColor: '#80bdff',
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        },
    },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export default function EditNewPump(props ) {
    const { editFieldsArr, PreProjectPump, parentEditPumpNameChange, parentEditFieldsListeChange, parentEditHpChange,
        parentEditHoursChange, parentEditEfficiencyChange, parentEditSystemPressureChange, parentEditprepumpressureChange,
        parentEditprepumpdepthChange, parentEditpredischargepressureChange, parentEditprefrictionlossChange,
        parentEditFuelTypeChange, parentEditFuelUseChange } = props
    
   
    // console.log('this is edit prepump', PreProjectPump);
    const [pumpName, setPumpName] = React.useState(PreProjectPump.preprojectpump.preprojpumpName);
    const [fields, setFields] = React.useState(PreProjectPump.preprojectpump.preprojfields);
    const [preEff, setPreEff] = React.useState(PreProjectPump.preprojectpump.preprojeff);
    const [preHP, setPreHP] = React.useState(PreProjectPump.preprojectpump.preprojhp);
    const [preHours, setPreHours] = React.useState(PreProjectPump.preprojectpump.preprojhours);
    const [preFuelUse, setPreFuelUse] = React.useState(PreProjectPump.preprojectpump.preprojfueluse);
    const [pumpsystempressure, setPumpsystempressure] = React.useState(PreProjectPump.preprojectpump.preprojsyspress);
    const [prepumppress, setPrePumpPress] = React.useState(PreProjectPump.preprojectpump.preprojpumpress);
    const [prepumpdepth, setPrePumpDepth] = React.useState(PreProjectPump.preprojectpump.preprojpumpdepth);
    const [predispress, setPredispress] = React.useState(PreProjectPump.preprojectpump.preprojdispress);
    const [prefricloss, setPrefricloss] = React.useState(PreProjectPump.preprojectpump.preprojfricloss);
    const [pumpfueltype, setPumpfueltype] = React.useState(PreProjectPump.preprojectpump.preprojfueltype);
    const [pumpfueltypeList, setPumpfueltypeList] = React.useState([]);

   

    useEffect(() => {
        showPreFuelUseDiv();
    }, [pumpfueltype]);

    const handleFieldsChange = (event) => {
        var value = event.target.value;
        setFields(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value);
        parentEditFieldsListeChange(value);
    };

    const handlePreHP = (event) => {
        setPreHP(event.target.value);
        parentEditHpChange(event.target.value);
    };

    const handlePreHours = (event) => {
        setPreHours(event.target.value);
        parentEditHoursChange(event.target.value);
    };

    const handlePreEff = (event) => {
        setPreEff(event.target.value);
        parentEditEfficiencyChange(event.target.value);
    };

    const handlePreFuelUse = (event) => {
        setPreFuelUse(event.target.value);
        parentEditFuelUseChange(event.target.value);
    };
        
    const handlePrePumpSystempressure = (event) => {
        setPumpsystempressure(event.target.value);
        parentEditSystemPressureChange(event.target.value)
    };

    const handlePrePumpPress = (event) => {
        setPrePumpPress(event.target.value);
        parentEditprepumpressureChange(event.target.value);
    };

    const handlePrePumpDepth = (event) => {
        setPrePumpDepth(event.target.value);
        parentEditprepumpdepthChange(event.target.value);
    };

    const handlePreDisPress = (event) => {
        setPredispress(event.target.value);
        parentEditpredischargepressureChange(event.target.value);
    };

    const handlePreFricLoss = (event) => {
        setPrefricloss(event.target.value);
        parentEditprefrictionlossChange(event.target.value);
    };

    const handlePrepumpNameChange = event => {
        setPumpName(event.target.value);
        parentEditPumpNameChange(event.target.value)
    };

    const handlePrePumpfueltype = event => {
        setPumpfueltype(event.target.value);
        parentEditFuelTypeChange(event.target.value)
        
    };
    
    const showPreSysPressDiv = event => {
        
            if (pumpsystempressure == 1) {
                if (document.getElementById("syspressitemspreedit") != null)
                    {
                    document.getElementById("syspressitemspreedit").style.display = "none";
                }
               
            } else if (pumpsystempressure == 2) {
                if (document.getElementById("syspressitemspreedit") != null) {
                    document.getElementById("syspressitemspreedit").style.display = "block";
                }
                
            }
    };

    const showPreFuelUseDiv = event => {
        if (document.getElementById("diveditprepumpfueluse") != null) {
            
            if ((pumpfueltype >= 150 && pumpfueltype <= 154 && pumpfueltype != 151) || (pumpfueltype=='')) {

                document.getElementById("diveditprepumpfueluse").style.display = "block";

            }
            else {
                
                document.getElementById("diveditprepumpfueluse").style.display = "none";
            }
        }
    };

    useEffect(() => {
        fetch(CMBYN.FETCH_POSTFUELTYPES)
            .then(response => response.json())
            .then(results => {
                var list = [];
                for (var u = 0; u < results.length; u++) {
                    let usr = results[u];
                    if (usr && usr.id < 155) {
                        let option = {};
                        option.name = usr.name;
                        option.id = usr.id;
                        list.push(option);
                    }
                }
                setPumpfueltypeList(list);
            });
    }, []);

    return (
        <div style={{ marginTop: 10 }}>
            <GridContainer
                direction="column"
                justifyContent="flex-start"
                alignItems="flex-start"
            >
                <GridItem xs={11} sm={11} md={11}>
                    <div style={{ marginTop: "10px", width: "max-content"}}>
                        <InputLabel htmlFor="newpump">Name of the pump :</InputLabel>
                    </div>
                </GridItem>

                <GridItem xs={11} sm={11} md={11}>
                    <div style={{ marginTop: "10px", width: "max-content"}}>
                        <TextField
                            id="addnewpump"
                            label=""
                            defaultValue={pumpName }
                            variant="outlined"
                            color="primary"
                            onChange={handlePrepumpNameChange}
                        />
                    </div>
                </GridItem>
            </GridContainer>

            <GridContainer
                direction="column"
                justifyContent="flex-start"
                alignItems="flex-start"
            >
                <GridItem xs={11} sm={11} md={11}>
                    <div style={{ marginTop: "10px", width: "max-content"}}>
                        <InputLabel id="demo-simple-select-standard-label">Which fields does this pump service :</InputLabel>
                    </div>
                </GridItem>
                <GridItem xs={11} sm={11} md={11}>
                    <div style={{ marginTop: "10px", width: "max-content"}}>
                        <Select
                            labelId="demo-multiple-name-label"
                            id="demo-multiple-name"
                            multiple
                            value={fields}
                            onChange={handleFieldsChange}
                            input={<OutlinedInput label="Fields" />}
                            MenuProps={MenuProps}
                        >
                            {editFieldsArr.map((field) => (
                                <MenuItem
                                    key={field.name}
                                    value={field.name}
                                >
                                    {field.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </div>
                </GridItem>
            </GridContainer>
                    
            <GridContainer
                direction="column"
                justifyContent="flex-start"
                alignItems="flex-start"
            >
                <GridItem xs={11} sm={11} md={11}>
                    <div style={{ marginTop: "10px", width: "max-content"}}>
                        <InputLabel htmlFor="motorratedhp">Motor Rated Horsepower :</InputLabel>
                    </div>
                </GridItem>
                <GridItem xs={11} sm={11} md={11}>
                    <div style={{ marginTop: "10px", width: "max-content"}}>
                        <TextField
                            id="fieldmotorhorseratedhp"
                            label=""
                            defaultValue={ preHP}
                            variant="outlined"
                            color="primary"
                            onChange={handlePreHP}
                            type="number"
                        />
                    </div>
                </GridItem>
            </GridContainer>
              
            

            <GridContainer
                direction="column"
                justifyContent="flex-start"
                alignItems="flex-start"
            >
                <GridItem xs={11} sm={11} md={11}>
                    <div style={{ marginTop: "10px", width: "max-content"}}>
                        <InputLabel htmlFor="addnewpumpefficiency">Overall Pumping Efficiency (%) :</InputLabel>
                    </div>
                </GridItem>

                <GridItem xs={11} sm={11} md={11}>
                    <div style={{ marginTop: "10px", width: "max-content"}}>
                        <TextField
                            id="fieldaddnewpumpefficiency"
                            label=""
                            defaultValue={preEff}
                            variant="outlined"
                            color="primary"
                            onChange={handlePreEff}
                            type="number"
                        />
                    </div>
                </GridItem>
            </GridContainer>

           
                   
           

            <GridContainer
                direction="column"
                justifyContent="flex-start"
                alignItems="flex-start"
            >
                <GridItem>
                    <div id="syspressitemspreedit" style={{ marginTop: "10px", width: "max-content"}}>
                        <GridContainer
                            direction="column"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                        >
                            <GridItem xs={11} sm={11} md={11}>
                                <div style={{ marginTop: "10px", width: "max-content" }}>
                                    <InputLabel htmlFor="pumpdepthpre">Enter the pumping depth (ft): </InputLabel>
                                </div>
                            </GridItem>

                            <GridItem xs={11} sm={11} md={11}>
                                <div style={{ marginTop: "10px", width: "max-content" }}>
                                    <TextField
                                        id="fieldumpingdepthpre"
                                        label=""
                                        defaultValue={prepumpdepth}
                                        variant="outlined"
                                        color="primary"
                                        onChange={handlePrePumpDepth}
                                        type="number"
                                    />
                                </div>
                            </GridItem>
                        </GridContainer>
                        <GridContainer
                            direction="column"
                            justifyContent="flex-start"
                            alignItems="flex-start">
                            <GridItem xs={11} sm={11} md={11}>
                                <div style={{ marginTop: "10px", width: "max-content" }}>
                                    <InputLabel htmlFor="dischargepresspre">Enter the discharge pressure (ft) (if only psi is known please multiply by 2.31 to convert to feet):</InputLabel>
                                </div>
                            </GridItem>

                            <GridItem xs={11} sm={11} md={11}>
                                <div style={{ marginTop: "10px", width: "max-content" }}>
                                    <TextField
                                        id="fielddischargepressurepre"
                                        label=""
                                        defaultValue={predispress}
                                        variant="outlined"
                                        color="primary"
                                        onChange={handlePreDisPress}
                                        type="number"
                                    />
                                </div>
                            </GridItem>
                        </GridContainer>
                        <GridContainer
                            direction="column"
                            justifyContent="flex-start"
                            alignItems="flex-start">
                            <GridItem xs={11} sm={11} md={11}>
                                <div style={{ marginTop: "10px", width: "max-content" }}>
                                    <InputLabel htmlFor="friclosspre">Enter the friction losses (ft) (friction loss is 5 for a booster and 10 for a well pump): </InputLabel>
                                </div>
                            </GridItem>

                            <GridItem xs={11} sm={11} md={11}>
                                <div style={{ marginTop: "10px", width: "max-content" }}>
                                    <TextField
                                        id="fieldfrictionlosspre"
                                        label=""
                                        defaultValue={prefricloss}
                                        variant="outlined"
                                        color="primary"
                                        onChange={handlePreFricLoss}
                                        type="number"
                                    />
                                </div>
                            </GridItem>
                        </GridContainer>
                    </div>
                </GridItem>
            </GridContainer>

            <GridContainer
                direction="column"
                justifyContent="flex-start"
                alignItems="flex-start"
            >
                <GridItem xs={11} sm={11} md={11}>
                    <div style={{ marginTop: "10px", width: "max-content"}}>
                        <InputLabel htmlFor="addnewpumpFuel">Fuel Type:</InputLabel>
                    </div>
                </GridItem>
                <GridItem xs={11} sm={11} md={11}>
                    <div style={{ marginTop: "10px", width: "max-content"}}>
                        <NativeSelect
                            id="addNewPumpFuelType"
                            value={pumpfueltype}
                            onChange={handlePrePumpfueltype}
                            input={<BootstrapInput />}
                        >
                            {pumpfueltypeList.map((cr) => (
                                <option value={cr.id}> {cr.name}
                                </option >

                            ))}
                        </NativeSelect>
                    </div>
                </GridItem>
            </GridContainer>

            <GridContainer direction="column" alignItems="flex-start">
                <GridItem>
                    <div id="diveditprepumpfueluse" style={{ marginTop: "10px", width: "max-content", display: "block" }}>
                        <GridContainer
                            direction="column"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                        >
                            <GridItem xs={11} sm={11} md={11}>
                                <div style={{ marginTop: "10px", width: "max-content"}}>
                                    <InputLabel htmlFor="addnewpumpfueluse">Annual Fuel Use (gallons or scf):</InputLabel>
                                </div>
                            </GridItem>

                            <GridItem xs={11} sm={11} md={11}>
                                <div style={{ marginTop: "10px", width: "max-content"}}>
                                    <TextField
                                        id="fieldaddnewpumfueluse"
                                        label=""
                                        defaultValue={ preFuelUse}
                                        variant="outlined"
                                        color="primary"
                                        onChange={handlePreFuelUse}
                                        type="number"
                                    />
                                </div>
                            </GridItem>
                        </GridContainer>
                    </div>
                </GridItem>
            </GridContainer>
        </div>
    );
}
