import React, { useState } from "react";
import Checkbox from "@material-ui/core/Checkbox";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";

export default function SpatialLayerList(props) {
  const { onChange, allLayers, visibleLayers } = props;
  // console.log('spatial layer props : ', props);
  return (
    <GridContainer style={{ width: "200px" }}>
      {allLayers &&
        allLayers.map((layer, index) => {
          var initValue = false;
          //Adding logic to check it a layer is already visible.
          for (var i = 0; i < visibleLayers.length; i++)
            if (visibleLayers[i] == layer) initValue = true;

          return (
            <GridItem xs={12} sm={12} md={12} key={index}>
              <GridContainer direction="row" justifyContent="flex-start" alignItems="flex-start">
              <GridItem xs={2} sm={2} md={2}>
                <div style={{marginTop:"10px"}}>
                  <Checkbox
                    color="primary"
                    onChange={(event) => onChange(event, layer)}
                    //checked={initValue}
                    inputProps={{ "aria-label": layer }}
                  />
                  </div>
                </GridItem>
                <GridItem xs={8} sm={8} md={8}>
                  <h4>{layer}</h4>
                </GridItem>


              </GridContainer>
            </GridItem>
          );
        })}
    </GridContainer>
  );
}
