import React, { useEffect } from 'react';
import { styled } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from '@material-ui/core/InputLabel';
import NativeSelect from '@material-ui/core/NativeSelect';
import InputBase from '@material-ui/core/InputBase';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Checkbox from '@material-ui/core/Checkbox';
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
    'label + &': {
        marginTop: theme.spacing(3),
        width: '90%',
    },
    '& .MuiInputBase-input': {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #ced4da',
        fontSize: 16,
      
        padding: '10px 26px 10px 12px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:focus': {
            borderRadius: 4,
            borderColor: '#80bdff',
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        },
    },
}));


export default function PreWaterMgmtToolsPopup(props) {

    const { selPreWaterField,parentchildpresm, parentchildpreflow, parentchildpreet, parentchildpreno, parentPreSMChange, parentPreFLChange, parentPreETChange, parentPreNOChange } = props;
    const [preSoilMoisture, setPreSoilMoisture] = (parentchildpresm != selPreWaterField.field.fielddetails.prewater.preyearsoilmoisture) ? React.useState(parentchildpresm) : React.useState(selPreWaterField.field.fielddetails.prewater.preyearsoilmoisture); 
    const [preFlow, setPreFlow] = (parentchildpreflow != selPreWaterField.field.fielddetails.prewater.preyearflow) ? React.useState(parentchildpreflow) : React.useState(selPreWaterField.field.fielddetails.prewater.preyearflow); 
    const [preET, setPreET] = (parentchildpreet != selPreWaterField.field.fielddetails.prewater.preyearet) ? React.useState(parentchildpreet) : React.useState(selPreWaterField.field.fielddetails.prewater.preyearet);
    const [preNone, setPreNone] = (parentchildpreno != selPreWaterField.field.fielddetails.prewater.preyearno) ? React.useState(parentchildpreno) : React.useState(selPreWaterField.field.fielddetails.prewater.preyearno); 
   
    const handleSoilMoistureChange = (event) => {
        if (!preNone) {
            setPreSoilMoisture(event.target.checked);

            parentPreSMChange(event.target.checked);
        }
    };
    const handleFlowChange = (event) => {
        if (!preNone) {
            setPreFlow(event.target.checked);

            parentPreFLChange(event.target.checked);
        }
    };
    const handleEtChange = (event) => {
        if (!preNone) {
            setPreET(event.target.checked);

            parentPreETChange(event.target.checked);
        }
    };
    const handleNoneChange = (event) => {
        setPreNone(event.target.checked);
        if (event.target.checked) { 
           
            setPreET(false);
            setPreFlow(false);
        setPreSoilMoisture(false);
            parentPreETChange(false);
            parentPreFLChange(false);

            parentPreSMChange(false);
    }
        parentPreNOChange(event.target.checked);
    };

    //    parentPreWaterMgmtToolsChange(preWaterInputs);
    //};
    const [preirrigtn, setPreirrigtn] = React.useState('');
    const [postirrigtn, setPostirrigtn] = React.useState('');
  
    const handlePreIrrigation = (event) => {
        setPreirrigtn (event.target.value);
    };
    const handlePostIrrigation = (event) => {
        setPostirrigtn (event.target.value);
    };
    useEffect(() => {
        if ((selPreWaterField.field.fielddetails.prewater.preyearno && preNone) || (preNone)) {
            setPreET(false);
            setPreFlow(false);
            setPreSoilMoisture(false);
        }

    });
    return (
        <div>
            
            
                <GridContainer direction="column" justifyContent="flex-start" alignItems="flex-start">
                    
                    <GridItem xs={12} sm={12} md={12}>
               
                   
                   
                        <div style={{ marginTop: "10px" }}>
                        <InputLabel variant="standard" htmlFor="uncontrolled-native" color="primary">
                            Select the water management tools currently in use on this field (select all that apply).
                        </InputLabel>
                    </div>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                    <div style={{ marginTop: "10px" }}>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Checkbox checked={preSoilMoisture} onChange={handleSoilMoistureChange} name="soilmoisture" />
                                }
                                label="Soil Moisture Equipment"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox checked={preFlow} onChange={handleFlowChange} name="flow" />
                                }
                                label="Flow Meter"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox checked={preET} onChange={handleEtChange} name="et" />
                                }
                                label="Use of ET Information"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox checked={preNone} onChange={handleNoneChange} name="no" />
                                }
                                label="None of the above"
                            />
                        </FormGroup>
                    </div>
                
                    
                        
                    </GridItem>
                    
                    </GridContainer>
           
        </div>
    );
}
