import React, { useState } from "react";
import { useSelector } from "react-redux";

// @material-ui/core components
import { CardContent, Collapse, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@material-ui/core";

// custom components
import Card from "../../components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import ExpandCollapseIcon from "../ExpandCollapseIcon.jsx";

import { formatResult, loadingText } from "helpers/report.js";

export default function FieldWaterReportTable (props) {
  const { classes, reportData } = props;
  const fields = useSelector(state => state.user.fields);

  const [collapsed, setCollapsed] = useState(false);
  
  function toggleCollapse() {
    setCollapsed(!collapsed);
  }

  return (
    <Card id="fieldWaterReport">
      <CardHeader style={{backgroundColor: '#8ea9db'}} onClick={toggleCollapse}>
        <ExpandCollapseIcon collapsed={collapsed} />
        <Typography align="center" variant="h5">
          Annual Irrigation Water Savings from Irrigation System Enhancements in Proposed Project 
        </Typography>
      </CardHeader>
      <Collapse in={!collapsed}>
        <CardContent>
          <Table>
            <TableHead className={classes.tableHeader}>
              <TableRow>
                <TableCell>
                  Field
                </TableCell>
                <TableCell align="left">
                  Acres
                </TableCell>
                <TableCell align="left">
                  Before Water Use (ac-in/ac)
                </TableCell>
                <TableCell align="left">
                  After Water Use (ac-in/ac)
                </TableCell>
                <TableCell align="left">
                  Water Savings (ac-in/ac)
                </TableCell>
                <TableCell align="left">
                  Water Savings (%)
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {fields.map(field =>
                <TableRow key={'fieldWaterReportRow_' + field.id}>
                  <TableCell>{field.name}</TableCell>
                  <TableCell>{field.acreage}</TableCell>
                  <TableCell>{field.results ? formatResult(field.results.beforeWater) : loadingText}</TableCell>
                  <TableCell>{field.results ? formatResult(field.results.afterWater) : loadingText}</TableCell>
                  <TableCell>{field.results ? formatResult(field.results.estWaterSavings) : loadingText}</TableCell>
                  <TableCell>{field.results ? formatResult(field.results.percentWaterSavings) : loadingText}</TableCell>
                </TableRow>
              )}
              <TableRow key={'fieldWaterReportRow_totals'} className={classes.totalsRow}>
                <TableCell>Total</TableCell>
                <TableCell>
                  {formatResult(reportData.waterReport.totalArea, true)}
                </TableCell>
                <TableCell>
                  {formatResult(reportData.waterReport.beforeWaterUse)}
                </TableCell>
                <TableCell>
                  {formatResult(reportData.waterReport.afterWaterUse)}
                </TableCell>
                <TableCell>
                  {formatResult(reportData.waterReport.waterSavings)}
                </TableCell>
                <TableCell>
                  {formatResult(reportData.waterReport.percentSavings)}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </CardContent>
      </Collapse>
    </Card>
  );
}