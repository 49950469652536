import React, { useEffect, useState } from "react";
import "./MapSwitcher.css";
import * as helpers from "../../helpers/helpers";
import MapConfig from "./MapSwitcherConfig.json";
import TileLayer from "ol/layer/Tile.js";
import BingMaps from "ol/source/BingMaps.js";
import SpatialLayerList from "./SpatialLayerList";
import LayerLegend from "./LayerLegend.js";
import { BACKEND } from "../../constants/api";


export default function MapSwitcher() {
  const [imagerySliderValue, setImagerySliderValue] = useState(null);
  const [imageryLayers, setImageryLayers] = useState([]);
  const [imageryPanelOpen, setImageryPanelOpen] = useState(false);
  const [streetsLayer, setStreetsLayer] = useState(null);
  const [streetsCheckbox, setStreetsCheckbox] = useState(true);
  const [containerCollapsed, setContainerCollapsed] = useState(false);
  const [topoPanelOpen, setTopoPanelOpen] = useState(false);
  const [topoLayers, setTopoLayers] = useState([]);
  const [topoActiveIndex, setTopoActiveIndex] = useState(0);
  const [activeButton, setActiveButton] = useState("imagery");
  const [allLayers, setAllLayers] = useState([]);
  const [forceUpdate, setForceUpdate] = useState([]);
  const [styles, setStyles] = useState([
    {
      styleName: "Road",
      isTileLayer: false,
    },

    {
      styleName: "Aerial",
      isTileLayer: false,
    },
    {
      styleName: "AerialWithLabels",
      isTileLayer: false,
    },
  ]);

  // LISTEN FOR MAP TO MOUNT
  window.emitter.addListener("mapLoaded", () => onMapLoad());


  const addLayersToState = (keys) => {
    let newList = [];

    if (keys)
      if (keys.length > 0) {
        keys.forEach((key) => {
          if (!allLayers.includes(key)) {
            newList.push(key);
          }
        });
      }

     // console.log('Layers at time: ', allLayers);
      var allLayersPlusNew = allLayers.concat(newList);  
     // console.log('Layers at time after new: ', allLayersPlusNew);

    setAllLayers(allLayersPlusNew);
  };
  

  const onMapLoad = () => {
    let newLayers = [];

    for (var i = 0; i < styles.length; i++) {
      if (styles[i].isTileLayer && styles[i].styleName == "ssurgoSoils") {
        /* window.map.addLayer(new TileLayer({
                    title: "Soils Imagery",
                    source: new XYZ(
                        {
                            tileUrlFunction: getSsurgoTileImage
                        }),
                    visible: false
                }));
                
                newLayers.push("Soils Imagery")*/
      } else {
        window.map.addLayer(
          new TileLayer({
            visible: styles[i].styleName == "AerialWithLabels" ? true : false,
            preload: Infinity,
            title: styles[i].styleName,
            source: new BingMaps({
              key: "AodaDoXgognX4vhPqaouMx44frx2G9a5Swio1r9aGK0iOiUNyYu9jnqpHMT0_1hL",
              imagerySet: styles[i].styleName,
            }),
          })
        );
        //  console.log("Adding bing maps layer", window.map);
        //  newLayers.push(styles[i].styleName)
      }
    }

    if (MapConfig) {
      if (MapConfig.cdlServices)
        MapConfig.cdlServices.map((service) => {
          let layer = helpers.getImageWMSLayerCDL(
            service.url,
            service.layers,
            service.name,
            service.version
          );
          layer.setOpacity(0.6);
          layer.setVisible(false);
          window.map.addLayer(layer);

          newLayers.push(service.name);
          // console.log("adding image layer");
        });

      if (MapConfig.geoserverServices)
        MapConfig.geoserverServices.map((service) => {
          let layer = helpers.getImageWMSLayer(
            service.url,
            service.layers,
            service.name,
            service.version
          );
          layer.setOpacity(0.6);
          layer.setVisible(false);
          window.map.addLayer(layer);

          newLayers.push(service.name);
        //   console.log('adding image layer: ' + service.name);
        });

      /*Turning off for now */
      if (!MapConfig.mapServerServices)
        MapConfig.mapServerServices.map((service) => {
          let layer = helpers.getMapServerVectorSource(
            service.url,
            service.layers,
            service.name,
            service.version
          );
          layer.setOpacity(0.9);
          layer.setVisible(false);
          window.map.addLayer(layer);

          newLayers.push(service.name);
          // console.log('adding image layer');
        });

      /*locally hosted postgis layers.  Fetch them first. */
      if (MapConfig.postgresServerServices)
        MapConfig.postgresServerServices.map((service) => {

          if (service.url) {
            getPostgresLayer(service);
          }

          if (service.name != 'States') {
            newLayers.push(service.name);
          }
        });

      // console.log("New Layers: ", newLayers);
      addLayersToState(newLayers);
    }
  };

  const getPostgresLayer = ( service) => {
    var data = { Fips: [] };

    fetch(`${BACKEND}${service.url}`, {
      method: "POST",
      body: JSON.stringify(data),
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => {
        if (response.status == 200) {
          response
            .json()
            .then((geoJson) => {
              // console.log("Fetching postgres layer: ", geoJson );
              let layer = helpers.getPostgresServerVectorSource(
                service.url,
                service.layers,
                service.name,
                service.version,
                "",
                geoJson
              );
              //    console.log("Layer object: ", layer );
              if (layer) {
                if (service.name == "States") 
                setStateLayerOnMap(layer);
                else if (service.name == "Counties") setCountyLayerOnMap(layer);
                else {
                  layer.setOpacity(0.9);
                  layer.setVisible(false);
                  window.map.addLayer(layer);
                }

                //addLayersToState([service.name]);
              }
              //console.log('layer : ', layer);
              //addLayersToState([layer] );
            })
            .catch((e) => {
              console.log("Layer Not Found", e);
            });
        }
      })
      .catch((e) => {
        console.log("Layer Not Found", e);
      });
  };

  /*
      Remove any existing state layers besides new layer
      TODO: Just hide layers so we don't need to fetch them again.
    */
 const setStateLayerOnMap = (newLayer) => {
    var layersToRemove = [];

    window.map.getLayers().forEach((layer) => {
      let title = layer.get("title");

      if (title !== null)
        if (title === "States") {
          //aka ''
          //    console.log(layer);
          layersToRemove.push(layer);
        }
    });

    layersToRemove.forEach((layerToRemove) => {
      window.map.removeLayer(layerToRemove);
    });

    var firstFeature = newLayer.getSource().getFeatures()[0];

    helpers.zoomToFeature(firstFeature);

    newLayer.setOpacity(0.9);
    newLayer.setVisible(true);
    window.map.addLayer(newLayer);
  };

  /*
      Remove any existing county layers besides new layers
      TODO: Just hide layers so we don't need to fetch them again.
    */
  const setCountyLayerOnMap = (newLayer) => {
    var layersToRemove = [];

    window.map.getLayers().forEach((layer) => {
      let title = layer.get("title");

      if (title !== null)
        if (title === "Counties") {
          //aka ''
          //    console.log(layer);
          layersToRemove.push(layer);
        }
    });

    layersToRemove.forEach((layerToRemove) => {
      window.map.removeLayer(layerToRemove);
    });

    newLayer.setOpacity(0.9);
    newLayer.setVisible(true);
    window.map.addLayer(newLayer);
  };

  const getSsurgoTileImage = (bounds) => {
    var tileObj = {
      z: bounds[0],
      x: bounds[1],
      y: bounds[2],
    };

    var tileBounds = helpers.getTileBounds(tileObj);
    var tileExtent = [
      tileBounds.sw.lng,
      -1 * tileBounds.ne.lat,
      tileBounds.ne.lng,
      -1 * tileBounds.sw.lat,
    ];

      var url = "https://SDMDataAccess.sc.egov.usda.gov/Spatial/SDM.wms";
    url += "?REQUEST=GetMap";
    url += "&SERVICE=WMS";
    url += "&VERSION=1.1.1";
    url += "&LAYERS=" + "MapunitPoly";
    url += "&FORMAT=" + "image/png";
    url += "&TRANSPARENT=" + "true";
    url += "&SRS=" + "EPSG:4326";
    url += "&BBOX=" + tileExtent;
    url += "&WIDTH=" + "256";
    url += "&HEIGHT=" + "256";
    //console.log(url);
    return url;
  };

  // CALLED WHEN SLIDING OR TO RESET
  const updateImageryLayers = (value) => {
    for (let index = 0; index < imageryLayers.length; index++) {
      let layer = imageryLayers[index];
      if (value === -1) layer.setVisible(false);
      else {
        const layerIndex = layer.getProperties().index;
        const indexRatio = 1 - Math.abs(layerIndex - value);
        if (layerIndex === value) {
          layer.setOpacity(1);
          layer.setVisible(true);
        } else if (indexRatio < 0) {
          layer.setOpacity(0);
          layer.setVisible(false);
        } else {
          layer.setOpacity(indexRatio);
          layer.setVisible(true);
        }
      }
    }
  };

  const onCollapsedClick = (evt) => {
    // HIDE OPEN PANELS
    if (containerCollapsed === false) {
      setImageryPanelOpen(false);
      setTopoPanelOpen(false);
    }

    setContainerCollapsed(!containerCollapsed);
  };

  const showLegendClicked = () => {
    if (activeButton === "legend")
    setActiveButton("imagery");
    
    else 
    setActiveButton("legend");
  };

  const setLayerVisibilty = (value, key) => {
    window.map.getLayers().forEach((layer) => {
      let title = layer.get("title");

      if (title !== null)
        if (title === key) {
          //aka ''
          //      console.log(key);
          //console.log("check visibility: " + title + " "+ key, value);
          layer.setVisible(value); 
          setForceUpdate(value);         
        }
    });
  };

  const onSpatialLayerClicked = (e, key) => {
    // console.log(e);
    if (e)
      if (e.target) {
          // console.log("check visibility ", key);
        setLayerVisibilty(e.target.checked, key);
      }
  };

  //console.log(allLayers);
  let visibleLayers = helpers.getVisibleLayers();
  //console.log('visible layers: ', visibleLayers);

  return (
      <div>
      <div id="sc-map-main-container">
        <div
          id="sc-map-collapse-button"
          className={
            containerCollapsed
              ? "sc-map-collapse-button closed"
              : "sc-map-collapse-button"
          }
          onClick={onCollapsedClick}
        />
        <div className={containerCollapsed ? "sc-hidden" : "sc-map-topo"}>
          <SpatialLayerList
            visibleLayers={visibleLayers}
            allLayers={allLayers}
            onChange={onSpatialLayerClicked}
          />
          {/*<button*/}
          {/*  className={*/}
          {/*    legend === "legend"*/}
          {/*      ? "sc-button sc-map-topo-button active"*/}
          {/*      : "sc-button sc-map-topo-button"*/}
          {/*  }*/}
          {/*  onClick={showLegendClicked}*/}
          {/*>*/}
          {/*  {activeButton === "legend" ? "Hide Legend" : "Show Legend"}*/}
          {/*</button>*/}
        </div>

        {activeButton === "legend" && (
          <div className={"sc-legend"}>
            {visibleLayers &&
              visibleLayers.length > 0 &&
              visibleLayers.map((layer, index) => (
                <div
                  className={"sc-legend-item"}
                  key={layer + index.toString()}
                >
                  <LayerLegend layer={layer} />
                </div>
              ))}
          </div>
        )}
          </div>
    </div>
  );
}

// IMPORT ALL IMAGES
const images = importAllImages(
  require.context("./images", false, /\.(png|jpe?g|svg|gif)$/)
);
function importAllImages(r) {
  let images = {};
  r.keys().map((item, index) => (images[item.replace("./", "")] = r(item)));
  return images;
}
