import React from 'react';
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";
// core components
import User from "pages/User.js";

const hist = createBrowserHistory();


export default function App({ ...props }) {
  return (
    <Router history={hist}>
      <Switch>
        <Route path="/user" render={() => <User />} />
        <Redirect from="/" to={"/user/Map"} />
      </Switch>
    </Router>
  );
}


