import { createReducer } from "redux-act";
import * as a from "../actions/user";

const getDefaultState = _ => ({
    id: crypto.randomUUID(),
    agopname:'',
    fields: [],
    preprojectpumps: [],
    postprojectpumps: [],
    electricity:null,
    fundsRequested: null,
    renewenrgycapcty: null
   });


export default (_) =>
    createReducer(
        {
            [a.updateUser]: (state, { agopname, fields, preprojectpumps, postprojectpumps, electricity, renewenrgycapcty}) => ({
                ...state,
                agopname,
                fields,
                preprojectpumps,
                postprojectpumps,
                electricity,
                renewenrgycapcty
                
            }),
            [a.receiveCalculationData]: (state, { fieldResults, pumpResults }) => {
                var fields = [...state.fields];

                fieldResults.map(fr => {
                    var field = fields.find(field => field.id == fr.fieldId);
                    field.results = fr;
                });

                return {
                    ...state,
                    fields,
                    pumpResults
                }
            },
            [a.receiveFieldMetadata]: (state, fieldList) => {
                var fields = [...state.fields];

                for (var fieldId in fieldList){
                    var field = fields.find(field => field.id == fieldId);
                    field.apns = fieldList[fieldId].apns;
                    field.senateDistricts = fieldList[fieldId].senateDistricts;
                    field.assemblyDistricts = fieldList[fieldId].assemblyDistricts;
                    field.censusTracts = fieldList[fieldId].censusTracts;
                    field.isEconomicallyDisadvantaged = fieldList[fieldId].isEconomicallyDisadvantaged.includes('Y');
                }

                return {
                    ...state,
                    fields
                }
            },
            [a.updateFundsRequested]: (state, fundsRequested) => ({
                ...state,
                fundsRequested
            })
        },
        getDefaultState()
    );
