import React from 'react';
import "./DrawButton.css";

const DrawButton = props => {

 // console.log("DrawButton ", props);
  //console.log("DrawButton ", images);
  return (
    <div className={props.isActive ? "sc-fieldsidebar-button-container active" : "sc-fieldsidebar-button-container"}>
      <button className={props.disabled === undefined || !props.disabled ? "sc-fieldsidebar-button" : "sc-fieldsidebar-button disabled"} title={props.title} onClick={props.onClick} disabled={props.disabled === undefined || !props.disabled ? false : true}>
        <img  src={images[props.imageName].default} alt={props.imageName}></img>
      </button>
    </div>
  )
}

export default DrawButton;

// IMPORT ALL IMAGES
const images = importAllImages(require.context('./images', false, /\.(png|jpe?g|svg)$/));
function importAllImages(r) {
    let images = {};
    r.keys().map((item, index) => images[item.replace('./', '')] = r(item));
    return images;
}