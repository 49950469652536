import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { jsPDF } from "jspdf";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Box, Button, Grid, Typography } from "@material-ui/core";

// core components
import FundsPopup from "views/Popups/Misc/FundsPopup.js";
import GHGOverallReportTable from "components/Report/GHGOverallReportTable.jsx";
import FieldWaterReportTable from "components/Report/FieldWaterReportTable.jsx";
import FieldDetailsReportTable from "components/Report/FieldDetailsReportTable.jsx";
import N2OReportTable from "components/Report/N2OReportTable.jsx";
import GHGPumpingReportTable from "components/Report/GHGPumpingReportTable.jsx";

//actions
import { runCalculations } from "../../actions/report";
import { updateFundsRequested } from "../../actions/user";

//helpers
import { formatResult } from "helpers/report.js";
import { createExcelReport } from "helpers/reportDownload/reportDownload.js";
import { FETCH_COMBINED_LOOKUPS } from "constants/api";
import SaveLoadButtons from "components/Field/SaveLoadButtons";

const styles = {
  typo: {
    paddingLeft: "25%",
    marginBottom: "40px",
    position: "relative",
  },
  card: {
    marginLeft: "35%",
    marginTop: "0",
    width:"65%"
  },
  gridCard: {
    padding: '10px',
    width: 1175,
    fontSize: '0.875rem'
  },
  note: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    bottom: "10px",
    color: "#c0c1c2",
    display: "block",
    fontWeight: "400",
    fontSize: "13px",
    lineHeight: "13px",
    left: "0",
    marginLeft: "20px",
    position: "absolute",
    width: "260px"
  },
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  },
  alertRoot: {
    margin: "3px",
    flexBasis: '100%'
  },
  column: {
    flexBasis: '50%',
  },
  reportCardContainer: {
    justifyContent: 'center'
  },
  tableHeader: {
    fontWeight: 'bold',
    fontSize: '1.2em',
    textAlign: 'center'
    },
    renewableEnergyRow: {
        width: "200px"
    },
  totalsRow: {
    fontWeight: 'bold'
  }
};

const getNewReportData = () => ({
  waterReport: {
    beforeWaterUse: null,
    afterWaterUse: null,
    waterSavings: null,
    percentSavings: null,
    totalArea: null
  },
  pumpingReport: {
    beforeGHG: null,
      afterGHG: null,
    renewableEnergy:null,
    yearlyGHGBenefits: null,
    tenYearGHGBenefits: null
  },
  n2oReport: {
    beforeN2O: null,
    afterN2O: null,
    avgYearlyN2OBenefits: null,
    tenYearN2OBenefits: null
  },
  overallReport: {
    yearlyGHGBenefits: null,
    convertedN2OBenefits: null,
    tenYearGHGBenefits: null,
    ghgBenefitsPerAcreYear: null,
    emissionReductionsPerFundsRequested: null
  }
});

const useStyles = makeStyles(styles);

export default function Report() {
  const classes = useStyles();
  
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);
  const [fundsPopupOpen, setFundsPopupOpen] = useState(!(user.fundsRequested));
  const [reportData, setReportData] = useState(getNewReportData());
  const [lookupData, setLookupData] = useState({});

    useEffect(() => {
       
    dispatch(runCalculations(user));
    
    fetch(FETCH_COMBINED_LOOKUPS)
      .then(response => response.json())
      .then(results => {
        var newLookupData = {};
        for (var lookup of results) {
          newLookupData[lookup.id] = lookup
        }
        setLookupData(newLookupData)
      });
  }, []);

  const fields = useSelector(state => state.user.fields);

  const handleFundsSubmit = (fundsRequested) => {
    setFundsPopupOpen(false);

    dispatch(updateFundsRequested(Number(fundsRequested)));
  }

  const updateReportTotals = () => {
    const totalArea = fields.reduce((sum, field) => sum + field.acreage, 0);
      if (totalArea > 0 && user.pumpResults) {
       const newReportData = getNewReportData();

      //compute before and after water use as a weighted average of water use over field area
      //todo: we might be able to take out all the field.results ? checks now that everything's inside a user.pumpResults check
      newReportData.waterReport.beforeWaterUse = fields.reduce((sum, field) => sum + (field.results ? (field.results.beforeWater * field.acreage) : 0), 0) / totalArea;
      newReportData.waterReport.afterWaterUse = fields.reduce((sum, field) => sum + (field.results ? (field.results.afterWater * field.acreage) : 0), 0) / totalArea;

      newReportData.waterReport.waterSavings = newReportData.waterReport.beforeWaterUse - newReportData.waterReport.afterWaterUse;
      newReportData.waterReport.percentSavings = newReportData.waterReport.beforeWaterUse != 0 ?
        newReportData.waterReport.waterSavings / newReportData.waterReport.beforeWaterUse * 100
        : 0;
      newReportData.waterReport.totalArea = totalArea;

      newReportData.pumpingReport.beforeGHG = user.pumpResults.reduce((sum, pump) => sum + pump.ghgPre, 0);
        newReportData.pumpingReport.afterGHG = user.pumpResults.reduce((sum, pump) => sum + pump.ghgPost, 0);
          newReportData.pumpingReport.renewableEnergy = user.pumpResults.reduce((sum, pump) => sum + pump.ghgSavingsRenewableEnergy, 0);
      newReportData.pumpingReport.yearlyGHGBenefits = newReportData.pumpingReport.beforeGHG - newReportData.pumpingReport.afterGHG;
      newReportData.pumpingReport.tenYearGHGBenefits = newReportData.pumpingReport.yearlyGHGBenefits * 10;
          
      newReportData.n2oReport.beforeN2O = fields.reduce((sum, field) => sum + (field.results ? field.results.beforeN2O : 0), 0);
      newReportData.n2oReport.afterN2O = fields.reduce((sum, field) => sum + (field.results ? field.results.afterN2O : 0), 0);
      newReportData.n2oReport.avgYearlyN2OBenefits = fields.reduce((sum, field) => sum + (field.results ? field.results.avgYearlyN2OBenefits : 0), 0);
      newReportData.n2oReport.tenYearN2OBenefits = fields.reduce((sum, field) => sum + (field.results ? field.results.tenYearN2OBenefits : 0), 0);
        
      newReportData.overallReport.convertedN2OBenefits = newReportData.n2oReport.avgYearlyN2OBenefits;  //convert lb N to tonnes CO2-equiv
      
      //todo: test that this works with no pumps defined
      newReportData.overallReport.yearlyGHGBenefits = newReportData.pumpingReport.yearlyGHGBenefits + newReportData.overallReport.convertedN2OBenefits;
      
      newReportData.overallReport.tenYearGHGBenefits = newReportData.overallReport.yearlyGHGBenefits * 10;
      
      if (!user.fundsRequested || !(user.fundsRequested > 0))  // not using user.fundsRequested <= 0 because it doesn't catch NaN
      {
        newReportData.overallReport.emissionReductionsPerFundsRequested = "N/A";
      }
      else {
        newReportData.overallReport.emissionReductionsPerFundsRequested = formatResult(newReportData.overallReport.tenYearGHGBenefits / user.fundsRequested);
      }
      
      newReportData.overallReport.ghgBenefitsPerAcreYear = totalArea != 0 ? newReportData.overallReport.yearlyGHGBenefits / totalArea : 0;

      setReportData(newReportData);
    }
  }

  useEffect(() => {
    updateReportTotals();
  }, [user])

  const getReport = () => {
    createExcelReport(user, lookupData, reportData);
  }
  
    const getMap = () => {
      
        window.map.once('rendercomplete', function () {
            const mapCanvas = document.createElement('canvas');
            const size = window.map.getSize();
            mapCanvas.width = size[0];
            mapCanvas.height = size[1];
            const mapContext = mapCanvas.getContext('2d');
            Array.prototype.forEach.call(
                window.map.getViewport().querySelectorAll('.ol-layer canvas, canvas.ol-layer'),
                function (canvas) {
                    if (canvas.width > 0) {
                        const opacity =
                            canvas.parentNode.style.opacity || canvas.style.opacity;
                        mapContext.globalAlpha = opacity === '' ? 1 : Number(opacity);
                        let matrix;
                        const transform = canvas.style.transform;
                        if (transform) {
                            // Get the transform parameters from the style's transform matrix
                            matrix = transform
                                .match(/^matrix\(([^\(]*)\)$/)[1]
                                .split(',')
                                .map(Number);
                        } else {
                            matrix = [
                                parseFloat(canvas.style.width) / canvas.width,
                                0,
                                0,
                                parseFloat(canvas.style.height) / canvas.height,
                                0,
                                0,
                            ];
                        }
                        // Apply the transform to the export map context
                        CanvasRenderingContext2D.prototype.setTransform.apply(
                            mapContext,
                            matrix
                        );
                        const backgroundColor = canvas.parentNode.style.backgroundColor;
                        if (backgroundColor) {
                            mapContext.fillStyle = backgroundColor;
                            mapContext.fillRect(0, 0, canvas.width, canvas.height);
                        }
                        mapContext.drawImage(canvas, 0, 0);
                    }
                }
            );
            mapContext.globalAlpha = 1;
            mapContext.setTransform(1, 0, 0, 1, 0, 0);
            
            
            const pdf = new jsPDF('landscape');
            pdf.addImage(
                mapCanvas.toDataURL('image/jpeg'),
                'JPEG',
                50, 10, 180, 150
            );
            pdf.save('map.pdf');
           
        });
        window.map.renderSync();
    
    }
  return (
     
      <Box  sx={{ p: 2, border: '1px ' }}>
        <FundsPopup open={fundsPopupOpen} handleSubmit={handleFundsSubmit} value={user.fundsRequested}/>

        <Typography align="center" variant="h4" style={{ fontWeight: 300 }} sx={{ mb: 4 }}>
            SWEEP Reports
        </Typography>
        
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
          style={{margin: "10px 0"}}
        >
          <Button
            color="primary"
            variant="contained"
            onClick={getReport}
            disabled={reportData.overallReport.yearlyGHGBenefits == null}
            style={{marginRight: 10}}
          >
            Download Report
          </Button>
          <Button color="primary" variant="contained" onClick={getMap} style={{marginRight: 10}}>Download Map</Button>
          <SaveLoadButtons saveText="Download Project File" loadText={null} />
        </Box>

        <Box style={{height: 'calc(100vh - 240px)', overflowY: 'scroll', paddingBottom: '10px'}}>
          <Grid container className={classes.reportCardContainer}>
            <Grid item className={classes.gridCard}>
              <GHGOverallReportTable reportData={reportData} handleFundsClick={() => setFundsPopupOpen(true)} classes={classes}/>
            </Grid>
          </Grid>

          <Grid container className={classes.reportCardContainer}>
            <Grid item className={classes.gridCard}>
              <FieldDetailsReportTable classes={classes}/>
            </Grid>
          </Grid>

          <Grid container className={classes.reportCardContainer}>
            <Grid item className={classes.gridCard}>
              <GHGPumpingReportTable reportData={reportData} classes={classes}/>
            </Grid>
          </Grid>

          <Grid container className={classes.reportCardContainer}>
            <Grid item className={classes.gridCard}>
              <N2OReportTable reportData={reportData} classes={classes}/>
            </Grid>
          </Grid>

          <Grid container className={classes.reportCardContainer}>
            <Grid item className={classes.gridCard}>
              <FieldWaterReportTable reportData={reportData} classes={classes}/>
            </Grid>
          </Grid>
        </Box>
      </Box>
  );
}