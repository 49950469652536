import { combineReducers } from 'redux';

import user from './user';

const getNewReducer = _ =>
  combineReducers(
    Object.entries({
      user
    }).reduce(
      (acc, [key, value]) => ({
        ...acc,
        [key]: value()
      }),
      {}
    )
  )

const reducer = getNewReducer()


export default (state, action) => {

  return reducer(state, action)
}