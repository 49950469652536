export const headerWithAuth = () => 
{

  //return 'fakeTok';
  return {'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem("token")};

};

export const login = ({token, tokenExpirationTime, id}) => {
  
  localStorage.setItem("token", token);
  localStorage.setItem("tokenExpirationTime", tokenExpirationTime);
  localStorage.setItem("id", id);
};

export const headerOnlyAuth = () => 
{
  //return 'fakeTok';
  return {'Authorization': 'Bearer ' + localStorage.getItem("token")};

};