import React, { useEffect, useState } from "react";
import { styled } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from '@material-ui/core/InputLabel';
import NativeSelect from '@material-ui/core/NativeSelect';
import InputBase from '@material-ui/core/InputBase';
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import * as CMBYN from "../../constants/api";
const BootstrapInput = styled(InputBase)(({ theme }) => ({
    'label + &': {
        marginTop: theme.spacing(3),
        width: '90%',
    },
    '& .MuiInputBase-input': {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #ced4da',
        fontSize: 16,
      
        padding: '10px 26px 10px 12px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:focus': {
            borderRadius: 4,
            borderColor: '#80bdff',
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        },
    },
}));


export default function PreIrrigationPopup(props) {
    const { selPreIrrField, parentchildpreirr, parentPreIrrChange } = props;
    const [preirrigtn, setPreirrigtn] = (selPreIrrField.field.fielddetails.preirr == '') ?React.useState(parentchildpreirr) : React.useState(selPreIrrField.field.fielddetails.preirr);
    const [preirrigtnList, setPreirrigtnList] = React.useState([]);
    const [postirrigtn, setPostirrigtn] = React.useState([]);
  
    const handlePreIrrigation = (event) => {
        setPreirrigtn(event.target.value);
        parentPreIrrChange(event.target.value);
    };
    const handlePostIrrigation = (event) => {
        setPostirrigtn (event.target.value);
    };
   
    useEffect(() => {
        fetch(CMBYN.FETCH_PREIRRTYPES)
            .then(response => response.json())
            .then(results => {
                var list = [];
                for (var u = 0; u < results.length; u++) {
                    let usr = results[u];
                    
                    if (usr) {
                        let option = {};
                        option.name = usr.name;
                        option.id = usr.id;
                        list.push(option);
                    }
                }

               
                setPreirrigtnList(list);
                
            })
    }, []);
    

    return (
        <div>
            
          
                <GridContainer direction="column" justifyContent="flex-start" alignItems="flex-start">
                   
                    <GridItem xs={12} sm={12} md={12}>
               
                   
                        <div style={{ marginTop: "10px" }}>
                        <InputLabel variant="standard" htmlFor="uncontrolled-native" color="primary">
                            Select the irrigation method that is currently used on this field.
                        </InputLabel>
                    </div>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                    <div style={{ marginTop: "10px" }}>
                       
                        <NativeSelect
                            id="preIrrigation"
                            value={preirrigtn }
                            onChange={handlePreIrrigation}
                            input={<BootstrapInput />}
                        >
                            <option value="">
                                None
                            </option>
                            {preirrigtnList.map((cr) => (
                                <option value={cr.id}> {cr.name}
                                </option >

                            ))}

                        </NativeSelect>
                    </div>
                  
                   
                  
                        
                    
                        
                    </GridItem>
                  
                    </GridContainer>
            
        </div>
    );
}
