import React from 'react';
import { styled } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from '@material-ui/core/InputLabel';
import NativeSelect from '@material-ui/core/NativeSelect';
import InputBase from '@material-ui/core/InputBase';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Checkbox from '@material-ui/core/Checkbox';
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
    'label + &': {
        marginTop: theme.spacing(3),
        width: '90%',
    },
    '& .MuiInputBase-input': {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #ced4da',
        fontSize: 16,
      
        padding: '10px 26px 10px 12px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:focus': {
            borderRadius: 4,
            borderColor: '#80bdff',
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        },
    },
}));


export default function PostWaterMgmtToolsPopup(props) {


    const { selPostWaterField, parentchildpostsm, parentchildpostflow, parentchildpostet, parentchildpostno, parentPostSMChange, parentPostFLChange, parentPostETChange, parentPostNOChange } = props;
    const [postSoilMoisture, setPostSoilMoisture] = (selPostWaterField.field.fielddetails.postwater.postyearsoilmoisture == false) ?React.useState(parentchildpostsm) : React.useState(selPostWaterField.field.fielddetails.postwater.postyearsoilmoisture); 
    const [postFlow, setPostFlow] = (selPostWaterField.field.fielddetails.postwater.postyearflow == false) ?React.useState(parentchildpostflow) : React.useState(selPostWaterField.field.fielddetails.postwater.postyearflow);
    const [postET, setPostET] = (selPostWaterField.field.fielddetails.postwater.postyearet == false) ?React.useState(parentchildpostet) : React.useState(selPostWaterField.field.fielddetails.postwater.postyearet);
    
    const handlePostSoilMoistureChange = (event) => {

        setPostSoilMoisture(event.target.checked);
        parentPostSMChange(event.target.checked);
    };
   
    const handlePostFlowChange = (event) => {
      
        setPostFlow(event.target.checked);
        parentPostFLChange(event.target.checked);
    };
    const handlePostEtChange = (event) => {
        setPostET(event.target.checked);
        parentPostETChange(event.target.checked);
    };
   
  
    

    

    return (
        <div>
            
          
                <GridContainer direction="column" justifyContent="flex-start" alignItems="flex-start">
                   
                    <GridItem xs={12} sm={12} md={12}>
              
                   
                   
                        
                    <div style={{ marginTop: "10px" }}>
                        <InputLabel variant="standard" htmlFor="uncontrolled-native" color="primary">
                            Select the water management tools that will be used  on this field as part of your proposed project (select all that apply).
                        </InputLabel>
                    </div>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                    <div style={{ marginTop: "10px" }}>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Checkbox checked={postSoilMoisture} onChange={handlePostSoilMoistureChange} name="soilmoisture" />
                                }
                                label="Soil Moisture Equipment"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox checked={postFlow} onChange={handlePostFlowChange} name="flow" />
                                }
                                label="Flow Meter"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox checked={postET} onChange={handlePostEtChange} name="et" />
                                }
                                label="Use of ET Information"
                            />
                        </FormGroup>
                    </div>
                  
                        
                    
                        
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                
                    </GridItem>
                </GridContainer>
           
        </div>
    );
}
