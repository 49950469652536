import { takeLatest } from "redux-saga/effects";

import * as userActions from "../actions/user";
import * as userSagas from "./user";

import * as reportActions from "../actions/report";
import * as reportSagas from "./report";


export default function* saga() {
  const relations = [
    [userActions, userSagas],
    [reportActions, reportSagas]
  ];

  for (const [actions, sagas] of relations) {
    for (const [actionName, action] of Object.entries(actions)) {
      const saga = sagas[actionName];

      if (saga) {
        yield takeLatest(action.getType(), saga);
      }
    }
  }
}
