import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import Button from "@material-ui/core/Button";
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import PreSpeciesPopup from "../Popups/PreSpeciesPopup";
import PostSpeciesPopup from "../Popups/PostSpeciesPopup";
import PreIrrigationPopup from "../Popups/PreIrrigationPopup";
import PostIrrigationPopup from "../Popups/PostIrrigationPopup";
import PreWaterMgmtToolsPopup from "../Popups/PreWaterMgmtToolsPopup";
import PostWaterMgmtToolsPopup from "../Popups/PostWaterMgmtToolsPopup";
import DynamicDialog from "../../components/Dialogs/Dialog.js";
import * as helpers from "../../helpers/helpers.js";
import greencheckmarkicon from "./images/green-check-mark-icon.png";


const styles = {
    card: {
        marginLeft: "10%",
        boxShadow: "none",
        marginTop: "unset",
    },

    preprojpumplabel: {
        width: "350px",
        marginTop: "15px",
    },
    preprojpumpAddIcon: {
        float: "left",
        padding: "unset",
    },
};

const useStyles = makeStyles(styles);
export default function UserDetails(props) {


    const { selField, detailsAdded, parentprespeciescroponeyearonechange, parentprespeciescroptwoyearonechange,
        parentprespeciescropthreeyearonechange, parentprespeciescroponeyeartwochange, parentprespeciescroptwoyeartwochange,
        parentprespeciescropthreeyeartwochange, parentprespeciescroponeyearthreechange, parentprespeciescroptwoyearthreechange,
        parentprespeciescropthreeyearthreechange, parentpostspeciescroponeyearonechange, parentpostspeciescroptwoyearonechange,
        parentpostspeciescropthreeyearonechange, parentpostspeciescroponeyeartwochange, parentpostspeciescroptwoyeartwochange,
        parentpostspeciescropthreeyeartwochange, parentpostspeciescroponeyearthreechange, parentpostspeciescroptwoyearthreechange,
        parentpostspeciescropthreeyearthreechange, parentpreirrchange, parentpostirrchange, parentpresoilmoisturechange, parentpreflowchange,
        parentpreetchange, parentprenochange, parentpostsoilmoisturechange, parentpostflowchange, parentpostetchange, parentpostnochange,
        parentprespeciescropverticalchange,parentprespeciescrop1verticalchange, parentprespeciescrop2verticalchange, parentprespeciescrop3verticalchange,
        parentpostspeciescropverticalchange,parentpostspeciescrop1verticalchange, parentpostspeciescrop2verticalchange, parentpostspeciescrop3verticalchange    } = props;
   
    const currentUser = useSelector((state) => state.user);
    const classes = useStyles();
    const [prespeciesopen, setprespeciesopen] = React.useState(false);
    const [postspeciesopen, setpostspeciesopen] = React.useState(false);
    const [preirrOpen, setPreIrrOpen] = React.useState(false);
    const [postirrOpen, setPostIrrOpen] = React.useState(false);
    const [prewaterOpen, setPreWaterOpen] = React.useState(false);
    const [postwaterOpen, setPostWaterOpen] = React.useState(false);

    
   
    const [preCroppingInputs, setPreCroppingInputs] = React.useState({
        croponeyearone: selField.field.fielddetails.precropping.preyearcroponeyearone,
        croponeyeartwo: selField.field.fielddetails.precropping.preyearcroponeyeartwo,
        croponeyearthree: selField.field.fielddetails.precropping.preyearcroponeyearthree,
        croptwoyearone: selField.field.fielddetails.precropping.preyearcroptwoyearone,
        croptwoyeartwo: selField.field.fielddetails.precropping.preyearcroptwoyeartwo,
        croptwoyearthree: selField.field.fielddetails.precropping.preyearcroptwoyearthree,
        cropthreeyearone: selField.field.fielddetails.precropping.preyearcropthreeyearone,
        cropthreeyeartwo: selField.field.fielddetails.precropping.preyearcropthreeyeartwo,
        cropthreeyearthree: selField.field.fielddetails.precropping.preyearcropthreeyearthree,

    })
   
    const [postCroppingInputs, setPostCroppingInputs] = React.useState({
        postcroponeyearone: selField.field.fielddetails.postcropping.postyearcroponeyearone,
        postcroponeyeartwo: selField.field.fielddetails.postcropping.postyearcroponeyeartwo,
        postcroponeyearthree: selField.field.fielddetails.postcropping.postyearcroponeyearthree,
        postcroptwoyearone: selField.field.fielddetails.postcropping.postyearcroptwoyearone,
        postcroptwoyeartwo: selField.field.fielddetails.postcropping.postyearcroptwoyeartwo,
        postcroptwoyearthree: selField.field.fielddetails.postcropping.postyearcroptwoyearthree,
        postcropthreeyearone: selField.field.fielddetails.postcropping.postyearcropthreeyearone,
        postcropthreeyeartwo: selField.field.fielddetails.postcropping.postyearcropthreeyeartwo,
        postcropthreeyearthree: selField.field.fielddetails.postcropping.postyearcropthreeyearthree,

    })
   
    const [preIrrInputs, setPreIrrInputs] = React.useState({
        preirrmethod: selField.field.fielddetails.preirr
    })

    const [postIrrInputs, setPostIrrInputs] = React.useState({
        postirrmethod: selField.field.fielddetails.postirr
    })

    const [preWaterMgmtInputs, setPreWaterMgmtInputs] = React.useState({
     
        presm: selField.field.fielddetails.prewater.preyearsoilmoisture,
        preflow: selField.field.fielddetails.prewater.preyearflow,
        preet: selField.field.fielddetails.prewater.preyearet,
        preno: selField.field.fielddetails.prewater.preyearno
    })

    const [postWaterMgmtInputs, setPostWaterMgmtInputs] = React.useState({
     
        postsm: selField.field.fielddetails.postwater.postyearsoilmoisture,
        postflow: selField.field.fielddetails.postwater.postyearflow,
        postet: selField.field.fielddetails.postwater.postyearet,
        postno: selField.field.fielddetails.postwater.postyearno
    })
    const [userFieldDetails, setUserFieldDetails] = React.useState({
        croponeyearone: selField.field.fielddetails.precropping.preyearcroponeyearone,
        croponeyeartwo: selField.field.fielddetails.precropping.preyearcroponeyeartwo,
        croponeyearthree: selField.field.fielddetails.precropping.preyearcroponeyearthree,
        croptwoyearone: selField.field.fielddetails.precropping.preyearcroptwoyearone,
        croptwoyeartwo: selField.field.fielddetails.precropping.preyearcroptwoyeartwo,
        croptwoyearthree: selField.field.fielddetails.precropping.preyearcroptwoyearthree,
        cropthreeyearone: selField.field.fielddetails.precropping.preyearcropthreeyearone,
        cropthreeyeartwo: selField.field.fielddetails.precropping.preyearcropthreeyeartwo,
        cropthreeyearthree: selField.field.fielddetails.precropping.preyearcropthreeyearthree,
        postcroponeyearone: selField.field.fielddetails.postcropping.postyearcroponeyearone,
        postcroponeyeartwo: selField.field.fielddetails.postcropping.postyearcroponeyeartwo,
        postcroponeyearthree: selField.field.fielddetails.postcropping.postyearcroponeyearthree,
        postcroptwoyearone: selField.field.fielddetails.postcropping.postyearcroptwoyearone,
        postcroptwoyeartwo: selField.field.fielddetails.postcropping.postyearcroptwoyeartwo,
        postcroptwoyearthree: selField.field.fielddetails.postcropping.postyearcroptwoyearthree,
        postcropthreeyearone: selField.field.fielddetails.postcropping.postyearcropthreeyearone,
        postcropthreeyeartwo: selField.field.fielddetails.postcropping.postyearcropthreeyeartwo,
        postcropthreeyearthree: selField.field.fielddetails.postcropping.postyearcropthreeyearthree,
        preirrmethod: selField.field.fielddetails.preirr,
        postirrmethod: selField.field.fielddetails.postirr,
        presm: selField.field.fielddetails.prewater.preyearsoilmoisture,
        preflow: selField.field.fielddetails.prewater.preyearflow,
        preet: selField.field.fielddetails.prewater.preyearet,
        preno: selField.field.fielddetails.prewater.preyearno,
        postsm: selField.field.fielddetails.postwater.postyearsoilmoisture,
        postflow: selField.field.fielddetails.postwater.postyearflow,
        postet: selField.field.fielddetails.postwater.postyearet,
        postno: selField.field.fielddetails.postwater.postyearno
    })
  
    const dialogTitle = "Edit Field Details for " + selField;
    const handleClickPreSpeciesOpen = () => {
        setprespeciesopen(true);
    };
    const handleClickPostSpeciesOpen = () => {
        setpostspeciesopen(true);
    };
    const handleClickPreIrrOpen = () => {
        setPreIrrOpen(true);
    };
    const handleClickPostIrrOpen = () => {
        setPostIrrOpen(true);
    };
    const handleClickPreWaterOpen = () => {
        setPreWaterOpen(true);
    };
    const handleClickPostWaterOpen = () => {
        setPostWaterOpen(true);
    };
    const savePreCropping = () => {
        document.getElementById("precropcomplete").style.display = "inline-block"; 
        setprespeciesopen(false);
    };

    const savePostCropping = () => {
        document.getElementById("postcropcomplete").style.display = "inline-block"; 
        setpostspeciesopen(false);
    };

    const savePreIrrigation = () => {
        document.getElementById("preirrcomplete").style.display = "inline-block"; 
        setPreIrrOpen(false);
    };

    const savePostIrrigation = () => {
        document.getElementById("postirrcomplete").style.display = "inline-block"; 
        setPostIrrOpen(false);
    };
    
    const savePreWaterMgmtTools = () => {
        document.getElementById("prewatercomplete").style.display = "inline-block"; 
        setPreWaterOpen(false);
    };
    const savePostWaterMgmtTools = () => {
        document.getElementById("postwatercomplete").style.display = "inline-block"; 
        setPostWaterOpen(false);
    };

    const handleCropOneYearOneChanged = (value) => {
      
        setPreCroppingInputs({ ...preCroppingInputs, croponeyearone: value });
        setUserFieldDetails({ ...userFieldDetails, croponeyearone: value });
        parentprespeciescroponeyearonechange(value);
    };
    
    const handleCropOneYearTwoChanged = (value) => {
      
        setPreCroppingInputs({ ...preCroppingInputs, croponeyeartwo: value });
        setUserFieldDetails({ ...userFieldDetails, croponeyeartwo: value });
        parentprespeciescroponeyeartwochange(value);

    };
    const handleCropOneYearThreeChanged = (value) => {
      
        setPreCroppingInputs({ ...preCroppingInputs, croponeyearthree: value });
        setUserFieldDetails({ ...userFieldDetails, croponeyearthree: value });
        parentprespeciescroponeyearthreechange(value);
    };

    const handleCropTwoYearOneChanged = (value) => {

        setPreCroppingInputs({ ...preCroppingInputs, croptwoyearone: value });
        setUserFieldDetails({ ...userFieldDetails, croptwoyearone: value });
        parentprespeciescroptwoyearonechange(value);
    };
    const handleCropTwoYearTwoChanged = (value) => {

        setPreCroppingInputs({ ...preCroppingInputs, croptwoyeartwo: value });
        setUserFieldDetails({ ...userFieldDetails, croptwoyeartwo: value });
        parentprespeciescroptwoyeartwochange(value);
    };
    const handleCropTwoYearThreeChanged = (value) => {

        setPreCroppingInputs({ ...preCroppingInputs, croptwoyearthree: value });
        setUserFieldDetails({ ...userFieldDetails, croptwoyearthree: value });
        parentprespeciescroptwoyearthreechange(value);

    };

    const handleCropThreeYearOneChanged = (value) => {

        setPreCroppingInputs({ ...preCroppingInputs, cropthreeyearone: value });
        setUserFieldDetails({ ...userFieldDetails, cropthreeyearone: value });
        parentprespeciescropthreeyearonechange(value);

    };
    const handleCropThreeYearTwoChanged = (value) => {

        setPreCroppingInputs({ ...preCroppingInputs, cropthreeyeartwo: value });
        setUserFieldDetails({ ...userFieldDetails, cropthreeyeartwo: value });
        parentprespeciescropthreeyeartwochange(value);

    };
    const handleCropThreeYearThreeChanged = (value) => {

        setPreCroppingInputs({ ...preCroppingInputs, cropthreeyearthree: value });
        setUserFieldDetails({ ...userFieldDetails, cropthreeyearthree: value });
        parentprespeciescropthreeyearthreechange(value);

    };

    const handleCropVerticalChanged = (prevalue1, prevalue2, prevalue3, postvalue1, postvalue2, postvalue3) => {


        parentprespeciescropverticalchange(prevalue1, prevalue2, prevalue3, postvalue1, postvalue2, postvalue3);

    };
   
    const handlePostCropOneYearOneChanged = (value) => {

        setPostCroppingInputs({ ...postCroppingInputs, postcroponeyearone: value });
        parentpostspeciescroponeyearonechange(value);
    };
  
    const handlePostCropOneYearTwoChanged = (value) => {

        setPostCroppingInputs({ ...postCroppingInputs, postcroponeyeartwo: value });
        parentpostspeciescroponeyeartwochange(value);

    };
    const handlePostCropOneYearThreeChanged = (value) => {

        setPostCroppingInputs({ ...postCroppingInputs, postcroponeyearthree: value });
        setUserFieldDetails({ ...userFieldDetails, postcroponeyearthree: value });
        parentpostspeciescroponeyearthreechange(value);

    };

    const handlePostCropTwoYearOneChanged = (value) => {

        setPostCroppingInputs({ ...postCroppingInputs, postcroptwoyearone: value });
        setUserFieldDetails({ ...userFieldDetails, postcroptwoyearone: value });
        parentpostspeciescroptwoyearonechange(value);

    };
    const handlePostCropTwoYearTwoChanged = (value) => {

        setPostCroppingInputs({ ...postCroppingInputs, postcroptwoyeartwo: value });
        setUserFieldDetails({ ...userFieldDetails, postcroptwoyeartwo: value });
        parentpostspeciescroptwoyeartwochange(value);

    };
    const handlePostCropTwoYearThreeChanged = (value) => {

        setPostCroppingInputs({ ...postCroppingInputs, postcroptwoyearthree: value });
        setUserFieldDetails({ ...userFieldDetails, postcroptwoyearthree: value });
        parentpostspeciescroptwoyearthreechange(value);

    };

    const handlePostCropThreeYearOneChanged = (value) => {

        setPostCroppingInputs({ ...postCroppingInputs, postcropthreeyearone: value });
        setUserFieldDetails({ ...userFieldDetails, postcropthreeyearone: value });
        parentpostspeciescropthreeyearonechange(value);

    };
    const handlePostCropThreeYearTwoChanged = (value) => {

        setPostCroppingInputs({ ...postCroppingInputs, postcropthreeyeartwo: value });
        setUserFieldDetails({ ...userFieldDetails, postcropthreeyeartwo: value });
        parentpostspeciescropthreeyeartwochange(value);

    };
    const handlePostCropThreeYearThreeChanged = (value) => {

        setPostCroppingInputs({ ...postCroppingInputs, postcropthreeyearthree: value });
        setUserFieldDetails({ ...userFieldDetails, postcropthreeyearthree: value });
        parentpostspeciescropthreeyearthreechange(value);

    };
    const handlePreIrrChanged = (value) => {
        setPreIrrInputs({ ...preIrrInputs, preirrmethod: value });
        setUserFieldDetails({ ...userFieldDetails, preirrmethod: value });
        parentpreirrchange(value);

    };
    const handlePostIrrChanged = (value) => {
        setPostIrrInputs({ ...postIrrInputs, postirrmethod: value });
        setUserFieldDetails({ ...userFieldDetails, postirrmethod: value });
        parentpostirrchange(value);

    };

    const handlePreSoilMoistureChanged = (value) => {
      
        setPreWaterMgmtInputs({ ...preWaterMgmtInputs, presm: value });
        setUserFieldDetails({ ...userFieldDetails, presm: value });
       
        parentpresoilmoisturechange(value);

    };
    const handlePreFlowChanged = (value) => {
        
       
        setPreWaterMgmtInputs({ ...preWaterMgmtInputs, preflow: value });
        setUserFieldDetails({ ...userFieldDetails, preflow: value });
        parentpreflowchange(value);

    };
    const handlePreETChanged = (value) => {
        
       
       
        setPreWaterMgmtInputs({ ...preWaterMgmtInputs, preet: value });
        setUserFieldDetails({ ...userFieldDetails, preet: value });
       
        parentpreetchange(value);

    };
    const handlePreNoChanged = (value) => {
        setPreWaterMgmtInputs({ ...preWaterMgmtInputs, preno: value });
        setUserFieldDetails({ ...userFieldDetails, preno: value });
        parentprenochange(value);

    };
    
    const handlePostSoilMoistureChanged = (value) => {
        setPostWaterMgmtInputs({ ...postWaterMgmtInputs, postsm: value });
        setUserFieldDetails({ ...userFieldDetails, postsm: value });
        parentpostsoilmoisturechange(value);

    };
    const handlePostFlowChanged = (value) => {
        setPostWaterMgmtInputs({ ...postWaterMgmtInputs, postflow: value });
        setUserFieldDetails({ ...userFieldDetails, postflow: value });
        parentpostflowchange(value);

    };
    const handlePostETChanged = (value) => {
        setPostWaterMgmtInputs({ ...postWaterMgmtInputs, postet: value });
        setUserFieldDetails({ ...userFieldDetails, postet: value });
        parentpostetchange(value);

    };
    const handlePostNoChanged = (value) => {
        setPostWaterMgmtInputs({ ...postWaterMgmtInputs, postno: value });
        setUserFieldDetails({ ...userFieldDetails, postno: value });
        parentpostnochange(value);

    };
    const handlePreCrop1VerticalChanged = (valueC1V) => {

        setPreCroppingInputs({
            croponeyearone: valueC1V, croponeyeartwo: valueC1V, croponeyearthree: valueC1V,
            croptwoyearone: preCroppingInputs.croptwoyearone, croptwoyeartwo: preCroppingInputs.croptwoyeartwo, croptwoyearthree: preCroppingInputs.croptwoyearthree,
            cropthreeyearone: preCroppingInputs.cropthreeyearone, cropthreeyeartwo: preCroppingInputs.cropthreeyeartwo, cropthreeyearthree: preCroppingInputs.cropthreeyearthree
        });
       
        parentprespeciescrop1verticalchange(valueC1V, preCroppingInputs,postCroppingInputs, userFieldDetails);

    };
    const handlePreCrop2VerticalChanged = (valueC2V) => {

        setPreCroppingInputs({
            croponeyearone: preCroppingInputs.croponeyearone, croponeyeartwo: preCroppingInputs.croponeyeartwo, croponeyearthree: preCroppingInputs.croponeyearthree,
            croptwoyearone: valueC2V, croptwoyeartwo: valueC2V, croptwoyearthree: valueC2V,
            cropthreeyearone: preCroppingInputs.cropthreeyearone, cropthreeyeartwo: preCroppingInputs.cropthreeyeartwo, cropthreeyearthree: preCroppingInputs.cropthreeyearthree
        });
        parentprespeciescrop2verticalchange(valueC2V, preCroppingInputs, postCroppingInputs, userFieldDetails);

    };
    const handlePreCrop3VerticalChanged = (valueC3V) => {

        setPreCroppingInputs({
            croponeyearone: preCroppingInputs.croponeyearone, croponeyeartwo: preCroppingInputs.croponeyeartwo, croponeyearthree: preCroppingInputs.croponeyearthree,
            croptwoyearone: preCroppingInputs.croptwoyearone, croptwoyeartwo: preCroppingInputs.croptwoyeartwo, croptwoyearthree: preCroppingInputs.croptwoyearthree,
            cropthreeyearone: valueC3V, cropthreeyeartwo: valueC3V, cropthreeyearthree: valueC3V
        });
        parentprespeciescrop3verticalchange(valueC3V,preCroppingInputs, postCroppingInputs,userFieldDetails);

    };

    const handlePostCrop1VerticalChanged = (postvalueC1V) => {

        setPostCroppingInputs({
            postcroponeyearone: postvalueC1V, postcroponeyeartwo: postvalueC1V, postcroponeyearthree: postvalueC1V,
            postcroptwoyearone: postCroppingInputs.postcroptwoyearone, postcroptwoyeartwo: postCroppingInputs.postcroptwoyeartwo, postcroptwoyearthree: postCroppingInputs.postcroptwoyearthree,
            postcropthreeyearone: postCroppingInputs.postcropthreeyearone, postcropthreeyeartwo: postCroppingInputs.postcropthreeyeartwo, postcropthreeyearthree: postCroppingInputs.postcropthreeyearthree
        });
        parentpostspeciescrop1verticalchange(postvalueC1V,preCroppingInputs, postCroppingInputs,userFieldDetails)
        //parentpostspeciescrop1verticalchange(value);

    };
    const handlePostCrop2VerticalChanged = (postvalueC2V) => {

        setPostCroppingInputs({
            postcroponeyearone: postCroppingInputs.postcroponeyearone, postcroponeyeartwo: postCroppingInputs.postcroponeyeartwo, postcroponeyearthree: postCroppingInputs.postcroponeyearthree,
            postcroptwoyearone: postvalueC2V, postcroptwoyeartwo: postvalueC2V, postcroptwoyearthree: postvalueC2V,
            postcropthreeyearone: postCroppingInputs.postcropthreeyearone, postcropthreeyeartwo: postCroppingInputs.postcropthreeyeartwo, postcropthreeyearthree: postCroppingInputs.postcropthreeyearthree
        });
        parentpostspeciescrop2verticalchange(postvalueC2V,preCroppingInputs,postCroppingInputs, userFieldDetails)
        //parentpostspeciescrop2verticalchange(value);

    };
    const handlePostCrop3VerticalChanged = (postvalueC3V) => {

        setPostCroppingInputs({
            postcroponeyearone: postCroppingInputs.postcroponeyearone, postcroponeyeartwo: postCroppingInputs.postcroponeyeartwo, postcroponeyearthree: postCroppingInputs.postcroponeyearthree,
            postcroptwoyearone: postCroppingInputs.postcroptwoyearone, postcroptwoyeartwo: postCroppingInputs.postcroptwoyeartwo, postcroptwoyearthree: postCroppingInputs.postcroptwoyearthree,
            postcropthreeyearone: postvalueC3V, postcropthreeyeartwo: postvalueC3V, postcropthreeyearthree: postvalueC3V
        });
        parentpostspeciescrop3verticalchange(postvalueC3V,preCroppingInputs, postCroppingInputs,userFieldDetails)
       /* parentpostspeciescrop3verticalchange(value);*/

    };
    useEffect(() => {
        if (selField.field.fielddetails != undefined) {
            if (selField.field.fielddetails.precropping != undefined) {
                if ((selField.field.fielddetails.precropping.preyearcroponeyearone != undefined && selField.field.fielddetails.precropping.preyearcroponeyearone != "") ||
                    (selField.field.fielddetails.precropping.preyearcroptwoyearone != undefined && selField.field.fielddetails.precropping.preyearcroptwoyearone != "") ||
                    (selField.field.fielddetails.precropping.preyearcropthreeyearone != undefined && selField.field.fielddetails.precropping.preyearcropthreeyearone != "") ||
                    (selField.field.fielddetails.precropping.preyearcroponeyeartwo != undefined && selField.field.fielddetails.precropping.preyearcroponeyeartwo != "") ||
                    (selField.field.fielddetails.precropping.preyearcroptwoyeartwo != undefined && selField.field.fielddetails.precropping.preyearcroptwoyeartwo != "") ||
                    (selField.field.fielddetails.precropping.preyearcropthreeyeartwo != undefined && selField.field.fielddetails.precropping.preyearcropthreeyeartwo != "") ||
                    (selField.field.fielddetails.precropping.preyearcroponeyearthree != undefined && selField.field.fielddetails.precropping.preyearcroponeyearthree != "") ||
                    (selField.field.fielddetails.precropping.preyearcroptwoyearthree != undefined && selField.field.fielddetails.precropping.preyearcroptwoyearthree != "") ||
                    (selField.field.fielddetails.precropping.preyearcropthreeyearthree != undefined && selField.field.fielddetails.precropping.preyearcropthreeyearthree != "")) {

                    if (!Object.values(selField.field.fielddetails.precropping).every(x => (x === '' || x === undefined))) {
                        document.getElementById("precropcomplete").style.display = "inline-block";
                    }
                }
                else {

                    if (!Object.values(selField.field.fielddetails.precropping).every(x => (x === '' || x === undefined))) {
                        document.getElementById("precropcomplete").style.display = "inline-block";
                    }
                }

            }

            if (selField.field.fielddetails.postcropping != undefined) {
                if ((selField.field.fielddetails.postcropping.postyearcroponeyearone != undefined && selField.field.fielddetails.postcropping.postyearcroponeyearone != "") ||
                    (selField.field.fielddetails.postcropping.postyearcroptwoyearone != undefined && selField.field.fielddetails.postcropping.postyearcroptwoyearone != "") ||
                    (selField.field.fielddetails.postcropping.postyearcropthreeyearone != undefined && selField.field.fielddetails.postcropping.postyearcropthreeyearone != "") ||
                    (selField.field.fielddetails.postcropping.postyearcroponeyeartwo != undefined && selField.field.fielddetails.postcropping.postyearcroponeyeartwo != "") ||
                    (selField.field.fielddetails.postcropping.postyearcroptwoyeartwo != undefined && selField.field.fielddetails.postcropping.postyearcroptwoyeartwo != "") ||
                    (selField.field.fielddetails.postcropping.postyearcropthreeyeartwo != undefined && selField.field.fielddetails.postcropping.postyearcropthreeyeartwo != "") ||
                    (selField.field.fielddetails.postcropping.postyearcroponeyearthree != undefined && selField.field.fielddetails.postcropping.postyearcroponeyearthree != "") ||
                    (selField.field.fielddetails.postcropping.postyearcroptwoyearthree != undefined && selField.field.fielddetails.postcropping.postyearcroptwoyearthree != "") ||
                    (selField.field.fielddetails.postcropping.postyearcropthreeyearthree != undefined && selField.field.fielddetails.postcropping.postyearcropthreeyearthree != "")) {

                    if (!Object.values(selField.field.fielddetails.postcropping).every(x => (x === '' || x === undefined))) {
                        document.getElementById("postcropcomplete").style.display = "inline-block";
                    }
                }
                else {
                    if (!Object.values(selField.field.fielddetails.postcropping).every(x => x === '' || x === undefined)) {
                        document.getElementById("postcropcomplete").style.display = "inline-block";
                    }
                }
            }

            if (selField.field.fielddetails.preirr != undefined) {
                if (!Object.values(selField.field.fielddetails.preirr).every(x => x === '' || x === undefined)) {
                    document.getElementById("preirrcomplete").style.display = "inline-block";
                }
            }

            if (selField.field.fielddetails.postirr != undefined) {
                if (!Object.values(selField.field.fielddetails.postirr).every(x => x === '' || x === undefined)) {
                    document.getElementById("postirrcomplete").style.display = "inline-block";
                }
            }

            if (selField.field.fielddetails.prewater != undefined) {
                if (!Object.values(selField.field.fielddetails.prewater).every(x => x === false || x === undefined)) {
                    document.getElementById("prewatercomplete").style.display = "inline-block";
                }
            }

            if (selField.field.fielddetails.postwater != undefined) {
                if (!Object.values(selField.field.fielddetails.postwater).every(x => x === false || x === undefined)) {
                    document.getElementById("postwatercomplete").style.display = "inline-block";
                }
            }
        }
    }, [detailsAdded]);

    var savePreWaterMgmtToolsButton = helpers.getDialogButton("Save", savePreWaterMgmtTools);
    var savePostWaterMgmtToolsButton = helpers.getDialogButton("Save", savePostWaterMgmtTools);
    var savePostIrrigationButton = helpers.getDialogButton("Save", savePostIrrigation);
    var savePreIrrigationButton = helpers.getDialogButton("Save", savePreIrrigation);
    var savePreCroppingButton = helpers.getDialogButton("Save", savePreCropping);
    var savePostCroppingButton = helpers.getDialogButton("Save", savePostCropping);
    var precroppingButtons = [savePreCroppingButton];
    var postcroppingButtons = [savePostCroppingButton];
    var preirrigationButtons = [savePreIrrigationButton];

    var postirrigationButtons = [savePostIrrigationButton];
    var prewatermgmttoolsButtons = [savePreWaterMgmtToolsButton];
    var postwatermgmttoolsButtons = [savePostWaterMgmtToolsButton];
   
    const getPreCroppingSysForm = () => {
        return (
           
            <PreSpeciesPopup
                selField={selField}
                parentchildprecroponeyearone={preCroppingInputs.croponeyearone}
                parentchildprecroptwoyearone={preCroppingInputs.croptwoyearone}
                parentchildprecropthreeyearone={preCroppingInputs.cropthreeyearone}
                parentchildprecroponeyeartwo={preCroppingInputs.croponeyeartwo}
                parentchildprecroptwoyeartwo={preCroppingInputs.croptwoyeartwo}
                parentchildprecropthreeyeartwo={preCroppingInputs.cropthreeyeartwo}
                parentchildprecroponeyearthree={preCroppingInputs.croponeyearthree}
                parentchildprecroptwoyearthree={preCroppingInputs.croptwoyearthree}
                parentchildprecropthreeyearthree={preCroppingInputs.cropthreeyearthree}
              
                parentcropOneYearOneChange={handleCropOneYearOneChanged}
                parentcropOneYearTwoChange={handleCropOneYearTwoChanged}
                parentcropOneYearThreeChange={handleCropOneYearThreeChanged}
                parentcropTwoYearOneChange={handleCropTwoYearOneChanged}
                parentcropTwoYearTwoChange={handleCropTwoYearTwoChanged}
                parentcropTwoYearThreeChange={handleCropTwoYearThreeChanged}
                parentcropThreeYearOneChange={handleCropThreeYearOneChanged}
                parentcropThreeYearTwoChange={handleCropThreeYearTwoChanged}
                parentcropThreeYearThreeChange={handleCropThreeYearThreeChanged}

                parentchildpostcroponeyearone={postCroppingInputs.postcroponeyearone}
                parentchildpostcroptwoyearone={postCroppingInputs.postcroptwoyearone}
                parentchildpostcropthreeyearone={postCroppingInputs.postcropthreeyearone}
                parentCopyPreCrop1VerticalChange={handlePreCrop1VerticalChanged}
                parentCopyPreCrop2VerticalChange={handlePreCrop2VerticalChanged}
                parentCopyPreCrop3VerticalChange={handlePreCrop3VerticalChanged}

            />
        );
    };
    const getPostCroppingSysForm = () => {
        return (
            <PostSpeciesPopup
                selFieldPost={selField}
                parentchildpostcroponeyearone={postCroppingInputs.postcroponeyearone}
                parentchildpostcroptwoyearone={postCroppingInputs.postcroptwoyearone}
                parentchildpostcropthreeyearone={postCroppingInputs.postcropthreeyearone}
                parentchildpostcroponeyeartwo={postCroppingInputs.postcroponeyeartwo}
                parentchildpostcroptwoyeartwo={postCroppingInputs.postcroptwoyeartwo}
                parentchildpostcropthreeyeartwo={postCroppingInputs.postcropthreeyeartwo}
                parentchildpostcroponeyearthree={postCroppingInputs.postcroponeyearthree}
                parentchildpostcroptwoyearthree={postCroppingInputs.postcroptwoyearthree}
                parentchildpostcropthreeyearthree={postCroppingInputs.postcropthreeyearthree}

                parentpostcropOneYearOneChange={handlePostCropOneYearOneChanged}
                parentpostcropOneYearTwoChange={handlePostCropOneYearTwoChanged}
                parentpostcropOneYearThreeChange={handlePostCropOneYearThreeChanged}
                parentpostcropTwoYearOneChange={handlePostCropTwoYearOneChanged}
                parentpostcropTwoYearTwoChange={handlePostCropTwoYearTwoChanged}
                parentpostcropTwoYearThreeChange={handlePostCropTwoYearThreeChanged}
                parentpostcropThreeYearOneChange={handlePostCropThreeYearOneChanged}
                parentpostcropThreeYearTwoChange={handlePostCropThreeYearTwoChanged}
                parentpostcropThreeYearThreeChange={handlePostCropThreeYearThreeChanged}

                parentchildprecroponeyearone={preCroppingInputs.croponeyearone}
                parentchildprecroptwoyearone={preCroppingInputs.croptwoyearone}
                parentchildprecropthreeyearone={preCroppingInputs.cropthreeyearone}
                parentCopyPostCrop1VerticalChange={handlePostCrop1VerticalChanged}
                parentCopyPostCrop2VerticalChange={handlePostCrop2VerticalChanged}
                parentCopyPostCrop3VerticalChange={handlePostCrop3VerticalChanged}


            />
        );
    };

    const getPreIrrigationSysForm = () => {
        return (
            <PreIrrigationPopup
                selPreIrrField={selField}
                parentchildpreirr={preIrrInputs.preirrmethod}
                parentPreIrrChange={handlePreIrrChanged}


            />
        );
    };

    const getPostIrrigationSysForm = () => {
        return (
            <PostIrrigationPopup
                selPostIrrField={selField}
                parentchildpostirr={postIrrInputs.postirrmethod}
                parentPostIrrChange={handlePostIrrChanged}


            />
        );
    };

    const getPreWaterMgmtToolsForm = () => {
        return (
            <PreWaterMgmtToolsPopup
                selPreWaterField={selField}
                parentchildprefirstload={preWaterMgmtInputs.prefirstload}
                parentchildpresm={preWaterMgmtInputs.presm}
                parentchildpreflow={preWaterMgmtInputs.preflow}
                parentchildpreet={preWaterMgmtInputs.preet}
                parentchildpreno={preWaterMgmtInputs.preno}
                parentPreSMChange={handlePreSoilMoistureChanged}
                parentPreFLChange={handlePreFlowChanged}
                parentPreETChange={handlePreETChanged}
                parentPreNOChange={handlePreNoChanged}


            />
        );
    };
    const getPostWaterMgmtToolsForm = () => {
        return (
            <PostWaterMgmtToolsPopup
                selPostWaterField={selField}
                parentchildpostsm={postWaterMgmtInputs.postsm}
                parentchildpostflow={postWaterMgmtInputs.postflow}
                parentchildpostet={postWaterMgmtInputs.postet}
                parentchildpostno={postWaterMgmtInputs.postno}
                parentPostSMChange={handlePostSoilMoistureChanged}
                parentPostFLChange={handlePostFlowChanged}
                parentPostETChange={handlePostETChanged}
                parentPostNOChange={handlePostNoChanged}


            />
        );
    };



    return (
        <div style={{ marginTop: 10, paddingRight: 50 }}>
            <GridContainer direction="column" justifyContent="flex-start" alignItems="flex-start">
                <GridItem xs={12} sm={12} md={12}>
                    <DynamicDialog
                        buttons={precroppingButtons}
                        open={prespeciesopen}
                        labelId={"showPreSpeciesOpenDialog"}
                        title={"Pre-Project Crop Rotation"}
                        textMessage={""}
                        form={getPreCroppingSysForm()}
                    />

                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                    <DynamicDialog
                        buttons={postcroppingButtons}
                        open={postspeciesopen}
                        labelId={"showPostSpeciesOpenDialog"}
                        title={"Post-Project Crop Rotation"}
                        textMessage={""}
                        form={getPostCroppingSysForm()}
                    />

                </GridItem>

                <GridItem xs={12} sm={12} md={12}>
                    <DynamicDialog
                        buttons={preirrigationButtons}
                        open={preirrOpen}
                        labelId={"showPreIrrSystemDialog"}
                        title={"Pre-Project Irrigation System"}
                        textMessage={""}
                        form={getPreIrrigationSysForm()}
                    />

                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                    <DynamicDialog
                        buttons={postirrigationButtons}
                        open={postirrOpen}
                        labelId={"showPostIrrSystemDialog"}
                        title={"Post-Project Irrigation System"}
                        textMessage={""}
                        form={getPostIrrigationSysForm()}
                    />

                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                    <DynamicDialog
                        buttons={prewatermgmttoolsButtons}
                        open={prewaterOpen}
                        labelId={"showPreWaterMgmtToolsDialog"}
                        title={"Pre-Project Water Management Tools"}
                        textMessage={""}
                        form={getPreWaterMgmtToolsForm()}
                    />

                </GridItem>

                <GridItem xs={12} sm={12} md={12}>
                    <DynamicDialog
                        buttons={postwatermgmttoolsButtons}
                        open={postwaterOpen}
                        labelId={"showPostWaterMgmtToolsDialog"}
                        title={"Post-Project Water Management Tools"}
                        textMessage={""}
                        form={getPostWaterMgmtToolsForm()}
                    />

                </GridItem>

                <GridItem xs={12} sm={12} md={12}>
                    <div style={{ marginTop: "10px" }}>
                        <InputLabel
                            variant="standard"
                            htmlFor="uncontrolled-native"
                            color="primary"
                        >
                            a. Pre-Project Cropping System
                        </InputLabel>
                    </div>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                    <div style={{ marginTop: "10px", marginRight: "20px", display:"inline-block" }}>
                      
                        <Button
                            variant="contained"
                            onClick={handleClickPreSpeciesOpen}

                            color="secondary"
                        >
                            Select
                            </Button>
                       
                    </div>
                    <div id="precropcomplete" style={{ display:"none",verticalAlign:"middle"}}> <img src={greencheckmarkicon}></img></div>
                </GridItem>

                <GridItem xs={12} sm={12} md={12}>
                    <div style={{ marginTop: "10px" }}>
                        <InputLabel
                            variant="standard"
                            htmlFor="uncontrolled-native"
                            color="primary"
                        >
                            b. Post-Project Cropping System
                        </InputLabel>
                    </div>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                    <div style={{ marginTop: "10px", marginRight: "20px", display: "inline-block" }}>
                      
                        <Button
                            variant="contained"
                            onClick={handleClickPostSpeciesOpen}
                            color="secondary"
                        >
                            Select
                            </Button>
                      
                    </div>
                    <div id="postcropcomplete" style={{ display: "none", verticalAlign: "middle" }}> <img src={greencheckmarkicon}></img></div>
                </GridItem>

                <GridItem xs={12} sm={12} md={12}>
                    <div style={{ marginTop: "10px" }}>
                        <InputLabel
                            variant="standard"
                            htmlFor="uncontrolled-native"
                            color="primary"
                        >
                            c. Pre-Project Irrigation System
                        </InputLabel>
                    </div>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                    <div style={{ marginTop: "10px", marginRight: "20px", display: "inline-block" }}>
                        <Button
                            variant="contained"
                            onClick={handleClickPreIrrOpen}
                            color="secondary"
                        >
                            Select
                        </Button>
                      
                    </div>
                    <div id="preirrcomplete" style={{ display: "none", verticalAlign: "middle" }}> <img src={greencheckmarkicon}></img></div>
                </GridItem>

                <GridItem xs={12} sm={12} md={12}>
                    <div style={{ marginTop: "10px" }}>
                        <InputLabel
                            variant="standard"
                            htmlFor="uncontrolled-native"
                            color="primary"
                        >
                            d. Post-Project Irrigation System
                        </InputLabel>
                    </div>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                    <div style={{ marginTop: "10px", marginRight: "20px", display: "inline-block" }}>
                        <Button
                            variant="contained"
                            onClick={handleClickPostIrrOpen}
                            color="secondary"
                        >
                            Select
                        </Button>
                      
                    </div>
                    <div id="postirrcomplete" style={{ display: "none", verticalAlign: "middle" }}> <img src={greencheckmarkicon}></img></div>
                </GridItem>

                <GridItem xs={12} sm={12} md={12}>
                    <div style={{ marginTop: "10px" }}>
                        <InputLabel
                            variant="standard"
                            htmlFor="uncontrolled-native"
                            color="primary"
                        >
                            e. Pre-Project Water Management Tools
                        </InputLabel>
                    </div>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                    <div style={{ marginTop: "10px", marginRight: "20px", display: "inline-block" }}>
                        <Button
                            variant="contained"
                            onClick={handleClickPreWaterOpen}
                            color="secondary"
                        >
                            Select
                        </Button>
                      
                    </div>
                    <div id="prewatercomplete" style={{ display: "none", verticalAlign: "middle" }}> <img src={greencheckmarkicon}></img></div>
                </GridItem>

                <GridItem xs={12} sm={12} md={12}>
                    <div style={{ marginTop: "10px" }}>
                        <InputLabel
                            variant="standard"
                            htmlFor="uncontrolled-native"
                            color="primary"
                        >
                            f. Post-Project Water Management Tools
                        </InputLabel>
                    </div>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                    <div style={{ marginTop: "10px", marginRight: "20px", display: "inline-block" }}>
                        <Button
                            variant="contained"
                            onClick={handleClickPostWaterOpen}
                            color="secondary"
                        >
                            Select
                        </Button>
                    
                    </div>
                    <div id="postwatercomplete" style={{ display: "none", verticalAlign: "middle" }}> <img src={greencheckmarkicon}></img></div>
                </GridItem>



    </GridContainer>

        </div>
    );
}
