import writeXlsxFile from "write-excel-file";

import { buildSummaryReportData } from "./summaryReport";
import { buildDetailedReportData } from "./detailedReport";
import { buildDataInputsData } from "./dataInputs";

export async function createExcelReport(user, lookupData, reportData) {

    var summaryReportColumns = [
        { width: 78 }, { width: 20 }
    ];

    var detailedReportColumns = [
        { width: 78 }, { width: 20 }
    ];

    var dataInputsColumns = [
        { width: 55 }, { width: 38 }
    ];

    const sheetData = [buildSummaryReportData(user, reportData), buildDetailedReportData(user, reportData), buildDataInputsData(user, lookupData)];

    await writeXlsxFile(sheetData, {
        sheets: ['Summary Report', 'Detailed Report', 'Data Inputs'],
        columns: [summaryReportColumns, detailedReportColumns, dataInputsColumns],
        fileName: 'SWEEP report.xlsx',
        dateFormat: 'mm/dd/yyyy'
    })
}