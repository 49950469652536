import { formatDollars, formatResult } from "../report";

export const emptyCellValue = undefined;

export function buildHeader(data, user) {
    data.push([
        {
            value: 'Operation Name',
            fontWeight: 'bold'
        },
        {
            value: user.agopname
        }
    ]);

    data.push([
        {
            value: 'Application Date',
            fontWeight: 'bold'
        },
        {
            type: Date,
            value: new Date(),
            align: "left"
        }
    ]);

    data.push([
        {
            value: 'Total Funds Requested',
            fontWeight: 'bold'
        },
        {
            value: formatDollars(user.fundsRequested)
        }
    ]);

    data.push([
        {
            value: 'Project Id',
            fontWeight: 'bold'
        },
        {
            value: user.id
        }
    ])
}

export function buildOverallSection(data, reportData) {
    data.push([
        {
            value: 'Total Greenhouse Gas Emission Reductions for Proposed Project',
            fontWeight: 'bold'
        }
    ]);

    data.push([
        {
            value: 'Total GHG Benefits per Growing Season (tonnes CO2-eq/yr)'
        },
        {
            value: formatResult(reportData.overallReport.yearlyGHGBenefits)
        }
    ]);

    data.push([
        {
            value: 'N2O GHG Benefits per Growing Season (tonnes CO2-eq/yr)'
        },
        {
            value: formatResult(reportData.overallReport.convertedN2OBenefits)
        }
    ]);

    data.push([
        {
            value: 'Pumping GHG Benefits per Growing Season (tonnes CO2-eq/yr)'
        },
        {
            value: formatResult(reportData.pumpingReport.yearlyGHGBenefits)
        }
    ]);

    data.push([
        {
            value: 'Net GHG Benefits over Useful Life (tonnes CO2-eq)'
        },
        {
            value: formatResult(reportData.overallReport.tenYearGHGBenefits)
        }
    ]);

    data.push([
        {
            value: 'GHG Benefits per Acre-Year (tonnes CO2-eq/ac/yr)'
        },
        {
            value: formatResult(reportData.overallReport.ghgBenefitsPerAcreYear)
        }
    ]);

    data.push([
        {
            value: 'Total GHG Emission Reductions per Total Funds Requested '
        },
        {
            value: formatResult(reportData.overallReport.emissionReductionsPerFundsRequested)
        }
    ]);
}

export function buildFieldSummary(data, field) {
    data.push([
        {
            value: 'Field Name'
        },
        {
            value: field?.name
        }
    ]);

    data.push([
        {
            value: 'Field Location (Lat/Lon)',
            alignVertical: "center"
        },
        {
            value: field?.latLon.join(', '),
            wrap: true
        }
    ]);

    data.push([
        {
            value: 'Field APN(s)',
            alignVertical: "center"
        },
        {
            value: field?.apns.join(', '),
            wrap: true
        }
    ]);

    data.push([
        {
            value: 'Field Area (acres)'
        },
        {
            value: field?.acreage,
            align: 'left'
        }
    ]);

    data.push([
        {
            value: 'Soil Type'
        },
        {
            value: field?.results.soilType.name
        }
    ]);

    data.push([
        {
            value: 'Assembly District(s)',
            alignVertical: "center"
        },
        {
            value: field?.assemblyDistricts.join(', '),
            wrap: true
        }
    ]);

    data.push([
        {
            value: 'Senate District(s)',
            alignVertical: "center"
        },
        {
            value: field?.senateDistricts.join(', '),
            wrap: true
        }
    ]);

    data.push([
        {
            value: 'ET Zone'
        },
        {
            value: field?.results.etZone.name.substring(8)
        }
    ]);

    data.push([
        {
            value: 'Census Tract(s)',
            alignVertical: "center"
        },
        {
            value: field?.censusTracts.join(', '),
            wrap: true
        }
    ]);

    data.push([
        {
            value: 'In Economically Disadvantaged Area'
        },
        {
            value: field ? getYesNo(field.isEconomicallyDisadvantaged) : emptyCellValue
        }
    ]);
}

export function getYesNo(bool) {
    return bool ? "Yes" : "No"
}