import React from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button } from "@material-ui/core";

import { updateUser } from "actions/user";

export default function SaveLoadButtons({saveText = "Download Project", loadText = "Load Project", ...props}) {
  const user = useSelector(state => state.user);
  const dispatch = useDispatch();

   var fileInput;

  const download = () => {
    var now = new Date();
    var filename = now.toLocaleDateString('en-CA') + '_' + now.toLocaleTimeString('en-GB') + '.txt'; //Canada format for the date, UK format for the time

    var element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(JSON.stringify(user)));
    element.setAttribute('download', filename);
    
    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  }

  const upload = () => {
    fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.style.display = 'none';
    fileInput.onchange = readFile;
    fileInput.func = loadUser;
    document.body.appendChild(fileInput);
    fileInput.click();
  }

  const readFile = (event) => {
    var file = event.target.files[0];

    if (!file) {
      // should we remove the fileInput element here?
      return;
    }

    var reader = new FileReader();

    reader.onload = function(e) {
      var contents = e.target.result;
      fileInput.func(contents);
      document.body.removeChild(fileInput);
    }

    reader.readAsText(file);
  }

  const loadUser = (contents) => {
    var user = JSON.parse(contents);

    if (user) {
      dispatch(updateUser(user));
    }
  }

  return (
    <Box style={{display: 'flex'}} flexDirection="row" justifyContent="space-around" alignItems="center">
          {saveText && <Button variant="contained" color="primary" title="Download project to resume at a later time" onClick={download}>{saveText}</Button> }
          {loadText && <Button variant="contained" color="primary" title="Upload project file to resume data entry" onClick={upload}>{loadText}</Button> }
    </Box>
  );
}