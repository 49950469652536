import { buildFieldSummary, buildHeader, emptyCellValue, getYesNo } from "./shared";

var lookupData = {};

export function buildDataInputsData(user, lookupDataParam) {
    lookupData = lookupDataParam;
    
    const data = [];

    buildHeader(data, user);
    data.push([]);

    data.push([
        {
            value: 'Water Savings and N2O Emissions Estimates Data Inputs',
            fontWeight: 'bold'
        }
    ]);

    for (var i = 0; i < 5; i++) {
        data.push([
            {
                value: 'Field ' + (i+1),
                fontWeight: 'bold'
            }
        ]);

        buildDataInputsForField(data, user.fields[i]);

        data.push([]);
    }

    data.push([]);
    data.push([]);

    data.push([
        {
            value: 'GHG Emissions from Pumping System Data Inputs',
            fontWeight: 'bold'
        }
    ]);

    for (var i = 0; i < 5; i++) {
        data.push([
            {
                value: 'Existing Pump ' + (i+1),
                fontWeight: 'bold'
            }
        ]);

        buildDataInputsForPrePump(data, user.preprojectpumps[i]);

        data.push([]);
    }

    var preprojectPumpIds = user.preprojectpumps.map(p => p.preprojpumpId);
    var postPumps = user.postprojectpumps.filter(p => !preprojectPumpIds.includes(p.postprojpumpId));

    for (var i = 0; i < 5; i++) {
        data.push([
            {
                value: 'Proposed Pump ' + (i+1),
                fontWeight: 'bold'
            }
        ]);

        buildDataInputsForPostPump(data, postPumps[i]);

        data.push([]);
    }

    data.push([]);

    data.push([
        {
            value: 'Electricity Use',
            fontWeight: 'bold'
        }
    ]);

    data.push([
        {
            value: 'Total Annual Electricity Usage (kWh)'
        },
        {
            value: user.electricity?.annualkwh
        }
    ]);

    data.push([
        {
            value: 'Non-irrigation Hardware Draws Electricity from Meters'
        },
        {
            value: user.electricity ? getYesNo(user.electricity?.irrgtnhardware == 0) : emptyCellValue
        }
    ])

    return data;
}

function buildDataInputsForField(data, field) {
    buildFieldSummary(data, field);

    data.push([
        {
            value: 'Pre-Project Cropping System',
            fontWeight: 'bold'
        }
    ]);

    buildDataInputsForCropping(data, field?.fielddetails.precropping, 'pre');

    data.push([
        {
            value: 'Pre-Project Cropping System',
            fontWeight: 'bold'
        }
    ]);

    buildDataInputsForCropping(data, field?.fielddetails.postcropping, 'post');

    data.push([
        {
            value: 'Pre-Project Irrigation System',
            alignVertical: "center"
        },
        {
            value: getLookup(field?.fielddetails.preirr),
            wrap: true
        }
    ]);

    data.push([
        {
            value: 'Post-Project Irrigation System',
            alignVertical: "center"
        },
        {
            value: getLookup(field?.fielddetails.postirr),
            wrap: true
        }
    ]);

    data.push([
        {
            value: 'Pre-Project Water Management Tools',
            alignVertical: "center"
        },
        {
            value: getWaterManagementTools(
                field?.fielddetails.prewater.preyearsoilmoisture,
                field?.fielddetails.prewater.preyearflow,
                field?.fielddetails.prewater.preyearet
            ),
            wrap: true
        }
    ]);

    data.push([
        {
            value: 'Post-Project Water Management Tools',
            alignVertical: "center"
        },
        {
            value: getWaterManagementTools(
                field?.fielddetails.postwater.postyearsoilmoisture,
                field?.fielddetails.postwater.postyearflow,
                field?.fielddetails.postwater.postyearet
            ),
            wrap: true
        }
    ]);
}

function buildDataInputsForCropping(data, cropping, period) {

    for (var year = 1; year <= 3; year++) {
        data.push([
            {
                value: 'Year ' + year
            }
        ]);

        var yearText = getNumberText(year);

        for (var crop = 1; crop <= 3; crop++) {
            var cropText = getNumberText(crop);

            // So many silly things we have to do because the values have pre/post
            // and the year/crop hard-coded into the attribute name. Kids, don't be
            // like Sweep when you grow up.
            var cropPropName = period + 'year' + 'crop' + cropText + 'year' + yearText;

            data.push([
                {
                    value: 'Crop ' + crop
                },
                {
                    value: cropping ? getLookup(cropping[cropPropName]) : emptyCellValue
                }
            ]);
        }
    }
}

function getWaterManagementTools(soilMoisture, flowMeter, et) {
    var tools = [];

    if (soilMoisture) {
        tools.push(getLookup("240"));
    }
    if (flowMeter) {
        tools.push(getLookup("241"));
    }
    if (et) {
        tools.push(getLookup("242"))
    }

    return tools.join(", ");
}

function buildDataInputsForPrePump(data, pump) {
    console.log("whats here", pump);
    data.push([
        {
            value: 'Pump Name'
        },
        {
            value: pump?.preprojpumpName
        }
    ]);

    data.push([
        {
            value: 'Fields Serviced'
        },
        {
            value: pump?.preprojfields.join(", ")
        }
    ]);

    data.push([
        {
            value: 'Motor Rated Horsepower'
        },
        {
            value: pump?.preprojhp
        }
    ]);

    data.push([
        {
            value: 'Operational Hours'
        },
        {
            value: pump?.preprojhours
        }
    ]);

    data.push([
        {
            value: 'Overall Pumping Efficiency'
        },
        {
            value: pump?.preprojeff
        }
    ]);

    var pressureKnown = pump?.preprojsyspress == "1";
    data.push([
        {
            value: 'System Pressure Known?'
        },
        {
            value: pump ? getYesNo(pressureKnown) : emptyCellValue
        }
    ]);

    data.push([
        {
            value: 'System Pressure (ft)'
        },
        {
            value: pressureKnown ? pump?.preprojpumpress : emptyCellValue
        }
    ]);

    data.push([
        {
            value: 'Pumping Depth (ft)'
        },
        {
            value: !pressureKnown ? pump?.preprojpumpdepth : emptyCellValue
        }
    ]);

    data.push([
        {
            value: 'Discharge Pressure (ft)'
        },
        {
            value: !pressureKnown ? pump?.preprojdispress : emptyCellValue
        }
    ]);

    data.push([
        {
            value: 'Friction Losses (ft)'
        },
        {
            value: !pressureKnown ? pump?.preprojfricloss : emptyCellValue
        }
    ]);

    data.push([
        {
            value: 'Fuel Type'
        },
        {
            value: getLookup(pump?.preprojfueltype)
        }
    ]);

    data.push([
        {
            value: 'Fuel Use'
        },
        {
            value: (pump?.preprojfueltype != "151") ? pump?.preprojfueluse : ""
        }
    ]);

    data.push([
        {
            value: 'Other hardware?'
        },
        {
            value: ''   //todo: what are we putting here?
        }
    ]);

    var improved = pump?.preprojimprovques == "0";
    data.push([
        {
            value: 'Proposed for Improvement?'
        },
        {
            value: pump ? getYesNo(improved) : emptyCellValue
        }
    ]);

    var stayingInUse = pump?.preprojstayinuseques == "0";
    data.push([
        {
            value: 'Pump Staying in Use?'
        },
        {
            value: pump && !improved ? getYesNo(stayingInUse) : emptyCellValue
        }
    ]);    

    data.push([
        {
            value: 'New Motor Rated Horsepower'
        },
        {
            value: improved ? pump?.postprojhp : emptyCellValue
        }
    ]);

    data.push([
        {
            value: 'Expected Operational Hours'
        },
        {
            value: improved ? pump?.postprojhours : emptyCellValue
        }
    ]);

    data.push([
        {
            value: 'Expected Overall Pumping Efficiency'
        },
        {
            value: improved ? pump?.postprojeff : emptyCellValue
        }
    ]);

    var newPressureKnown = pump?.postprojsyspress == "1";
    data.push([
        {
            value: 'New System Pressure Known?'
        },
        {
            value: pump && improved ? getYesNo(newPressureKnown) : emptyCellValue
        }
    ]);
    
    data.push([
        {
            value: 'New System Pressure (ft)'
        },
        {
            value: improved && newPressureKnown ? pump?.postprojpumpress : emptyCellValue
        }
    ]);
    
    data.push([
        {
            value: 'New Pumping Depth (ft)'
        },
        {
            value: improved && !newPressureKnown ? pump?.postprojpumpdepth : emptyCellValue
        }
    ]);
    
    data.push([
        {
            value: 'New Discharge Pressure (ft)'
        },
        {
            value: improved && !newPressureKnown ? pump?.postprojdispress : emptyCellValue
        }
    ]);
    
    data.push([
        {
            value: 'New Friction Losses (ft)'
        },
        {
            value: improved && !newPressureKnown ? pump?.postprojfricloss : emptyCellValue
        }
    ]);
    
    data.push([
        {
            value: 'VFD Installation'
        },
        {
            value: improved ? getLookup(pump?.postprojvfd) : emptyCellValue
        }
    ]);

    var renewableEnergy = pump?.postprojrenewenrgysel == "0";
    data.push([
        {
            value: 'Renewable Energy?'
        },
        {
            value: pump && improved ? getYesNo(renewableEnergy) : emptyCellValue
        }
    ]);
    
    data.push([
        {
            value: 'Renewable Energy Capacity'
        },
        {
            value: improved && renewableEnergy ? pump?.postprojrenewenrgycapcty : emptyCellValue
        }
    ]);
    
    data.push([
        {
            value: 'New Fuel Type'
        },
        {
            value: improved ? getLookup(pump?.postprojfueltype) : emptyCellValue
        }
    ]);
}

function buildDataInputsForPostPump(data, pump) {
    data.push([
        {
            value: 'Pump Name'
        },
        {
            value: pump?.postprojpumpName
        }
    ]);

    data.push([
        {
            value: 'Fields Serviced'
        },
        {
            value: pump?.postprojfields.join(", ")
        }
    ]);  

    data.push([
        {
            value: 'New Motor Rated Horsepower'
        },
        {
            value: pump?.postprojhp
        }
    ]);

    data.push([
        {
            value: 'Expected Operational Hours'
        },
        {
            value: pump?.postprojhours
        }
    ]);

    data.push([
        {
            value: 'Expected Overall Pumping Efficiency'
        },
        {
            value: pump?.postprojeff
        }
    ]);

    var newPressureKnown = pump?.postprojsyspress == "1";
    data.push([
        {
            value: 'New System Pressure Known?'
        },
        {
            value: pump ? getYesNo(newPressureKnown) : emptyCellValue
        }
    ]);
    
    data.push([
        {
            value: 'New System Pressure (ft)'
        },
        {
            value: newPressureKnown ? pump?.postprojpumpress : emptyCellValue
        }
    ]);
    
    data.push([
        {
            value: 'New Pumping Depth (ft)'
        },
        {
            value: !newPressureKnown ? pump?.postprojpumpdepth : emptyCellValue
        }
    ]);
    
    data.push([
        {
            value: 'New Discharge Pressure (ft)'
        },
        {
            value: !newPressureKnown ? pump?.postprojdispress : emptyCellValue
        }
    ]);
    
    data.push([
        {
            value: 'New Friction Losses (ft)'
        },
        {
            value: !newPressureKnown ? pump?.postprojfricloss : emptyCellValue
        }
    ]);
    
    data.push([
        {
            value: 'VFD Installation'
        },
        {
            value: getLookup(pump?.postprojvfd)
        }
    ]);

    var renewableEnergy = pump?.postprojrenewenrgysel == "0";
    data.push([
        {
            value: 'Renewable Energy?'
        },
        {
            value: pump ? getYesNo(renewableEnergy) : emptyCellValue
        }
    ]);
    
    data.push([
        {
            value: 'Renewable Energy Capacity'
        },
        {
            value: renewableEnergy ? pump?.postprojrenewenrgycapcty : emptyCellValue
        }
    ]);
    
    data.push([
        {
            value: 'New Fuel Type'
        },
        {
            value: getLookup(pump?.postprojfueltype)
        }
    ]);
}

function getNumberText(number) {
    return number == 1 ? "one"
        : number == 2 ? "two"
        : number == 3 ? "three"
        : emptyCellValue;
}

function getLookup(idString) {
    var id = parseInt(idString);
    if (isNaN(id)) {
        return emptyCellValue;
    }
    return lookupData[id] ? lookupData[id].name : emptyCellValue;
}