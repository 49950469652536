import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import './index.css';
import configureStore from './store'
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import { login } from './utils/auth';
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";
const { EventEmitter } = require("fbemitter");

// GLOBAL VARIABLES
window.map = null; // MAIN MAP OBJECT
window.emitter = new EventEmitter();

const store = configureStore();

if (window.location.protocol == 'http:') {

    console.log("you are accessing us via "
        + "an insecure protocol (HTTP). "
        + "Redirecting you to HTTPS.");

    window.location.href =
        window.location.href.replace(
            'http:', 'https:');
}
else if(window.location.protocol == 'https:') {

    console.log("you are accessing us via"
        + " our secure HTTPS protocol.");
}
const theme = createTheme({
    Typography: {
        // In Japanese the characters are usually larger.
        fontSize: 18,
          
    },
    overrides: {
        MuiButton: {
            root: {
                textTransform: 'none',
                paddingTop: '4px',
                paddingRight: '13px',
                paddingBottom: '4px',
                paddingLeft: '13px',
            }
        },
        MuiTableCell: {
            head: {
                fontSize: 'inherit',
                fontWeight: 'inherit',
                textAlign: 'inherit'
            },
            root: {
                fontWeight: 'inherit'
            }
        }
    },
    
    palette: {
        "common": { "black": "#000", "white": "#fff" }, "background":
            { "paper": "#fff", "default": "#fafafa" },
        "primary": { "contrastText": "#fff", "main": "rgba(63,120,173,1)", "light": "rgba(81,202,223,1)", "dark": "rgba(46,58,129,1)" },
        "secondary": { "contrastText": "#fff", "main": "rgba(52,179,216,1)", "light": "rgba(57,196,220,1)", "dark": "rgba(35,143,168,1)" },
        "error": { "contrastText": "#fff", "main": "rgba(255, 61, 0, 1)", "light": "rgba(250, 105, 60, 1)", "dark": "rgba(204, 56, 9, 1)" },
        "text": {
            "secondary": "rgba(0, 0, 0, 0.54)", "disabled": "rgba(0, 0, 0, 0.38)", "hint": "rgba(0, 0, 0, 0.38)",
            "primary": "rgba(51, 51, 51, 1)"
        }
    }
});

//Logs user in.  Not using for now.
login({
  token:'90cb7971-ea38-4ba2-9e4e-967ce765cb34',
  tokenExpirationTime:'1500000002221',
  id:'90cb7971-ea38-4ba2-9e4e-967ce765cb34'
});

ReactDOM.render(
  
  <Provider store={store}>
        <MuiThemeProvider theme={theme}>
            <App />
        </MuiThemeProvider>
  </Provider>
  ,
  document.getElementById("root")
);


registerServiceWorker();


//
