import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
// core components
import "ol/ol.css";
import Map from "ol/Map";
import View from "ol/View";
import { defaults as defaultControls, ScaleLine, FullScreen } from "ol/control.js";
import { defaults as defaultInteractions } from "ol/interaction.js";
import GridItem from "./Grid/GridItem.js";
import GridContainer from "./Grid/GridContainer.js";
import * as helpers from "../helpers/helpers";
import SoilsInfoImage from "./images/tools.png";
import ZoomInImage from "./images/zoom-in.png";
import ZoomOutImage from "./images/zoom-out.png"
import FloatingMenu, { FloatingMenuItem } from "../helpers/FloatingMenu.js";
import { unmountComponentAtNode } from 'react-dom';
import Menu, { SubMenu, Item as MenuItem, Divider } from "rc-menu";
import Portal from "../helpers/Portal.js";

import "./MapComponent.css";
var contextCoords = null;

export default function MapComponent() {
  const [mapRendered, setMapRendered] = useState(false);
  const [contextCoords2, setContextCoords] = useState(null);
   
  React.useLayoutEffect(() => {
      if (mapRendered == false) {
      
      const centerCoords = [-11134123.288132, 4891969.810251]; //centroid
      const scaleLineControl = new ScaleLine();

      var map = new Map({
        controls: defaultControls().extend([
          scaleLineControl,
          new FullScreen(),
        ]),
        layers: [],
        target: "map",
        view: new View({
          center: centerCoords,
          zoom: 7,
          maxZoom: 20,
          minZoom: 6,
        }),
        interactions: defaultInteractions({
          keyboard: true,
          altShiftDragRotate: false,
          pinchRotate: false,
        })
      });

      window.map = map;

      //console.log(window);
      // EMIT A CHANGE IN THE SIDEBAR (IN OR OUT)
      window.emitter.emit("mapLoaded");

      document.getElementById("map").focus();

      window.map.getViewport().addEventListener("contextmenu", evt => {
        evt.preventDefault();
  
        //this.contextCoords = window.map.getEventCoordinate(evt);
       
        var contextCoordsU = window.map.getEventCoordinate(evt);
        // console.log("setting context coords: ", contextCoordsU );
        setContextCoords(contextCoordsU);
        contextCoords = contextCoordsU;
  
        const menu = (
          <Portal>
            <FloatingMenu
              key={"helpers.getUID()"}
              buttonEvent={evt}
              onMenuItemClick={onMenuItemClick}
              styleMode="left"
              autoY={true}
            >
              <MenuItem
                className="sc-floating-menu-toolbox-menu-item"
                key="sc-floating-menu-property-click"
              >
                <FloatingMenuItem
                  imageName={SoilsInfoImage}
                  label="Show Soils Information"
                />
              </MenuItem>
              <MenuItem
                className="sc-floating-menu-toolbox-menu-item"
                key="sc-floating-menu-zoomin"
              >
                <FloatingMenuItem imageName={ZoomInImage} label="Zoom In" />
              </MenuItem>
              <MenuItem
                className="sc-floating-menu-toolbox-menu-item"
                key="sc-floating-menu-zoomout"
              >
                <FloatingMenuItem imageName={ZoomOutImage} label="Zoom Out" />
              </MenuItem>
            </FloatingMenu>
          </Portal>
        );
        ReactDOM.render(menu, document.getElementById("portal-root"));
      });

      // HANDLE URL PARAMETERS (ZOOM TO XY)
      const x = helpers.getURLParameter("X");
      const y = helpers.getURLParameter("Y");
      const sr =
        helpers.getURLParameter("SR") === null
          ? "WEB"
          : helpers.getURLParameter("SR");

      if (x !== null || y !== null) {
        let coords = [x, y];
        if (sr === "WGS84")
          coords = fromLonLat([
            Math.round(x * 100000) / 100000,
            Math.round(y * 100000) / 100000,
          ]);

        helpers.flashPoint(coords);
      }

      setMapRendered(true);
    }
  }, [mapRendered]);

  const onMenuItemClick = key => {
      if (key === "sc-floating-menu-zoomin") {
          window.map.getView().setZoom(window.map.getView().getZoom() + 1);
          var root = document.getElementById("portal-root");
          unmountComponentAtNode(root);
      }
      else if (key === "sc-floating-menu-zoomout") {
          window.map.getView().setZoom(window.map.getView().getZoom() - 1);
          var root = document.getElementById("portal-root");
          unmountComponentAtNode(root);
      }
    else if (key === "sc-floating-menu-property-click")
    {
      // console.log(contextCoords);
      window.emitter.emit("showPropertyReport", contextCoords);
    }
  };

  return (
    <GridContainer direction="row" justifyContent="flex-start" alignItems="flex-start">
      <GridItem xs={12} sm={12} md={12}>
      <div id="map" style={{width: "100%", height: "100%", position:"fixed", zIndex:"1"}} tabIndex="0" />
          </GridItem>
       
    </GridContainer>
  );
}
