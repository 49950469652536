import * as React from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import DrawingIcon from '@material-ui/icons/AddLocation';
import ReportIcon from '@material-ui/icons/Assessment';
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Map from "../../views/Map/Map.js";
import Report from "../../views/Report/Report.js";
import { makeStyles } from "@material-ui/core/styles";
import {
    BrowserRouter ,
    Link,
    Route,
    Switch
} from 'react-router-dom';

const useStyles = makeStyles((theme) => ({

    customTab: {
        width: "100%"
    }
}));
export default function IconTabs() {
    const routes = ["/Map", "/Report","/UserDropDown"];
    const basepath = "/user";
   
    const classes = useStyles();

    return (
        <BrowserRouter>
            <Route path="/"
                render={(history) => (
                    <AppBar position="sticky" className={classes.customTab}>
                        <Toolbar>
                            <Tabs value={history.location.pathname !== "/" ? history.location.pathname : false} aria-label="icon tabs example" >
                                <Tab id="MapsTab" icon={<DrawingIcon />} title={"Project Management"} aria-label="Drawing" value={basepath + routes[0]} component={Link} to={basepath +routes[0]} />
                                <Tab id="ReportsTab" icon={<ReportIcon />} title={"Report"} aria-label="Report" value={basepath + routes[1]} component={Link} to={basepath + routes[1]} />
                            </Tabs>
                        </Toolbar>
                    </AppBar>
                )}
            />
            <Switch>
                <Route path="/user/Map" component={Map} />
                <Route path="/user/Report" component={Report} />
            </Switch>
        </BrowserRouter>
    );
}
