

import React, { useEffect } from "react";
import Box from "@material-ui/core/Box";
import { styled } from '@material-ui/core/styles';
import { useState } from "react";
import Button from "@material-ui/core/Button";
import GridItem from "../../../components/Grid/GridItem.js";
import GridContainer from "../../../components/Grid/GridContainer.js";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Select, { SelectChangeEvent } from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import NativeSelect from '@material-ui/core/NativeSelect';
import InputBase from '@material-ui/core/InputBase';
import { string } from "prop-types";
import * as CMBYN from "../../../constants/api";
import OutlinedInput from '@material-ui/core/OutlinedInput';
import KWhWarning from "./KWhWarning.js";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
    'label + &': {
        marginTop: theme.spacing(3),
        width: '90%',
    },
    '& .MuiInputBase-input': {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #ced4da',
        fontSize: 16,

        padding: '10px 26px 10px 12px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:focus': {
            borderRadius: 4,
            borderColor: '#80bdff',
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        },
    },
}));
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};


const styles = {
  card: {
    marginLeft: "10%",
    boxShadow: "none",
    marginTop: "unset",
  },

  preprojpumplabel: {
    width: "100px",
    marginTop: "15px",
  },
  preprojpumpAddIcon: {
    float: "left",
    padding: "unset",
    },
    paper: { minWidth: "300px" },
};

const useStyles = makeStyles(styles);
export default function AddNewPump(props ) {
    const { fieldsArr, parentPumpNameChange, parentFieldsListeChange, parentHpChange, parentHoursChange,
        parentEfficiencyChange, parentSystemPressureChange, parentprepumpressureChange, parentprepumpdepthChange, parentpredischargepressureChange, parentprefrictionlossChange,
        parentVFDChange, parentFuelTypeChange,parentFuelUseChange, parentPumpImprovQuesChange, parentPumpStayInUseQuesChange, parentHpPostChange, parentHoursPostChange, parentEfficiencyPostChange,
        parentSystemPressurePostChange, parentpostpumpressureChange, parentpostpumpdepthChange, parentpostdischargepressureChange, parentpostfrictionlossChange, parentVFDPostChange, parentFuelTypePostChange, parentFuelUsePostChange,
        parentPostRenewenergySelectChange,parentPostRenewenergySelectCapctyChange } = props
    
   
    const classes = useStyles();
   
    const [pumpName, setPumpName] = useState('');
   // const [fields, setFields] = React.useState('');
    // @ts-check
    const [fields, setFields] = useState([]);
    const [preEff, setPreEff] = React.useState('');
    const [preHP, setPreHP] = React.useState('');
    const [preHours, setPreHours] = React.useState('');
    const [preVFD, setPreVFD] = React.useState('');
    const [preVFDList, setPreVFDList] = React.useState([]);
    const [preFuelUse, setPreFuelUse] = React.useState('');
    const [pumpSystemPressure, setPumpSystemPressure] = React.useState(2);
    const [prepumpressure, setPrepumpressure] = React.useState('');
    const [prepumpdepth, setPrepumpdepth] = React.useState('');
    const [predischargepressure, setPredischargepressure] = React.useState('');
    const [prefrictionloss, setPrefrictionloss] = React.useState('');
    const [pumpSystemPressurePost, setPumpSystemPressurePost] = React.useState(2);
    const [postpumpressure, setPostpumpressure] = React.useState('');
    const [postpumpdepth, setPostpumpdepth] = React.useState('');
    const [postdischargepressure, setPostdischargepressure] = React.useState('');
    const [postfrictionloss, setPostfrictionloss] = React.useState('');
   
    const [pumpFueltype, setPumpFueltype] = React.useState('');
    const [pumpFueltypeList, setPumpFueltypeList] = React.useState([]);
    const [postEff, setPostEff] = React.useState('');
    const [postHP, setPostHP] = React.useState('');
    const [postHours, setPostHours] = React.useState('');
    const [postVFD, setPostVFD] = React.useState('');
    const [postVFDList, setPostVFDList] = React.useState([]);
    const [postFuelUse, setPostFuelUse] = React.useState('');
    const [pumpFueltypePost, setPumpFueltypePost] = React.useState('');
    const [pumpFueltypePostList, setPumpFueltypePostList] = React.useState([]);
    const [pumpImproveques, setPumpImproveques] = React.useState("1");
    const [pumpStayinuseques, setPumpStayinuseques] = React.useState("0");
    const [renewenergyselect, setRenewenergyselect] = React.useState("1");
    const [renewenergyselectcapcty, setRenewenergyselectcapcty] = React.useState('');


    
    //const handleChange = (event) => {
    //    setFields(event.target.value);

    //    //setPumpInputs({ ...pumpinputs, fields: event.target.value });
    //    parentFieldsListeChange(event.target.value);

    //};
     // @ts-check
    
    const handleFieldsChange = function (event) {
        var value = event.target.value;
        setFields(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value);
        parentFieldsListeChange(value);
    };

    const handlePumpChange = (event) => {
        setPumpType(event.target.value);
       
    };
    const handlePreHP = (event) => {
        setPreHP(event.target.value);
        //setPumpInputs({ ...pumpinputs, prehp: event.target.value });
        parentHpChange(event.target.value);
    };
    const handlePreHours = (event) => {
        setPreHours(event.target.value);
        //setPumpInputs({ ...pumpinputs, prehours: event.target.value });
        parentHoursChange(event.target.value);

    };
    const handlePreEff = (event) => {
        setPreEff(event.target.value);
        //setPumpInputs({ ...pumpinputs, preeff: event.target.value });
        parentEfficiencyChange(event.target.value);

    };
    const handlePreFuelUse = (event) => {
        setPreFuelUse(event.target.value);
        //setPumpInputs({ ...pumpinputs, prefueluse: event.target.value });
        parentFuelUseChange(event.target.value);

    };

    const handlePreVFD = (event) => {
        setPreVFD(event.target.value);
        //setPumpInputs({ ...pumpinputs, prevfd: event.target.value });
        parentVFDChange(event.target.value);

    };
        
    const handlePumpSystemPressure
        = (event) => {
            setPumpSystemPressure(event.target.value);
            //setPumpInputs({ ...pumpinputs, presyspress: event.target.value });
            if (event.target.value == 1) {
                document.getElementById("syspressitemspre").style.display = "none";
                //document.getElementById("syspressinputpre").style.display = "block";
            } else if (event.target.value == 2) {

                document.getElementById("syspressitemspre").style.display = "block";
               // document.getElementById("syspressinputpre").style.display = "none";
            }

            // console.log('calling parentSystemPressureChange with: ', typeof event.target.value);
            // console.log(event);
            // console.log(event.target);
            // console.log(event.target.value);
            parentSystemPressureChange(event.target.value)
        };

    const handlePreSysPress
        = (event) => {
            setPrepumpressure(event.target.value)
            parentprepumpressureChange(event.target.value)
        };
    const handlePrePumpDepth
        = (event) => {
            setPrepumpdepth(event.target.value)
            parentprepumpdepthChange(event.target.value)
        };
    const handlePreDisPress
        = (event) => {
            setPredischargepressure(event.target.value)
            parentpredischargepressureChange(event.target.value)
        };
    const handlePreFricLoss
        = (event) => {
            setPrefrictionloss(event.target.value)
            parentprefrictionlossChange(event.target.value)
        };
    
    
    const handlePumpSystemPressurePost
        = (event) => {
            setPumpSystemPressurePost(event.target.value);
            //setPumpInputs({ ...pumpinputs, presyspress: event.target.value });
            if (event.target.value == 1) {
                document.getElementById("syspressitemspost").style.display = "none";
                document.getElementById("syspressinputpost").style.display = "block";
            } else if (event.target.value == 2) {

                document.getElementById("syspressitemspost").style.display = "block";
                document.getElementById("syspressinputpost").style.display = "none";
            }
            parentSystemPressurePostChange(event.target.value)
        };
    const handlePostSysPress
        = (event) => {
            setPostpumpressure(event.target.value)
            parentpostpumpressureChange(event.target.value)
        };
    const handlePostPumpDepth
        = (event) => {
            setPostpumpdepth(event.target.value)
            parentpostpumpdepthChange(event.target.value)
        };
    
    const handlePostDisPress
        = (event) => {
            setPostdischargepressure(event.target.value)
            parentpostdischargepressureChange(event.target.value)
        };
    const handlePostFricLoss
        = (event) => {
            setPostfrictionloss(event.target.value)
            parentpostfrictionlossChange(event.target.value)
        };
   
    const handlePumpNameChange = event => {
        setPumpName(event.target.value);
        
       
        //setPumpInputs({ ...pumpinputs, pumpName: event.target.value });
        parentPumpNameChange(event.target.value)
    };

    const handlePumpFueltype = event => {
        

        setPumpFueltype(event.target.value);
        
        //setPumpInputs({ ...pumpinputs, prefueltype: event.target.value });
        if (event.target.value >= 150 && event.target.value <= 154 && event.target.value != 151) {
            document.getElementById("divnewpumpfueluse").style.display = "block";
        } else {
         document.getElementById("divnewpumpfueluse").style.display = "none";
        }
        
        parentFuelTypeChange(event.target.value)
    };

    const handlePumpFueltypePost = event => {
        
        setPumpFueltypePost(event.target.value);
        //if (event.target.value == 140 || event.target.value == 142 || event.target.value == 143) {

        //    document.getElementById("divpostpumpfueluse").style.display = "block";
        //} else {
        //    document.getElementById("divpostpumpfueluse").style.display = "none";
        //}
        parentFuelTypePostChange(event.target.value)
    };
    const handlePumpImproveQues = event => {
        setPumpImproveques(event.target.value);
       
        if (event.target.value === "0") {
            document.getElementById("pumpImproveDiv").style.display = "block";
            document.getElementById("pumpStayInUseDiv").style.display = "none"; 
        }
        else {
            document.getElementById("pumpStayInUseDiv").style.display = "block"; 
            document.getElementById("pumpImproveDiv").style.display = "none";
        }
        parentPumpImprovQuesChange(event.target.value)
    };
    const handlePumpStayInUseQues= event => {
        setPumpStayinuseques(event.target.value);

        
        parentPumpStayInUseQuesChange(event.target.value)
    };
    const handlePostHP = (event) => {
        setPostHP(event.target.value);
        //setPumpInputs({ ...pumpinputs, prehp: event.target.value });
        parentHpPostChange(event.target.value);
    };

    const handlePostHours = (event) => {
        setPostHours(event.target.value);
        //setPumpInputs({ ...pumpinputs, prehours: event.target.value });
        parentHoursPostChange(event.target.value);

    };

    const handlePostEff = (event) => {
        setPostEff(event.target.value);
        //setPumpInputs({ ...pumpinputs, preeff: event.target.value });
        parentEfficiencyPostChange(event.target.value);

    };
   
    const handlePostVFD = (event) => {
    setPostVFD(event.target.value);
    //setPumpInputs({ ...pumpinputs, prevfd: event.target.value });
    parentVFDPostChange(event.target.value);

    };
    const handlePostFuelUse = (event) => {
        setPostFuelUse(event.target.value);
        //setPumpInputs({ ...pumpinputs, prevfd: event.target.value });
        parentFuelUsePostChange(event.target.value);

    };

    const handleRenewEnrgySelChange = event => {


        setRenewenergyselect(event.target.value);

        if (event.target.value === "0") {

            document.getElementById("renewenrgycapctyDiv").style.display = "block";

        }
        else {
            document.getElementById("renewenrgycapctyDiv").style.display = "none";
        }
        parentPostRenewenergySelectChange(event.target.value)
    };
    const handlePostRenewEnrgyCapcty = event => {
        setRenewenergyselectcapcty(event.target.value);
        parentPostRenewenergySelectCapctyChange(event.target.value);

    };
    useEffect(() => {
        fetch(CMBYN.FETCH_POSTFUELTYPES)
            .then(response => response.json())
            .then(results => {
                var list = [];
                for (var u = 0; u < results.length; u++) {
                    let usr = results[u];
                    if (usr && usr.id < 155) {
                        let option = {};
                        option.name = usr.name;
                        option.id = usr.id;
                        list.push(option);
                    }
                }
                setPumpFueltypeList(list);
                
            })
    }, []);
    useEffect(() => {
        fetch(CMBYN.FETCH_POSTFUELTYPES)
            .then(response => response.json())
            .then(results => {
                var list = [];
                for (var u = 0; u < results.length; u++) {
                    let usr = results[u];
                    if (usr) {
                        let option = {};
                        option.name = usr.name;
                        option.id = usr.id;
                        list.push(option);
                    }
                }
                setPumpFueltypePostList(list);

            })
    }, []);
    useEffect(() => {
        fetch(CMBYN.FETCH_VFDTYPES)
            .then(response => response.json())
            .then(results => {
                var list = [];
                for (var u = 0; u < results.length; u++) {
                    let usr = results[u];
                    if (usr) {
                       
                        let option = {};
                        option.name = usr.vfdType.name;
                        option.id = usr.vfdType.id;
                        list.push(option);
                    }
                }


                
                setPostVFDList(list);
            })
    }, []);
    
    return (
        <div style={{ marginTop: 10 }}>
      <GridContainer
                direction="column"
        justifyContent="flex-start"
        alignItems="flex-start"
      >
                      
    
                          <GridItem xs={11} sm={11} md={11}>
                            <div style={{ marginTop: "10px", width: "max-content" }}>
                                <InputLabel htmlFor="newpump">Name of the pump :</InputLabel>
                           </div>
                        </GridItem>

                          <GridItem xs={11} sm={11} md={11}>
                            <div style={{ marginTop: "10px", width: "max-content" }}>
                                <TextField

                                    id="addnewpump"
                                    label=""
                                    defaultValue=""
                                    variant="outlined"
                                color="primary"
                            onChange={handlePumpNameChange}
                                        
                                />
                                </div>
                            
                        </GridItem>
                    </GridContainer>

                    <GridContainer
                     
                        direction="column"
                        justifyContent="flex-start"
                        alignItems="flex-start">

                          <GridItem xs={11} sm={11} md={11}>

                            <div style={{ marginTop: "10px", width: "max-content" }}>
                                <InputLabel id="demo-simple-select-standard-label">Which fields does this pump service :</InputLabel>
                                </div>
                        </GridItem>
                          <GridItem xs={11} sm={11} md={11}>
                    <div style={{ marginTop: "10px", width: "max-content" }}>

                        <Select
                            labelId="demo-multiple-name-label"
                            id="demo-multiple-name"
                            multiple
                            value={fields}
                            onChange={handleFieldsChange}
                            input={<OutlinedInput label="Fields" />}
                            MenuProps={MenuProps}
                        >
                            {fieldsArr.map((field) => (
                                <MenuItem
                                    key={field.name}
                                    value={field.name}
                                    
                                >
                                    {field.name}
                                </MenuItem>
                            ))}
                        </Select>
                                </div>

                        </GridItem>
                 </GridContainer>
                    <GridContainer
                        direction="column"
                     
                        justifyContent="flex-start"
                        alignItems="flex-start">
                          <GridItem xs={11} sm={11} md={11}>
                            <div style={{ marginTop: "10px", width: "max-content" }}>
                        <InputLabel htmlFor="motorratedhp">Motor Rated Horsepower :</InputLabel>
                            </div>
                        </GridItem>

                          <GridItem xs={11} sm={11} md={11}>
                            <div style={{ marginTop: "10px", width: "max-content" }}>
                                <TextField

                                    id="fieldmotorhorseratedhp"
                                    label=""
                                    defaultValue=""
                                    variant="outlined"
                                        color="primary"
                            onChange={handlePreHP}
                                        type="number"
                                />
                            </div>
                        </GridItem>
                    </GridContainer>
              
                    
                    <GridContainer
                        direction="column"
                        justifyContent="flex-start"
                        alignItems="flex-start">
                          <GridItem xs={11} sm={11} md={11}>
                            <div style={{ marginTop: "10px", width: "max-content" }}>
                        <InputLabel htmlFor="addnewpumpefficiency">Overall Pumping Efficiency (%)</InputLabel>
                            </div>
                        </GridItem>

                          <GridItem xs={11} sm={11} md={11}>
                            <div style={{ marginTop: "10px", width: "max-content" }}>
                                <TextField

                                    id="fieldaddnewpumpefficiency"
                                    label=""
                                    defaultValue=""
                                    variant="outlined"
                                        color="primary"
                            onChange={handlePreEff}
                            type="number"
                                />
                            </div>
                        </GridItem>
                    </GridContainer>
                   
           
            <GridContainer
                direction="column"
                justifyContent="flex-start"
                alignItems="flex-start">
                <GridItem>
                    <div id="syspressitemspre" style={{ marginTop: "10px", width: "max-content" }}>
                        <GridContainer direction="column"
                            justifyContent="flex-start"
                            alignItems="flex-start">
                            <GridItem xs={11} sm={11} md={11}>
                                <div style={{ marginTop: "10px", width: "max-content" }}>
                                    <InputLabel htmlFor="pumpdepthpre">Enter the pumping depth (ft): </InputLabel>
                                </div>
                            </GridItem>
                            <GridItem xs={11} sm={11} md={11}>               
                                <div style={{ marginTop: "10px", width: "max-content" }}>
                                    <TextField

                                        id="fieldumpingdepthpre"
                                        label=""
                                        defaultValue=""
                                        variant="outlined"
                                        color="primary"
                                        onChange={handlePrePumpDepth}
                                        type="number"
                                    />
                                </div>
                            </GridItem>
                        </GridContainer>
                        <GridContainer
                            direction="column"
                            justifyContent="flex-start"
                            alignItems="flex-start">
                            <GridItem xs={11} sm={11} md={11}>
                                <div style={{ marginTop: "10px", width: "max-content" }}>
                                    <InputLabel htmlFor="dischargepresspre">Enter the discharge pressure (ft) (if only psi is known please multiply by 2.31 to convert to feet):</InputLabel>
                                   
                                </div>
                            </GridItem>

                            <GridItem xs={11} sm={11} md={11}>
                                <div style={{ marginTop: "10px", width: "max-content" }}>
                                    <TextField

                                        id="fielddischargepressurepre"
                                        label=""
                                        defaultValue=""
                                        variant="outlined"
                                        color="primary"
                                        onChange={handlePreDisPress}
                                        type="number"
                                    />
                                </div>
                            </GridItem>
                        </GridContainer>
                        <GridContainer
                            direction="column"
                            justifyContent="flex-start"
                            alignItems="flex-start">
                            <GridItem xs={11} sm={11} md={11}>
                                <div style={{ marginTop: "10px", width: "max-content" }}>
                                    <InputLabel htmlFor="friclosspre">Enter the friction losses (ft) (friction loss is 5 for a booster and 10 for a well pump): </InputLabel>
                                </div>
                            </GridItem>

                            <GridItem xs={11} sm={11} md={11}>
                                <div style={{ marginTop: "10px", width: "max-content" }}>
                                    <TextField

                                        id="fieldfrictionlosspre"
                                        label=""
                                        defaultValue=""
                                        variant="outlined"
                                        color="primary"
                                        onChange={handlePreFricLoss}
                                        type="number"
                                    />
                                </div>
                            </GridItem>
                        </GridContainer>
                    </div>
                </GridItem>
            </GridContainer>
                    <GridContainer
                        direction="column"
                        justifyContent="flex-start"
                        alignItems="flex-start">
                          <GridItem xs={11} sm={11} md={11}>
                            <div style={{ marginTop: "10px", width: "max-content" }}>
                        <InputLabel htmlFor="addnewpumpFuel">Fuel Type:</InputLabel>
                            </div>
                        </GridItem>
                          <GridItem xs={11} sm={11} md={11}>
                            <div style={{ marginTop: "10px", width: "max-content" }}>
                                <NativeSelect
                                    id="addNewPumpFuelType"
                                    value={pumpFueltype}
                            onChange={handlePumpFueltype}
                                    input={<BootstrapInput />}
                                >
                            {pumpFueltypeList.map((cr) => (
                                <option  value={cr.id}> {cr.name}
                                </option >

                            ))}
                                </NativeSelect>
                            </div>
                        </GridItem>
            </GridContainer>
            <GridContainer direction="column" alignItems="flex-start">
                <GridItem>
                    <div id="divnewpumpfueluse" style={{ marginTop: "10px", width: "max-content", display: "block" }}>
                    <GridContainer
                        direction="column"
                        justifyContent="flex-start"
                        alignItems="flex-start">
                          <GridItem xs={11} sm={11} md={11}>
                            <div style={{ marginTop: "10px", width: "max-content" }}>
                                    <InputLabel htmlFor="addnewpumpfueluse">Annual Fuel Use (gallons or scf):</InputLabel>
                            </div>
                        </GridItem>

                          <GridItem xs={11} sm={11} md={11}>
                            <div style={{ marginTop: "10px", width: "max-content" }}>
                                <TextField

                                    id="fieldaddnewpumfueluse"
                                    label=""
                                    defaultValue=""
                                    variant="outlined"
                                        color="primary"
                                        onChange={handlePreFuelUse}
                                        type="number"
                                />
                            </div>
                        </GridItem>
                        </GridContainer>
                    </div>
                </GridItem> 
                </GridContainer>
                    <GridContainer
                        direction="column"
                        justifyContent="flex-start"
                        alignItems="flex-start">

                          <GridItem xs={11} sm={11} md={11}>
                            <div style={{ marginTop: "10px", width: "max-content" }}>
                        <InputLabel htmlFor="addnewpumpimprove">Is this pump proposed for improvement? Improvements include improvements to the pump, replacement of the pump,<br/> and fuel conversions including the installation of renewable energy as a new fuel source.</InputLabel>
                            </div>
                        </GridItem>
                          <GridItem xs={11} sm={11} md={11}>
                            <div style={{ marginTop: "10px", width: "max-content" }}>
                        <Select
                            labelId="pumpimprovqueslabel"
                            id="fieldpumpimprovques"
                            value={pumpImproveques}
                            onChange={handlePumpImproveQues}
                            label=""
                        >
                            <MenuItem value={"0"}>Yes</MenuItem>
                            <MenuItem value={"1"}>No</MenuItem>

                        </Select>
                            </div>
                        </GridItem>
            </GridContainer>
            <GridContainer direction="column" alignItems="flex-start">
                <GridItem>
            <div id="pumpStayInUseDiv" style={{ marginTop: "10px", width: "max-content" }}>
            <GridContainer
                direction="column"
                justifyContent="flex-start"
                alignItems="flex-start">

                <GridItem xs={11} sm={11} md={11}>
                    <div style={{ marginTop: "10px", width: "max-content" }}>
                        <InputLabel htmlFor="addnewpumpstayinuse">As part of your project will this pump stay in use?</InputLabel>
                    </div>
                </GridItem>
                <GridItem xs={11} sm={11} md={11}>
                    <div style={{ marginTop: "10px", width: "max-content" }}>
                        <Select
                            labelId="pumpstayinuselabel"
                            id="fieldpumpstayinuse"
                            value={pumpStayinuseques}
                            onChange={handlePumpStayInUseQues}
                            label=""
                        >
                            <MenuItem value={"0"}>Yes</MenuItem>
                            <MenuItem value={"1"}>No</MenuItem>

                        </Select>
                    </div>
                </GridItem>
                </GridContainer>
                    </div>
                </GridItem>
                </GridContainer>
            <div id="pumpImproveDiv" style={{ marginTop: "10px", width: "max-content", display: "none" }}>
                    <GridContainer
                        direction="column"

                        justifyContent="flex-start"
                    alignItems="flex-start">

                    <GridItem xs={11} sm={11} md={11}>
                        <div style={{ marginTop: "10px", width: "max-content" }}>
                            <InputLabel htmlFor="wordingpost" style={{ fontWeight: "bold" }}>Enter details for your proposed improved pump.<br/><br/>Details for this pump will populate in the Post-Project Pumps section when complete.</InputLabel>
                        </div>
                    </GridItem>
                          <GridItem xs={11} sm={11} md={11}>
                            <div style={{ marginTop: "10px", width: "max-content" }}>
                            <InputLabel htmlFor="motorratedhppost">Motor Rated Horsepower :</InputLabel>
                            </div>
                        </GridItem>

                          <GridItem xs={11} sm={11} md={11}>
                            <div style={{ marginTop: "10px", width: "max-content" }}>
                                <TextField

                                    id="fieldmotorhorseratedhppost"
                                    label=""
                                    defaultValue={postHP}
                                    variant="outlined"
                            color="primary"
                                onChange={handlePostHP}
                                type="number"

                                />
                            </div>
                        </GridItem>
                    </GridContainer>

                    <GridContainer
                        direction="column"
                        justifyContent="flex-start"
                        alignItems="flex-start">
                          <GridItem xs={11} sm={11} md={11}>
                            <div style={{ marginTop: "10px", width: "max-content" }}>
                            <InputLabel htmlFor="addnewpumpefficiencypost">Expected Overall Pumping Efficiency (%)</InputLabel>
                            </div>
                        </GridItem>

                          <GridItem xs={11} sm={11} md={11}>
                            <div style={{ marginTop: "10px", width: "max-content" }}>
                                <TextField

                                    id="fieldaddnewpumpefficiencypost"
                                    label=""
                                    defaultValue={postEff}
                                    variant="outlined"
                            color="primary"
                                onChange={handlePostEff}
                                type="number"
                                />
                            </div>
                        </GridItem>
                    </GridContainer>
                   
                <GridContainer
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="flex-start">

                    <GridItem xs={11} sm={11} md={11}>
                        <div id="syspressinputpost" style={{ marginTop: "10px", width: "max-content", display: "none" }}>
                            <GridContainer
                                direction="column"
                                justifyContent="flex-start"
                                alignItems="flex-start">
                                <GridItem xs={11} sm={11} md={11}>
                                    <div style={{ marginTop: "10px", width: "max-content"}}>
                                        <InputLabel htmlFor="pumppressurepost">Enter System Pressure (ft) (if only psi is known please multiply by 2.31 to convert to feet):</InputLabel>
                                    </div>
                                </GridItem>

                                <GridItem xs={11} sm={11} md={11}>
                                    <div style={{ marginTop: "10px", width: "max-content" }}>
                                        <TextField

                                            id="fieldpumppressurepost"
                                            label=""
                                            defaultValue=""
                                            variant="outlined"
                                            color="primary"
                                            onChange={handlePostSysPress}
                                            type="number"
                                        />
                                    </div>
                                </GridItem>
                            </GridContainer>
                        </div>
                    </GridItem>
                </GridContainer>
                <GridContainer
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="flex-start">
                    <GridItem xs={11} sm={11} md={11}>
                        <div id="syspressitemspost" style={{ marginTop: "10px", width: "max-content" }}>
                            <GridContainer
                                direction="column"
                                justifyContent="flex-start"
                                alignItems="flex-start">
                                <GridItem>
                                    <div style={{ marginTop: "10px", width: "max-content" }}>
                                        <InputLabel htmlFor="pumpdepthpost">Enter the pumping depth (ft):</InputLabel>
                                    </div>
                                </GridItem>

                                <GridItem xs={11} sm={11} md={11}>
                                    <div style={{ marginTop: "10px", width: "max-content" }}>
                                        <TextField

                                            id="fieldumpingdepthpost"
                                            label=""
                                            defaultValue=""
                                            variant="outlined"
                                            color="primary"
                                            onChange={handlePostPumpDepth}
                                            type="number"
                                        />
                                    </div>
                                </GridItem>
                            </GridContainer>
                            <GridContainer
                                direction="column"
                                justifyContent="flex-start"
                                alignItems="flex-start">
                                <GridItem xs={11} sm={11} md={11}>
                                    <div style={{ marginTop: "10px", width: "max-content" }}>
                                        <InputLabel htmlFor="dischargepresspost">Discharge Pressure (ft) (if only psi is known please multiply by 2.31 to convert to feet):</InputLabel>
                                    </div>
                                </GridItem>

                                <GridItem xs={11} sm={11} md={11}>
                                    <div style={{ marginTop: "10px", width: "max-content" }}>
                                        <TextField

                                            id="fielddischargepressurepost"
                                            label=""
                                            defaultValue=""
                                            variant="outlined"
                                            color="primary"
                                            onChange={handlePostDisPress}
                                            type="number"
                                        />
                                    </div>
                                </GridItem>
                            </GridContainer>
                            <GridContainer
                                direction="column"
                                justifyContent="flex-start"
                                alignItems="flex-start">
                                <GridItem xs={11} sm={11} md={11}>
                                    <div style={{ marginTop: "10px", width: "max-content" }}>
                                        <InputLabel htmlFor="friclosspost">Enter the friction losses (ft) (friction loss is 5 for a booster and 10 for a well pump): </InputLabel>
                                    </div>
                                </GridItem>

                                <GridItem xs={11} sm={11} md={11}>
                                    <div style={{ marginTop: "10px", width: "max-content" }}>
                                        <TextField

                                            id="fieldfrictionlosspost"
                                            label=""
                                            defaultValue=""
                                            variant="outlined"
                                            color="primary"
                                            onChange={handlePostFricLoss}
                                            type="number"
                                        />
                                    </div>
                                </GridItem>
                            </GridContainer>
                        </div>
                    </GridItem>
                </GridContainer>
                    <GridContainer
                        direction="column"
                        justifyContent="flex-start"
                        alignItems="flex-start">
                          <GridItem xs={11} sm={11} md={11}>
                            <div style={{ marginTop: "10px", width: "max-content" }}>
                            <InputLabel htmlFor="addnewpumpvfdpost">Are you installing a VFD?</InputLabel>
                            </div>
                    </GridItem>
                    <GridItem xs={11} sm={11} md={11}>
                        <div style={{ marginTop: "10px", width: "max-content" }}>
                            <NativeSelect
                                id="fieldaddnewpumpvfdpost"
                                value={postVFD}
                                onChange={handlePostVFD}
                                input={<BootstrapInput />}
                            >
                                {postVFDList.map((cr) => (
                                    <option value={cr.id}> {cr.name}
                                    </option >

                                ))}
                            </NativeSelect>
                        </div>
                    </GridItem>
                          
                    </GridContainer>
                
                    <GridContainer
                        direction="column"
                        justifyContent="flex-start"
                        alignItems="flex-start">
                          <GridItem xs={11} sm={11} md={11}>
                            <div style={{ marginTop: "10px", width: "max-content" }}>
                                <InputLabel htmlFor="addnewpumpFuelpost">New Fuel Type :</InputLabel>
                            </div>
                        </GridItem>
                          <GridItem xs={11} sm={11} md={11}>
                            <div style={{ marginTop: "10px", width: "max-content" }}>
                                <NativeSelect
                                    id="addNewPumpFuelType"
                                    value={pumpFueltypePost}
                                    onChange={handlePumpFueltypePost}
                                    input={<BootstrapInput />}
                                >
                                {pumpFueltypePostList.map((cr) => (
                                    <option  value={cr.id}> {cr.name}
                                    </option >

                                ))}
                                </NativeSelect>
                            </div>
                        </GridItem>
                </GridContainer>
            </div>
        </div>
  );
}
