import React from "react";
import "./ButtonBar.css";
import DrawButton from "./DrawButton.js";

const ButtonBar = props => {
  return (
    <div className={props.isEditing ? "sc-fieldsidebar-button-bar sc-disabled" : "sc-fieldsidebar-button-bar"}>
      <DrawButton
        title="Draw a polygon on the map"
        imageName="polygon.png"
        onClick={() => {
          props.onClick("Polygon");
        }}
        isActive={props.activeButton === "Polygon" ? true : false}
      />
      <DrawButton
        title="Draw a circle on the map"
        imageName="circle.png"
        onClick={() => {
          props.onClick("Circle");
        }}
        isActive={props.activeButton === "Circle" ? true : false}
      />
      <DrawButton
        title="Draw a rectangle on the map"
        imageName="rectangle.png"
        onClick={() => {
          props.onClick("Rectangle");
        }}
        isActive={props.activeButton === "Rectangle" ? true : false}
          />
         
      <DrawButton
        title="Cancel current drawing tool"
        imageName="hand.png"
        onClick={() => {
          props.onClick("Cancel");
        }}
        isActive={props.activeButton === "Cancel" ? true : false}
      />
    </div>
  );
};

export default ButtonBar;
