import React, { useState } from "react";
import { useSelector } from "react-redux";

// @material-ui/core components
import { CardContent, Collapse, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@material-ui/core";

// custom components
import Card from "../../components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import ExpandCollapseIcon from "../ExpandCollapseIcon.jsx";

import { CO2Units, formatResult, loadingText, N2OYearUnits } from "helpers/report.js";

export default function N2OReportTable (props) {
  const { classes, reportData } = props;
  const fields = useSelector(state => state.user.fields);

  const [collapsed, setCollapsed] = useState(false);
  
  function toggleCollapse() {
    setCollapsed(!collapsed);
  }

  return (
    <Card id="n2oReport">
      <CardHeader style={{backgroundColor: '#ffd966'}} onClick={toggleCollapse}>
        <ExpandCollapseIcon collapsed={collapsed} />
        <Typography align="center" variant="h5">
          Nitrous Oxide GHG Emission Reductions from Irrigation System Enhancements in Proposed Project 
        </Typography>
      </CardHeader>
      <Collapse in={!collapsed}>
        <CardContent>
          <Table>
            <TableHead className={classes.tableHeader}>
              <TableRow>
                <TableCell>
                  Field
                </TableCell>
                <TableCell>
                                  Pre-Project Average N<sub>2</sub>O <br /> Emissions (<CO2Units/>)
                </TableCell>
                <TableCell>
                                  Post-Project Average N<sub>2</sub>O <br /> Emissions (<CO2Units/>)
                </TableCell>
                <TableCell>
                                  Average Yearly N<sub>2</sub>O-N Emission <br /> Reductions (<CO2Units/>)
                </TableCell>
                <TableCell>
                  10-Year Project Benefits <br/> (<CO2Units/>)
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {fields.map(field =>
                <TableRow key={'n2oReportRow_' + field.id}>
                  <TableCell>{field.name}</TableCell>
                  <TableCell>{field.results ? formatResult(field.results.beforeN2O) : loadingText}</TableCell>
                  <TableCell>{field.results ? formatResult(field.results.afterN2O) : loadingText}</TableCell>
                  <TableCell>{field.results ? formatResult(field.results.avgYearlyN2OBenefits) : loadingText}</TableCell>
                  <TableCell>{field.results ? formatResult(field.results.tenYearN2OBenefits) : loadingText}</TableCell>
                </TableRow>
              )}
              <TableRow key={'n2oReportRow_totals'} className={classes.totalsRow}>
                <TableCell>Total</TableCell>
                <TableCell>
                  {formatResult(reportData.n2oReport.beforeN2O)}
                </TableCell>
                <TableCell>
                  {formatResult(reportData.n2oReport.afterN2O)}
                </TableCell>
                <TableCell>
                  {formatResult(reportData.n2oReport.avgYearlyN2OBenefits)}
                </TableCell>
                <TableCell>
                  {formatResult(reportData.n2oReport.tenYearN2OBenefits)}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </CardContent>
      </Collapse>
    </Card>
  );
}