import React, { useState } from 'react';
import { Button, DialogActions, DialogContent, InputAdornment, TextField } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import GridContainer from 'components/Grid/GridContainer';

export default function FundsPopup({open, handleSubmit, value}) {
    const [fundsRequested, setFundsRequested] = useState(0);

    const handleFundsChange = (event) => {
        setFundsRequested(event.target.value);
    }

    const checkKeyPress = (e) => {
        if (e.key ==='Enter' || e.key ==='NumpadEnter' )
        {
            e.preventDefault();
            handleSubmit(fundsRequested);
        }
    }

    return (
        <Dialog open={open}>
            <DialogContent>
                <GridContainer
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                >
                    <span>Please enter the total funds requested from SWEEP</span>
                    <TextField
                        id="fundsRequested"
                        type="number"
                        defaultValue={value}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>
                        }}
                        onChange={handleFundsChange}
                        onKeyDown={checkKeyPress}
                    />
                </GridContainer>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="secondary" onClick={() => {handleSubmit(fundsRequested)}}>Ok</Button>
            </DialogActions>
        </Dialog>
    )
}