import React from "react";

// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import Navbar from "../components/Navbars/Navbar.js";
import Tabbar from "../components/Tabbar/Tabbar.js";

import styles from "../assets/jss/material-dashboard-react/layouts/userStyle.js";

const useStyles = makeStyles(styles);
let ps;

export default function User({ ...rest }) 
{
   
  const classes = useStyles();
  const mainPanel = React.createRef();

  // initialize and destroy the PerfectScrollbar plugin
  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(mainPanel.current, {
        suppressScrollX: true,
        suppressScrollY: false
      });
      document.body.style.overflow = "hidden";
    }
    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
    };
  }, [mainPanel]);


    return (
        
    <div className={classes.wrapper}>
            <Navbar
               
            />
            <Tabbar

              
                color={"blue"}
                {...rest} />
      
            <div className={classes.mainPanel} ref={mainPanel}>
            
        
        { 
          <div className={classes.content}>
          </div>
        }
      </div>
            </div>
            
  );
}