const dialogStyle = theme => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
    width: "1000px",
  },
  button: {
    margin: '1px'
  },
  input: {
    display: "none",
  },
  cssRoot: {
    width: "100%",
  },
  cssRootMobile: {
    width: "50%",
  },
  cssRootMobileSearch: {
    width: "100%",
  },
  dialogTitleRootForm: {
    fontSize: "24px",
    backgroundColor: "#333",
    color: "#fff",
  },
  dialogTitleRoot: {
    fontSize: "24px",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText
  },
  dialogPaper: {
    maxWidth: "80%",
  },
  primaryColor: {
    color: theme.palette.primary.main
  }
});

export default dialogStyle;
