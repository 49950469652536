import React, { useState } from "react";

// core components
import Card from "../../components/Card/Card.js";
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import Box from "@material-ui/core/Box";
import MapComponent from "../../components/MapComponent.js";
import MapSwitcher from "../../components/MapSwitcher/MapSwitcher.js";
import FieldSidebar from "../../components/Field/FieldSidebar.js";
import PropertyReportClick from "../../components/PropertyReportClick.js";
import WelcomePopup from "../Popups/Misc/WelcomePopup.jsx";
import LocationSearchbar from "components/LocationSearchbar.jsx";


export default function Map() {
  
  return (
    <GridContainer direction="row" justifyContent="flex-start" alignItems="flex-start">
          <WelcomePopup/>
          
      <GridItem xs={12} sm={6} md={4} lg={3}>
              <Box sx={{ maxHeight: "80vh", overflowY: "auto", overflowX:"hidden"}}>
        <GridContainer direction="row" justifyContent="flex-start" alignItems="flex-start">
                      <GridItem xs={12} sm={12} md={12} lg={12}>
                              <FieldSidebar />
                      </GridItem>
                      
          {/*<GridItem xs={12} sm={12} md={12} lg={12}>*/}
          {/*<div style={{ paddingLeft:"20px", paddingRight:"10px", paddingTop:"10px", paddingBottom:"10px" }}>*/}
          {/*<UserDetails />*/}
          {/*</div>*/}
          {/*</GridItem>*/}
          </GridContainer>          
              </Box>
      </GridItem>
      <GridItem xs={8} sm={8} md={8}>
              <div id="portal-root" />
                  
      <MapComponent />
      <LocationSearchbar />
      <MapSwitcher />
      <PropertyReportClick />
          </GridItem>
          
    </GridContainer>
  );
}
