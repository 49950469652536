import React, { useEffect, useState } from "react";
import MapConfig from "./MapSwitcherConfig.json";

export default function LayerLegend(props) {

    const [allLayers, setAllLayers] = useState([]);
    const { layer } = props

    const getLegendImageUrl = () => {

        let url = '';

        if (layer) {
            if (MapConfig) {
                if (MapConfig.cdlServices)
                    MapConfig.cdlServices.map((service) => {
                        if (layer.toLowerCase() === service.name.toLowerCase())
                            if (service.legendUrl)
                                url = service.legendUrl;
                    });

                if (MapConfig.geoserverServices)
                    MapConfig.geoserverServices.map((service) => {
                        if (layer.toLowerCase() === service.name.toLowerCase()) 
                            if (service.legendUrl)
                                url = service.legendUrl;
                    });
            }
        }

        return url;
    }

      let imageUrl = getLegendImageUrl();

      if (imageUrl != '')
          return <img src={imageUrl} alt={layer + " legend"} />;

      else
          return <div> </div>;
  
}




