import React, { useEffect, useState } from "react";
import { styled } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from '@material-ui/core/InputLabel';
import NativeSelect from '@material-ui/core/NativeSelect';
import InputBase from '@material-ui/core/InputBase';
import Link from '@material-ui/core/Link';
import GridItem from "../../components/Grid/GridItem.js";
import * as CMBYN from "../../constants/api";
import GridContainer from "../../components/Grid/GridContainer.js";
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { IconButton } from "@material-ui/core";
const BootstrapInput = styled(InputBase)(({ theme }) => ({
    'label + &': {
        marginTop: theme.spacing(3),
    },
    '& .MuiInputBase-input': {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #ced4da',
        fontSize: 16,
        padding: '10px 26px 10px 12px',
        width: '150px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:focus': {
            borderRadius: 4,
            borderColor: '#80bdff',
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        },
    },
}));


export default function PostSpeciesPopup(props) {
    const { selFieldPost, parentchildpostcroponeyearone, parentchildpostcroptwoyearone, parentchildpostcropthreeyearone, parentchildpostcroponeyeartwo,
        parentchildpostcroptwoyeartwo, parentchildpostcropthreeyeartwo, parentchildpostcroponeyearthree, parentchildpostcroptwoyearthree, parentchildpostcropthreeyearthree,
        parentpostcropOneYearOneChange, parentpostcropOneYearTwoChange, parentpostcropOneYearThreeChange, parentpostcropTwoYearOneChange,
        parentpostcropTwoYearTwoChange, parentpostcropTwoYearThreeChange, parentpostcropThreeYearOneChange, parentpostcropThreeYearTwoChange,
        parentpostcropThreeYearThreeChange, parentchildprecroponeyearone, parentchildprecroptwoyearone, parentchildprecropthreeyearone,
        parentCopyPostCrop1VerticalChange, parentCopyPostCrop2VerticalChange, parentCopyPostCrop3VerticalChange } = props; 
    const [cropOneYear1, setCropOneYear1] = ((selFieldPost.field.fielddetails.postcropping.postyearcroponeyearone == '') || (selFieldPost.field.fielddetails.postcropping.postyearcroponeyearone != parentchildpostcroponeyearone)) ? React.useState(parentchildpostcroponeyearone) : React.useState(selFieldPost.field.fielddetails.postcropping.postyearcroponeyearone);
    const [cropOneYear1List, setCropOneYear1List] = React.useState([]);
    const [cropTwoYear1, setCropTwoYear1] = ((selFieldPost.field.fielddetails.postcropping.postyearcroptwoyearone == '') || (selFieldPost.field.fielddetails.postcropping.postyearcroptwoyearone != parentchildpostcroptwoyearone)) ? React.useState(parentchildpostcroptwoyearone) : React.useState(selFieldPost.field.fielddetails.postcropping.postyearcroptwoyearone);
    const [cropTwoYear1List, setCropTwoYear1List] = React.useState([]);
    const [cropThreeYear1, setCropThreeYear1] = ((selFieldPost.field.fielddetails.postcropping.postyearcropthreeyearone == '') || (selFieldPost.field.fielddetails.postcropping.postyearcropthreeyearone != parentchildpostcropthreeyearone)) ? React.useState(parentchildpostcropthreeyearone) : React.useState(selFieldPost.field.fielddetails.postcropping.postyearcropthreeyearone);
    const [cropThreeYear1List, setCropThreeYear1List] = React.useState([]);
    const handleChangeYear1 = (event) => {
        setCropOneYear1(event.target.value);
        parentpostcropOneYearOneChange(event.target.value);
    };
    const handleChangeTwoYear1 = (event) => {
        setCropTwoYear1(event.target.value);
        parentpostcropTwoYearOneChange(event.target.value);
       
    };
    const handleChangeThreeYear1 = (event) => {
        setCropThreeYear1(event.target.value);
        parentpostcropThreeYearOneChange(event.target.value);
       
    };
    const [cropOneYear2, setCropOneYear2] = ((selFieldPost.field.fielddetails.postcropping.postyearcroponeyeartwo == '') || (selFieldPost.field.fielddetails.postcropping.postyearcroponeyeartwo != parentchildpostcroponeyeartwo)) ? React.useState(parentchildpostcroponeyeartwo) : React.useState(selFieldPost.field.fielddetails.postcropping.postyearcroponeyeartwo);
    const [cropOneYear2List, setCropOneYear2List] = React.useState([]);
    const [cropTwoYear2, setCropTwoYear2] = ((selFieldPost.field.fielddetails.postcropping.postyearcroptwoyeartwo == '') || (selFieldPost.field.fielddetails.postcropping.postyearcroptwoyeartwo != parentchildpostcroptwoyeartwo)) ? React.useState(parentchildpostcroptwoyeartwo) : React.useState(selFieldPost.field.fielddetails.postcropping.postyearcroptwoyeartwo);
    const [cropTwoYear2List, setCropTwoYear2List] = React.useState([]);
    const [cropThreeYear2, setCropThreeYear2] = ((selFieldPost.field.fielddetails.postcropping.postyearcropthreeyeartwo == '') || (selFieldPost.field.fielddetails.postcropping.postyearcropthreeyeartwo != parentchildpostcropthreeyeartwo)) ? React.useState(parentchildpostcropthreeyeartwo) : React.useState(selFieldPost.field.fielddetails.postcropping.postyearcropthreeyeartwo);
    const [cropThreeYear2List, setCropThreeYear2List] = React.useState([]);
    const handleChangeYear2 = (event) => {
        setCropOneYear2(event.target.value);
        parentpostcropOneYearTwoChange(event.target.value);
    };
    const handleChangeTwoYear2 = (event) => {
        setCropTwoYear2(event.target.value);
        parentpostcropTwoYearTwoChange(event.target.value);
    };
    const handleChangeThreeYear2 = (event) => {
        setCropThreeYear2(event.target.value);
        parentpostcropThreeYearTwoChange(event.target.value);
    };

    const [cropOneYear3, setCropOneYear3] = ((selFieldPost.field.fielddetails.postcropping.postyearcroponeyearthree == '') || (selFieldPost.field.fielddetails.postcropping.postyearcroponeyearthree != parentchildpostcroponeyearthree)) ? React.useState(parentchildpostcroponeyearthree) : React.useState(selFieldPost.field.fielddetails.postcropping.postyearcroponeyearthree);
    const [cropOneYear3List, setCropOneYear3List] = React.useState([]);
    const [cropTwoYear3, setCropTwoYear3] = ((selFieldPost.field.fielddetails.postcropping.postyearcroptwoyearthree == '') || (selFieldPost.field.fielddetails.postcropping.postyearcroptwoyearthree != parentchildpostcroptwoyearthree)) ? React.useState(parentchildpostcroptwoyearthree) : React.useState(selFieldPost.field.fielddetails.postcropping.postyearcroptwoyearthree);
    const [cropTwoYear3List, setCropTwoYear3List] = React.useState([]);
    const [cropThreeYear3, setCropThreeYear3] = ((selFieldPost.field.fielddetails.postcropping.postyearcropthreeyearthree == '') || (selFieldPost.field.fielddetails.postcropping.postyearcropthreeyearthree != parentchildpostcropthreeyearthree)) ? React.useState(parentchildpostcropthreeyearthree) : React.useState(selFieldPost.field.fielddetails.postcropping.postyearcropthreeyearthree);
    const [cropThreeYear3List, setCropThreeYear3List] = React.useState([]);
    const handleChangeYear3 = (event) => {
        setCropOneYear3(event.target.value);
        parentpostcropOneYearThreeChange(event.target.value);
    };
    const handleChangeTwoYear3 = (event) => {
        setCropTwoYear3(event.target.value);
        parentpostcropTwoYearThreeChange(event.target.value);
    };
    const handleChangeThreeYear3 = (event) => {
        setCropThreeYear3(event.target.value);
        parentpostcropThreeYearThreeChange(event.target.value);
    };
    const copyPostCropVerticalChange = (value1, value2, value3) => {
        var prevalue1 = parentchildprecroponeyearone;
        var prevalue2 =parentchildprecroptwoyearone; 
        var prevalue3 = parentchildprecropthreeyearone;

        parentcropVerticalChange(prevalue1, prevalue2,prevalue3,value1, value2, value3);
    };
    const copyPostCrop1Vertical = () => {

        document.getElementById("firstCropYear2").value = cropOneYear1;
        setCropOneYear2(cropOneYear1);
        document.getElementById("firstCropYear3").value = cropOneYear1;
        setCropOneYear3(cropOneYear1);
        parentCopyPostCrop1VerticalChange(cropOneYear1);
    };
    const copyPostCrop2Vertical = () => {

      
        document.getElementById("secondCropYear2").value = cropTwoYear1;
        setCropTwoYear2(cropTwoYear1);
        document.getElementById("secondCropYear3").value = cropTwoYear1;
        setCropTwoYear3(cropTwoYear1);
        parentCopyPostCrop2VerticalChange(cropTwoYear1);
    };
    const copyPostCrop3Vertical = () => {

       
        document.getElementById("thirdCropYear2").value = cropThreeYear1;
        setCropThreeYear2(cropThreeYear1);
        document.getElementById("thirdCropYear3").value = cropThreeYear1;
        setCropThreeYear3(cropThreeYear1);
        parentCopyPostCrop3VerticalChange(cropThreeYear1);
    };
    useEffect(() => {
        fetch(CMBYN.FETCH_CROPLOOKUP)
            .then(response => response.json())
            .then(results => {
                var list = [];
                for (var u = 0; u < results.length; u++) {
                    let usr = results[u];
                    if (usr) {
                        let option = {};
                        option.name = usr.name;
                        option.id = usr.id;
                        list.push(option);
                    }
                }
                setCropOneYear1List(list);
                setCropTwoYear1List(list);
                setCropThreeYear1List(list);
                setCropOneYear2List(list);
                setCropTwoYear2List(list);
                setCropThreeYear2List(list);
                setCropOneYear3List(list);
                setCropTwoYear3List(list);
                setCropThreeYear3List(list);
            })
    }, []);
    return (
        <div style={{ marginTop: 10 }}>
            
            
                <GridContainer direction="row" justifyContent="flex-start" alignItems="flex-start">
               
                    <GridItem xs={12} sm={12} md={12}>

                        <div style={{ marginTop: "10px" }}>
                        Enter your anticipated crop rotation for the three years following your proposed irrigation system enhancement.
                    </div>
                    <div style={{ marginTop: "10px" }}>
                        If you are growing more than one crop in a growing season, please select a crop for Crop 2 and/or Crop 3. If growing a perennial or continuous crop, use <i>copy crop to all years</i>, to copy to every year.
                    </div>
                        <div style={{ marginTop: "10px" }}>
                        You must have at least one crop selected for each year. If the field was fallow, please select <i>Fallow</i>.
                        </div>
                    </GridItem>
                
                <GridItem xs={12} sm={12} md={12}>
                    <div>
                    <div style={{ marginTop: "10px" ,float:"left"}}>
                        <InputLabel variant="standard" htmlFor="uncontrolled-native" color="primary">
                           Year 1:
                        </InputLabel>
                    </div>
                        <div style={{ marginTop: "10px" ,width: "200px", float: "left" }}>
                        <InputLabel htmlFor="Cropone">Crop 1</InputLabel>
                        <NativeSelect
                            id="firstCrop"
                            value={cropOneYear1 }
                            onChange={handleChangeYear1}
                            input={<BootstrapInput />}
                        >
                                {cropOneYear1List.map((cr) => (
                                    <option value={cr.id}> {cr.name}
                                    </option >

                                ))}
                        </NativeSelect>
                    </div>
                        <div style={{ marginTop: "10px" , width: "200px", float: "left" }}>
                        <InputLabel htmlFor="Croptwo">Crop 2 (if any)</InputLabel>
                        <NativeSelect
                            id="secondCrop"
                            value={cropTwoYear1 }
                            onChange={handleChangeTwoYear1}
                            input={<BootstrapInput />}
                        >
                                {cropTwoYear1List.map((cr) => (
                                    <option value={cr.id}> {cr.name}
                                    </option >

                                ))}
                        </NativeSelect>
                    </div>
                        <div style={{ marginTop: "10px" ,width: "200px", float: "left" }}>
                        <InputLabel htmlFor="CropThree">Crop 3 (if any)</InputLabel>
                        <NativeSelect
                            id="thirdCrop"
                            value={cropThreeYear1 }
                            onChange={handleChangeThreeYear1}
                            input={<BootstrapInput />}
                        >
                                {cropThreeYear1List.map((cr) => (
                                    <option value={cr.id}> {cr.name}
                                    </option >

                                ))}
                        </NativeSelect>
                        </div>
                    </div>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                    <div style={{ width: "60%" }} >
                        <GridContainer flexDirection="row" justifyContent="space-around" alignItems="center">
                            <GridItem xs={12} sm={12} md={12}>
                                <div  style={{ marginTop: "10px", float: "left", marginLeft: "60px" }}>
                                    {/*<IconButton id="copyPostCrop1V">*/}
                                    {/*    <FileCopyIcon />*/}
                                    {/*    <div style={{ fontSize: "small" }} >Copy</div>*/}
                                    {/*</IconButton>*/}
                                    <Link
                                        id="copyPostCrop1V"
                                        component="button"
                                        variant="body2"
                                        onClick={() => { copyPostCrop1Vertical(); }}
                                        style={{ fontSize: "small" }}
                                    >
                                        <a title="Click to Copy Crop 1 to Year 2 and Year 3">Copy crop to all years</a>
                                    </Link>
                                </div>
                                <div style={{ marginTop: "10px", float: "left", marginLeft: "80px" }}>
                                    {/*<IconButton id="copyPostCrop2V">*/}
                                    {/*    <FileCopyIcon />*/}
                                    {/*    <div style={{ fontSize: "small" }} >Copy</div>*/}
                                    {/*</IconButton>*/}
                                    <Link
                                        id="copyPostCrop2V"
                                        component="button"
                                        variant="body2"
                                        onClick={() => { copyPostCrop2Vertical(); }}
                                        style={{ fontSize: "small" }}
                                    >
                                        <a title="Click to Copy Crop 2 to Year 2 and Year 3">Copy crop to all years</a>
                                    </Link>
                                </div>
                                <div  style={{ marginTop: "10px", textAlign: "left", margin: "0 auto !important", display: "inline-block", marginLeft: "70px" }}>
                                    {/*<IconButton id="copyPostCrop3V">*/}
                                    {/*    <FileCopyIcon />*/}
                                    {/*    <div style={{ fontSize: "small" }} >Copy</div>*/}
                                    {/*</IconButton>*/}
                                    <Link
                                        id="copyPostCrop3V"
                                        component="button"
                                        variant="body2"
                                        onClick={() => { copyPostCrop3Vertical(); }}
                                        style={{ fontSize: "small" }}
                                    >
                                        <a title="Click to Copy Crop 3 to Year 2 and Year 3">Copy crop to all years</a>
                                    </Link>
                                </div>
                            </GridItem>
                        </GridContainer>
                    </div>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                    <div id="postCropRow2" style={{marginTop: "30px"}}>
                        <div style={{ marginRight: "5px", float: "left" }}>
                            <InputLabel variant="standard" htmlFor="uncontrolled-native" color="primary">
                                Year 2:
                            </InputLabel>
                        </div>
                        <div style={{ width: "200px", float: "left" }}>
                            <InputLabel htmlFor="Croponeyear2">Crop 1</InputLabel>
                            <NativeSelect
                                id="firstCropYear2"
                                value={cropOneYear2}
                                onChange={handleChangeYear2}
                                input={<BootstrapInput />}
                            >
                                {cropOneYear2List.map((cr) => (
                                    <option value={cr.id}> {cr.name}
                                    </option >

                                ))}
                            </NativeSelect>
                        </div>
                        <div style={{ width: "200px", float: "left" }}>
                            <InputLabel htmlFor="Croptwoyear2">Crop 2 (if any)</InputLabel>
                            <NativeSelect
                                id="secondCropYear2"
                                value={cropTwoYear2}
                                onChange={handleChangeTwoYear2}
                                input={<BootstrapInput />}
                            >
                                {cropTwoYear2List.map((cr) => (
                                    <option value={cr.id}> {cr.name}
                                    </option >

                                ))}
                            </NativeSelect>
                        </div>
                        <div style={{ width: "200px", float: "left" }}>
                            <InputLabel htmlFor="CropThreeyear2">Crop 3 (if any)</InputLabel>
                            <NativeSelect
                                id="thirdCropYear2"
                                value={cropThreeYear2}
                                onChange={handleChangeThreeYear2}
                                input={<BootstrapInput />}
                            >
                                {cropThreeYear2List.map((cr) => (
                                    <option value={cr.id}> {cr.name}
                                    </option >

                                ))}
                            </NativeSelect>
                        </div>
                    </div>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                    <div id="postCropRow3" style={{marginTop: "30px"}}>
                        <div style={{ marginRight: "5px", float: "left" }}>
                            <InputLabel variant="standard" htmlFor="uncontrolled-native" color="primary">
                                Year 3:
                            </InputLabel>
                        </div>
                        <div style={{ width: "200px", float: "left" }}>
                            <InputLabel htmlFor="Croponeyear3">Crop 1</InputLabel>
                            <NativeSelect
                                id="firstCropYear3"
                                value={cropOneYear3}
                                onChange={handleChangeYear3}
                                input={<BootstrapInput />}
                            >
                                {cropOneYear3List.map((cr) => (
                                    <option value={cr.id}> {cr.name}
                                    </option >

                                ))}
                            </NativeSelect>
                        </div>
                        <div style={{ width: "200px", float: "left" }}>
                            <InputLabel htmlFor="Croptwoyear3">Crop 2 (if any)</InputLabel>
                            <NativeSelect
                                id="secondCropYear3"
                                value={cropTwoYear3}
                                onChange={handleChangeTwoYear3}
                                input={<BootstrapInput />}
                            >
                                {cropTwoYear3List.map((cr) => (
                                    <option value={cr.id}> {cr.name}
                                    </option >

                                ))}
                            </NativeSelect>
                        </div>
                        <div style={{ width: "200px", float: "left" }}>
                            <InputLabel htmlFor="CropThreeyear3">Crop 3 (if any)</InputLabel>
                            <NativeSelect
                                id="thirdCropYear3"
                                value={cropThreeYear3}
                                onChange={handleChangeThreeYear3}
                                input={<BootstrapInput />}
                            >
                                {cropThreeYear3List.map((cr) => (
                                    <option value={cr.id}> {cr.name}
                                    </option >

                                ))}
                            </NativeSelect>
                        </div>
                    </div>
                </GridItem>
            </GridContainer>
        </div>
    );
}
