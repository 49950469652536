export const BACKEND = process.env.REACT_APP_MAIN_API_URL;
// export const BACKEND = process.env.REACT_APP_MAIN_URL;

export const PROJECT_UPDATE = `${BACKEND}project/update`;
export const RUN_CALCULATIONS = `${BACKEND}user/calculations`;

export const FETCH_COMBINED_LOOKUPS = `${BACKEND}user/GetCombinedLookups`;
export const FETCH_CROPLOOKUP = `${BACKEND}user/GetCropLookups`;
export const FETCH_PREIRRTYPES = `${BACKEND}user/GetPreIrrTypes`;
export const FETCH_POSTIRRTYPES = `${BACKEND}user/GetPostIrrTypes`;

export const FETCH_PREFUELTYPES = `${BACKEND}user/GetPreFuelTypes`;
export const FETCH_POSTFUELTYPES = `${BACKEND}user/GetPostFuelTypes`;
export const FETCH_VFDTYPES = `${BACKEND}user/GetVFDTypes`;


export const DOWNLOAD_REPORT = `${BACKEND}user/report`;

export const FETCH_APN = 'https://gis.water.ca.gov/arcgis/rest/services/Planning/i15_Parcels_Assessor_Lightbox/MapServer/identify';
export const FETCH_SENATE_DISTRICTS = 'https://services3.arcgis.com/fdvHcZVgB2QSRNkL/arcgis/rest/services/Legislative/FeatureServer/1/query';
export const FETCH_ASSEMBLY_DISTRICTS = 'https://services3.arcgis.com/fdvHcZVgB2QSRNkL/arcgis/rest/services/Legislative/FeatureServer/0/query';
export const FETCH_CENSUS_TRACTS = 'https://gis.water.ca.gov/arcgis/rest/services/Society/i16_Census_Tract_EconomicallyDistressedAreas_2016/FeatureServer/0/query';