import React from "react";
import Box from "@material-ui/core/Box";
import { styled } from '@material-ui/core/styles';
import { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import GridItem from "../../../components/Grid/GridItem.js";
import GridContainer from "../../../components/Grid/GridContainer.js";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Select, { SelectChangeEvent } from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import NativeSelect from '@material-ui/core/NativeSelect';
import InputBase from '@material-ui/core/InputBase';
import * as CMBYN from "../../../constants/api";
import OutlinedInput from '@material-ui/core/OutlinedInput';
import KWhWarning from "./KWhWarning.js";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
    'label + &': {
        marginTop: theme.spacing(3),
        width: '90%',
    },
    '& .MuiInputBase-input': {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #ced4da',
        fontSize: 16,

        padding: '10px 26px 10px 12px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:focus': {
            borderRadius: 4,
            borderColor: '#80bdff',
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        },
    },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};
const styles = {
  card: {
    marginLeft: "10%",
    boxShadow: "none",
    marginTop: "unset",
  },

  preprojpumplabel: {
    width: "100px",
    marginTop: "15px",
  },
  preprojpumpAddIcon: {
    float: "left",
    padding: "unset",
    },
    paper: { minWidth: "300px" },
};

const useStyles = makeStyles(styles);
export default function EditNewPostPump(props ) {
    const { editFieldsArrPost, PostProjectPumpEdit, parentEditPostPumpNameChange, parentEditPostFieldsListeChange, parentEditPostHpChange,
        parentEditPostHoursChange, parentEditPostEfficiencyChange, parentEditPostSystemPressureChange, parentEditPostPumpPressureChange, parentEditPostPumpDepthChange, parentEditPostDischargePressureChange,
        parentEditPostFrictionLossChange, parentEditPostVFDChange,
        parentEditPostFuelTypeChange, parentEditPostFuelUseChange, parentEditPostRenewEnrgySelChange, parentEditPostRenewEnrgyCapctyChange } = props
    
   
    const classes = useStyles();
   
    const [postPumpNameEdit, setPostPumpNameEdit] = React.useState(PostProjectPumpEdit.postprojectpump.postprojpumpName);
    const [postPumpfieldsEdit, setPostPumpfieldsEdit] = React.useState(PostProjectPumpEdit.postprojectpump.postprojfields);
    const [postPumpEffEdit, setPostPumpEffEdit] = React.useState(PostProjectPumpEdit.postprojectpump.postprojeff);
    const [postPumpHPEdit, setPostPumpHPEdit] = React.useState(PostProjectPumpEdit.postprojectpump.postprojhp);
    const [postPumpHoursEdit, setPostPumpHoursEdit] = React.useState(PostProjectPumpEdit.postprojectpump.postprojhours);
    const [postPumpVFDEdit, setPostPumpVFDEdit] = React.useState(PostProjectPumpEdit.postprojectpump.postprojvfd);
    const [postPumpVFDEditList, setPostPumpVFDEditList] = React.useState([]);
    const [postPumpFuelUseEdit, setPostPumpFuelUseEdit] = React.useState(PostProjectPumpEdit.postprojectpump.postprojfueluse);
    const [postPumpSystempressureEdit, setPostPumpSystempressureEdit] = React.useState(PostProjectPumpEdit.postprojectpump.postprojsyspress);

    const [postpumppressEdit, setPostPumpPressEdit] = React.useState(PostProjectPumpEdit.postprojectpump.postprojpumpress);
    const [postpumpdepthEdit, setPostPumpDepthEdit] = React.useState(PostProjectPumpEdit.postprojectpump.postprojpumpdepth);
    const [postdispressEdit, setPostdispressEdit] = React.useState(PostProjectPumpEdit.postprojectpump.postprojdispress);
    const [postfriclossEdit, setPostfriclossEdit] = React.useState(PostProjectPumpEdit.postprojectpump.postprojfricloss);

    const [postPumpFuelType, setPostPumpFuelType] = React.useState(PostProjectPumpEdit.postprojectpump.postprojfueltype);
    const [postPumpFuelTypeList, setPostPumpFuelTypeList] = React.useState([]);
    const [postPumpRenewenergySelEdit, setPostPumpRenewenergySelEdit] = React.useState(PostProjectPumpEdit.postprojectpump.postprojrenewenrgysel);
    const [postPumpRenewenergyCapctyEdit, setPostPumpRenewenergyCapctyEdit] = React.useState(PostProjectPumpEdit.postprojectpump.postprojrenewenrgycapcty);
  
   
    //useEffect(() => {

    //    showHideRenewEnrgyCapactyDiv();
    //}, [postPumpRenewenergySelEdit]);
    //useEffect(() => {

    //    showSysPressPostDiv();
    //}, [postPumpSystempressureEdit])
    useEffect(() => {

        showAnnualFuelUseDiv();
    }, [])
    
    const handlePostPumpEditFieldsChange = (event) => {
      
        var value = event.target.value;
        setPostPumpfieldsEdit(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value);
        parentEditPostFieldsListeChange(value);
    };

    const handlePumpChange = (event) => {
        setPumpType(event.target.value);
       
    };
    const handlePostPumpHP = (event) => {
        setPostPumpHPEdit(event.target.value);
        //setPumpInputs({ ...pumpinputs, prehp: event.target.value });
        parentEditPostHpChange(event.target.value);
    };
    const handlePostPumpHoursEdit= (event) => {
        setPostPumpHoursEdit(event.target.value);
        //setPumpInputs({ ...pumpinputs, prehours: event.target.value });
        parentEditPostHoursChange(event.target.value);

    };
    const handlePostPumpEffEdit = (event) => {
        setPostPumpEffEdit(event.target.value);
        //setPumpInputs({ ...pumpinputs, preeff: event.target.value });
        parentEditPostEfficiencyChange(event.target.value);

    };
    const handlePostPumpFuelUseEdit = (event) => {
        setPostPumpFuelUseEdit(event.target.value);
        //setPumpInputs({ ...pumpinputs, prefueluse: event.target.value });
        parentEditPostFuelUseChange(event.target.value);

    };

    const handlePostPumpVFDEdit = (event) => {
        setPostPumpVFDEdit(event.target.value);
        //setPumpInputs({ ...pumpinputs, prevfd: event.target.value });
        parentEditPostVFDChange(event.target.value);

    };
   
    const handlePostPumpSysPressEdit
        = (event) => {
            setPostPumpSystempressureEdit(event.target.value);
            //setPumpInputs({ ...pumpinputs, presyspress: event.target.value });

            if (postPumpSystempressureEdit == 1) {
                if (document.getElementById("syspressitemspost") != null) {
                    document.getElementById("syspressitemspost").style.display = "none";
                }
                if (document.getElementById("syspressinputpost") != null) {
                    document.getElementById("syspressinputpost").style.display = "block";
                }
            } else if (postPumpSystempressureEdit == 2) {
                if (document.getElementById("syspressitemspost") != null) {
                    document.getElementById("syspressitemspost").style.display = "block";
                }
                if (document.getElementById("syspressinputpost") != null) {
                    document.getElementById("syspressinputpost").style.display = "none";
                }
            }
            parentEditPostSystemPressureChange(event.target.value);
        };

    const handlePostPumpPressEdit
        = (event) => {
            setPostPumpPressEdit(event.target.value);

            parentEditPostPumpPressureChange(event.target.value);
        };
    const handlePostPumpDepthEdit
        = (event) => {
            setPostPumpDepthEdit(event.target.value);
            parentEditPostPumpDepthChange(event.target.value);
        };
    const handlePostDisPressEdit
        = (event) => {
            setPostdispressEdit(event.target.value);
            parentEditPostDischargePressureChange(event.target.value);
        };
    const handlePostFricLossEdit
        = (event) => {
            setPostfriclossEdit(event.target.value);
            parentEditPostFrictionLossChange(event.target.value);
        };
    
    const handlePostPumpEditNameChange = event => {
        setPostPumpNameEdit(event.target.value);
        
       
        //setPumpInputs({ ...pumpinputs, pumpName: event.target.value });
        parentEditPostPumpNameChange(event.target.value)
    };

    const handlePostPumpFuelTypeEdit = event => {
        

        setPostPumpFuelType(event.target.value);
        //setPumpInputs({ ...pumpinputs, prefueltype: event.target.value });

        parentEditPostFuelTypeChange(event.target.value);
        if (event.target.value === "155" || event.target.value === "156" || event.target.value === "157") {
            document.getElementById("annualfueluseedit").style.display = "none";
        }
        else {
            document.getElementById("annualfueluseedit").style.display = "block";
        }
    };

    const handlePostPumpRenewEnrgySelEdit = event => {
   
        setPostPumpRenewenergySelEdit(event.target.value);
        if (postPumpRenewenergySelEdit === "0") {

            document.getElementById("postpumprenewenrgycapctyEditDiv").style.display = "block";

        }
        else {
            document.getElementById("postpumprenewenrgycapctyEditDiv").style.display = "none";
        }
        parentEditPostRenewEnrgySelChange(event.target.value);

    };
    const handlePostPumpRenewEnrgyCapctyEdit = event => {

     
        setPostPumpRenewenergyCapctyEdit(event.target.value);
        parentEditPostRenewEnrgyCapctyChange(event.target.value);

    };
    const showAnnualFuelUseDiv = event => {
       
        if (PostProjectPumpEdit.postprojectpump.postprojisprepump == 'N') {
            if (PostProjectPumpEdit.postprojectpump.postprojfueltype != 155 && PostProjectPumpEdit.postprojectpump.postprojfueltype != 156 && PostProjectPumpEdit.postprojectpump.postprojfueltype != 157) {
                document.getElementById("annualfueluseedit").style.display = "block";
            }
            else {
                document.getElementById("annualfueluseedit").style.display = "none";
            }

        }
        else {
            document.getElementById("annualfueluseedit").style.display = "none";
        }
    };
    const showHideRenewEnrgyCapactyDiv = event => {
       
        if (postPumpRenewenergySelEdit === "0") {

            document.getElementById("postpumprenewenrgycapctyEditDiv").style.display = "block";

        }
        else {
            document.getElementById("postpumprenewenrgycapctyEditDiv").style.display = "none";
        }
    };

    const showSysPressPostDiv = event => {

        
        if (postPumpSystempressureEdit == 1) {
                if (document.getElementById("syspressitemspost") != null)
                    {
                    document.getElementById("syspressitemspost").style.display = "none";
                }
                if (document.getElementById("syspressinputpost") != null) {
                    document.getElementById("syspressinputpost").style.display = "block";
                }
        } else if (postPumpSystempressureEdit == 2) {
                if (document.getElementById("syspressitemspost") != null) {
                    document.getElementById("syspressitemspost").style.display = "block";
                }
                if (document.getElementById("syspressinputpost") != null) {
                    document.getElementById("syspressinputpost").style.display = "none";
                }
            }
        
    };
    useEffect(() => {
        fetch(CMBYN.FETCH_POSTFUELTYPES)
            .then(response => response.json())
            .then(results => {
                var list = [];
                for (var u = 0; u < results.length; u++) {
                    let usr = results[u];
                    if (usr) {
                        let option = {};
                        option.name = usr.name;
                        option.id = usr.id;
                        list.push(option);
                    }
                }
                setPostPumpFuelTypeList(list);

            })
    }, []);
    useEffect(() => {
        fetch(CMBYN.FETCH_VFDTYPES)
            .then(response => response.json())
            .then(results => {
                var list = [];
                for (var u = 0; u < results.length; u++) {
                    let usr = results[u];
                    if (usr) {

                        let option = {};
                        option.name = usr.vfdType.name;
                        option.id = usr.vfdType.id;
                        list.push(option);
                    }
                }



                setPostPumpVFDEditList(list);
            })
    }, []);
    function handleSubmit(event) {
       
        event.preventDefault();
        // console.log('pumpname:', textInput);
        // console.log('age:', age);
        // console.log("is this the one?");
        // console.log(pumpinputs);
        // console.log("is this the one?");
        getFormValues(pumpinputs);
        // You should see email and password in console.
        // ..code to submit form to backend here...

    }
    return (
        <div style={{ marginTop: 10 }}>
      <GridContainer
                direction="column"
        justifyContent="flex-start"
        alignItems="flex-start"
      >
                      
    
                          <GridItem xs={11} sm={11} md={11}>
                    <div style={{ marginTop: "10px", width: "max-content" }}>
                                <InputLabel htmlFor="postpumpnameeditlabel">Name of the pump :</InputLabel>
                           </div>
                        </GridItem>

                          <GridItem xs={11} sm={11} md={11}>
                    <div style={{ marginTop: "10px", width: "max-content" }}>
                                <TextField

                                    id="postpumpnameeditid"
                                    label=""
                            defaultValue={postPumpNameEdit }
                                    variant="outlined"
                                color="primary"
                            onChange={handlePostPumpEditNameChange}
                                        
                                />
                                </div>
                            
                        </GridItem>
                    </GridContainer>

                    <GridContainer
                     
                        direction="column"
                        justifyContent="flex-start"
                        alignItems="flex-start">

                          <GridItem xs={11} sm={11} md={11}>

                    <div style={{ marginTop: "10px", width: "max-content" }}>
                                <InputLabel id="demo-simple-select-standard-label">Which fields does this pump service :</InputLabel>
                                </div>
                        </GridItem>
                          <GridItem xs={11} sm={11} md={11}>
                    <div style={{ marginTop: "10px", width: "max-content" }}>
                        <Select
                            labelId="demo-multiple-name-label"
                            id="demo-multiple-name"
                            multiple
                            value={postPumpfieldsEdit}
                            onChange={handlePostPumpEditFieldsChange}
                            input={<OutlinedInput label="Fields" />}
                            MenuProps={MenuProps}
                        >
                            {editFieldsArrPost.map((field) => (
                                <MenuItem
                                    key={field.name}
                                    value={field.name}

                                >
                                    {field.name}
                                </MenuItem>
                            ))}
                        </Select>
                                </div>
                           {/* </FormControl>*/}

                        </GridItem>
                 </GridContainer>
                    <GridContainer
                        direction="column"
                     
                        justifyContent="flex-start"
                        alignItems="flex-start">
                          <GridItem xs={11} sm={11} md={11}>
                    <div style={{ marginTop: "10px", width: "max-content" }}>
                                <InputLabel htmlFor="hppostpumpeditlabel">Expected Motor Rated Horsepower :</InputLabel>
                            </div>
                        </GridItem>

                          <GridItem xs={11} sm={11} md={11}>
                    <div style={{ marginTop: "10px", width: "max-content" }}>
                                <TextField

                                    id="fieldhppostpumpedit"
                                    label=""
                            defaultValue={postPumpHPEdit}
                                    variant="outlined"
                                        color="primary"
                            onChange={handlePostPumpHP}
                            type="number"
                                />
                            </div>
                        </GridItem>
                    </GridContainer>
              
                    <GridContainer
                        direction="column"
                        justifyContent="flex-start"
                        alignItems="flex-start">
                          <GridItem xs={11} sm={11} md={11}>
                    <div style={{ marginTop: "10px", width: "max-content" }}>
                                <InputLabel htmlFor="postpumpeffeditlabel">Expected Overall Pumping Efficiency (%) :</InputLabel>
                            </div>
                        </GridItem>

                          <GridItem xs={11} sm={11} md={11}>
                    <div style={{ marginTop: "10px", width: "max-content" }}>
                                <TextField

                            id="fieldpostpumpeffedit"
                            defaultValue={postPumpEffEdit}
                                    variant="outlined"
                                        color="primary"
                            onChange={handlePostPumpEffEdit}
                            type="number"
                                />
                            </div>
                        </GridItem>
                    </GridContainer>
                   
            <GridContainer
                direction="column"
                justifyContent="flex-start"
                alignItems="flex-start">

                <GridItem xs={11} sm={11} md={11}>
                    <div id="syspressitemspost" style={{ marginTop: "10px", width: "max-content"}}>
                        <GridContainer
                            direction="column"
                            justifyContent="flex-start"
                            alignItems="flex-start">
                            <GridItem xs={11} sm={11} md={11}>
                                <div style={{ marginTop: "10px", width: "max-content" }}>
                                    <InputLabel htmlFor="pumpdepthpost">Enter the pumping depth (ft): </InputLabel>
                                </div>
                            </GridItem>

                            <GridItem xs={11} sm={11} md={11}>
                                <div style={{ marginTop: "10px", width: "max-content" }}>
                                    <TextField

                                        id="fieldumpingdepthpost"
                                        label=""
                                        defaultValue={postpumpdepthEdit}
                                        variant="outlined"
                                        color="primary"
                                        onChange={handlePostPumpDepthEdit}
                                        type="number"
                                    />
                                </div>
                            </GridItem>
                        </GridContainer>
                        <GridContainer
                            direction="column"
                            justifyContent="flex-start"
                            alignItems="flex-start">

                            <GridItem xs={11} sm={11} md={11}>
                                <div style={{ marginTop: "10px", width: "max-content" }}>
                                    <InputLabel htmlFor="dischargepresspost">Enter the discharge Pressure (ft) (if only psi is known please multiply by 2.31 to convert to feet):</InputLabel>
                                </div>
                            </GridItem>

                            <GridItem xs={11} sm={11} md={11}>
                                <div style={{ marginTop: "10px", width: "max-content" }}>
                                    <TextField

                                        id="fielddischargepressurepost"
                                        label=""
                                        defaultValue={postdispressEdit}
                                        variant="outlined"
                                        color="primary"
                                        onChange={handlePostDisPressEdit}
                                        type="number"
                                    />
                                </div>
                            </GridItem>
                        </GridContainer>
                        <GridContainer
                            direction="column"
                            justifyContent="flex-start"
                            alignItems="flex-start">

                            <GridItem xs={11} sm={11} md={11}>
                                <div style={{ marginTop: "10px", width: "max-content" }}>
                                    <InputLabel htmlFor="friclosspost">Enter the friction losses (ft) (friction loss is 5 for a booster and 10 for a well pump): </InputLabel>
                                </div>
                            </GridItem>

                            <GridItem xs={11} sm={11} md={11}>
                                <div style={{ marginTop: "10px", width: "max-content" }}>
                                    <TextField

                                        id="fieldfrictionlosspost"
                                        label=""
                                        defaultValue={postfriclossEdit}
                                        variant="outlined"
                                        color="primary"
                                        onChange={handlePostFricLossEdit}
                                        type="number"
                                    />
                                </div>
                            </GridItem>
                        </GridContainer>
                    </div>
                </GridItem>
            </GridContainer>
                    <GridContainer
                        direction="column"
                        justifyContent="flex-start"
                        alignItems="flex-start">
                          <GridItem xs={11} sm={11} md={11}>
                    <div style={{ marginTop: "10px", width: "max-content" }}>
                        <InputLabel htmlFor="postpumpvfdeditlabel">Are you installing a VFD?</InputLabel>
                            </div>
                      </GridItem>
                          <GridItem xs={11} sm={11} md={11}>
                      
                    <div style={{ marginTop: "10px", width: "max-content" }}>
                        <NativeSelect
                            id="fieldpostpumpvfdedit"
                            value={postPumpVFDEdit}
                            onChange={handlePostPumpVFDEdit}
                            input={<BootstrapInput />}
                        >
                            {postPumpVFDEditList.map((cr) => (
                                <option value={cr.id}> {cr.name}
                                </option >

                            ))}
                        </NativeSelect>
                    </div>
                  </GridItem>
                    </GridContainer>

           
                    <GridContainer
                        direction="column"
                        justifyContent="flex-start"
                        alignItems="flex-start">
                          <GridItem xs={11} sm={11} md={11}>
                    <div style={{ marginTop: "10px", width: "max-content" }}>
                                <InputLabel htmlFor="postpumpfueltypeeditlabel">New Fuel Type :</InputLabel>
                            </div>
                        </GridItem>
                          <GridItem xs={11} sm={11} md={11}>
                    <div style={{ marginTop: "10px", width: "max-content" }}>
                                <NativeSelect
                            id="fieldpostpumpfueltypeedit"
                                    value={postPumpFuelType}
                            onChange={handlePostPumpFuelTypeEdit}
                                    input={<BootstrapInput />}
                                >
                            {postPumpFuelTypeList.map((cr) => (
                                <option value={cr.id}> {cr.name}
                                </option >

                            ))}
                                </NativeSelect>
                            </div>
                        </GridItem>
            </GridContainer>

            <GridContainer direction="column" alignItems="flex-start">
                <GridItem>
                   
                        <div id="annualfueluseedit" style={{ marginTop: "10px", width: "max-content", display: "none" }}>
                        <GridContainer
                            direction="column"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                        >
                            <GridItem xs={11} sm={11} md={11}>
                                <div style={{ marginTop: "10px", width: "max-content" }}>
                                    <InputLabel htmlFor="editpostpumpfueluse">Anticipated Fuel Use (gallons or scf or kWh):</InputLabel>
                                </div>
                            </GridItem>

                            <GridItem xs={11} sm={11} md={11}>
                                <div style={{ marginTop: "10px", width: "max-content" }}>
                                    <TextField
                                        id="fieldeditpostpumpfueluse"
                                        label=""
                                    defaultValue={postPumpFuelUseEdit}
                                        variant="outlined"
                                        color="primary"
                                        onChange={handlePostPumpFuelUseEdit}
                                        type="number"
                                    />
                                </div>
                            </GridItem>
                        </GridContainer>
                   </div>
                </GridItem>
            </GridContainer>
        </div>
  );
}
