import React, { useState } from "react";
import { useSelector } from "react-redux";

// @material-ui/core components
import { CardContent, Collapse, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@material-ui/core";

// custom components
import Card from "../../components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import ExpandCollapseIcon from "../ExpandCollapseIcon.jsx";

import { loadingText } from "helpers/report.js";

export default function FieldDetailsReportTable (props) {
  const { classes } = props;
  const fields = useSelector(state => state.user.fields);

  const [collapsed, setCollapsed] = useState(false);
  
  function toggleCollapse() {
    setCollapsed(!collapsed);
  }

  return (
    <Card id="fieldDetailsReport">
      <CardHeader style={{backgroundColor: '#a8d08d'}} onClick={toggleCollapse}>
        <ExpandCollapseIcon collapsed={collapsed} />
        <Typography align="center" variant="h5">
          SWEEP Project Assessment Tool Field Data 
        </Typography>
      </CardHeader>
      <Collapse in={!collapsed}>
        <CardContent>
          <Table>
            <TableHead className={classes.tableHeader}>
              <TableRow>
                <TableCell>
                  Field
                </TableCell>
                <TableCell>
                  Acres
                </TableCell>
                <TableCell>
                  Latitude
                </TableCell>
                <TableCell>
                  Longitude
                </TableCell>
                <TableCell>
                  Assembly District
                </TableCell>
                <TableCell>
                  Senate District
                </TableCell>
                <TableCell>
                  ET Zone
                </TableCell>
                <TableCell>
                  Soil Type
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {fields.map(field =>
                <TableRow key={'fieldDetailsReportRow_' + field.id}>
                  <TableCell align="center">{field.name}</TableCell>
                  <TableCell align="center">{field.acreage}</TableCell>
                  <TableCell align="center">{field.latLon[0]}</TableCell>
                  <TableCell align="center">{field.latLon[1]}</TableCell>
                  <TableCell align="center">{field.assemblyDistricts ? field.assemblyDistricts.join(", ") : loadingText}</TableCell>
                  <TableCell align="center">{field.senateDistricts ? field.senateDistricts.join(", ") : loadingText}</TableCell>
                  <TableCell align="center">{field.results ? field.results.etZone.name.substring(8) : loadingText}</TableCell>
                  <TableCell align="center">{field.results ? field.results.soilType.name : loadingText}</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </CardContent>
      </Collapse>
    </Card>
  );
}