import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import { ExpandLess, ExpandMore } from '@material-ui/icons';

const styles = {
  expandCollapseIcon: {
    position: 'absolute',
    top: '15px'
  }
};

const useStyles = makeStyles(styles);

export default function ExpandCollapseIcon (props) {
  const { collapsed } = props;

  const classes = useStyles();
  
  return (
    <div className={classes.expandCollapseIcon}>
      {collapsed ? <ExpandMore  /> : <ExpandLess />}
    </div>
  );
}