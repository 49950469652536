import {Circle as CircleStyle, Fill, Stroke, Style, Text} from 'ol/style';


const image = new CircleStyle({
  radius: 5,
  fill: null,
  stroke: new Stroke({color: 'red', width: 1}),
});

const etZoneColors = {
  301: 'rgb(195, 161, 203)',
  302: 'rgb(209, 200, 227)',
  303: 'rgb(196, 205, 231)',
  304: 'rgb(153, 174, 218)',
  305: 'rgb(154, 201, 226)',
  306: 'rgb(173, 219, 221)',
  307: 'rgb(213, 237, 231)',
  308: 'rgb(185, 223, 191)',
  309: 'rgb(170, 198, 181)',
  310: 'rgb(203, 225, 173)',
  311: 'rgb(228, 233, 199)',
  312: 'rgb(255, 251, 205)',
  313: 'rgb(255, 252, 221)',
  314: 'rgb(239, 234, 204)',
  315: 'rgb(239, 212, 177)',
  316: 'rgb(209, 180, 150)',
  317: 'rgb(193, 187, 182)',
  318: 'rgb(228, 177, 159)'
}

const styles = {
  'Point': new Style({
    image: image,
  }),
  'LineString': new Style({
    stroke: new Stroke({
      color: 'green',
      width: 1,
    }),
  }),
  'MultiLineString': new Style({
    stroke: new Stroke({
      color: 'green',
      width: 1,
    }),
  }),
  'MultiPoint': new Style({
    image: image,
  }),
  'MultiPolygon': new Style({
    stroke: new Stroke({
      color: 'blue',
      width: 2,
    }),
    fill: new Fill({
      color: 'rgba(255, 255, 255, 0.1)',
    }),
  }),
  'AltMultipolygon': new Style({
    stroke: new Stroke({
      color: 'black',
      width: 2,
    }),
    fill: new Fill({
      color: 'rgba(255, 255, 255, 0.1)',
    }),
  }),
  'Polygon': new Style({
    stroke: new Stroke({
      color: 'blue',
      lineDash: [4],
      width: 3,
    }),
    fill: new Fill({
      color: 'rgba(0, 0, 255, 0.1)',
    }),
  }),
  'GeometryCollection': new Style({
    stroke: new Stroke({
      color: 'magenta',
      width: 2,
    }),
    fill: new Fill({
      color: 'magenta',
    }),
    image: new CircleStyle({
      radius: 10,
      fill: null,
      stroke: new Stroke({
        color: 'magenta',
      }),
    }),
  }),
  'Circle': new Style({
    stroke: new Stroke({
      color: 'red',
      width: 2,
    }),
    fill: new Fill({
      color: 'rgba(255,0,0,0.2)',
    }),
  }),
};

export const styleFunction = function (feature) {
  return styles[feature.getGeometry().getType()];
};

export const styleFunctionAlt = function (feature) {
  return styles['AltMultipolygon'];
};

export const etZoneStyle = function (feature) {
  return new Style({
    stroke: new Stroke({ color: etZoneColors[feature.get('zoneId')], width: 1}),
    fill: new Fill({ color: etZoneColors[feature.get('zoneId')] }),
    text: new Text({ text: feature.get('zoneName') + '\n' + feature.get('zoneDescription')})
  })
}